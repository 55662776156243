import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const createProjectAlternativeAsyncMutation = gqlFn(/* GraphQL */ `
  mutation CreateProjectAlternativeAsync($input: CreateProjectAlternativeInput!) {
    createProjectAlternativeAsync(input: $input) {
      taskId
    }
  }
`);

export const projectAlternativeCreateTaskQuery = gqlFn(/* GraphQL */ `
  query ProjectAlternativeCreateTask($taskId: ID!) {
    projectAlternativeCreateTask(taskId: $taskId) {
      status
      projectAlternative {
        ...ProjectAlternative
      }
    }
  }
`);

export const updateProjectAlternativeMutation = gqlFn(/* GraphQL */ `
  mutation UpdateProjectAlternative($input: UpdateProjectAlternativeInput!) {
    updateProjectAlternative(input: $input) {
      projectAlternative {
        ...ProjectAlternative
      }
    }
  }
`);

export const projectAlternativesQuery = gqlFn(/* GraphQL */ `
  query ProjectAlternatives($project: ID!, $orderBy: [String!]) {
    projectAlternatives(project: $project, orderBy: $orderBy) {
      edges {
        node {
          ...ProjectAlternative
        }
      }
    }
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProjectAlternativeFragment = gql`
  fragment ProjectAlternative on ProjectAlternativeNode {
    createdAt
    updatedAt
    id
    name
    description
    projectQueryId
    liveScheduleTime
    createdBy {
      ...UserSimple
    }
  }
`;

import { CalendarEntity, PartialEntity } from '@/common/types';
import { pickKeys } from '@/helpers/utils/objects';

import { generateUndoRedoCommitId, UndoRedoActionNames, UndoRedoCommit } from '../undoRedo/types';

export const createUndoRedoCreateCommit = (vars: CalendarEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.CreateCalendars,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteCalendars,
      data: vars,
    },
  };
};

export const createUndoRedoUpdateCommit = (
  vars: PartialEntity<CalendarEntity>[],
  calendars: Map<string, CalendarEntity>,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.UpdateCalendars,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.UpdateCalendars,
      data: vars.map((partialCalendar) => {
        const existingCalendar = calendars.get(partialCalendar.id);
        if (!existingCalendar) {
          throw new Error(`Calendar with id ${partialCalendar.id} not found`);
        }
        return pickKeys(existingCalendar, Object.keys(partialCalendar) as (keyof CalendarEntity)[]);
      }),
    },
  };
};

export const createUndoRedoRestoreCommit = (vars: CalendarEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RestoreCalendars,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteCalendars,
      data: vars,
    },
  };
};

export const createUndoRedoDeleteCommit = (vars: CalendarEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.DeleteCalendars,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.RestoreCalendars,
      data: vars,
    },
  };
};

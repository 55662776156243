import { startSpanManual } from '@sentry/vue';

export type UseTimerOptions = {
  name: string;
  start?: Date;
  callback?: () => void;
};

export function useTimer(options: UseTimerOptions) {
  const startTime = options?.start ?? new Date();
  const name = options?.name;
  startSpanManual({ startTime, name }, (_, finish) => {
    options?.callback?.();
    finish();
  });
}

import { OrderDependencyEntity } from '@/common/types';
import {
  generateRTCMessageId,
  LocalProjectChangeEventTemplate,
} from '@/features/realTimeCollaboration';

import { OperationNames } from '../../../events.schema';
import {
  parseEntityExceptionIntoOperationInputException,
  parseEntityWorkingDayIntoOperationInputWorkingDay,
} from '../calendars/calendarUtils';
import { ProjectChangeEventContext } from '../realTimeCollaboration/types';
import {
  RemoveEntitiesVariables,
  RescheduleEntitiesVariables,
  RestoreEntitiesVariables,
} from './types';

export const createProjectChangeRescheduleNodesEvent = (
  vars: RescheduleEntitiesVariables,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RescheduleScheduleNodes,
      input: {
        orders: vars.orders?.map((order) => ({
          ...order,
          startAt: order.startAt?.toISOString(),
          finishAt: order.finishAt?.toISOString(),
          wbsSectionId: order.wbsSection === null ? null : order.wbsSection?.id,
          workingTimeDuration: order.duration,
        })),
        milestones: vars.milestones?.map((milestone) => ({
          ...milestone,
          date: milestone.date?.toISOString(),
          wbsSectionId: milestone.wbsSection === null ? null : milestone.wbsSection?.id,
        })),
        pauses: vars.pauses?.map((pause) => ({
          ...pause,
          startAt: pause.start?.toISOString(),
          finishAt: pause.end?.toISOString(),
        })),
        calendars: vars.calendars?.map((calendar) => ({
          id: calendar.id,
          ...(calendar.workingDays !== undefined
            ? {
                workingDays: calendar.workingDays.map(
                  parseEntityWorkingDayIntoOperationInputWorkingDay,
                ),
              }
            : {}),
          ...(calendar.exceptions !== undefined
            ? {
                exceptions: calendar.exceptions.map(
                  parseEntityExceptionIntoOperationInputException,
                ),
              }
            : {}),
        })),
      },
      context,
    },
  };
};
export const createProjectChangeRemoveNodesEvent = (
  vars: RemoveEntitiesVariables,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RemoveScheduleNodes,
      input: {
        orders: vars.orders?.map((order) => order.id),
        milestones: vars.milestones?.map((milestone) => milestone.id),
        pauses: vars.pauses?.map((pause) => pause.id),
        calendars: vars.calendars?.map((calendar) => calendar.id),
      },
      context,
    },
  };
};
export const createProjectChangeRestoreNodesEvent = (
  vars: RestoreEntitiesVariables,
  context?: ProjectChangeEventContext,
  adjacentDependenciesToRestore?: OrderDependencyEntity[],
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RestoreScheduleNodes,
      input: {
        orders: vars.orders?.map((order) => order.id),
        milestones: vars.milestones?.map((milestone) => milestone.id),
        pauses: vars.pauses?.map((pause) => pause.id),
        calendars: vars.calendars?.map((calendar) => calendar.id),
      },
      context,
    },
    restoredEntities: {
      orders: vars.orders?.map((order) => order.id),
      milestones: vars.milestones?.map((milestone) => milestone.id),
      pauses: vars.pauses?.map((pause) => pause.id),
      calendars: vars.calendars?.map((calendar) => calendar.id),
      dependencyDetails: adjacentDependenciesToRestore?.map((dependency) => ({
        ...dependency,
        from: { orderId: dependency.from.id, milestoneId: dependency.from.id },
        to: { orderId: dependency.to.id, milestoneId: dependency.to.id },
      })),
    },
  };
};

import { OrderDependencyEntity, PartialEntity } from '@/common/types';
import { pickKeys } from '@/helpers/utils/objects';

import { generateUndoRedoCommitId, UndoRedoActionNames, UndoRedoCommit } from '../undoRedo/types';

export const createUndoRedoCreateCommit = (vars: OrderDependencyEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.CreateOrderDependencies,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteOrderDependencies,
      data: vars,
    },
  };
};

export const createUndoRedoUpdateCommit = (
  vars: PartialEntity<OrderDependencyEntity>[],
  orderDependencies: Map<string, OrderDependencyEntity>,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.UpdateOrderDependencies,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.UpdateOrderDependencies,
      data: vars.map((partialOrderDependency) => {
        const existingOrderDependency = orderDependencies.get(partialOrderDependency.id);
        if (!existingOrderDependency) {
          throw new Error(`Order dependency with id ${partialOrderDependency.id} not found`);
        }
        return pickKeys(
          existingOrderDependency,
          Object.keys(partialOrderDependency) as (keyof OrderDependencyEntity)[],
        );
      }),
    },
  };
};

export const createUndoRedoRestoreCommit = (vars: OrderDependencyEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RestoreOrderDependencies,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteOrderDependencies,
      data: vars,
    },
  };
};

export const createUndoRedoDeleteCommit = (vars: OrderDependencyEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.DeleteOrderDependencies,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.RestoreOrderDependencies,
      data: vars,
    },
  };
};

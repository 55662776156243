import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

export type AnalyticsAuthProvider = 'microsoft' | 'password';

/** User related stuff */
/** Track user signin */
export class SignInEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.USER_SIGN_IN;

  public constructor(
    public properties: {
      username: string;
      provider: AnalyticsAuthProvider;
    },
  ) {
    super();
  }
}

/** Track user signout */
export class SignOutEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.USER_SIGN_OUT;

  public constructor(
    public properties: {
      username: string;
    },
  ) {
    super();
  }
}

/** Track user signup */
export class SignUpEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.USER_SIGN_UP;

  public constructor(
    public properties: {
      username: string;
      firstName: string;
      lastName: string;
      email: string;
      type?: 'invited';
      provider: AnalyticsAuthProvider;
    },
  ) {
    super();
  }
}

export class UserSSOProviderConnectedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.USER_SSO_PROVIDER_CONNECTED;

  public properties = {
    provider: 'microsoft',
  };

  public constructor() {
    super();
  }
}

export class UserProfileEditedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.USER_PROFILE_EDITED;

  public constructor(
    public properties: {
      pictureChanged: boolean;
    },
  ) {
    super();
  }
}

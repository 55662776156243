import { LoggingService } from '@/interfaces/services';
import { ErrorInfo } from '@/interfaces/services/logging';
import { AnalyticsEntity, AnalyticsEvent } from '@/utils/analyticsEvents/categories';

export class ConsoleLoggingService extends LoggingService {
  private logger = console;

  private convertToString(type: string, entity: AnalyticsEntity): string {
    return `[TRACK] ${type} "${entity.identification}"
    ${
      entity.properties
        ? Object.keys(entity.properties)
            .filter((key) => entity.properties![key] !== undefined)
            .reduce(
              (log, info) => `${log}${log ? '\n' : ''}\t${info} = ${entity.properties![info]}`,
              ' with:',
            )
        : ''
    }
    ${
      Object.keys(this.additionalProperties).some((propertyKey) =>
        Boolean(this.additionalProperties[propertyKey]),
      )
        ? Object.keys(this.additionalProperties).reduce(
            (log, propertyKey) =>
              `${log}${log ? '\n' : ''}\t${propertyKey} = ${
                this.additionalProperties[propertyKey]
              }`,
            '\n on:',
          )
        : ''
    }`;
  }

  public error(message: string | Error, { code, contexts }: ErrorInfo): void {
    this.logger.error(message, code, contexts);
  }

  public warn(message: string): void {
    this.logger.warn(message);
  }

  public info(message: string): void {
    this.logger.info(message);
  }

  public async trackEvent(event: AnalyticsEvent): Promise<void> {
    const eventString = this.convertToString('Event', event);

    this.logger.info(eventString);
  }

  public setPage(title: string, companyId?: string, projectId?: string): void {
    const eventString = this.convertToString(`Page change to`, {
      identification: title,
      properties: { companyId, projectId },
    });
    this.logger.info(eventString);
  }

  public setTenantGroup(tenant: AnalyticsEntity): void {
    const tenantString = this.convertToString('Group', tenant);
    this.logger.info(tenantString);
  }

  public setProjectGroup(project: AnalyticsEntity): void {
    const projectString = this.convertToString('Group', project);
    this.logger.info(projectString);
  }

  public async setUser(user: AnalyticsEntity): Promise<void> {
    const userString = this.convertToString('User', user);
    this.logger.info(userString);
  }

  public reset(): void {
    this.logger.info('Purged cached user data');
  }
}

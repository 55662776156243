import gql from 'graphql-tag';
import { defineStore } from 'pinia';

import { gql as gqlFn } from '@/graphql/__generated__';
import {
  CreateTenantTradeMutationVariables,
  DeleteTenantTradeMutationVariables,
  TenantTradeDetailsQueryVariables,
  TenantTradeFragment,
  TenantTradesQueryVariables,
  UpdateTenantTradeMutationVariables,
} from '@/graphql/__generated__/graphql';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection, getOrderByArgument } from '@/repositories/utils/fetchAll';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TENANT_TRADE_FRAGMENT = gql`
  fragment TenantTrade on TenantTradeVariationNode {
    color
    id
    name
    type
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TENANT_TRADE_DETAILS_FRAGMENT = gql`
  fragment TenantTradeDetails on TenantTradeVariationNode {
    ...TenantTrade
    usedIn {
      name
    }
  }
`;

export type TenantTradeStore = ReturnType<typeof useTenantTradeStore>;

export const useTenantTradeStore = defineStore('tenant-trade-store', () => {
  const isInitialized = ref(false);
  const tenantTrades = ref<Map<string, TenantTradeFragment>>(new Map());

  const createTenantTrade = async (
    variables: Omit<CreateTenantTradeMutationVariables['input'], 'trade'>,
  ) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation CreateTenantTrade($input: CreateTenantTradeVariationInput!) {
        createTenantTradeVariation(input: $input) {
          tenantTradeVariation {
            ...TenantTrade
          }
        }
      }
    `);

    return client.mutate({ mutation, variables: { input: variables } }).then((response) => {
      const result = response.data?.createTenantTradeVariation?.tenantTradeVariation ?? null;
      if (!result) return null;
      tenantTrades.value.set(result.id, result);
      return result;
    });
  };
  const updateTenantTrade = async (variables: UpdateTenantTradeMutationVariables['input']) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation UpdateTenantTrade($input: UpdateTradeVariationInput!) {
        updateTradeVariation(input: $input) {
          tradeVariation {
            ...TenantTrade
          }
        }
      }
    `);

    return client.mutate({ mutation, variables: { input: variables } }).then((response) => {
      const result = response.data?.updateTradeVariation?.tradeVariation ?? null;
      if (!result) return null;
      tenantTrades.value.set(result.id, result);
      return result;
    });
  };
  const deleteTenantTrade = async (variables: DeleteTenantTradeMutationVariables['input']) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation DeleteTenantTrade($input: DeleteTradeVariationInput!) {
        deleteTradeVariation(input: $input) {
          success
        }
      }
    `);

    return client.mutate({ mutation, variables: { input: variables } }).then(() => {
      tenantTrades.value.delete(variables.id);
      return null;
    });
  };

  const fetchDetails = async (vars: TenantTradeDetailsQueryVariables) => {
    const client = useApolloClient();

    const query = gqlFn(/* GraphQL */ `
      query TenantTradeDetails($id: ID!) {
        tenantTradeVariation(id: $id) {
          ...TenantTradeDetails
        }
      }
    `);

    return client
      .query({
        query,
        variables: vars,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const tradeVariation = result.data.tenantTradeVariation;
        if (!tradeVariation) return null;
        tenantTrades.value.set(tradeVariation.id, tradeVariation);
        return tradeVariation;
      });
  };

  const fetchAll = async (vars: TenantTradesQueryVariables) => {
    const client = useApolloClient();

    const query = gqlFn(/* GraphQL */ `
      query TenantTrades($tenant: ID!, $orderBy: [String!]) {
        tenantTradeVariations(tenant: $tenant, orderBy: $orderBy, includeProjectTrades: false) {
          edges {
            node {
              ...TenantTrade
            }
          }
        }
      }
    `);

    return client
      .query({
        query,
        variables: {
          ...vars,
          orderBy: getOrderByArgument({ name: 'ASC' }),
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        isInitialized.value = true;
        const flattened = flattenNodeConnection(result.data.tenantTradeVariations);
        tenantTrades.value = new Map(
          flattened.map((tradeVariation) => [tradeVariation.id, tradeVariation]),
        );
        return flattened;
      });
  };

  const setState = (trades: TenantTradeFragment[]) => {
    tenantTrades.value = new Map(trades.map((trade) => [trade.id, trade]));
  };

  const deleteTrade = (tradeId: string) => {
    tenantTrades.value.delete(tradeId);
  };

  return {
    isInitialized,
    tenantTrades,
    createTenantTrade,
    updateTenantTrade,
    deleteTenantTrade,
    fetchDetails,
    fetchAll,
    setState,
    deleteTrade,
  };
});

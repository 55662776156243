import { AnalyticsEntity, AnalyticsEvent } from '@/utils/analyticsEvents/categories';
import { EventCategories } from '@/utils/analyticsEvents/eventCategories';

export enum AdditionalAnalyticsProperties {
  ProjectMembership = 'projectMembership',
}

export interface ErrorInfo {
  code: string;
  contexts?: Record<string, Dictionary>;
}

export interface AdditionalProperties {
  alternativeScheduleId?: string;
}

export abstract class LoggingService {
  public AnalyticEventCategories = EventCategories;

  public additionalProperties: AdditionalProperties = {};

  /** Log event on error level */
  public abstract error(message: string | Error, info: ErrorInfo): void;

  /** Log event on warning level */
  public abstract warn(message: string): void;

  /** Log event on info level */
  public abstract info(message: string): void;

  /** Track specific event with analytic tools */
  public abstract trackEvent(event: AnalyticsEvent): Promise<void>;

  /** Track page change with analytic tools */
  public abstract setPage(title: string, companyId?: string, projectId?: string): void;

  /** Sets the user for the current session for logging */
  public abstract setUser(
    user: AnalyticsEntity<Dictionary & { hasMembership: boolean }>,
  ): Promise<void>;

  /** Sets the tenant for the current session for logging */
  public abstract setTenantGroup(
    group: AnalyticsEntity<Dictionary & { hasMembership: boolean }>,
  ): void;

  /** Sets the project for the current session for logging */
  public abstract setProjectGroup(group: AnalyticsEntity): void;

  /** Resets any cached information about the current user */
  public abstract reset(): void;

  /**
   * Sets optional property for all tracked events.
   * Setting the value to undefined removes the key.
   * @param key
   * @param value
   */
  public setAdditionalProperty(
    key: AdditionalAnalyticsProperties,
    value: string | undefined,
  ): void {
    if (value) this.additionalProperties[key] = value;
    else delete this.additionalProperties[key];
  }

  public setAdditionalProperties(properties: Dictionary): void {
    this.additionalProperties.alternativeScheduleId = properties.alternative;
  }
}

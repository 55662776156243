import { Entity, InteractiveEntityMaps, PartialEntity, WbsSectionEntity } from '@/common/types';
import { outdentSections } from '@/features/projectStructure/utils/sectionIndentOutdent';
import { OperationInputType } from '@/features/realTimeCollaboration/types';
import { checkIfInRange, getRange } from '@/helpers/utils/numbers';
import { OrderedTree } from '@/helpers/utils/orderedTree';

import { WbsSection } from '../types';
import {
  getSectionChildren,
  getSectionDescendants,
  getSectionSiblingsByParentId,
} from './sectionTreeUtils';

export function convertSectionCreateOperationInput(
  input: OperationInputType<'CreateWBSSections'>,
  entityStates: InteractiveEntityMaps,
): {
  addedSections?: WbsSectionEntity[];
  updatedSections?: PartialEntity<WbsSectionEntity>[];
} {
  if (!input.length || !entityStates.wbsSections) return {};

  return input.length === 1
    ? convertSingleSectionCreateInput(input[0], Array.from(entityStates.wbsSections.values()))
    : convertMultipleSectionCreateInput(input, Array.from(entityStates.wbsSections.values()));
}

export function convertSingleSectionCreateInput(
  input: OperationInputType<'CreateWBSSections'>[0],
  sections: WbsSectionEntity[],
): {
  addedSections?: WbsSectionEntity[];
  updatedSections?: PartialEntity<WbsSectionEntity>[];
} {
  const orderedSectionsTree = new OrderedTree(sections);
  const siblings =
    input.parentId === null
      ? orderedSectionsTree.rootEntities
      : (orderedSectionsTree.get(input.parentId)?.children ?? []);
  if (siblings.length === 0) return { addedSections: [input] };

  const sectionsToUpdate: PartialEntity<WbsSectionEntity>[] = [];

  const occupiedPositions = getRange([input.position]);
  siblings.forEach((sibling) => {
    if (checkIfInRange(sibling.position, occupiedPositions)) {
      const newPosition = occupiedPositions[1] + 1;
      sectionsToUpdate.push({
        id: sibling.id,
        position: newPosition,
      });
      occupiedPositions[1] = newPosition;
    }
  });

  return { addedSections: [input], updatedSections: sectionsToUpdate };
}

// this is called e.g. when undoing an outdent, thus we don't need to shift and adapt positions but rather just apply the changes
export function convertMultipleSectionCreateInput(
  input: OperationInputType<'CreateWBSSections'>,
  sections: WbsSectionEntity[],
): {
  addedSections?: WbsSectionEntity[];
  updatedSections?: PartialEntity<WbsSectionEntity>[];
} {
  const orderedSectionsTree = new OrderedTree(sections);
  const sectionIdsToUpdate: Set<string> = new Set();
  input
    .slice()
    .sort((a, b) => a.position - b.position)
    .forEach((addedSection) => {
      const updatedSections =
        convertSingleSectionCreateInput(addedSection, orderedSectionsTree.baseEntities)
          .updatedSections ?? [];
      updatedSections.forEach(({ id }) => sectionIdsToUpdate.add(id));
      orderedSectionsTree.update(updatedSections);
      orderedSectionsTree.setMultiple([addedSection]);
    });

  const sectionsToUpdate = Array.from(sectionIdsToUpdate)
    .map((id) => orderedSectionsTree.entityMap.get(id))
    .filter(Boolean) as WbsSectionEntity[];

  return { addedSections: input, updatedSections: sectionsToUpdate };
}

export function convertSectionUpdateOperationInput(
  input: OperationInputType<'UpdateWBSSection'>,
  entityStates: InteractiveEntityMaps,
): PartialEntity<WbsSectionEntity>[] {
  if (!entityStates.wbsSections) return [];

  const sanitizedUpdate = {
    id: input.id,
    ...(input.parentId !== undefined ? { parentId: input.parentId } : {}),
    ...(input.name ? { name: input.name } : {}),
    ...(input.position !== undefined && input.position !== null
      ? { position: input.position }
      : {}),
  };

  if (sanitizedUpdate.position === undefined) {
    return [sanitizedUpdate];
  }

  const section = entityStates.wbsSections.get(sanitizedUpdate.id);
  if (!section) throw new Error(`The section to update was not found`);

  const previousPosition = section.position;
  const isMovingToLowerPosition = sanitizedUpdate.position < previousPosition;
  const isMovingToDifferentParent = sanitizedUpdate.parentId !== undefined;

  const sectionSiblings = getSectionSiblingsByParentId(
    entityStates.wbsSections,
    sanitizedUpdate.parentId ? sanitizedUpdate.parentId : section.parentId,
  );

  const sectionsToUpdate: PartialEntity<WbsSectionEntity>[] = [];

  if (isMovingToLowerPosition || isMovingToDifferentParent) {
    sectionSiblings.forEach((sibling) => {
      if (sibling.id === sanitizedUpdate.id) return undefined;

      if (sibling.position >= sanitizedUpdate.position!) {
        sectionsToUpdate.push({ id: sibling.id, position: sibling.position + 1 });
      }
    });
  } else {
    const updateRange: [number, number] = [previousPosition, sanitizedUpdate.position];
    sectionSiblings.forEach((sibling) => {
      if (sibling.id === sanitizedUpdate.id) return undefined;

      if (checkIfInRange(sibling.position, updateRange)) {
        sectionsToUpdate.push({ id: sibling.id, position: sibling.position - 1 });
      }
    });
  }

  sectionsToUpdate.push(sanitizedUpdate);

  return sectionsToUpdate;
}

export function convertSectionDeleteOperationInput(
  input: OperationInputType<'RemoveScheduleNodes'>,
  entityStates: InteractiveEntityMaps,
):
  | {
      wbsSections: Entity[];
      orders: Entity[];
      milestones: Entity[];
    }
  | undefined {
  if (!entityStates.wbsSections || !entityStates.orders || !entityStates.milestones) {
    return undefined;
  }
  if (!input.wbsSections || input.wbsSections.length === 0) return undefined;

  if (input.wbsSections.length > 1) throw new Error('Only one section can be deleted at a time');

  const sectionToDelete = entityStates.wbsSections.get(input.wbsSections[0]);
  if (!sectionToDelete) return undefined;

  const sectionsToDelete = getSectionDescendants(entityStates.wbsSections, sectionToDelete).map(
    ({ id }) => ({ id }),
  );

  const sectionsToDeleteIds = new Set(sectionsToDelete.map(({ id }) => id));

  const ordersToDelete = Array.from(entityStates.orders.values())
    .filter(({ wbsSection }) => sectionsToDeleteIds.has(wbsSection.id))
    .map(({ id }) => ({ id }));
  const milestonesToDelete = Array.from(entityStates.milestones.values())
    .filter(({ wbsSection }) => Boolean(wbsSection && sectionsToDeleteIds.has(wbsSection.id)))
    .map(({ id }) => ({ id }));

  return {
    wbsSections: sectionsToDelete,
    orders: ordersToDelete,
    milestones: milestonesToDelete,
  };
}

export function convertSectionIndentOperationInput(
  input: OperationInputType<'IndentWBSSection'>,
  entityStates: InteractiveEntityMaps,
): {
  addedSections?: WbsSectionEntity[];
  updatedSections?: PartialEntity<WbsSectionEntity>[];
} {
  if (!entityStates.wbsSections) throw new Error('No sections provided');
  const parent = entityStates.wbsSections.get(input.id);
  if (!parent) throw new Error('Parent section to indent section in not found');

  const newSection: WbsSectionEntity = {
    id: input.newSectionId,
    name: input.name,
    position: 0,
    parentId: parent.id,
  };
  const childrenToReassignToNewSection = getSectionChildren(entityStates.wbsSections, parent);

  const update: WbsSection[] = childrenToReassignToNewSection.map((child) => ({
    ...child,
    parentId: newSection.id,
  }));
  const add: WbsSection[] = [newSection];

  return { updatedSections: update, addedSections: add };
}

export function convertSectionOutdentOperationInput(
  input: OperationInputType<'OutdentWBSSection'>,
  entityStates: InteractiveEntityMaps,
): {
  updatedSections?: PartialEntity<WbsSectionEntity>[];
  deletedSections?: Entity[];
  deletedMilestones?: Entity[];
} {
  if (!entityStates.wbsSections) throw new Error('No sections provided');

  const sectionToOutdent = entityStates.wbsSections.get(input.id);
  if (!sectionToOutdent) throw new Error('Section to outdent not found');

  const changes = outdentSections(Array.from(entityStates.wbsSections.values()), sectionToOutdent);

  const deletedSectionIds = new Set(changes.deletedSections?.map(({ id }) => id) ?? []);

  const milestonesToDelete = Array.from(entityStates.milestones?.values() ?? [])
    .filter(({ wbsSection }) => Boolean(wbsSection && deletedSectionIds.has(wbsSection.id)))
    .map(({ id }) => ({ id }));

  return {
    ...changes,
    deletedMilestones: milestonesToDelete,
  };
}

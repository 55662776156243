import { useMutation } from '@vue/apollo-composable';
import { computed, Ref } from 'vue';

import {
  CreateAttachmentMutation,
  CreateAttachmentMutationVariables,
} from '@/graphql/__generated__/graphql';
import AttachmentCreate from '@/graphql/attachments/Create.gql';
import { AttachmentRepository } from '@/interfaces/repositories';
import {
  AttachmentMutateReturn,
  CreateAttachmentVariables,
} from '@/interfaces/repositories/attachments';
import { MutationResult } from '@/interfaces/repositories/base';

export class GraphQLAttachmentRepository implements AttachmentRepository {
  public constructor() {}

  public useCreate(
    vars?: Ref<CreateAttachmentVariables>,
  ): MutationResult<CreateAttachmentVariables, AttachmentMutateReturn | null> {
    const variables: Ref<CreateAttachmentMutationVariables> = computed(() => ({
      ...(vars?.value ?? ({} as CreateAttachmentMutationVariables)),
      timestamp: new Date(),
    }));

    const { mutate, error, loading } = useMutation<
      CreateAttachmentMutation,
      CreateAttachmentMutationVariables
    >(AttachmentCreate, () => ({
      variables: variables.value,
    }));

    return {
      mutate: (
        mutationVars?: CreateAttachmentVariables,
      ): Promise<AttachmentMutateReturn | null> => {
        return mutate({
          ...(vars?.value ?? ({} as CreateAttachmentMutationVariables)),
          ...mutationVars,
          timestamp: new Date(),
        }).then((result) => result?.data?.createAttachment ?? null);
      },
      loading,
      error,
    };
  }
}

import { PartialEntity, WbsSectionEntity } from '@/common/types';
import { getSectionDescendants } from '@/features/projectStructure';
import {
  generateUndoRedoCommitId,
  UndoRedoActionNames,
  UndoRedoCommit,
} from '@/features/undoRedo/types';
import { pickKeys } from '@/helpers/utils/objects';

export const createUndoRedoCreateCommit = (
  vars: WbsSectionEntity[],
  root: WbsSectionEntity | null,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.CreateWBSSections,
      data: { sections: vars, root },
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteWBSSections,
      data: root ? [root] : [],
    },
  };
};

export const createUndoRedoUpdateCommit = (
  vars: [PartialEntity<WbsSectionEntity>],
  sections: Map<string, WbsSectionEntity>,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.UpdateWBSSection,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.UpdateWBSSection,
      data: vars.map((partialSection) => {
        const existingSection = sections.get(partialSection.id);
        if (!existingSection) {
          throw new Error(`Section with id ${partialSection.id} not found`);
        }
        return pickKeys(existingSection, Object.keys(partialSection) as (keyof WbsSectionEntity)[]);
      }),
    },
  };
};

export const createUndoRedoRestoreCommit = (vars: WbsSectionEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RestoreWBSSections,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteWBSSections,
      data: vars,
    },
  };
};

export const createUndoRedoDeleteCommit = (vars: WbsSectionEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.DeleteWBSSections,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.RestoreWBSSections,
      data: vars,
    },
  };
};

export const createUndoRedoIndentCommit = (
  vars: WbsSectionEntity,
  newSectionId: string,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.IndentWBSSection,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.OutdentWBSSection,
      data: { id: newSectionId },
    },
  };
};

export const createUndoRedoOutdentCommit = (
  vars: WbsSectionEntity,
  sections: Map<string, WbsSectionEntity>,
): UndoRedoCommit => {
  if (!vars.parentId) throw new Error('Cannot outdent top level section');
  const parentOfSectionToOutdent = sections.get(vars.parentId);
  if (!parentOfSectionToOutdent) throw new Error('Parent of section to outdent could not be found');

  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.OutdentWBSSection,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.UndoOutdentWBSSection,
      data: {
        originallyOutdentedSection: vars,
        sectionsToRecreate: getSectionDescendants(sections, parentOfSectionToOutdent, false),
      },
    },
  };
};

export const createUndoRedoUndoOutdentCommit = (
  originallyOutdentedSection: WbsSectionEntity,
  sectionsToRecreate: WbsSectionEntity[],
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.UndoOutdentWBSSection,
      data: {
        originallyOutdentedSection,
        sectionsToRecreate,
      },
    },
    inverseAction: {
      name: UndoRedoActionNames.OutdentWBSSection,
      data: { id: originallyOutdentedSection.id },
    },
  };
};

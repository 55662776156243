<template>
  <Transition name="fade">
    <div
      v-if="modelValue"
      class="ds-snackbar"
      :class="{ '--closable': hasCloseButton }"
      :data-testid="dsComponentSelectors.snackbar"
    >
      <div>
        <slot name="title">
          <p v-if="props.title" class="ds-snackbar-title">{{ props.title }}</p>
        </slot>
        <slot name="subtitle">
          <p v-if="props.subtitle" class="ds-snackbar-subtitle">{{ props.subtitle }}</p>
        </slot>
      </div>

      <DsSvgIcon
        v-if="hasCloseButton"
        size="md"
        class="tw-cursor-pointer tw-flex-none"
        icon="x-close"
        @click="close"
      />

      <button
        v-if="props.actionText && !hasCloseButton"
        type="button"
        class="ds-snackbar-action"
        :data-testid="dsComponentSelectors.snackbarButton"
        @click="emit('clickAction')"
      >
        <span>{{ props.actionText }}</span>
      </button>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { dsComponentSelectors } from '@/common/selectors/dsComponents';

const props = defineProps({
  /*
   * Provide an action text to make the action button visible
   */
  actionText: {
    type: String as PropType<string | null>,
    default: null,
  },
  hasCloseButton: {
    type: Boolean,
    default: false,
  },
  /*
   * Time in ms after which the snackbar automatically closes
   * set to 0 make it permanent
   */
  hideAfter: {
    type: Number,
    default: 0,
    validator: (val: number) => val >= 0,
  },
  subtitle: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
});

const emit = defineEmits<{
  (ev: 'update:modelValue', value: boolean): void;
  (ev: 'clickAction'): void;
}>();

const modelValue = defineModel<boolean>({ default: true });

const setHideTimer = () => {
  setTimeout(() => {
    modelValue.value = false;
  }, props.hideAfter);
};

const close = () => {
  modelValue.value = false;
};

watchEffect(() => {
  if (!props.hideAfter || !modelValue.value) return;

  setHideTimer();
});

onMounted(() => {
  if (props.hideAfter) {
    setHideTimer();
  }
});
</script>

<style scoped>
.ds-snackbar {
  @apply tw-fixed tw-left-1/2 -tw-translate-x-1/2 tw-bottom-10 tw-z-10;
  @apply tw-flex tw-items-center tw-gap-3 tw-p-3;
  @apply tw-rounded-xs tw-text-white tw-bg-grey-800;
}

.ds-snackbar.--closable {
  @apply tw-items-start;
}

.ds-snackbar-title {
  @apply tw-ds-text-xs--semibold;
}

.ds-snackbar-subtitle {
  @apply tw-ds-text-xs--regular tw-max-w-[280px] tw-mt-1;
}

.ds-snackbar-action {
  @apply tw-flex tw-items-center tw-h-8 tw-px-3;
  @apply tw-ds-text-md--semibold tw-text-white;
}
</style>

<template>
  <div
    v-if="canShowStatusReport"
    name="status-report-content"
    class="tw-ds-text-sm tw-text-grey-500"
  >
    <p v-if="event.reason" class="tw-font-bold">
      {{
        getStatusReportReasonDescription({
          ...event,
          id: event.reportId,
          reason: event.reason ?? null,
        })
      }}
    </p>
    <p v-if="event.comment">
      {{ event.comment }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { OrderHistoryEntry, OrderHistoryStatusReportEvent } from '@/features/orderHistory/models';
import { OrderStatusReportNode } from '@/graphql/__generated__/graphql';
import { useStatusReport } from '@/helpers/orders/status';

const props = defineProps({
  entry: {
    type: Object as PropType<OrderHistoryEntry>,
    default: null,
  },
  report: {
    type: Object as PropType<OrderStatusReportNode | null>,
    default: null,
  },
});

const event = computed(
  () => (props.entry?.primary as OrderHistoryStatusReportEvent) ?? props.report,
);
const canShowStatusReport = computed(() => event.value?.comment || event.value?.reason);

const { getStatusReportReasonDescription } = useStatusReport(useI18n());
</script>

import { DependencyType } from '@koppla-tech/scheduling-engine';
import { defineStore } from 'pinia';

import {
  Entity,
  InsertTradeSequenceVariables,
  InteractiveEntityChanges,
  OrderDependencyEntity,
  PartialEntity,
  TradeSequenceEntity,
  WrapRef,
} from '@/common/types';
import { omitIdsOfNewlyCreatedTasks } from '@/features/flexibleTradeSequences/tradeSequenceUtils';
import { useOrderDependencyStoreV2 } from '@/features/orderDependencies';
import { ProjectChangeEventContext } from '@/features/realTimeCollaboration/types';
import { pushLocalChangeEventAndCommit } from '@/features/realTimeCollaboration/utils';
import { omitKeys } from '@/helpers/utils/objects';
import { useApolloClient } from '@/plugins/apollo';
import { NodeName } from '@/repositories/utils/cache';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

import { useOrderStoreV2 } from '../orders';
import { useRTCController } from '../realTimeCollaboration';
import { TradeSequenceTemplateToProjectVariables } from '../tenantTemplates/types';
import { useUndoRedoQueue } from '../undoRedo/install';
import { generateUndoRedoCommitId, UndoRedoCommit } from '../undoRedo/types';
import {
  createUndoRedoCreateCommit,
  createUndoRedoDeleteCommit,
  createUndoRedoRestoreCommit,
  createUndoRedoUpdateCommit,
  getInsertCommit,
  getRestoreInsertCommit,
  getRevertInsertCommit,
} from './projectTradeSequenceCommits';
import {
  createProjectChangeCreateEvent,
  createProjectChangeDeleteEvent,
  createProjectChangeRestoreEvent,
  createProjectChangeUpdateEvent,
  getInsertEvent,
  getRestoreInsertEvent,
  getRevertInsertEvent,
} from './projectTradeSequenceEvents';
import { tradeSequenceQuery, tradeSequencesQuery } from './projectTradeSequenceGql';
import { duplicateProjectTradeSequence } from './projectTradeSequenceUtils';
import {
  CreateTradeSequenceVariables,
  DeleteTradeSequenceVariables,
  ProjectTradeSequenceStoreV2,
  RestoreTradeSequenceVariables,
} from './types';

export const useProjectTradeSequenceStoreV2 = defineStore(
  'project-trade-sequence-store-v2',
  (): WrapRef<ProjectTradeSequenceStoreV2, 'tradeSequences' | 'fetchAllPromise'> => {
    const rtcController = useRTCController();
    const undoRedoQueue = useUndoRedoQueue();
    const tradeSequences = ref(new Map<string, TradeSequenceEntity>());
    const deletedTradeSequences = ref(new Map<string, TradeSequenceEntity>());

    const activityIdToTradeSequenceIdMap = computed(() => {
      const map = new Map<string, string>();
      tradeSequences.value.forEach((tradeSequence) => {
        tradeSequence.activities.forEach((activity) => {
          map.set(activity.id, tradeSequence.id);
        });
      });
      return map;
    });

    const create = async (
      vars: CreateTradeSequenceVariables[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const varsWithOmmitedTaskIds = vars.map((variables) => {
        return {
          tradeSequence: {
            ...variables.tradeSequence,
            activities: omitIdsOfNewlyCreatedTasks(variables.tradeSequence.activities),
          },
          orderAssignments: variables.orderAssignments,
        };
      });
      const event = createProjectChangeCreateEvent(varsWithOmmitedTaskIds, context);
      const commit = createUndoRedoCreateCommit(varsWithOmmitedTaskIds);
      return pushLocalChangeEventAndCommit(
        event,
        commit,
        // NO_UNDO_FOR_TRADE_SEQUENCE_CREATE_WITH_ASSIGNMENTS
        createCommit ? !vars[0].orderAssignments : false,
      );
    };

    const update = async (
      vars: TradeSequenceEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const varsWithOmittedTasks = vars.map((tradeSequence) => {
        const oldTradeSequence = tradeSequences.value.get(tradeSequence.id);
        return {
          ...tradeSequence,
          activities: omitIdsOfNewlyCreatedTasks(
            tradeSequence.activities,
            oldTradeSequence?.activities,
          ),
        };
      });
      const event = createProjectChangeUpdateEvent(varsWithOmittedTasks, context);
      const commit = createUndoRedoUpdateCommit(varsWithOmittedTasks, tradeSequences.value);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
      });
    };

    const restore = async (
      vars: RestoreTradeSequenceVariables[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeRestoreEvent(vars, context);
      const commit = createUndoRedoRestoreCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const remove = async (
      vars: DeleteTradeSequenceVariables[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeDeleteEvent(vars, context);
      const commit = createUndoRedoDeleteCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const fetchAllPromise: Ref<Promise<TradeSequenceEntity[]> | null> = ref(null);

    const fetchAll = async (projectId: string): Promise<TradeSequenceEntity[]> => {
      const client = useApolloClient();

      fetchAllPromise.value = client
        .query({
          query: tradeSequencesQuery,
          variables: {
            project: projectId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          const newTradeSequences = flattenNodeConnection(result.data.tradeSequences).map(
            (tradeSequence) => {
              return {
                ...tradeSequence,
                activities: flattenNodeConnection(tradeSequence.activities).map((activity) => ({
                  ...omitKeys(activity, ['__typename']),
                  tenantTradeVariation: {
                    id: activity.tenantTradeVariation.id,
                  },
                  dryingBreak:
                    activity.dryingBreak != null
                      ? omitKeys(activity.dryingBreak, ['__typename'])
                      : null,
                })),
                dependencies: tradeSequence.dependencies.map((dependency) => ({
                  ...omitKeys(dependency, ['__typename']),
                  from: { id: dependency.from.id },
                  to: { id: dependency.to.id },
                  type: dependency.type as DependencyType,
                })),
                calendar: {
                  id: tradeSequence.calendar.id,
                },
              };
            },
          );
          tradeSequences.value = new Map(
            newTradeSequences.map((tradeSequence) => [tradeSequence.id, tradeSequence]),
          );
          return newTradeSequences;
        });
      return fetchAllPromise.value;
    };

    const fetchSingle = async (id: string): Promise<TradeSequenceEntity | null> => {
      const client = useApolloClient();

      const newTradeSequencePromise = await client.query({
        query: tradeSequenceQuery,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      });

      const tradeSequence = newTradeSequencePromise.data.tradeSequence;
      if (!tradeSequence) return null;
      const newTradeSequence = {
        ...tradeSequence,
        activities: flattenNodeConnection(tradeSequence.activities).map((activity) => ({
          ...omitKeys(activity, ['__typename']),
          tenantTradeVariation: {
            id: activity.tenantTradeVariation.id,
          },
          dryingBreak:
            activity.dryingBreak != null ? omitKeys(activity.dryingBreak, ['__typename']) : null,
        })),
        dependencies: tradeSequence.dependencies.map((dependency) => ({
          ...omitKeys(dependency, ['__typename']),
          from: { id: dependency.from.id },
          to: { id: dependency.to.id },
          type: dependency.type as DependencyType,
        })),
        calendar: {
          id: tradeSequence.calendar.id,
        },
      };

      tradeSequences.value.set(newTradeSequence.id, newTradeSequence);

      return newTradeSequence;
    };

    const insert = async (
      vars: InsertTradeSequenceVariables,
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = getInsertEvent(vars, context);
      const commitId = generateUndoRedoCommitId();

      const changes = await rtcController.pushLocalProjectChangeEvent(
        event,
        createCommit
          ? {
              commitId,
            }
          : {},
      );
      const commit = getInsertCommit(commitId, vars, changes);
      if (createCommit) {
        undoRedoQueue.commit(commit, changes);
      }

      return { changes, commit };
    };

    const revertInsert = async (
      orders: Entity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = getRevertInsertEvent(orders, context);
      const commitId = generateUndoRedoCommitId();

      const changes = await rtcController.pushLocalProjectChangeEvent(
        event,
        createCommit
          ? {
              commitId,
            }
          : {},
      );
      const commit = getRevertInsertCommit(commitId, changes);
      if (createCommit) {
        undoRedoQueue.commit(commit, changes);
      }

      return { changes, commit };
    };

    const restoreInsert = async (
      orders: Entity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const restoredAdjacentDependencies = orders?.flatMap((order) => {
        const deletedDependencies =
          useOrderStoreV2().getSoftDeletedEntity(order.id)?.dependencies ?? [];
        return deletedDependencies
          .map((dependencyId) => useOrderDependencyStoreV2().dependencies.get(dependencyId))
          .filter(Boolean) as OrderDependencyEntity[];
      });

      const event = getRestoreInsertEvent(orders, context, restoredAdjacentDependencies);
      const commitId = generateUndoRedoCommitId();

      const changes = await rtcController.pushLocalProjectChangeEvent(
        event,
        createCommit
          ? {
              commitId,
            }
          : {},
      );
      const commit = getRestoreInsertCommit(commitId, changes);
      if (createCommit) {
        undoRedoQueue.commit(commit, changes);
      }

      return { changes, commit };
    };

    const duplicate = (tradeSequence: TradeSequenceEntity, name: string) => {
      return create([
        {
          tradeSequence: duplicateProjectTradeSequence({
            ...tradeSequence,
            name,
          }),
        },
      ]);
    };

    const createFromTemplate = (
      template: TradeSequenceTemplateToProjectVariables,
      name: string,
      calendarId: string,
    ) => {
      return create([
        {
          tradeSequence: duplicateProjectTradeSequence({
            ...template,
            dependencies: template.dependencies.map((dependency) => ({
              ...dependency,
              type: dependency.type as DependencyType,
            })),
            __typename: NodeName.TRADE_SEQUENCE,
            name,
            calendar: { id: calendarId },
          }),
        },
      ]);
    };

    const reset = () => {
      tradeSequences.value = new Map();
      fetchAllPromise.value = null;
    };

    const setState = (state?: Map<string, TradeSequenceEntity>) => {
      if (!state) {
        return;
      }
      state.forEach((tradeSequence) => {
        if (deletedTradeSequences.value.has(tradeSequence.id)) {
          deletedTradeSequences.value.set(tradeSequence.id, tradeSequence);
        }
      });
      tradeSequences.value.forEach((tradeSequence) => {
        if (!state.has(tradeSequence.id)) {
          deletedTradeSequences.value.set(tradeSequence.id, tradeSequence);
        }
      });
      tradeSequences.value = new Map(state);
    };

    const applyChanges = (changes: {
      add?: TradeSequenceEntity[];
      update?: PartialEntity<TradeSequenceEntity>[];
      delete?: Entity[];
    }): void => {
      changes.add?.forEach((tradeSequence) => {
        tradeSequences.value.set(tradeSequence.id, tradeSequence);
      });
      changes.update?.forEach((tradeSequence) => {
        tradeSequences.value.set(tradeSequence.id, {
          ...tradeSequences.value.get(tradeSequence.id)!,
          ...tradeSequence,
        });
        if (deletedTradeSequences.value.has(tradeSequence.id)) {
          deletedTradeSequences.value.set(tradeSequence.id, {
            ...tradeSequences.value.get(tradeSequence.id)!,
            ...tradeSequence,
          });
        }
      });
      changes.delete?.forEach((tradeSequence) => {
        const existingTradeSequence = tradeSequences.value.get(tradeSequence.id);
        if (existingTradeSequence) {
          deletedTradeSequences.value.set(tradeSequence.id, existingTradeSequence);
          tradeSequences.value.delete(tradeSequence.id);
        }
      });
    };

    const copyState = () => {
      return new Map(tradeSequences.value);
    };

    const getSoftDeletedEntity = (id: string): TradeSequenceEntity | undefined => {
      return deletedTradeSequences.value.get(id);
    };

    const replaceTrade = (previousTradeId: string, newTradeId: string) => {
      tradeSequences.value.forEach((template) => {
        const updatedActivities = template.activities.map((activity) => {
          if (activity.tenantTradeVariation.id === previousTradeId) {
            return {
              ...activity,
              tenantTradeVariation: { ...activity.tenantTradeVariation, id: newTradeId },
            };
          }
          return activity;
        });

        tradeSequences.value.set(template.id, {
          ...template,
          activities: updatedActivities,
        });
      });
    };

    const getAssociatedTradeSequenceId = (activityId: string): string | undefined => {
      return activityIdToTradeSequenceIdMap.value.get(activityId);
    };

    return {
      tradeSequences,
      create,
      update,
      restore,
      delete: remove,
      fetchAll,
      fetchAllPromise,
      fetchSingle,
      applyChanges,
      copyState,
      setState,
      reset,
      createFromTemplate,
      duplicate,
      insert,
      revertInsert,
      restoreInsert,
      getSoftDeletedEntity,
      replaceTrade,
      getAssociatedTradeSequenceId,
    };
  },
);

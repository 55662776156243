import { UndoRedoQueue } from './queue';

let undoRedoQueue: UndoRedoQueue;

export function installUndoRedoQueue(queue: UndoRedoQueue) {
  undoRedoQueue = queue;
}

export function useUndoRedoQueue() {
  if (!undoRedoQueue) {
    throw new Error("Queue not installed, please call 'installUndoRedoQueue' during setup.");
  }
  return undoRedoQueue;
}

import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const fetchQuery = gqlFn(/* GraphQL */ `
  query projectContributorGroups($project: ID!) {
    projectContributorGroups(project: $project) {
      edges {
        node {
          ...ProjectContributorGroup
        }
      }
    }
  }
`);

export const fetchContributorGroupsSearch = gqlFn(/* GraphQL */ `
  query projectContributorGroupsSearch($project: ID!) {
    projectContributorGroupsSearch(project: $project) {
      edges {
        node {
          ...ProjectContributorGroup
        }
      }
    }
  }
`);

export const projectContributorGroupFragment = gql`
  fragment ProjectContributorGroup on ProjectContributorGroupNode {
    id
    name
    permission
    isTenantOwnerGroup
    type
    memberships {
      ...ProjectContributorGroupMembership
    }
    tenantTradeVariationAssignments {
      isDefaultContributor
      tenantTradeVariation {
        id
      }
    }
  }
`;

export const projectContributorGroupMembershipFragment = gql`
  fragment ProjectContributorGroupMembership on ProjectContributorGroupMembershipNode {
    id
    permission
    user {
      id
      email
      firstName
      lastName
      isActive
      profilePicture {
        imageThumbnail {
          url
        }
      }
    }
  }
`;

export const createProjectContributorGroupMembershipMutation = gqlFn(/* GraphQL */ `
  mutation createProjectContributorGroupMembership(
    $input: CreateProjectContributorGroupMembershipInput!
  ) {
    createProjectContributorGroupMembership(input: $input) {
      projectContributorGroupMembership {
        ...ProjectContributorGroupMembership
      }
    }
  }
`);

export const createProjectContributorGroupMutation = gqlFn(/* GraphQL */ `
  mutation createProjectContributorGroup($input: CreateProjectContributorGroupInput!) {
    createProjectContributorGroup(input: $input) {
      projectContributorGroup {
        ...ProjectContributorGroup
      }
    }
  }
`);

export const updateProjectContributorGroupMutation = gqlFn(/* GraphQL */ `
  mutation updateProjectContributorGroup($input: UpdateProjectContributorGroupInput!) {
    updateProjectContributorGroup(input: $input) {
      projectContributorGroup {
        ...ProjectContributorGroup
      }
    }
  }
`);

export const deleteProjectContributorGroupMutation = gqlFn(/* GraphQL */ `
  mutation deleteProjectContributorGroup($input: DeleteProjectContributorGroupInput!) {
    deleteProjectContributorGroup(input: $input) {
      success
    }
  }
`);

export const deleteProjectContributorGroupMembershipMutation = gqlFn(/* GraphQL */ `
  mutation deleteProjectContributorGroupMembership(
    $input: DeleteProjectContributorGroupMembershipInput!
  ) {
    deleteProjectContributorGroupMembership(input: $input) {
      success
    }
  }
`);

export const updateProjectContributorGroupMembershipMutation = gqlFn(/* GraphQL */ `
  mutation updateProjectContributorGroupMembership(
    $input: UpdateProjectContributorGroupMembershipInput!
  ) {
    updateProjectContributorGroupMembership(input: $input) {
      projectContributorGroupMembership {
        id
        permission
      }
    }
  }
`);

export const ownContributorGroupMembershipsQuery = gqlFn(/* GraphQL */ `
  query ownProjectContributorGroupMemberships {
    ownProjectContributorGroupMemberships {
      edges {
        node {
          id
          permission
          contributorGroup {
            id
            name
            type
            isTenantOwnerGroup
            project {
              id
            }
          }
        }
      }
    }
  }
`);

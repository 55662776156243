import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

/** Schedule handling */

export class ScheduleOrderViewToggledEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.SCHEDULE_ORDER_VIEW_TOGGLED;

  public constructor(
    public properties: {
      type: 'trade' | 'status' | 'baseplan_comparison';
      source: 'schedule';
      method: 'filter_menu' | 'keyboard';
    },
  ) {
    super();
  }
}

export class ScheduleJumpToTodayClickedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.SCHEDULE_JUMP_TO_TODAY;

  public constructor(public properties: { method: 'keyboard' | 'click' }) {
    super();
  }
}

export class ScheduleUndoEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.SCHEDULE_UNDO;

  public constructor(public properties: { method: 'keyboard' | 'click' }) {
    super();
  }
}

export class ScheduleRedoEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.SCHEDULE_REDO;

  public constructor(public properties: { method: 'keyboard' | 'click' }) {
    super();
  }
}

/** Schedule Multi Entity Changes */

export type MultiEventCounts = {
  orderCount: number;
  milestoneCount: number;
  constructionBreakCount: number;
};
export class ScheduleEntitiesSelected extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SCHEDULE_ENTITIES_SELECTED;

  public constructor(public properties: MultiEventCounts) {
    super();
  }
}

export class ScheduleEntitiesDeleted extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SCHEDULE_ENTITIES_DELETED;

  public constructor(
    public properties: {
      method: 'keyboard' | 'toolbar';
    } & MultiEventCounts,
  ) {
    super();
  }
}

export class ScheduleEntitiesEdited extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SCHEDULE_ENTITIES_EDITED;

  public constructor(
    public properties: {
      method: 'drag_and_drop';
    } & MultiEventCounts,
  ) {
    super();
  }
}

/** Schedule Multi Entity Changes */

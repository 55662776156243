import { AjaxHelper, SchedulerPro } from '@bryntum/schedulerpro';
import { useQuery } from '@vue/apollo-composable';
import pako from 'pako';
import { Ref, ref, SetupContext, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useGlobalStore } from '@/common/globalStore';
import {
  GanttExportEvent,
  getPaperHeightInPx,
  getPaperWidthInPx,
  printPdfPagesToOtherTab,
  XLSExportEvent,
} from '@/features/filter-and-export';
import { BRYNTUM_FETCH_OPTIONS } from '@/features/filter-and-export/pdfExportConfig';
import { useNotificationStore } from '@/features/notifications';
import {
  AsyncProjectExportResult,
  ExportProjectMutation,
  ExportProjectMutationVariables,
  FileContent,
  ProjectExportQuery,
  ProjectExportQueryVariables,
  SyncProjectExportResult,
} from '@/graphql/__generated__/graphql';
import CREATE_PROJECT_EXPORT from '@/graphql/leanProject/export/Create.gql';
import FetchProjectExport from '@/graphql/leanProject/export/Detail.gql';
import { useIocProvider } from '@/ioc/injectKey';
import { useApolloClient } from '@/plugins/apollo';
import { i18nInstance } from '@/plugins/i18n';

import { ExportKey, PdfExportOrientation, PdfPage, PdfPaperFormat } from '../types';

enum ProjectExportStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

enum ProjectExportFormat {
  EXCEL = 'XLSX',
  MS_PROJECT = 'MPP',
  POWER_PROJECT = 'POWER_PROJECT',
  PRIMAVERA_P6 = 'P6',
}

const exportFormatMap: { [key in Exclude<ExportKey, ExportKey.PDF>]: ProjectExportFormat } = {
  [ExportKey.EXCEL]: ProjectExportFormat.EXCEL,
  [ExportKey.MS_PROJECT]: ProjectExportFormat.MS_PROJECT,
  [ExportKey.POWER_PROJECT]: ProjectExportFormat.POWER_PROJECT,
  [ExportKey.PRIMAVERA_P6]: ProjectExportFormat.PRIMAVERA_P6,
};

export interface UseProjectExportErrorHandling {
  setError: () => void;
}

export interface UseProjectExport extends UseProjectExportErrorHandling {
  onClose: () => void;
  onExport: (exportType: ExportKey | null) => void;
  loading: Ref<boolean>;
}

/**
 * Provides functionality to export a project to different formats
 * @param context
 * @param format
 * @returns
 */
export function useProjectExport(
  context: SetupContext,
  projectName: Ref<string | null>,
): UseProjectExport {
  const globalStore = useGlobalStore();
  const i18n = useI18n();
  const client = useApolloClient();
  const { fileDownloadService, loggingService } = useIocProvider();

  const pushNotificationStore = useNotificationStore();

  const setError = () => {
    pushNotificationStore.showAttentionNotification({
      titleI18nKey: 'Calendar.export.failed',
    });
  };

  const loading = ref(false);

  const exportId = ref('');
  const enableQuery = ref(false);
  const format = ref('');

  const { result: exportResult } = useQuery<ProjectExportQuery, ProjectExportQueryVariables>(
    FetchProjectExport,
    () => ({ id: exportId.value }),
    { enabled: enableQuery, pollInterval: 1000 },
  );

  function onClose(): void {
    context.emit('close');
  }

  const finishLoading = () => {
    loading.value = false;
  };

  function onDownload(file: FileContent): void {
    const name = `${projectName.value}_${i18n
      .d(new Date(), 'dateDMY')
      .toString()
      .replaceAll(/[^0-9]+/gi, '-')}.${format.value.toLowerCase()}`;

    fileDownloadService
      .download(
        {
          url: file.downloadUrl || file.url,
          name,
        },
        false,
      )
      .then(() => {
        context.emit('done');
      })
      .catch(() => {
        context.emit('error');
      })
      .finally(() => {
        finishLoading();
      });
  }

  function onExport(exportType: ExportKey | null): void {
    if (!exportType) {
      return;
    }
    loading.value = true;
    const exportFormat = exportFormatMap[exportType];
    format.value = exportFormat;
    context.emit('load');

    let analyticsEvent: XLSExportEvent | GanttExportEvent;
    switch (exportFormat) {
      case ProjectExportFormat.EXCEL:
        analyticsEvent = new XLSExportEvent();
        break;
      case ProjectExportFormat.MS_PROJECT:
        analyticsEvent = new GanttExportEvent({ type: 'ms_project' });
        break;
      case ProjectExportFormat.POWER_PROJECT:
        analyticsEvent = new GanttExportEvent({ type: 'powerproject' });
        break;
      case ProjectExportFormat.PRIMAVERA_P6:
      default:
        analyticsEvent = new GanttExportEvent({ type: 'p6' });
        break;
    }
    loggingService.trackEvent(analyticsEvent);

    const mutation = client.mutate<ExportProjectMutation, ExportProjectMutationVariables>({
      mutation: CREATE_PROJECT_EXPORT,
      variables: {
        project: globalStore.scheduleProjectId!,
        format: exportFormat,
      },
    });
    mutation
      .then((result) => {
        if (!result) {
          loading.value = false;
          return;
        }

        const { data } = result;
        if (data && (data?.exportProject as SyncProjectExportResult)?.file) {
          onDownload((data.exportProject as SyncProjectExportResult).file);
        } else {
          exportId.value = (data?.exportProject as AsyncProjectExportResult).exportId ?? '';
          enableQuery.value = true;
        }
      })
      .catch(() => {
        loading.value = false;
        setError();
      });
  }

  watch(exportResult, () => {
    if (!exportResult.value) return;

    if (exportResult.value.projectExport?.status === ProjectExportStatus.ERROR) {
      loading.value = false;
      enableQuery.value = false;
      setError();
    } else if (
      exportResult.value.projectExport?.status === ProjectExportStatus.COMPLETED &&
      exportResult.value.projectExport?.file
    ) {
      enableQuery.value = false;
      exportId.value = '';
      onDownload(exportResult.value.projectExport.file);
    }
  });

  return {
    onClose,
    onExport,
    loading,
    setError,
  };
}

export function registerDigitalExport(schedulerInstance: SchedulerPro): void {
  schedulerInstance.features.pdfExport.receiveExportContent = (
    pages: PdfPage[],
    config: Dictionary,
  ) => {
    return fetchExport(pages, config);
  };
}

export function registerSinglePageExport(
  schedulerInstance: SchedulerPro,
  scalingFactor: number,
): void {
  schedulerInstance.features.pdfExport.receiveExportContent = (
    pages: PdfPage[],
    config: Dictionary,
  ) => {
    const adjustedPages = [setScalingFactorsForExportBody(pages[0], scalingFactor)];
    return fetchExport(adjustedPages, config);
  };
}

const setScalingFactorsForExportBody = (page: PdfPage, scalingFactor: number): PdfPage => {
  const iframe = document.createElement('iframe');
  iframe.style.visibility = 'hidden';
  iframe.style.position = 'fixed';
  iframe.style.zIndex = '-1000';
  document.body.appendChild(iframe);
  const doc = iframe.contentWindow?.document || iframe.contentDocument;
  if (!doc) throw new Error('no doc available');
  doc.open();
  doc.write(page.html);
  doc.close();
  const exportContent = doc.querySelector<HTMLDivElement>('.b-export-content');
  const exportBody = doc.querySelector<HTMLDivElement>('.b-export-body');
  if (exportContent && exportBody) {
    exportContent.classList.add('--single-page');
    exportBody.style.transform = `scale(${scalingFactor})`;
  }
  const newPage = { html: doc.documentElement.outerHTML };
  document.body.removeChild(iframe);
  return newPage;
};

export function registerMultiPageExport(schedulerInstance: SchedulerPro): void {
  schedulerInstance.features.pdfExport.receiveExportContent = (
    pages: PdfPage[],
    config: Dictionary,
  ) => {
    const sanitizedPages = sanitizePages(pages, config);
    return fetchExport(sanitizedPages, config);
  };
}

export function registerMultiPageExportWithAdditionalPages(
  schedulerInstance: SchedulerPro,
  additionalPageBodies: HTMLBodyElement[] = [],
): void {
  schedulerInstance.features.pdfExport.receiveExportContent = (
    pages: PdfPage[],
    config: Dictionary,
  ) => {
    let sanitizedPages = sanitizePages(pages, config);

    if (sanitizedPages.length > 0) {
      const pageHtmlSkeleton = new DOMParser().parseFromString(sanitizedPages[0].html, 'text/html');
      sanitizedPages = sanitizedPages.concat(
        additionalPageBodies.map((page) => {
          pageHtmlSkeleton.body.innerHTML = page.innerHTML;

          return {
            html: `<!DOCTYPE html>${pageHtmlSkeleton.documentElement.outerHTML}`,
          };
        }),
      );
    }
    return fetchExport(sanitizedPages, config);
  };
}

const sanitizePages = (pages: PdfPage[], config: Dictionary): PdfPage[] => {
  const filteredPages = filterEmptyPdfPages(pages, config);
  return getPagesWithPageHeaderFilled(filteredPages);
};

const filterEmptyPdfPages = (pages: PdfPage[], config: Dictionary): PdfPage[] => {
  const paperConfig = {
    orientation: config.orientation as PdfExportOrientation,
    paperFormat: config.paperFormat as PdfPaperFormat | string,
  };
  const iframe = document.createElement('iframe');
  iframe.style.width = `${getPaperWidthInPx(paperConfig)}`;
  iframe.style.height = `${getPaperHeightInPx(paperConfig)}`;
  iframe.style.visibility = 'hidden';
  iframe.style.position = 'fixed';
  iframe.style.zIndex = '-1000';
  document.body.appendChild(iframe);
  const doc = iframe.contentWindow?.document || iframe.contentDocument;
  if (!doc) throw new Error('no doc available');
  const filteredPages = pages.filter((page) => {
    doc.open();
    doc.write(page.html);
    doc.close();
    const exportViewport = doc.body.querySelector('.b-export-viewport');
    const exportViewportMarginTop = Math.abs(
      exportViewport
        ? parseInt(iframe.contentWindow?.getComputedStyle(exportViewport).marginTop || '0px', 10)
        : 0,
    );
    return exportViewport && exportViewport.clientHeight - exportViewportMarginTop > 0;
  });
  document.body.removeChild(iframe);
  return filteredPages;
};

const getPagesWithPageHeaderFilled = (pages: PdfPage[]): PdfPage[] => {
  return pages.map(({ html }, index, all) => {
    const headerInfo = i18nInstance.global.t('Calendar.export.pdf.headerInfo.pages', [
      index + 1,
      all.length,
    ]);
    return {
      html: html.replace(
        i18nInstance.global.t('Calendar.export.pdf.headerInfo.pagesPlaceholder'),
        headerInfo,
      ),
    };
  });
};

function fetchExport(pages: PdfPage[], config: Dictionary) {
  // crossorigin attribute on stylesheet links makes PDF export fail in the backend
  const sanitizedPages = pages.map((page) => {
    return { html: page.html.replace(`rel="stylesheet" crossorigin`, `rel="stylesheet"`) };
  });
  if (process.env.PDF_EXPORT_OPEN_HTML_TAB === 'true') {
    printPdfPagesToOtherTab(sanitizedPages, config);
    return Promise.resolve();
  }
  const jsonBodyString = JSON.stringify({
    html: sanitizedPages,
    orientation: config.orientation,
    format: config.paperFormat,
    fileFormat: config.fileFormat,
    fileName: config.fileName,
    clientURL: config.clientURL,
    sendAsBinary: config.sendAsBinary,
  });

  const body = pako.gzip(jsonBodyString);

  return AjaxHelper.fetch(config.exportServer, {
    method: 'POST',
    credentials: 'omit',
    body,
    ...BRYNTUM_FETCH_OPTIONS,
    headers: {
      ...BRYNTUM_FETCH_OPTIONS.headers,
      'Content-Encoding': 'gzip',
    },
  });
}

import { AuthenticationService } from '@/interfaces/services';
import { BULK_API_ENDPOINT } from '@/utils/config';
import { BulkApiError } from '@/utils/errors';

import { BulkApiClient } from './store/bulkApiClient';

let client: BulkApiClient | null = null;

export function installBulkApiClient(authenticationService: AuthenticationService) {
  client = new BulkApiClient(BULK_API_ENDPOINT, (refresh) => {
    return authenticationService.getIdToken(refresh)!.then((v) => v!);
  });
}

export function getBulkApiClient(): BulkApiClient {
  if (!client) {
    throw new BulkApiError("Call 'installBulkApiClient' before this function to initialize it.");
  }
  return client;
}

import {
  ProjectVersionFragment,
  ProjectVersionsQueryVariables,
} from '@/graphql/__generated__/graphql';
import { getRandomId } from '@/helpers/utils/strings';

export const MINIMUM_VISIBLE_VERSIONS = 50;
export const PROJECT_VERSIONS_BATCH_SIZE = 50;
export const PROJECT_VERSIONS_REFETCH_BATCH_SIZE = 20;
export const VERSION_NOTIFICATION_NAME = 'version_restore';
export const PROJECT_VERSIONS_POLLING_INTERVAL = 30000;
export const MINIMUM_TIME_BUFFER_MS = 40000;

export interface ProjectVersion extends Omit<ProjectVersionFragment, 'timestamp'> {
  timestamp: Date;
}

export const PROJECT_CREATED_PLACEHOLDER_ID = getRandomId();
export const CURRENT_SCHEDULE_VERSION_ID = getRandomId();

export interface ProjectVersionsPaginationQueryVariables
  extends Omit<ProjectVersionsQueryVariables, 'limit' | 'after'> {
  limit: number;
  after?: string;
}

export enum TimelineType {
  MANUAL = 'manual',
  PLACEHOLDER = 'placeholder',
  AUTOMATIC = 'automatic',
  CURRENT = 'current',
  CREATED = 'created',
}

export interface ParsedVersion {
  id: string;
  type: TimelineType;
  placeholder: string | null;
  collapsed: boolean;
  timestamp: Date;
  hasChanges: boolean;
  text?: string;
  subtext?: string;
}

export interface DisplayedVersion extends Omit<ParsedVersion, 'subtext'> {
  text: string;
  subtext: string | null;
}

import { PartialEntity, PauseEntity } from '@/common/types';
import { pickKeys } from '@/helpers/utils/objects';

import { UndoRedoCommit } from '../undoRedo';
import { generateUndoRedoCommitId, UndoRedoActionNames } from '../undoRedo/types';

export const createUndoRedoCreateCommit = (vars: PauseEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.CreatePauses,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeletePauses,
      data: vars,
    },
  };
};

export const createUndoRedoUpdateCommit = (
  vars: PartialEntity<PauseEntity>[],
  pauses: Map<string, PauseEntity>,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.UpdatePauses,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.UpdatePauses,
      data: vars.map((pause) => {
        const existingPause = pauses.get(pause.id);
        if (!existingPause) {
          throw new Error('Pause not found');
        }
        return pickKeys(existingPause, Object.keys(pause) as (keyof PauseEntity)[]);
      }),
    },
  };
};

export const createUndoRedoRestoreCommit = (vars: PauseEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RestorePauses,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeletePauses,
      data: vars,
    },
  };
};

export const createUndoRedoDeleteCommit = (vars: PauseEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.DeletePauses,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.RestorePauses,
      data: vars,
    },
  };
};

import { DateRangeObject } from '@/helpers/utils/dates';

export enum ExportTab {
  EXCEL = 'excel',
  GANTT = 'gantt',
  PDF = 'pdf',
}

export enum ExportKey {
  EXCEL = 'excel',
  MS_PROJECT = 'msproject',
  POWER_PROJECT = 'powerproject',
  PRIMAVERA_P6 = 'primaverap6',
  PDF = 'pdf',
}

export enum GanttExportKey {
  MS_PROJECT = 'msproject',
  POWER_PROJECT = 'powerproject',
  PRIMAVERA_P6 = 'primaverap6',
}

export function mapGanttExportKeyToExportKey(key: GanttExportKey): ExportKey {
  switch (key) {
    case GanttExportKey.MS_PROJECT:
      return ExportKey.MS_PROJECT;
    case GanttExportKey.POWER_PROJECT:
      return ExportKey.POWER_PROJECT;
    case GanttExportKey.PRIMAVERA_P6:
      return ExportKey.PRIMAVERA_P6;
  }
}

export enum PdfPaperFormat {
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
}

export enum PdfExportOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

/** Desired export type (form of exported pdf pages) */
export enum PdfExportType {
  DIGITAL = 'digital',
  PRINT = 'print',
  PLOT = 'plot',
}

/** Exporter to be used to achieve the desired export type */
export enum PdfExporterType {
  PAPER = 'multipage',
  PAPER_SINGLEPAGE = 'singlepage',
  DIGITAL = 'customsinglepageexporter',
}

export interface PdfExportFormat {
  type: PdfExportType;
  orientation: PdfExportOrientation;
  paperFormat: PdfPaperFormat | string;
  isMultiPage: boolean;
}

export type PdfCustomExportConfig = PdfExportFormat & {
  currentScrollDate: number | null;
  currentZoomPreset: string;
  isStatusView: boolean;
  isBaseActualView: boolean;
  hideDependencies: boolean;
  projectName: string | undefined;
  tenantLogo: string | null | undefined;
  exportRange: DateRangeObject;
  maxExportWidth: undefined | number;
};

export function getInitialPdfExportFormat(): PdfExportFormat {
  return {
    type: PdfExportType.PRINT,
    orientation: PdfExportOrientation.LANDSCAPE,
    paperFormat: PdfPaperFormat.A4,
    isMultiPage: false,
  };
}

// font size (11px) * n + rest to be divisible by 4
export enum MaxLinesOrderHeightThreshold {
  One = 16,
  Two = 24,
  Three = 36,
}

export interface HeaderTplOptions {
  currentPage: string | number;
  totalPages: string | number;
}

export type LegendReturn = {
  legendHTML: string;
  legendHeight: number;
};

export type PdfPage = { html: string };

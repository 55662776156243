import { useQuery, UseQueryOptions } from '@vue/apollo-composable';
import { computed, Ref, unref } from 'vue';
import { useRoute } from 'vue-router';

import {
  TenantQuery,
  TenantQueryVariables,
  TenantsQuery,
  TenantsQueryVariables,
  UpdateTenantMutation,
  UpdateTenantMutationVariables,
} from '@/graphql/__generated__/graphql';
import TenantsAllQuery from '@/graphql/tenants/All.gql';
import TenantDetailQuery from '@/graphql/tenants/Detail.gql';
import TenantFragmentDocument from '@/graphql/tenants/OwnFragment.gql';
import TenantUpdate from '@/graphql/tenants/Update.gql';
import { omitKeys } from '@/helpers/utils/objects';
import { AppApolloClient } from '@/interfaces/graphql';
import { TenantRepository } from '@/interfaces/repositories';
import {
  Entity,
  MutationResult,
  OrderByCondition,
  QueryAllResult,
  QueryResult,
} from '@/interfaces/repositories/base';
import {
  FindAllTenantsVariables,
  OwnTenant,
  Tenant,
  TenantType,
  UpdateTenantVariables,
} from '@/interfaces/repositories/tenant';
import { useDefaultMutationOnEntity, useDefaultQueryOnEntity } from '@/repositories/utils/defaults';
import {
  flattenNodeConnection,
  getOrderByArgument,
  useFetchAllResult,
} from '@/repositories/utils/fetchAll';
import {
  getTypedMutate,
  omitAndReplaceAddress,
  useCalledIndicator,
} from '@/repositories/utils/helper';

import { FragmentName, getDataId, getOptimisticResponse, NodeName } from './utils/cache';

export class GraphQLTenantRepository implements TenantRepository {
  public constructor(private client: AppApolloClient) {}

  public useUpdate(
    id: string,
    vars: Ref<UpdateTenantVariables>,
  ): MutationResult<UpdateTenantVariables> {
    const { mutate, error, loading } = useDefaultMutationOnEntity<
      UpdateTenantMutation,
      UpdateTenantMutationVariables
    >(
      TenantUpdate,
      computed(() => ({
        id,
        ...omitKeys(vars.value, ['previewUrl']),
      })),
    );

    return {
      mutate: (data?: UpdateTenantVariables) => {
        const ownId = unref(id) ?? '';

        const mergedData = { ...vars.value, ...(data || {}) };

        // read fragment of own object from cache to get additional data, that never gets updated
        const tenant = this.client.readFragment({
          id: getDataId(NodeName.TENANT, ownId),
          fragment: TenantFragmentDocument,
          fragmentName: FragmentName.OWN_TENANT,
        });

        const optimisticResponse = getOptimisticResponse<UpdateTenantMutation>(() => {
          return {
            updateTenant: {
              __typename: 'UpdateTenantPayload',
              tenant: {
                ...tenant,
                ...omitKeys(mergedData, ['image', 'previewUrl']),
                type: tenant.type ?? '',
                logoUrl: mergedData.previewUrl ?? tenant.logoUrl ?? '',
                address: {
                  ...mergedData.address,
                  __typename: NodeName.ADDRESS,
                },
                __typename: NodeName.TENANT,
              },
            },
          };
        });

        return getTypedMutate<UpdateTenantVariables, UpdateTenantMutationVariables>(mutate)(data, {
          optimisticResponse,
        }).then((result) => result?.data?.updateUser?.user);
      },
      loading,
      error,
    };
  }

  public fetchOne(id: string): QueryResult<OwnTenant | null, Entity> {
    const variables: TenantQueryVariables = {
      id,
    };

    const { result, loading, error, refetch } = useDefaultQueryOnEntity<
      TenantQuery,
      TenantQueryVariables
    >(TenantDetailQuery, variables);

    const { called } = useCalledIndicator(result);

    return {
      result: computed(() =>
        result.value?.tenant
          ? {
              ...result.value.tenant,
              ...omitAndReplaceAddress(result.value.tenant),
              type: result.value.tenant.type as TenantType,
            }
          : null,
      ),
      loading,
      error,
      refetch,
      called,
    };
  }

  public fetchAll(
    vars: Ref<FindAllTenantsVariables>,
    orderBy?: OrderByCondition,
  ): QueryAllResult<Tenant> {
    const {
      result: rawResult,
      loading,
      error,
      refetch,
    } = useQuery<TenantsQuery, TenantsQueryVariables>(
      TenantsAllQuery,
      () => ({
        ...vars.value,
        orderBy: getOrderByArgument(orderBy),
      }),
      {
        // enable query only if valid name
        // hack: vue apollo has a type error here -> enabled should be a Ref, but the type is declared a boolean only
        enabled: computed(() => !!vars.value.nameContains),
        fetchPolicy: 'cache-and-network',
      },
    );
    const result = useFetchAllResult<TenantsQuery, NonNullable<TenantsQuery['tenants']>, Tenant>(
      rawResult,
      'tenants',
      flattenNodeConnection,
    );

    const { called } = useCalledIndicator(rawResult);

    return { ...result, loading, error, refetch, called };
  }

  public fetchOwn(options?: UseQueryOptions): QueryResult<OwnTenant | null, Entity> {
    const route = useRoute();

    const variables: Ref<TenantQueryVariables> = computed(() => ({
      id: route.params.tenantId as string,
    }));

    const { result, loading, error, refetch } = useDefaultQueryOnEntity<
      TenantQuery,
      TenantQueryVariables
    >(TenantDetailQuery, variables, {
      enabled: computed(() => unref(options?.enabled) ?? true),
    });

    const { called } = useCalledIndicator(result);

    return {
      result: computed(() =>
        result.value?.tenant
          ? {
              ...result.value.tenant,
              ...omitAndReplaceAddress(result.value.tenant),
              type: result.value.tenant.type as TenantType,
            }
          : null,
      ),
      loading,
      error,
      refetch,
      called,
    };
  }
}

import { DependencyType } from '@koppla-tech/scheduling-engine';

import {
  CalendarEntity,
  Entity,
  InteractiveEntityMaps,
  InteractiveEntityStores,
  MilestoneEntity,
  OrderDependencyEntity,
  OrderEntity,
  PartialEntity,
  PauseEntity,
  TradeSequenceEntity,
  WbsSectionEntity,
} from '@/common/types';
import {
  convertCalendarDeleteOperationInput,
  convertCalendarUpdateOperationInput,
} from '@/features/calendars/calendarOperationsUtils';
import {
  convertMilestoneDeleteOperationInput,
  convertMilestoneUpdateOperationInput,
} from '@/features/milestones/stores/milestoneOperationsUtils';
import { convertOrderDependencyDeleteOperationInput } from '@/features/orderDependencies/orderDependencyOperationsUtils';
import {
  convertOrderDeleteOperationInput,
  convertOrderUpdateOperationInput,
} from '@/features/orders/orderOperationsUtils';
import {
  convertPauseDeleteOperationInput,
  convertPauseUpdateOperationInput,
} from '@/features/pauses/pauseOperationsUtils';
import { convertSectionDeleteOperationInput } from '@/features/projectStructure/utils/sectionOperationsUtils';
import { convertProjectTradeSequenceDeleteOperationInput } from '@/features/projectTradeSequences/projectTradeSequenceOperationsUtils';
import { DependencyDetail } from '@/helpers/orders/dependencies';

import { RestoredEntityNotFoundError } from '../realTimeCollaboration/rtcController/errors';
import { OperationInputType, RemoteProjectChangeEvent } from '../realTimeCollaboration/types';

export function convertRescheduleScheduleNodesOperationInput(
  input: OperationInputType<'RescheduleScheduleNodes'>,
  entityStates: InteractiveEntityMaps,
): {
  pauses?: PartialEntity<PauseEntity>[];
  orders?: PartialEntity<OrderEntity>[];
  milestones?: PartialEntity<MilestoneEntity>[];
  calendars?: PartialEntity<CalendarEntity>[];
} {
  return {
    ...(input.pauses
      ? {
          pauses: convertPauseUpdateOperationInput(input.pauses),
        }
      : {}),
    ...(input.orders
      ? {
          orders: convertOrderUpdateOperationInput(input.orders),
        }
      : {}),
    ...(input.milestones
      ? {
          milestones: convertMilestoneUpdateOperationInput(input.milestones),
        }
      : {}),
    ...(input.calendars
      ? {
          calendars: convertCalendarUpdateOperationInput(input.calendars, entityStates.calendars!),
        }
      : {}),
  };
}

export function convertRemoveScheduleNodesOperationInput(
  input: OperationInputType<'RemoveScheduleNodes'>,
  entityStates: InteractiveEntityMaps,
): {
  pauses?: Entity[];
  orders?: Entity[];
  milestones?: Entity[];
  calendars?: Entity[];
  dependencies?: Entity[];
  wbsSections?: Entity[];
  tradeSequences?: Entity[];
} {
  const sectionDeletionChanges = convertSectionDeleteOperationInput(input, entityStates);
  const orderDeletionChanges = convertOrderDeleteOperationInput(input);
  const milestoneDeletionChanges = convertMilestoneDeleteOperationInput(input);
  return {
    pauses: convertPauseDeleteOperationInput(input),
    orders: orderDeletionChanges
      ? [...orderDeletionChanges, ...(sectionDeletionChanges?.orders ?? [])]
      : sectionDeletionChanges?.orders,
    milestones: milestoneDeletionChanges
      ? [...milestoneDeletionChanges, ...(sectionDeletionChanges?.milestones ?? [])]
      : sectionDeletionChanges?.milestones,
    calendars: convertCalendarDeleteOperationInput(input),
    dependencies: convertOrderDependencyDeleteOperationInput(input),
    wbsSections: sectionDeletionChanges?.wbsSections,
    tradeSequences: convertProjectTradeSequenceDeleteOperationInput(input),
  };
}

export function convertRestoreScheduleNodesOperationInput(
  restoredEntities: RemoteProjectChangeEvent['restoredEntities'],
  entityStores: InteractiveEntityStores,
): {
  pauses: PauseEntity[];
  orders: OrderEntity[];
  milestones: MilestoneEntity[];
  calendars: CalendarEntity[];
  dependencies: OrderDependencyEntity[];
  tradeSequences: TradeSequenceEntity[];
  wbsSections: WbsSectionEntity[];
} {
  if (!restoredEntities) {
    return {
      pauses: [],
      orders: [],
      milestones: [],
      calendars: [],
      dependencies: [],
      tradeSequences: [],
      wbsSections: [],
    };
  }

  return {
    pauses: getRestoredPauses(restoredEntities.pauses, entityStores),
    orders: getRestoredOrders(restoredEntities.orders, entityStores),
    milestones: getRestoredMilestones(restoredEntities.milestones, entityStores),
    calendars: getRestoredCalendars(restoredEntities.calendars, entityStores),
    dependencies: getRestoredDependencies(
      restoredEntities.dependencyDetails,
      restoredEntities.dependencies,
      entityStores,
    ),
    tradeSequences: getRestoredTradeSequences(restoredEntities.tradeSequences, entityStores),
    wbsSections: getRestoredWbsSections(restoredEntities.wbsSections, entityStores),
  };
}

function getRestoredWbsSections(
  wbsSections: string[] | null | undefined,
  entityStores: InteractiveEntityStores,
): WbsSectionEntity[] {
  const restoredWbsSections =
    wbsSections?.flatMap((id) => entityStores.wbsSectionStore().getSoftDeletedEntities(id)) ?? [];
  const unresolvedWbsSectionIdx = restoredWbsSections.findIndex((wbsSection) => !wbsSection);
  if (unresolvedWbsSectionIdx !== -1) {
    throw new RestoredEntityNotFoundError('wbsSection', wbsSections![unresolvedWbsSectionIdx]);
  }
  return restoredWbsSections as WbsSectionEntity[];
}

function getRestoredTradeSequences(
  tradeSequences: string[] | null | undefined,
  entityStores: InteractiveEntityStores,
) {
  const restoredTradeSequences =
    tradeSequences?.map((id) =>
      entityStores.projectTradeSequenceStore().getSoftDeletedEntity(id),
    ) ?? [];
  const unresolvedTradeSequenceIdx = restoredTradeSequences.findIndex(
    (tradeSequence) => !tradeSequence,
  );
  if (unresolvedTradeSequenceIdx !== -1) {
    throw new RestoredEntityNotFoundError(
      'tradeSequence',
      tradeSequences![unresolvedTradeSequenceIdx],
    );
  }

  return restoredTradeSequences as TradeSequenceEntity[];
}

function getRestoredDependencies(
  dependencyDetails: DependencyDetail[] | null | undefined,
  dependencies: string[] | null | undefined,
  entityStores: InteractiveEntityStores,
): OrderDependencyEntity[] {
  const restoredDependencies: (OrderDependencyEntity | undefined)[] = [];
  const restoredDependencyIds = new Set();

  dependencyDetails?.forEach((dependencyDetail) => {
    if (restoredDependencyIds.has(dependencyDetail.id)) {
      return;
    }
    restoredDependencyIds.add(dependencyDetail.id);
    restoredDependencies.push({
      ...dependencyDetail,
      from: {
        id: dependencyDetail.from.orderId ?? dependencyDetail.from.milestoneId ?? '',
      },
      to: {
        id: dependencyDetail.to.orderId ?? dependencyDetail.to.milestoneId ?? '',
      },
      type: dependencyDetail.type as DependencyType,
    });
  });

  dependencies?.forEach((id) => {
    if (restoredDependencyIds.has(id)) {
      return;
    }
    restoredDependencies.push(entityStores.orderDependencyStore().getSoftDeletedEntity(id));
  });

  const unresolvedDependencyIdx = restoredDependencies.findIndex((dependency) => !dependency);
  if (unresolvedDependencyIdx !== -1) {
    throw new RestoredEntityNotFoundError('dependency', dependencies![unresolvedDependencyIdx]);
  }

  return restoredDependencies as OrderDependencyEntity[];
}

function getRestoredCalendars(
  calendars: string[] | null | undefined,
  entityStores: InteractiveEntityStores,
): CalendarEntity[] {
  const restoredCalendars =
    calendars?.map((id) => entityStores.calendarStore().getSoftDeletedEntity(id)) ?? [];
  const unresolvedCalendarIdx = restoredCalendars.findIndex((calendar) => !calendar);
  if (unresolvedCalendarIdx !== -1) {
    throw new RestoredEntityNotFoundError('calendar', calendars![unresolvedCalendarIdx]);
  }
  return restoredCalendars as CalendarEntity[];
}

function getRestoredMilestones(
  milestones: string[] | null | undefined,
  entityStores: InteractiveEntityStores,
): MilestoneEntity[] {
  const restoredMilestones =
    milestones?.map((id) => entityStores.milestoneStore().getSoftDeletedEntity(id)?.milestone) ??
    [];

  const unresolvedMilestoneIdx = restoredMilestones.findIndex((milestone) => !milestone);
  if (unresolvedMilestoneIdx !== -1) {
    throw new RestoredEntityNotFoundError('milestone', milestones![unresolvedMilestoneIdx]);
  }

  return restoredMilestones as MilestoneEntity[];
}

function getRestoredPauses(
  pauses: string[] | null | undefined,
  entityStores: InteractiveEntityStores,
): PauseEntity[] {
  const restoredPauses =
    pauses?.map((id) => entityStores.pauseStore().getSoftDeletedEntity(id)) ?? [];

  const unresolvedPauseIdx = restoredPauses.findIndex((pause) => !pause);
  if (unresolvedPauseIdx !== -1) {
    throw new RestoredEntityNotFoundError('pause', pauses![unresolvedPauseIdx]);
  }
  return restoredPauses as PauseEntity[];
}

function getRestoredOrders(
  orders: string[] | null | undefined,
  entityStores: InteractiveEntityStores,
) {
  const restoredOrders =
    orders?.map((id) => entityStores.orderStore().getSoftDeletedEntity(id)?.order) ?? [];

  const unresolvedOrderIdx = restoredOrders.findIndex((order) => !order);
  if (unresolvedOrderIdx !== -1) {
    throw new RestoredEntityNotFoundError('order', orders![unresolvedOrderIdx]);
  }

  return restoredOrders as OrderEntity[];
}

import { OperationNames } from 'events.schema';

import { CalendarEntity, PartialEntity } from '@/common/types';
import {
  parseEntityExceptionIntoOperationInputException,
  parseEntityWorkingDayIntoOperationInputWorkingDay,
} from '@/features/calendars/calendarUtils';

import { generateRTCMessageId, LocalProjectChangeEventTemplate } from '../realTimeCollaboration';
import { ProjectChangeEventContext } from '../realTimeCollaboration/types';

export const createProjectChangeCreateEvent = (
  vars: CalendarEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CreateCalendars,
      input: vars.map((calendar) => ({
        id: calendar.id,
        name: calendar.name,
        isDefault: calendar.isDefault,
        minutesPerDay: calendar.minutesPerDay,
        workingDays: calendar.workingDays.map(parseEntityWorkingDayIntoOperationInputWorkingDay),
        exceptions: calendar.exceptions.map(parseEntityExceptionIntoOperationInputException),
      })),
      context,
    },
  };
};

export const createProjectChangeUpdateEvent = (
  vars: PartialEntity<CalendarEntity>[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.UpdateCalendars,
      input: vars.map((partialCalendar) => ({
        id: partialCalendar.id,
        ...(partialCalendar.name !== undefined
          ? {
              name: partialCalendar.name,
            }
          : {}),
        ...(partialCalendar.isDefault !== undefined
          ? {
              isDefault: partialCalendar.isDefault,
            }
          : {}),
        ...(partialCalendar.workingDays !== undefined
          ? {
              workingDays: partialCalendar.workingDays.map(
                parseEntityWorkingDayIntoOperationInputWorkingDay,
              ),
            }
          : {}),
        ...(partialCalendar.exceptions !== undefined
          ? {
              exceptions: partialCalendar.exceptions.map(
                parseEntityExceptionIntoOperationInputException,
              ),
            }
          : {}),
        ...(partialCalendar.minutesPerDay !== undefined
          ? {
              minutesPerDay: partialCalendar.minutesPerDay,
            }
          : {}),
      })),
      context,
    },
  };
};

export const createProjectChangeRestoreEvent = (
  calendars: CalendarEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RestoreScheduleNodes,
      input: {
        calendars: calendars.map((calendar) => calendar.id),
      },
      context,
    },
    restoredEntities: {
      calendars: calendars.map((calendar) => calendar.id),
    },
  };
};

export const createProjectChangeDeleteEvent = (
  calendars: CalendarEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RemoveScheduleNodes,
      input: {
        calendars: calendars.map((calendar) => calendar.id),
      },
      context,
    },
  };
};

import { defineStore } from 'pinia';

import { UserFragment } from '@/graphql/__generated__/graphql';

import { UserStoreRepository } from './repository';

export const useUserStore = (repository?: UserStoreRepository) => {
  const repo = repository ?? new UserStoreRepository();
  return defineStore('user-store', {
    state: (): {
      membershipId: string | null;
      ownUser: UserFragment | null;
      fetchLoading: boolean;
      fetchError: Error | null;
    } => ({
      membershipId: null,
      ownUser: null,
      fetchError: null,
      fetchLoading: false,
    }),
    actions: {
      async fetchOwn() {
        if (this.ownUser) return Promise.resolve(this.ownUser);
        this.fetchLoading = true;
        return repo
          .fetchOwn()
          .then((result) => {
            if (!result) return null;
            this.ownUser = result;
            return this.ownUser;
          })
          .catch((error) => {
            this.fetchError = error;
            return Promise.resolve(null);
          })
          .finally(() => {
            this.fetchLoading = false;
          });
      },
      setMembershipId(membershipId: string | null) {
        this.membershipId = membershipId;
      },
      async updateOwn(vars: UserFragment) {
        this.ownUser = {
          ...vars,
          profilePicture: vars.profilePicture ?? this.ownUser?.profilePicture,
        };
      },
      reset() {
        this.$reset();
      },
    },
    persist: {
      pick: ['membershipId'],
    },
  })();
};

/**
 * Use file specific import paths instead of modules (e.g. @/interfaces/services)
 * so we don't automatically import a ton of other stuff.
 *
 * Changing this might break the showroom build because .gql are
 * included and we currently don't use the GraphQL rollup plugin for t.
 */
import { LoggingService } from '@/interfaces/services/logging';

import { ConsoleLoggingService } from './console';

let loggingService: LoggingService = new ConsoleLoggingService();

export function registerLoggingServiceComposable(logger: LoggingService): void {
  loggingService = logger;
}

export function useLoggingService(): LoggingService {
  return loggingService;
}

import { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from 'vue-router';

import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { GuardMeta, useWithMembership } from '@/router/helpers/memberships';
import {
  getProjectsAllOrPickTenantRoute,
  getRouteForMembershipStatus,
} from '@/router/helpers/redirection';
import { useRouteNames } from '@/router/helpers/routeNames';

const { Routes } = useRouteNames();

export function useUnauthenticatedRoutes(): RouteRecordRaw[] {
  return [
    { path: '', redirect: { name: Routes.Unauthenticated.Login } },
    {
      path: '/sign_in',
      name: Routes.Unauthenticated.Login,
      component: () => import('@/views/unauthenticated/Login.vue'),
    },
    {
      path: '/reset_password',
      name: Routes.Unauthenticated.ResetPassword,
      component: () => import('@/views/unauthenticated/ResetPassword.vue'),
    },
    // Redirect google auth mail links
    {
      path: '/auth',
      redirect: ({ query }) => {
        if (query.mode === 'resetPassword') {
          return { name: Routes.Unauthenticated.SetPassword, query };
        }
        return { name: Routes.Unauthenticated.Login };
      },
      meta: {
        // auth links require logout to be accessed
        isLogoutRequired: true,
      },
    },
    {
      path: '/set_password',
      name: Routes.Unauthenticated.SetPassword,
      props: (route) => ({
        code: route.query.oobCode,
      }),
      component: () => import('@/views/unauthenticated/SetPassword.vue'),
    },
    {
      path: '/sign_up',
      name: Routes.Unauthenticated.SignUp,
      props: (route) => ({
        email: route.query.email,
        tenant: route.query.tenant,
        invitationToken: route.query.code,
      }),
      component: () => import('@/views/unauthenticated/SignUp.vue'),
    },
  ];
}

export function useUnauthenticatedGuard({
  authenticationService,
  loggingService,
  membershipStore,
}: GuardMeta): (to: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<any> {
  return async (to): Promise<any> => {
    const isAuthenticated = await authenticationService.isAuthenticated().catch(() => {});
    if (!isAuthenticated) {
      return;
    }

    const guard = (
      membership: MembershipWithTenant | undefined | null,
      memberships: MembershipWithTenant[],
    ): boolean | RouteLocationRaw => {
      const redirection = getRouteForMembershipStatus(to, membership, memberships);
      if (redirection) return redirection;
      const activeMembership = membership!;

      // We expect that either activeMembership is set
      const tempTenantId = activeMembership.tenant.id;
      /** redirect to projects, if authenticated and membership selected */
      return getProjectsAllOrPickTenantRoute(tempTenantId);
    };
    useWithMembership({ to }, { loggingService, membershipStore }, guard);
  };
}

import { addMinutes, differenceInMinutes, max as maxDate } from 'date-fns';

import { OrderEntity } from '@/common/types';
import { useMilestoneStoreV2 } from '@/features/milestones';
import { useOrderStoreV2 } from '@/features/orders';
import { zonedTimeToUtc } from '@/helpers/utils/dates';

import { getSectionDescendants, useWbsSectionStoreV2 } from '../projectStructure';
import { useBasePlanStore } from './basePlanStore';
import {
  BaseActualStatus,
  BaseEntityState,
  BaseMilestoneState,
  BaseOrderState,
  BaseSectionState,
  BaseState,
} from './types';

export const useBaseOrder = (id: Ref<string | null>) => {
  const basePlanStore = useBasePlanStore();
  const orderStore = useOrderStoreV2();

  const getBaseOrder = (): BaseEntityState<BaseOrderState> | undefined => {
    const orderId = unref(id);
    if (!basePlanStore.hasBasePlan || !orderId) return undefined;

    const order = orderStore.orders.get(orderId);
    if (!order) return undefined;

    const baseOrder = basePlanStore.baseOrders.get(orderId);

    if (!baseOrder) {
      return { status: BaseActualStatus.UNAVAILABLE, timeDifference: undefined, entity: undefined };
    }

    return {
      ...calculateBaseOrderState(
        { finishAt: new SchedulingDate(order.finishAt) },
        { finishAt: new SchedulingDate(baseOrder.finishAt) },
      ),
      entity: baseOrder,
    };
  };

  return computed(getBaseOrder);
};

export function calculateBaseOrderState(
  order: { finishAt: Date },
  baseOrder: { finishAt: Date },
): BaseState {
  const timeDifference = differenceInMinutes(
    zonedTimeToUtc(baseOrder.finishAt),
    zonedTimeToUtc(order.finishAt),
  );

  return { status: timeDifferenceToStatus(timeDifference), timeDifference };
}

export const useBaseDryingTime = (dryingTimeOrderId: Ref<string | null>) => {
  const basePlanStore = useBasePlanStore();
  const orderStore = useOrderStoreV2();

  const getBaseOrder = (): BaseEntityState<BaseOrderState> | undefined => {
    if (!basePlanStore.hasBasePlan || !dryingTimeOrderId.value) return undefined;

    const order = orderStore.orders.get(dryingTimeOrderId.value);
    if (!order) return undefined;

    const baseOrder = basePlanStore.baseOrders.get(dryingTimeOrderId.value);

    if (!baseOrder) {
      return { status: BaseActualStatus.UNAVAILABLE, timeDifference: undefined, entity: undefined };
    }

    return {
      ...calculateBaseDryingTimeState(
        {
          finishAt: new SchedulingDate(order.finishAt),
          dryingTimeDuration: order.dryingBreak?.duration ?? 0,
        },
        {
          finishAt: new SchedulingDate(baseOrder.finishAt),
          dryingTimeDuration: baseOrder.dryingBreakDuration ?? 0,
        },
      ),
      entity: baseOrder,
    };
  };

  return computed(getBaseOrder);
};

export function calculateBaseDryingTimeState(
  order: { finishAt: SchedulingDate; dryingTimeDuration: number | null | undefined },
  baseOrder: { finishAt: SchedulingDate; dryingTimeDuration: number | null | undefined },
): BaseState {
  const timeDifference = differenceInMinutes(
    addMinutes(zonedTimeToUtc(baseOrder.finishAt), baseOrder.dryingTimeDuration ?? 0),
    addMinutes(zonedTimeToUtc(order.finishAt), order.dryingTimeDuration ?? 0),
  );

  return { status: timeDifferenceToStatus(timeDifference), timeDifference };
}

export const useBaseMilestone = (milestoneId: Ref<string>) => {
  const basePlanStore = useBasePlanStore();
  const milestoneStore = useMilestoneStoreV2();

  const getBaseMilestone = (): BaseEntityState<BaseMilestoneState> | undefined => {
    if (!basePlanStore.hasBasePlan || !milestoneId.value) return undefined;

    const milestone = milestoneStore.milestones.get(milestoneId.value);
    if (!milestone) return undefined;

    const baseMilestone = basePlanStore.baseMilestones.get(milestoneId.value);

    if (!baseMilestone) {
      return { status: BaseActualStatus.UNAVAILABLE, timeDifference: undefined, entity: undefined };
    }

    const timeDifference = differenceInMinutes(
      zonedTimeToUtc(baseMilestone.date),
      zonedTimeToUtc(new SchedulingDate(milestone.date)),
    );

    return {
      status: timeDifferenceToStatus(timeDifference),
      timeDifference,
      entity: baseMilestone,
    };
  };

  return computed(getBaseMilestone);
};

export const useBaseSection = (
  sectionId: Ref<string | null>,
  preComputedSectionOrders?: OrderEntity[],
): ComputedRef<BaseEntityState<BaseSectionState> | undefined> => {
  const basePlanStore = useBasePlanStore();
  const sectionStore = useWbsSectionStoreV2();
  const orderStore = useOrderStoreV2();

  const getBaseSection = (): BaseEntityState<BaseSectionState> | undefined => {
    if (!basePlanStore.hasBasePlan || !sectionId.value) return undefined;

    const section = sectionStore.wbsSections.get(sectionId.value);
    if (!section) return undefined;

    const baseSection = basePlanStore.baseSections.get(sectionId.value);
    if (!baseSection) {
      return { status: BaseActualStatus.UNAVAILABLE, timeDifference: undefined, entity: undefined };
    }

    let sectionOrders = preComputedSectionOrders;
    if (!sectionOrders) {
      const descendants = getSectionDescendants(sectionStore.wbsSections, section).map(
        (descendant) => descendant.id,
      );
      sectionOrders = [...orderStore.orders.values()].filter((order) =>
        descendants.includes(order.wbsSection?.id ?? ''),
      );
    }

    return {
      ...calculateBaseSectionState(
        baseSection,
        sectionOrders.map((order) => {
          return {
            finishAt: new SchedulingDate(order.finishAt),
            dryingTimeDuration: order.dryingBreak?.duration ?? 0,
          };
        }),
      ),
      entity: baseSection,
    };
  };

  return computed(getBaseSection);
};

export function calculateBaseSectionState(
  baseSection: { finishAt: Date },
  orders: { finishAt: Date; dryingTimeDuration: number }[],
) {
  const currentFinishAt = maxDate(
    orders.map((order) => addMinutes(zonedTimeToUtc(order.finishAt), order.dryingTimeDuration)),
  );

  const timeDifference = differenceInMinutes(zonedTimeToUtc(baseSection.finishAt), currentFinishAt);

  return { status: timeDifferenceToStatus(timeDifference), timeDifference, entity: baseSection };
}

function timeDifferenceToStatus(timeDifference: number): BaseActualStatus {
  if (timeDifference === 0) return BaseActualStatus.ON_TIME;
  if (timeDifference > 0) return BaseActualStatus.AHEAD_OF_TIME;
  return BaseActualStatus.DELAYED;
}

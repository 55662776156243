import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

export class VersionHistoryOpened extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.VERSION_HISTORY_OPENED;
}

/** NOTE: This event is not yet implemented */
export class VersionHistoryViewed extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.VERSION_HISTORY_VIEWED;
}

export class VersionHistoryApplied extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.VERSION_HISTORY_APPLIED;

  public constructor(
    public properties: {
      ageInMinutes: number;
      type: 'manual' | 'automatic';
    },
  ) {
    super();
  }
}

export class VersionHistoryHelpClicked extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.VERSION_HISTORY_HELP_CLICKED;
}

export class VersionHistoryManuallySaved extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.VERSION_HISTORY_MANUALLY_SAVED;
}

import { Entity } from '@/interfaces/repositories/base';

/**
 * Returns generic onError function for stores to update the state based on given new fragment
 * @param state
 * @param id
 * @returns
 */
export function getOnError<T extends Entity>(
  state: Map<string, T>,
  fragments: T[],
  options: {
    delete: boolean;
  } = { delete: false },
): () => void {
  return () => {
    fragments.forEach((fragment) => {
      if (options.delete) {
        state.delete(fragment.id);
      } else {
        state.set(fragment.id, fragment);
      }
    });
  };
}

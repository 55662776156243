import { register } from 'register-service-worker';

import { FIVE_MINUTES_IN_MS } from '@/helpers/utils/timeConstants';
import { LoggingService } from '@/interfaces/services';
import { BASE_URL, NODE_ENV } from '@/utils/config';
import { AppErrorCode } from '@/utils/errors';

export function registerServiceWorker(loggingService: LoggingService): void {
  if (NODE_ENV !== 'production') return;

  register(`${BASE_URL}sw.js`, {
    ready() {
      loggingService.info(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered(registration: ServiceWorkerRegistration) {
      loggingService.info('Service worker has been registered.');

      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update().catch((error) => {
          loggingService.error(error, { code: AppErrorCode.SERVICE_WORKER });
        });
      }, FIVE_MINUTES_IN_MS);
    },
    cached() {
      loggingService.info('Content has been cached for offline use.');
    },
    updatefound() {
      loggingService.info('New content is downloading.');
    },
    updated(registration: ServiceWorkerRegistration) {
      loggingService.info('New content is available; please refresh.');

      // Add a custom event and dispatch it.
      // Used to display of a 'refresh' banner following a service worker update.
      // Set the event payload to the service worker registration object.
      document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }));
    },
    offline() {
      loggingService.info('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      loggingService.error(error, { code: AppErrorCode.SERVICE_WORKER });
    },
  });
}

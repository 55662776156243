export const support = {
  BASE_PLAN: 'https://support.koppla.de/de/articles/77885-wie-funktioniert-der-soll-ist-vergleich',
  FLEXIBLE_TRADE_SEQUENCE:
    'https://support.koppla.de/de/articles/43627-der-neue-gewerkesequenzeditor',
  GANTT_EXPORT:
    'https://support.koppla.de/de/articles/213705-wie-funktioniert-der-koppla-export-fur-gantt-ansichten',
  MILESTONE_ASSISTANT:
    'https://support.koppla.de/de/articles/84970-wie-funktioniert-der-meilensteinassistent',
  MSSO: 'https://support.koppla.de/de/articles/67060-microsoft-konto-mit-koppla-verknupfen',
  PROJECT_STRUCTURE_TEMPLATE:
    'https://support.koppla.de/de/articles/122875-wie-funktionieren-unternehmensweite-vorlagen-fur-meine-struktur',
  TENANT_TEMPLATE:
    'https://support.koppla.de/de/articles/58194-wie-du-gewerkesequenzen-fur-dein-gesamtes-unternehmen-nutzen-kannst',
  TICKET: 'https://support.koppla.de/de/articles/108075-wie-funktionieren-tickets-in-koppla',
  TRADE_TEMPLATE:
    'https://support.koppla.de/de/articles/58530-gewerke-in-koppla-projektubergreifend-vs-projektspezifisch',
  VERSION: 'https://support.koppla.de/de/articles/17691-was-ist-der-versionsverlauf',
  WORKING_TOGETHER: 'https://support.koppla.de/de/articles/151795-gemeinsam-in-koppla-arbeiten',
  HOW_DEPENDENCIES_WORK:
    'https://support.koppla.de/de/articles/13411-wie-funktionieren-abhangigkeiten-in-koppla',
  CONNECTION_ERROR:
    'https://support.koppla.de/de/articles/197280-verbindungsfehler-mit-koppla-beheben',
  STATUS_UPDATE: 'https://support.koppla.de/de/articles/203604-projekte-organisieren-in-koppla',
};

export const contact = {
  ENTERPRISE_FEATURES: 'https://calendly.com/marco-trippler/koppla-enterprise-funktionen',
  EXPORT_APPLICATION_SUGGESTION: 'https://forms.gle/vh7x9qTAN2xLpvfa8',
};

export const website = {
  STATUS: 'https://status.koppla.de',
  IMPRINT: 'https://koppla.de/imprint',
  PRIVACY:
    'https://koppla.notion.site/Datenschutzerkl-rung-my-koppla-de-459266af87d34f009aa7e1418b1bae9d',
  TERMS:
    'https://koppla.notion.site/Allgemeine-Gesch-ftsbedingungen-AGB-6570f78a09df4512ba0c0b896cb6749f',
};

import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const milestoneQuery = gqlFn(/* GraphQL */ `
  query projectMilestonesV2($project: ID!) {
    projectMilestones(project: $project) {
      edges {
        node {
          ...ProjectMilestone
        }
      }
    }
  }
`);

export const milestoneFragment = gql`
  fragment ProjectMilestone on ProjectMilestoneNode {
    acceptanceCriteria {
      completedAt
      id
      name
      sequenceNumber
    }
    color
    completedAt
    completedBy {
      firstName
      lastName
    }
    date
    id
    isFixed
    name
    wbsSection {
      id
    }
  }
`;

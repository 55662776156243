import { computed, ComputedRef } from 'vue';

import bremerLogo from '@/assets/themes/bremer/logo.svg';
import defaultWithText from '@/assets/themes/default/logo.svg';
import defaultWithoutText from '@/assets/themes/default/logo_without_text.svg';
import dresslerLogo from '@/assets/themes/dressler/logo.svg';
import jwaLogo from '@/assets/themes/jwa/logo.svg';
import mbnWithText from '@/assets/themes/mbn/logo.svg';
import mbnWithoutText from '@/assets/themes/mbn/logo_without_text.svg';
import schmeingLogo from '@/assets/themes/schmeing/logo.svg';
import { useGlobalStore } from '@/common/globalStore';
import { IS_MBN } from '@/utils/config';

interface Theme {
  color: string;
  backgroundColor: string;
  iconColor: string;
  iconBackgroundColor: string;
  icon: string;
  iconWithoutText: string;
}

type TenantThemes = {
  [key: string]: Theme;
};

export function useTheme(): ComputedRef<Theme> {
  const globalStore = useGlobalStore();

  const theme = computed(() => {
    if (IS_MBN) {
      return TENANT_THEMES.MBN;
    }

    const customTheme = TENANT_THEMES[globalStore.currentTenantId || ''];

    return customTheme || TENANT_THEMES.DEFAULT;
  });

  return theme;
}

const TENANT_THEMES: TenantThemes = {
  // MBN
  MBN: {
    color: '#FFFFFF',
    backgroundColor: '#293134',
    iconColor: '#FE8F1A',
    iconBackgroundColor: '#3F4B4F',
    icon: mbnWithText,
    iconWithoutText: mbnWithoutText,
  },
  // JWA
  'VGVuYW50Tm9kZTozMzg=': {
    color: '#FFFFFF',
    backgroundColor: '#383B37',
    iconColor: '#383B37',
    iconBackgroundColor: '#FFFFFF',
    icon: jwaLogo,
    iconWithoutText: jwaLogo,
  },
  // Dreßler
  'VGVuYW50Tm9kZTo5MDc=': {
    color: '#383A3B',
    backgroundColor: '#DBEAF1',
    iconColor: '#FFFFFF',
    iconBackgroundColor: '#009FE3',
    icon: dresslerLogo,
    iconWithoutText: dresslerLogo,
  },
  // Schmeing Bau GmbH
  VGVuYW50Tm9kZToxOTcz: {
    color: '#FFFFFF',
    backgroundColor: '#092657',
    iconColor: '#092657',
    iconBackgroundColor: '#FFD616',
    icon: schmeingLogo,
    iconWithoutText: schmeingLogo,
  },
  // Bremer Bau
  VGVuYW50Tm9kZTozOTE3: {
    color: '#FFFFFF',
    backgroundColor: '#01011A',
    iconColor: '#FFFFFF',
    iconBackgroundColor: '#0067FE',
    icon: bremerLogo,
    iconWithoutText: bremerLogo,
  },
  DEFAULT: {
    color: '#FFFFFF',
    backgroundColor: '#002837',
    iconColor: '#F2C36B',
    iconBackgroundColor: '#004460',
    icon: defaultWithText,
    iconWithoutText: defaultWithoutText,
  },
};

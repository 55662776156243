export enum OperationNames {
  PublishProjectAlternative = 'PublishProjectAlternative',
  RestoreProjectVersion = 'RestoreProjectVersion',
  RestoreProjectVersionResult = 'RestoreProjectVersionResult',
  PublishProjectAlternativeResult = 'PublishProjectAlternativeResult',
  CreateCalendars = 'CreateCalendars',
  UpdateCalendars = 'UpdateCalendars',
  CreateDependencies = 'CreateDependencies',
  UpdateDependencies = 'UpdateDependencies',
  RemoveScheduleNodes = 'RemoveScheduleNodes',
  RestoreScheduleNodes = 'RestoreScheduleNodes',
  RescheduleScheduleNodes = 'RescheduleScheduleNodes',
  CreateMilestones = 'CreateMilestones',
  UpdateMilestones = 'UpdateMilestones',
  UpdateMilestoneStatus = 'UpdateMilestoneStatus',
  CreateOrders = 'CreateOrders',
  UpdateOrders = 'UpdateOrders',
  CopyOrders = 'CopyOrders',
  CreateOrderStatusReports = 'CreateOrderStatusReports',
  CompleteProjectSetup = 'CompleteProjectSetup',
  CreatePauses = 'CreatePauses',
  UpdatePauses = 'UpdatePauses',
  DeleteProject = 'DeleteProject',
  DeleteProjectAlternative = 'DeleteProjectAlternative',
  UpdateProjectStatus = 'UpdateProjectStatus',
  InsertTradeSequence = 'InsertTradeSequence',
  CreateTradeSequence = 'CreateTradeSequence',
  UpdateTradeSequence = 'UpdateTradeSequence',
  CreateWBSSections = 'CreateWBSSections',
  UpdateWBSSection = 'UpdateWBSSection',
  OutdentWBSSection = 'OutdentWBSSection',
  IndentWBSSection = 'IndentWBSSection',
}

export type Callback =
  | {
      status: 'SUCCESS';
    }
  | {
      status: 'ERROR';
      error: {
        reason: string;
        message?: string | undefined;
        errorCode: string;
      };
    };

export type ClientProjectChangeMessage = {
  messageId: string;
  stateId: number;
  clientStateId: number;
  operation: {
    name: string;
    input: Buffer;
    context?:
      | ({
          orders?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    /** Date as ISO string. */
                    startAt?: (string | null) | undefined;
                    /** Date as ISO string. */
                    finishAt?: (string | null) | undefined;
                    /** GraphQL encoded id. */
                    wbsSectionId?: (string | null) | undefined;
                    /** Working time duration in minutes. */
                    workingTimeDuration?: (number | null) | undefined;
                  }[]
                | null
              )
            | undefined;
          milestones?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    /** GraphQL encoded id. */
                    wbsSectionId?: (string | null) | undefined;
                    /** Date as ISO string. */
                    date?: (string | null) | undefined;
                  }[]
                | null
              )
            | undefined;
          /** Dependencies overwrite any changes made by scheduling engine during scheduling of operation. */
          dependencies?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    lagInMinutes?: (number | null) | undefined;
                    bufferInMinutes?: (number | null) | undefined;
                    useDryingBreak?: (boolean | null) | undefined;
                  }[]
                | null
              )
            | undefined;
        } | null)
      | undefined;
  };
  clientTimestampMs: number;
  projectId: string;
};

export type IncomingProjectChangeMessage = {
  messageId: string;
  stateId?: number | undefined;
  clientId?: string | undefined;
  clientStateId?: number | undefined;
  userId?: number | undefined;
  operation: {
    name: string;
    input: Buffer;
    context?:
      | ({
          orders?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    /** Date as ISO string. */
                    startAt?: (string | null) | undefined;
                    /** Date as ISO string. */
                    finishAt?: (string | null) | undefined;
                    /** GraphQL encoded id. */
                    wbsSectionId?: (string | null) | undefined;
                    /** Working time duration in minutes. */
                    workingTimeDuration?: (number | null) | undefined;
                  }[]
                | null
              )
            | undefined;
          milestones?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    /** GraphQL encoded id. */
                    wbsSectionId?: (string | null) | undefined;
                    /** Date as ISO string. */
                    date?: (string | null) | undefined;
                  }[]
                | null
              )
            | undefined;
          /** Dependencies overwrite any changes made by scheduling engine during scheduling of operation. */
          dependencies?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    lagInMinutes?: (number | null) | undefined;
                    bufferInMinutes?: (number | null) | undefined;
                    useDryingBreak?: (boolean | null) | undefined;
                  }[]
                | null
              )
            | undefined;
        } | null)
      | undefined;
  };
  clientTimestampMs: number;
  projectId: string;
  isSystem?: (boolean | null) | undefined;
  publishToClient?: (boolean | null) | undefined;
};

export type OperationCreateOrders = {
  /** GraphQL encoded id. */
  id: string;
  name: string;
  /** GraphQL encoded id. */
  subcontractorId?: (string | null) | undefined;
  /** GraphQL encoded id. */
  contributorGroupId?: (string | null) | undefined;
  /** GraphQL encoded id. */
  tradeId: string;
  /** GraphQL encoded id. */
  calendarId: string;
  /** Date as ISO string. */
  startAt: string;
  /** Date as ISO string. */
  finishAt: string;
  /** GraphQL encoded id. */
  wbsSectionId: string;
  /** Working time duration in minutes. */
  workingTimeDuration?: (number | null) | undefined;
  dryingBreak?:
    | ({
        duration: number;
        name?: (string | null) | undefined;
      } | null)
    | undefined;
  isFixed?: (boolean | null) | undefined;
  tasks?:
    | (
        | {
            /** GraphQL encoded id. */
            id?: (string | null) | undefined;
            name: string;
            sequenceNumber: number;
          }[]
        | null
      )
    | undefined;
}[];

export type OperationCreatePauses = {
  /** GraphQL encoded id. */
  id: string;
  name: string;
  /** Date as ISO string. */
  start: string;
  /** Date as ISO string. */
  end: string;
}[];

export type OperationRemoveScheduleNodes = {
  /** GraphQL encoded id. */
  orders?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  milestones?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  pauses?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  dependencies?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  calendars?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  tradeSequences?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  wbsSections?: (string[] | null) | undefined;
};

export type OperationRescheduleScheduleNodes = {
  orders?:
    | (
        | {
            /** GraphQL encoded id. */
            id: string;
            /** Date as ISO string. */
            startAt?: (string | null) | undefined;
            /** Date as ISO string. */
            finishAt?: (string | null) | undefined;
            /** GraphQL encoded id. */
            wbsSectionId?: (string | null) | undefined;
            /** Working time duration in minutes. */
            workingTimeDuration?: (number | null) | undefined;
          }[]
        | null
      )
    | undefined;
  milestones?:
    | (
        | {
            /** GraphQL encoded id. */
            id: string;
            /** GraphQL encoded id. */
            wbsSectionId?: (string | null) | undefined;
            /** Date as ISO string. */
            date?: (string | null) | undefined;
          }[]
        | null
      )
    | undefined;
  pauses?:
    | (
        | {
            /** GraphQL encoded id. */
            id: string;
            /** Date as ISO string. */
            startAt?: (string | null) | undefined;
            /** Date as ISO string. */
            finishAt?: (string | null) | undefined;
          }[]
        | null
      )
    | undefined;
  calendars?:
    | (
        | {
            /** GraphQL encoded id. */
            id: string;
            workingDays?:
              | (
                  | {
                      /** Day of week. 0 = Monday, 6 = Sunday */
                      weekday: 0 | 1 | 2 | 3 | 4 | 5 | 6;
                      workingTimes: {
                        /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                        from: string;
                        /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                        to: string;
                      }[];
                    }[]
                  | null
                )
              | undefined;
            exceptions?:
              | (
                  | {
                      /** Date as ISO string. */
                      startAt: string;
                      /** Date as ISO string. */
                      finishAt: string;
                      isWorkingDay: boolean;
                      workingTimes: {
                        /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                        from: string;
                        /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                        to: string;
                      }[];
                    }[]
                  | null
                )
              | undefined;
          }[]
        | null
      )
    | undefined;
};

export type OperationRestoreScheduleNodes = {
  /** GraphQL encoded id. */
  orders?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  milestones?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  pauses?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  dependencies?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  calendars?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  tradeSequences?: (string[] | null) | undefined;
  /** GraphQL encoded id. */
  wbsSections?: (string[] | null) | undefined;
};

export type Operations =
  | (
      | (
          | (
              | (
                  | (
                      | (
                          | (
                              | (
                                  | (
                                      | (
                                          | (
                                              | (
                                                  | (
                                                      | (
                                                          | (
                                                              | (
                                                                  | (
                                                                      | (
                                                                          | (
                                                                              | (
                                                                                  | (
                                                                                      | (
                                                                                          | (
                                                                                              | (
                                                                                                  | (
                                                                                                      | (
                                                                                                          | (
                                                                                                              | (
                                                                                                                  | (
                                                                                                                      | {
                                                                                                                          name: 'PublishProjectAlternative';
                                                                                                                          input: {
                                                                                                                            /** ID of project alternative */
                                                                                                                            alternativeId: string;
                                                                                                                          };
                                                                                                                        }
                                                                                                                      | {
                                                                                                                          name: 'RestoreProjectVersion';
                                                                                                                          input: {
                                                                                                                            /** The ID of the project version. */
                                                                                                                            versionId: string;
                                                                                                                            /** Name that will be used for automatically created version after restore completed. */
                                                                                                                            newVersionName: string;
                                                                                                                          };
                                                                                                                        }
                                                                                                                    )
                                                                                                                  | {
                                                                                                                      name: 'RestoreProjectVersionResult';
                                                                                                                      input: {
                                                                                                                        status:
                                                                                                                          | 'SUCCESS'
                                                                                                                          | 'ERROR';
                                                                                                                        error?:
                                                                                                                          | ({
                                                                                                                              reason: 'INTERNAL';
                                                                                                                              message?:
                                                                                                                                | string
                                                                                                                                | undefined;
                                                                                                                              errorCode: string;
                                                                                                                            } | null)
                                                                                                                          | undefined;
                                                                                                                        source: {
                                                                                                                          /** The ID of the project version. */
                                                                                                                          versionId: string;
                                                                                                                          /** Name that will be used for automatically created version after restore completed. */
                                                                                                                          newVersionName: string;
                                                                                                                        };
                                                                                                                      };
                                                                                                                    }
                                                                                                                )
                                                                                                              | {
                                                                                                                  name: 'PublishProjectAlternativeResult';
                                                                                                                  input: {
                                                                                                                    status:
                                                                                                                      | 'SUCCESS'
                                                                                                                      | 'ERROR';
                                                                                                                    error?:
                                                                                                                      | ({
                                                                                                                          reason: 'INTERNAL';
                                                                                                                          message?:
                                                                                                                            | string
                                                                                                                            | undefined;
                                                                                                                          errorCode: string;
                                                                                                                        } | null)
                                                                                                                      | undefined;
                                                                                                                    source: {
                                                                                                                      /** ID of project alternative */
                                                                                                                      alternativeId: string;
                                                                                                                    };
                                                                                                                  };
                                                                                                                }
                                                                                                            )
                                                                                                          | {
                                                                                                              name: 'CreateCalendars';
                                                                                                              input: {
                                                                                                                /** GraphQL encoded id. */
                                                                                                                id: string;
                                                                                                                /** Defaults to 'false' if not defined. */
                                                                                                                isDefault?:
                                                                                                                  | (
                                                                                                                      | boolean
                                                                                                                      | null
                                                                                                                    )
                                                                                                                  | undefined;
                                                                                                                name: string;
                                                                                                                workingDays: {
                                                                                                                  /** Day of week. 0 = Monday, 6 = Sunday */
                                                                                                                  weekday:
                                                                                                                    | 0
                                                                                                                    | 1
                                                                                                                    | 2
                                                                                                                    | 3
                                                                                                                    | 4
                                                                                                                    | 5
                                                                                                                    | 6;
                                                                                                                  workingTimes: {
                                                                                                                    /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                                    from: string;
                                                                                                                    /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                                    to: string;
                                                                                                                  }[];
                                                                                                                }[];
                                                                                                                exceptions?:
                                                                                                                  | (
                                                                                                                      | {
                                                                                                                          /** Date as ISO string. */
                                                                                                                          startAt: string;
                                                                                                                          /** Date as ISO string. */
                                                                                                                          finishAt: string;
                                                                                                                          isWorkingDay: boolean;
                                                                                                                          workingTimes: {
                                                                                                                            /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                                            from: string;
                                                                                                                            /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                                            to: string;
                                                                                                                          }[];
                                                                                                                        }[]
                                                                                                                      | null
                                                                                                                    )
                                                                                                                  | undefined;
                                                                                                              }[];
                                                                                                            }
                                                                                                        )
                                                                                                      | {
                                                                                                          name: 'UpdateCalendars';
                                                                                                          input: {
                                                                                                            /** GraphQL encoded id. */
                                                                                                            id: string;
                                                                                                            isDefault?:
                                                                                                              | (
                                                                                                                  | boolean
                                                                                                                  | null
                                                                                                                )
                                                                                                              | undefined;
                                                                                                            name?:
                                                                                                              | (
                                                                                                                  | string
                                                                                                                  | null
                                                                                                                )
                                                                                                              | undefined;
                                                                                                            workingDays?:
                                                                                                              | (
                                                                                                                  | {
                                                                                                                      /** Day of week. 0 = Monday, 6 = Sunday */
                                                                                                                      weekday:
                                                                                                                        | 0
                                                                                                                        | 1
                                                                                                                        | 2
                                                                                                                        | 3
                                                                                                                        | 4
                                                                                                                        | 5
                                                                                                                        | 6;
                                                                                                                      workingTimes: {
                                                                                                                        /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                                        from: string;
                                                                                                                        /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                                        to: string;
                                                                                                                      }[];
                                                                                                                    }[]
                                                                                                                  | null
                                                                                                                )
                                                                                                              | undefined;
                                                                                                            exceptions?:
                                                                                                              | (
                                                                                                                  | {
                                                                                                                      /** Date as ISO string. */
                                                                                                                      startAt: string;
                                                                                                                      /** Date as ISO string. */
                                                                                                                      finishAt: string;
                                                                                                                      isWorkingDay: boolean;
                                                                                                                      workingTimes: {
                                                                                                                        /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                                        from: string;
                                                                                                                        /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                                        to: string;
                                                                                                                      }[];
                                                                                                                    }[]
                                                                                                                  | null
                                                                                                                )
                                                                                                              | undefined;
                                                                                                          }[];
                                                                                                        }
                                                                                                    )
                                                                                                  | {
                                                                                                      name: 'CreateDependencies';
                                                                                                      input: {
                                                                                                        useDryingBreak: boolean;
                                                                                                        lagInMinutes: number;
                                                                                                        bufferInMinutes: number;
                                                                                                        type:
                                                                                                          | 'START_TO_START'
                                                                                                          | 'START_TO_FINISH'
                                                                                                          | 'FINISH_TO_START'
                                                                                                          | 'FINISH_TO_FINISH';
                                                                                                        /** GraphQL encoded id. */
                                                                                                        id: string;
                                                                                                        /** GraphQL encoded id. */
                                                                                                        fromOrderId?:
                                                                                                          | (
                                                                                                              | string
                                                                                                              | null
                                                                                                            )
                                                                                                          | undefined;
                                                                                                        /** GraphQL encoded id. */
                                                                                                        toOrderId?:
                                                                                                          | (
                                                                                                              | string
                                                                                                              | null
                                                                                                            )
                                                                                                          | undefined;
                                                                                                        /** GraphQL encoded id. */
                                                                                                        fromMilestoneId?:
                                                                                                          | (
                                                                                                              | string
                                                                                                              | null
                                                                                                            )
                                                                                                          | undefined;
                                                                                                        /** GraphQL encoded id. */
                                                                                                        toMilestoneId?:
                                                                                                          | (
                                                                                                              | string
                                                                                                              | null
                                                                                                            )
                                                                                                          | undefined;
                                                                                                      }[];
                                                                                                    }
                                                                                                )
                                                                                              | {
                                                                                                  name: 'UpdateDependencies';
                                                                                                  input: {
                                                                                                    useDryingBreak?:
                                                                                                      | (
                                                                                                          | boolean
                                                                                                          | null
                                                                                                        )
                                                                                                      | undefined;
                                                                                                    lagInMinutes?:
                                                                                                      | (
                                                                                                          | number
                                                                                                          | null
                                                                                                        )
                                                                                                      | undefined;
                                                                                                    bufferInMinutes?:
                                                                                                      | (
                                                                                                          | number
                                                                                                          | null
                                                                                                        )
                                                                                                      | undefined;
                                                                                                    type?:
                                                                                                      | (
                                                                                                          | 'START_TO_START'
                                                                                                          | 'START_TO_FINISH'
                                                                                                          | 'FINISH_TO_START'
                                                                                                          | 'FINISH_TO_FINISH'
                                                                                                          | null
                                                                                                        )
                                                                                                      | undefined;
                                                                                                    /** GraphQL encoded id. */
                                                                                                    id: string;
                                                                                                  }[];
                                                                                                }
                                                                                            )
                                                                                          | {
                                                                                              name: 'RemoveScheduleNodes';
                                                                                              input: {
                                                                                                /** GraphQL encoded id. */
                                                                                                orders?:
                                                                                                  | (
                                                                                                      | string[]
                                                                                                      | null
                                                                                                    )
                                                                                                  | undefined;
                                                                                                /** GraphQL encoded id. */
                                                                                                milestones?:
                                                                                                  | (
                                                                                                      | string[]
                                                                                                      | null
                                                                                                    )
                                                                                                  | undefined;
                                                                                                /** GraphQL encoded id. */
                                                                                                pauses?:
                                                                                                  | (
                                                                                                      | string[]
                                                                                                      | null
                                                                                                    )
                                                                                                  | undefined;
                                                                                                /** GraphQL encoded id. */
                                                                                                dependencies?:
                                                                                                  | (
                                                                                                      | string[]
                                                                                                      | null
                                                                                                    )
                                                                                                  | undefined;
                                                                                                /** GraphQL encoded id. */
                                                                                                calendars?:
                                                                                                  | (
                                                                                                      | string[]
                                                                                                      | null
                                                                                                    )
                                                                                                  | undefined;
                                                                                                /** GraphQL encoded id. */
                                                                                                tradeSequences?:
                                                                                                  | (
                                                                                                      | string[]
                                                                                                      | null
                                                                                                    )
                                                                                                  | undefined;
                                                                                                /** GraphQL encoded id. */
                                                                                                wbsSections?:
                                                                                                  | (
                                                                                                      | string[]
                                                                                                      | null
                                                                                                    )
                                                                                                  | undefined;
                                                                                              };
                                                                                            }
                                                                                        )
                                                                                      | {
                                                                                          name: 'RestoreScheduleNodes';
                                                                                          input: {
                                                                                            /** GraphQL encoded id. */
                                                                                            orders?:
                                                                                              | (
                                                                                                  | string[]
                                                                                                  | null
                                                                                                )
                                                                                              | undefined;
                                                                                            /** GraphQL encoded id. */
                                                                                            milestones?:
                                                                                              | (
                                                                                                  | string[]
                                                                                                  | null
                                                                                                )
                                                                                              | undefined;
                                                                                            /** GraphQL encoded id. */
                                                                                            pauses?:
                                                                                              | (
                                                                                                  | string[]
                                                                                                  | null
                                                                                                )
                                                                                              | undefined;
                                                                                            /** GraphQL encoded id. */
                                                                                            dependencies?:
                                                                                              | (
                                                                                                  | string[]
                                                                                                  | null
                                                                                                )
                                                                                              | undefined;
                                                                                            /** GraphQL encoded id. */
                                                                                            calendars?:
                                                                                              | (
                                                                                                  | string[]
                                                                                                  | null
                                                                                                )
                                                                                              | undefined;
                                                                                            /** GraphQL encoded id. */
                                                                                            tradeSequences?:
                                                                                              | (
                                                                                                  | string[]
                                                                                                  | null
                                                                                                )
                                                                                              | undefined;
                                                                                            /** GraphQL encoded id. */
                                                                                            wbsSections?:
                                                                                              | (
                                                                                                  | string[]
                                                                                                  | null
                                                                                                )
                                                                                              | undefined;
                                                                                          };
                                                                                        }
                                                                                    )
                                                                                  | {
                                                                                      name: 'RescheduleScheduleNodes';
                                                                                      input: {
                                                                                        orders?:
                                                                                          | (
                                                                                              | {
                                                                                                  /** GraphQL encoded id. */
                                                                                                  id: string;
                                                                                                  /** Date as ISO string. */
                                                                                                  startAt?:
                                                                                                    | (
                                                                                                        | string
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                  /** Date as ISO string. */
                                                                                                  finishAt?:
                                                                                                    | (
                                                                                                        | string
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                  /** GraphQL encoded id. */
                                                                                                  wbsSectionId?:
                                                                                                    | (
                                                                                                        | string
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                  /** Working time duration in minutes. */
                                                                                                  workingTimeDuration?:
                                                                                                    | (
                                                                                                        | number
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                }[]
                                                                                              | null
                                                                                            )
                                                                                          | undefined;
                                                                                        milestones?:
                                                                                          | (
                                                                                              | {
                                                                                                  /** GraphQL encoded id. */
                                                                                                  id: string;
                                                                                                  /** GraphQL encoded id. */
                                                                                                  wbsSectionId?:
                                                                                                    | (
                                                                                                        | string
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                  /** Date as ISO string. */
                                                                                                  date?:
                                                                                                    | (
                                                                                                        | string
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                }[]
                                                                                              | null
                                                                                            )
                                                                                          | undefined;
                                                                                        pauses?:
                                                                                          | (
                                                                                              | {
                                                                                                  /** GraphQL encoded id. */
                                                                                                  id: string;
                                                                                                  /** Date as ISO string. */
                                                                                                  startAt?:
                                                                                                    | (
                                                                                                        | string
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                  /** Date as ISO string. */
                                                                                                  finishAt?:
                                                                                                    | (
                                                                                                        | string
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                }[]
                                                                                              | null
                                                                                            )
                                                                                          | undefined;
                                                                                        calendars?:
                                                                                          | (
                                                                                              | {
                                                                                                  /** GraphQL encoded id. */
                                                                                                  id: string;
                                                                                                  workingDays?:
                                                                                                    | (
                                                                                                        | {
                                                                                                            /** Day of week. 0 = Monday, 6 = Sunday */
                                                                                                            weekday:
                                                                                                              | 0
                                                                                                              | 1
                                                                                                              | 2
                                                                                                              | 3
                                                                                                              | 4
                                                                                                              | 5
                                                                                                              | 6;
                                                                                                            workingTimes: {
                                                                                                              /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                              from: string;
                                                                                                              /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                              to: string;
                                                                                                            }[];
                                                                                                          }[]
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                  exceptions?:
                                                                                                    | (
                                                                                                        | {
                                                                                                            /** Date as ISO string. */
                                                                                                            startAt: string;
                                                                                                            /** Date as ISO string. */
                                                                                                            finishAt: string;
                                                                                                            isWorkingDay: boolean;
                                                                                                            workingTimes: {
                                                                                                              /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                              from: string;
                                                                                                              /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                                                              to: string;
                                                                                                            }[];
                                                                                                          }[]
                                                                                                        | null
                                                                                                      )
                                                                                                    | undefined;
                                                                                                }[]
                                                                                              | null
                                                                                            )
                                                                                          | undefined;
                                                                                      };
                                                                                    }
                                                                                )
                                                                              | {
                                                                                  name: 'CreateMilestones';
                                                                                  input: {
                                                                                    /** GraphQL encoded id. */
                                                                                    id: string;
                                                                                    name: string;
                                                                                    /** Date as ISO string. */
                                                                                    date: string;
                                                                                    /** Color in hex format e.g. '#ff0000'. */
                                                                                    color: string;
                                                                                    /** GraphQL encoded id. */
                                                                                    wbsSectionId?:
                                                                                      | (
                                                                                          | string
                                                                                          | null
                                                                                        )
                                                                                      | undefined;
                                                                                    type:
                                                                                      | 'FIXED'
                                                                                      | 'FLEXIBLE';
                                                                                    criteria?:
                                                                                      | (
                                                                                          | {
                                                                                              /** GraphQL encoded id. */
                                                                                              id: string;
                                                                                              name: string;
                                                                                              sequenceNumber: number;
                                                                                            }[]
                                                                                          | null
                                                                                        )
                                                                                      | undefined;
                                                                                  }[];
                                                                                }
                                                                            )
                                                                          | {
                                                                              name: 'UpdateMilestones';
                                                                              input: {
                                                                                /** GraphQL encoded id. */
                                                                                id: string;
                                                                                name?:
                                                                                  | (string | null)
                                                                                  | undefined;
                                                                                /** Date as ISO string. */
                                                                                date?:
                                                                                  | (string | null)
                                                                                  | undefined;
                                                                                /** Color in hex format e.g. '#ff0000'. */
                                                                                color?:
                                                                                  | (string | null)
                                                                                  | undefined;
                                                                                /** GraphQL encoded id. */
                                                                                wbsSectionId?:
                                                                                  | (string | null)
                                                                                  | undefined;
                                                                                type?:
                                                                                  | (
                                                                                      | 'FIXED'
                                                                                      | 'FLEXIBLE'
                                                                                      | null
                                                                                    )
                                                                                  | undefined;
                                                                                criteria?:
                                                                                  | (
                                                                                      | {
                                                                                          /** GraphQL encoded id. */
                                                                                          id: string;
                                                                                          name: string;
                                                                                          sequenceNumber: number;
                                                                                        }[]
                                                                                      | null
                                                                                    )
                                                                                  | undefined;
                                                                                /** Setting this to 'true' bypasses the current 'isFixed' value of the milestone and allows updates to the date. */
                                                                                ignoreFixed?:
                                                                                  | (boolean | null)
                                                                                  | undefined;
                                                                              }[];
                                                                            }
                                                                        )
                                                                      | {
                                                                          name: 'UpdateMilestoneStatus';
                                                                          input: {
                                                                            /** GraphQL encoded id. */
                                                                            milestoneId: string;
                                                                            /** Date as ISO string. */
                                                                            completedAt:
                                                                              | string
                                                                              | null;
                                                                          };
                                                                        }
                                                                    )
                                                                  | {
                                                                      name: 'CreateOrders';
                                                                      input: {
                                                                        /** GraphQL encoded id. */
                                                                        id: string;
                                                                        name: string;
                                                                        /** GraphQL encoded id. */
                                                                        subcontractorId?:
                                                                          | (string | null)
                                                                          | undefined;
                                                                        /** GraphQL encoded id. */
                                                                        contributorGroupId?:
                                                                          | (string | null)
                                                                          | undefined;
                                                                        /** GraphQL encoded id. */
                                                                        tradeId: string;
                                                                        /** GraphQL encoded id. */
                                                                        calendarId: string;
                                                                        /** Date as ISO string. */
                                                                        startAt: string;
                                                                        /** Date as ISO string. */
                                                                        finishAt: string;
                                                                        /** GraphQL encoded id. */
                                                                        wbsSectionId: string;
                                                                        /** Working time duration in minutes. */
                                                                        workingTimeDuration?:
                                                                          | (number | null)
                                                                          | undefined;
                                                                        dryingBreak?:
                                                                          | ({
                                                                              duration: number;
                                                                              name?:
                                                                                | (string | null)
                                                                                | undefined;
                                                                            } | null)
                                                                          | undefined;
                                                                        isFixed?:
                                                                          | (boolean | null)
                                                                          | undefined;
                                                                        tasks?:
                                                                          | (
                                                                              | {
                                                                                  /** GraphQL encoded id. */
                                                                                  id?:
                                                                                    | (
                                                                                        | string
                                                                                        | null
                                                                                      )
                                                                                    | undefined;
                                                                                  name: string;
                                                                                  sequenceNumber: number;
                                                                                }[]
                                                                              | null
                                                                            )
                                                                          | undefined;
                                                                      }[];
                                                                    }
                                                                )
                                                              | {
                                                                  name: 'UpdateOrders';
                                                                  input: {
                                                                    /** GraphQL encoded id. */
                                                                    id: string;
                                                                    name?:
                                                                      | (string | null)
                                                                      | undefined;
                                                                    /** GraphQL encoded id. */
                                                                    subcontractorId?:
                                                                      | (string | null)
                                                                      | undefined;
                                                                    /** GraphQL encoded id. */
                                                                    contributorGroupId?:
                                                                      | (string | null)
                                                                      | undefined;
                                                                    /** GraphQL encoded id. */
                                                                    tradeId?:
                                                                      | (string | null)
                                                                      | undefined;
                                                                    /** GraphQL encoded id. */
                                                                    calendarId?:
                                                                      | (string | null)
                                                                      | undefined;
                                                                    /** Date as ISO string. */
                                                                    startAt?:
                                                                      | (string | null)
                                                                      | undefined;
                                                                    /** Date as ISO string. */
                                                                    finishAt?:
                                                                      | (string | null)
                                                                      | undefined;
                                                                    /** GraphQL encoded id. */
                                                                    wbsSectionId?:
                                                                      | (string | null)
                                                                      | undefined;
                                                                    /** Working time duration in minutes. */
                                                                    workingTimeDuration?:
                                                                      | (number | null)
                                                                      | undefined;
                                                                    dryingBreak?:
                                                                      | ({
                                                                          duration: number;
                                                                          name?:
                                                                            | (string | null)
                                                                            | undefined;
                                                                        } | null)
                                                                      | undefined;
                                                                    isFixed?:
                                                                      | (boolean | null)
                                                                      | undefined;
                                                                    /** Setting this to 'true' bypasses the current isFixed value of the order and allows updates to the start and end time. The value of isFixed is unaffected by this. */
                                                                    ignoreFixed?:
                                                                      | (boolean | null)
                                                                      | undefined;
                                                                    tasks?:
                                                                      | (
                                                                          | {
                                                                              /** GraphQL encoded id. */
                                                                              id?:
                                                                                | (string | null)
                                                                                | undefined;
                                                                              name: string;
                                                                              sequenceNumber: number;
                                                                            }[]
                                                                          | null
                                                                        )
                                                                      | undefined;
                                                                  }[];
                                                                }
                                                            )
                                                          | {
                                                              name: 'CopyOrders';
                                                              input: {
                                                                orders: {
                                                                  /** GraphQL encoded id. */
                                                                  newId: string;
                                                                  /** GraphQL encoded id. */
                                                                  originalOrderId: string;
                                                                  /** GraphQL encoded id. */
                                                                  wbsSectionId?:
                                                                    | (string | null)
                                                                    | undefined;
                                                                  /** Date as ISO string. */
                                                                  startAt: string;
                                                                  /** Date as ISO string. */
                                                                  finishAt: string;
                                                                  dryingBreak?:
                                                                    | ({
                                                                        duration: number;
                                                                        name?:
                                                                          | (string | null)
                                                                          | undefined;
                                                                      } | null)
                                                                    | undefined;
                                                                }[];
                                                                idMapping: {
                                                                  dependencies: {
                                                                    /** GraphQL encoded id. */
                                                                    fromOrderId: string;
                                                                    /** GraphQL encoded id. */
                                                                    toOrderId: string;
                                                                    /** GraphQL encoded id. */
                                                                    dependencyId: string;
                                                                  }[];
                                                                };
                                                              };
                                                            }
                                                        )
                                                      | {
                                                          name: 'CreateOrderStatusReports';
                                                          input: {
                                                            /** GraphQL encoded id. */
                                                            id: string;
                                                            /** GraphQL encoded id. */
                                                            orderId: string;
                                                            status:
                                                              | 'GREEN'
                                                              | 'YELLOW'
                                                              | 'RED'
                                                              | 'REPORTED_DONE'
                                                              | 'REJECTED'
                                                              | 'DONE';
                                                            reason?:
                                                              | (
                                                                  | 'STAFF'
                                                                  | 'PRELIMINARY_WORK'
                                                                  | 'MATERIAL'
                                                                  | 'REWORK'
                                                                  | 'OTHER'
                                                                  | null
                                                                )
                                                              | undefined;
                                                            comment?: (string | null) | undefined;
                                                            progress: number;
                                                            /** Date as ISO string. */
                                                            reportedAt: string;
                                                          }[];
                                                        }
                                                    )
                                                  | {
                                                      name: 'CompleteProjectSetup';
                                                      input: {
                                                        hourlyPlanningEnabled: boolean;
                                                        calendar: {
                                                          /** GraphQL encoded id. */
                                                          id: string;
                                                          name: string;
                                                          workingDays: {
                                                            /** Day of week. 0 = Monday, 6 = Sunday */
                                                            weekday: 0 | 1 | 2 | 3 | 4 | 5 | 6;
                                                            workingTimes: {
                                                              /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                              from: string;
                                                              /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                              to: string;
                                                            }[];
                                                          }[];
                                                          exceptions?:
                                                            | (
                                                                | {
                                                                    /** Date as ISO string. */
                                                                    startAt: string;
                                                                    /** Date as ISO string. */
                                                                    finishAt: string;
                                                                    isWorkingDay: boolean;
                                                                    workingTimes: {
                                                                      /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                      from: string;
                                                                      /** Working time in format HH:MM:SSZ (e.g. 00:00:00Z). */
                                                                      to: string;
                                                                    }[];
                                                                  }[]
                                                                | null
                                                              )
                                                            | undefined;
                                                        };
                                                        sections: {
                                                          /** GraphQL encoded id. */
                                                          id: string;
                                                          name: string;
                                                          position: number;
                                                          /** GraphQL encoded id. */
                                                          parentId: string | null;
                                                        }[];
                                                      };
                                                    }
                                                )
                                              | {
                                                  name: 'CreatePauses';
                                                  input: {
                                                    /** GraphQL encoded id. */
                                                    id: string;
                                                    name: string;
                                                    /** Date as ISO string. */
                                                    start: string;
                                                    /** Date as ISO string. */
                                                    end: string;
                                                  }[];
                                                }
                                            )
                                          | {
                                              name: 'UpdatePauses';
                                              input: {
                                                /** GraphQL encoded id. */
                                                id: string;
                                                name?: (string | null) | undefined;
                                                /** Date as ISO string. */
                                                start?: (string | null) | undefined;
                                                /** Date as ISO string. */
                                                end?: (string | null) | undefined;
                                              }[];
                                            }
                                        )
                                      | {
                                          name: 'DeleteProject';
                                          input: {};
                                        }
                                    )
                                  | {
                                      name: 'DeleteProjectAlternative';
                                      /** Currently only works if called while being subscribed to the alternative project (having it open in planning mode). Otherwise throws an error. */
                                      input: {
                                        /** GraphQL encoded id. */
                                        alternativeId: string;
                                      };
                                    }
                                )
                              | {
                                  name: 'UpdateProjectStatus';
                                  input: {
                                    status: 'COMPLETED' | 'IN_PREPARATION' | 'ARCHIVED' | 'ACTIVE';
                                  };
                                }
                            )
                          | {
                              name: 'InsertTradeSequence';
                              input: {
                                /** GraphQL encoded id. */
                                tradeSequenceId: string;
                                /** Date as ISO string. */
                                startAt: string;
                                /** GraphQL encoded id. */
                                wbsSectionId: string;
                                instanceId: string;
                                idMapping: {
                                  activityToOrderId: {
                                    [x: string]: string;
                                  };
                                  dependencies: {
                                    /** GraphQL encoded id. */
                                    fromOrderId: string;
                                    /** GraphQL encoded id. */
                                    toOrderId: string;
                                    /** GraphQL encoded id. */
                                    dependencyId: string;
                                  }[];
                                };
                              };
                            }
                        )
                      | {
                          name: 'CreateTradeSequence';
                          input: {
                            /** GraphQL encoded id. */
                            id: string;
                            name: string;
                            /** GraphQL encoded id. */
                            calendarId: string;
                            defaultDuration: number;
                            activities: {
                              /** GraphQL encoded id. */
                              id: string;
                              name: string;
                              /** GraphQL encoded id. */
                              tradeId: string;
                              /** Working time duration in minutes. */
                              duration: number;
                              offset: number;
                              dryingBreak?:
                                | ({
                                    duration: number;
                                    name?: (string | null) | undefined;
                                  } | null)
                                | undefined;
                              taskTemplates?:
                                | (
                                    | {
                                        name: string;
                                        sequenceNumber: number;
                                        /** GraphQL encoded id. */
                                        id?: (string | null) | undefined;
                                      }[]
                                    | null
                                  )
                                | undefined;
                            }[];
                            dependencies: {
                              useDryingBreak: boolean;
                              lagInMinutes: number;
                              bufferInMinutes: number;
                              type:
                                | 'START_TO_START'
                                | 'START_TO_FINISH'
                                | 'FINISH_TO_START'
                                | 'FINISH_TO_FINISH';
                              /** GraphQL encoded id. */
                              id: string;
                              /** GraphQL encoded id. */
                              fromActivityId: string;
                              /** GraphQL encoded id. */
                              toActivityId: string;
                            }[];
                            /** Assignments that will be created for orders. Every order will be assigned to the given trade sequence instance and matching activity. Trying to assign a non existing order will cause an error. */
                            orderAssignments?:
                              | ({
                                  tradeSequenceInstanceId: string;
                                  assignments: {
                                    /** GraphQL encoded id. */
                                    orderId: string;
                                    /** GraphQL encoded id. */
                                    activityId: string;
                                  }[];
                                } | null)
                              | undefined;
                            idMapping?:
                              | ({
                                  activityToOrderId: {
                                    [x: string]: string;
                                  };
                                  dependencies: {
                                    /** GraphQL encoded id. */
                                    fromOrderId: string;
                                    /** GraphQL encoded id. */
                                    toOrderId: string;
                                    /** GraphQL encoded id. */
                                    dependencyId: string;
                                  }[];
                                } | null)
                              | undefined;
                          };
                        }
                    )
                  | {
                      name: 'UpdateTradeSequence';
                      /** Not implemented */
                      input: {
                        /** GraphQL encoded id. */
                        id: string;
                        name: string;
                        /** GraphQL encoded id. */
                        calendarId: string;
                        defaultDuration: number;
                        activities: {
                          /** GraphQL encoded id. */
                          id: string;
                          name: string;
                          /** GraphQL encoded id. */
                          tradeId: string;
                          /** Working time duration in minutes. */
                          duration: number;
                          offset: number;
                          dryingBreak?:
                            | ({
                                duration: number;
                                name?: (string | null) | undefined;
                              } | null)
                            | undefined;
                          taskTemplates?:
                            | (
                                | {
                                    name: string;
                                    sequenceNumber: number;
                                    /** GraphQL encoded id. */
                                    id?: (string | null) | undefined;
                                  }[]
                                | null
                              )
                            | undefined;
                        }[];
                        dependencies: {
                          useDryingBreak: boolean;
                          lagInMinutes: number;
                          bufferInMinutes: number;
                          type:
                            | 'START_TO_START'
                            | 'START_TO_FINISH'
                            | 'FINISH_TO_START'
                            | 'FINISH_TO_FINISH';
                          /** GraphQL encoded id. */
                          id: string;
                          /** GraphQL encoded id. */
                          fromActivityId: string;
                          /** GraphQL encoded id. */
                          toActivityId: string;
                        }[];
                        idMappings: {
                          activityToOrderId: {
                            [x: string]: string;
                          };
                          dependencies: {
                            /** GraphQL encoded id. */
                            fromOrderId: string;
                            /** GraphQL encoded id. */
                            toOrderId: string;
                            /** GraphQL encoded id. */
                            dependencyId: string;
                          }[];
                          tradeSequenceInstanceId: string;
                        }[];
                      };
                    }
                )
              | {
                  name: 'CreateWBSSections';
                  /** Creates sections for project. Soft deleted sections with matching id are restored and position conflicts resolved. All sections must share a common ancestor. */
                  input: {
                    /** ID of section */
                    id: string;
                    /** Name of section. Max length: 256 */
                    name: string;
                    /** Position of section within parent. Must be greater than or equal to 0. Existing sections with a greater than or equal position will be shifted (existing position +1) to prevent conflicts. */
                    position: number;
                    /** GraphQL encoded id. */
                    parentId: string | null;
                  }[];
                }
            )
          | {
              name: 'UpdateWBSSection';
              input: {
                /** ID of section */
                id: string;
                /** Name of section. Max length: 256 */
                name?: (string | null) | undefined;
                /** Position of section within parent. Must be greater than or equal to 0. Existing sections with a greater than or equal position will be shifted (existing position +1) to prevent conflicts. */
                position?: (number | null) | undefined;
                /** GraphQL encoded id. */
                parentId?: (string | null) | undefined;
              };
            }
        )
      | {
          name: 'OutdentWBSSection';
          input: {
            /** ID of section */
            id: string;
          };
        }
    )
  | {
      name: 'IndentWBSSection';
      input: {
        /** ID of parent section whose children will be indented. */
        id: string;
        /** New ID for section that will be created. */
        newSectionId: string;
        /** Name for new section. */
        name: string;
      };
    };

export type ProjectChangeMessage = {
  messageId: string;
  stateId?: (number | null) | undefined;
  clientId?: (string | null) | undefined;
  operation: {
    name: string;
    input: Buffer;
    context?:
      | ({
          orders?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    /** Date as ISO string. */
                    startAt?: (string | null) | undefined;
                    /** Date as ISO string. */
                    finishAt?: (string | null) | undefined;
                    /** GraphQL encoded id. */
                    wbsSectionId?: (string | null) | undefined;
                    /** Working time duration in minutes. */
                    workingTimeDuration?: (number | null) | undefined;
                  }[]
                | null
              )
            | undefined;
          milestones?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    /** GraphQL encoded id. */
                    wbsSectionId?: (string | null) | undefined;
                    /** Date as ISO string. */
                    date?: (string | null) | undefined;
                  }[]
                | null
              )
            | undefined;
          /** Dependencies overwrite any changes made by scheduling engine during scheduling of operation. */
          dependencies?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    lagInMinutes?: (number | null) | undefined;
                    bufferInMinutes?: (number | null) | undefined;
                    useDryingBreak?: (boolean | null) | undefined;
                  }[]
                | null
              )
            | undefined;
        } | null)
      | undefined;
  };
  clientTimestampMs: number;
  serverTimestampMs: number;
  projectId: string;
  status: 'SUCCESS' | 'ERROR';
  restoredEntities?:
    | {
        /** GraphQL encoded id. */
        orders?: (string[] | null) | undefined;
        /** GraphQL encoded id. */
        milestones?: (string[] | null) | undefined;
        /** GraphQL encoded id. */
        pauses?: (string[] | null) | undefined;
        /** GraphQL encoded id. */
        dependencies?: (string[] | null) | undefined;
        dependencyDetails?:
          | (
              | {
                  /** GraphQL encoded id. */
                  id: string;
                  useDryingBreak: boolean;
                  lagInMinutes: number;
                  bufferInMinutes: number;
                  type:
                    | 'START_TO_START'
                    | 'START_TO_FINISH'
                    | 'FINISH_TO_START'
                    | 'FINISH_TO_FINISH';
                  from: {
                    /** GraphQL encoded id. */
                    orderId?: (string | null) | undefined;
                    /** GraphQL encoded id. */
                    milestoneId?: (string | null) | undefined;
                  };
                  to: {
                    /** GraphQL encoded id. */
                    orderId?: (string | null) | undefined;
                    /** GraphQL encoded id. */
                    milestoneId?: (string | null) | undefined;
                  };
                }[]
              | null
            )
          | undefined;
        /** GraphQL encoded id. */
        calendars?: (string[] | null) | undefined;
        /** GraphQL encoded id. */
        tradeSequences?: (string[] | null) | undefined;
        /** GraphQL encoded id. */
        wbsSections?: (string[] | null) | undefined;
      }
    | undefined;
  error?:
    | (
        | (
            | (
                | (
                    | (
                        | {
                            reason: 'STATE';
                            message?: string | undefined;
                            errorCode: string;
                          }
                        | {
                            reason: 'CONFLICT';
                            message?: string | undefined;
                            conflictType: ('BLOCKED' | 'CHANGE') | 'OPERATION';
                            conflictingMessageId: string;
                            conflictingMessage?:
                              | ({
                                  clientStateId?: (number | null) | undefined;
                                  stateId?: (number | null) | undefined;
                                  clientId?: (string | null) | undefined;
                                  /** Serialized JSON string of conflicting properties. */
                                  conflict?: (string | null) | undefined;
                                } | null)
                              | undefined;
                          }
                      )
                    | {
                        reason: 'PERMISSION';
                        message?: string | undefined;
                        errorCode: string;
                      }
                  )
                | {
                    reason: 'INVALID_MESSAGE';
                    message?: string | undefined;
                    errorCode: string;
                  }
              )
            | {
                reason: 'INTERNAL';
                message?: string | undefined;
                errorCode: string;
              }
          )
        | null
      )
    | undefined;
  publishToClient?: (boolean | null) | undefined;
  user?:
    | ({
        /** GraphQL encoded id. */
        id: string;
        firstName: string;
        lastName: string;
      } | null)
    | undefined;
};

/* Payload for events of type 'project_contributor_groups_changed'. */
export type ProjectContributorGroupChangePayload = {
  /** GraphQL encoded id. */
  projectId: string;
  contributorGroups: {
    /** ID of contributor group */
    id: string;
    name: string;
    permission: string;
    isTenantOwnerGroup: boolean;
    type: 'INTERNAL' | 'EXTERNAL';
    tenantTradeVariationAssignments: {
      /** GraphQL encoded id. */
      tenantTradeVariationId: string;
      isDefaultContributor: boolean;
    }[];
  }[];
  /** Timestamp of milliseconds of data change. Change should be discarded if state with newer timestamp has been received. */
  timestamp: number;
};

/* Payload for events of type 'project_subcontractors_changed'. */
export type ProjectSubcontractorChangePayload = {
  /** GraphQL encoded id. */
  projectId: string;
  subcontractors: {
    /** ID of subcontractor tenant */
    id: string;
    name: string;
    /** ID of subcontractor assignment. */
    subcontractorAssignmentId: string;
    tenantTradeVariationAssignments: {
      /** GraphQL encoded id. */
      tenantTradeVariationId: string;
      isDefaultSubcontractor: boolean;
    }[];
  }[];
  /** Timestamp of milliseconds of data change. Change should be discarded if state with newer timestamp has been received. */
  timestamp: number;
};

export type ProjectSubscriptionCallback =
  | {
      status: 'SUCCESS';
      stateId: number;
    }
  | {
      status: 'ERROR';
      error: {
        reason: string;
        message?: string | undefined;
        errorCode: string;
      };
    };

export type ProjectSubscriptionCompletionCallback = {
  /** GraphQL encoded id. */
  projectId: string;
};

export type ProjectSubscriptionEvent = {
  projectId: string;
  clientState: {
    lastReceivedStateId: number;
    earliestUnprocessedStateId: number;
  } | null;
};

/* Payload for events of type 'project_trades_changed'. */
export type ProjectTradeChangePayload = {
  /** GraphQL encoded id. */
  tenantId: string;
  trades: {
    /** GraphQL encoded id. */
    id: string;
    name: string;
    color: string;
    type: 'Project' | 'Tenant';
  }[];
  /** Whether change only includes tenant trade changes. */
  isTenantChange: boolean;
  /** Timestamp of milliseconds of data change. Change should be discarded if state with newer timestamp has been received. */
  timestamp: number;
};

/* Payload for events of type 'trade_replaced'. */
export type ProjectTradeReplacementPayload = {
  replacement: {
    /** GraphQL encoded id. */
    previousTradeId: string;
    /** GraphQL encoded id. */
    newTradeId: string;
    /** GraphQL encoded id. */
    tenantId: string;
    /** GraphQL encoded id. */
    projectId: string | null;
  };
};

export type ProjectUnsubscribeEvent = {
  projectId: string;
};

/* Payload for events of type 'live_users_changed'. */
export type ProjectUsersChangePayload = {
  /** GraphQL encoded id. */
  projectId: string;
  users: {
    /** GraphQL encoded id. */
    id: string;
    firstName: string;
    lastName: string;
    /** Date as ISO string. */
    joinedAt: string;
    thumbnailUrl: string | null;
    tenant: {
      /** GraphQL encoded id. */
      id: string;
      name: string;
    };
  }[];
};

export type SingleProjectChangeMessage = {
  messageId: string;
  operation: {
    name: string;
    input: Buffer;
    context?:
      | ({
          orders?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    /** Date as ISO string. */
                    startAt?: (string | null) | undefined;
                    /** Date as ISO string. */
                    finishAt?: (string | null) | undefined;
                    /** GraphQL encoded id. */
                    wbsSectionId?: (string | null) | undefined;
                    /** Working time duration in minutes. */
                    workingTimeDuration?: (number | null) | undefined;
                  }[]
                | null
              )
            | undefined;
          milestones?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    /** GraphQL encoded id. */
                    wbsSectionId?: (string | null) | undefined;
                    /** Date as ISO string. */
                    date?: (string | null) | undefined;
                  }[]
                | null
              )
            | undefined;
          /** Dependencies overwrite any changes made by scheduling engine during scheduling of operation. */
          dependencies?:
            | (
                | {
                    /** GraphQL encoded id. */
                    id: string;
                    lagInMinutes?: (number | null) | undefined;
                    bufferInMinutes?: (number | null) | undefined;
                    useDryingBreak?: (boolean | null) | undefined;
                  }[]
                | null
              )
            | undefined;
        } | null)
      | undefined;
  };
  clientTimestampMs: number;
  projectId: string;
};

/* Internal backend events. Don't use this in frontend. */
export type TenantEvent = {
  timestampMs: number;
  messageId: string;
  userId: number | null;
  event: {
    name: 'TradeReplacement';
    payload: {
      previousTrade: {
        id: number;
        project: {
          id: number;
        } | null;
        tenant: {
          id: number;
        };
      };
      newTrade: {
        id: number;
        project: {
          id: number;
        } | null;
        tenant: {
          id: number;
        };
      };
    };
  };
};
export const RTCSocketActions = {
  ClientServerSocketActions: {
    Subscribe: 'subscribe_to_project',
    Unsubscribe: 'unsubscribe_from_project',
    ProjectChange: 'project_change',
    SingleProjectChange: 'single_project_change',
  },
  ServerClientSocketActions: {
    ProjectChangeMessage: 'project_changed',
    ProjectSubscriptionCompleted: 'project_subscription_completed',
    ProjectDataChanges: {
      Trades: 'project_trades_changed',
      Subcontractors: 'project_subcontractors_changed',
      TradeReplacement: 'trade_replaced',
      ContributorGroups: 'project_contributor_groups_changed',
    },
    LiveUsersChanged: 'live_users_changed',
  },
};

const online = ref(navigator.onLine);

window.addEventListener('online', () => {
  online.value = true;
});

window.addEventListener('offline', () => {
  online.value = false;
});

/**
 * A composable function that returns the online status.
 * We return a computed value so it cannot be changed from the outside.
 *
 * @returns {boolean} The online status.
 */
export function useIsOnline(): ComputedRef<boolean> {
  return computed(() => online.value);
}

import { InteractiveEntityStores, InteractiveOperationReturnType } from '@/common/types';
import { MilestoneStoreV2 } from '@/features/milestones/types';
import { OrderDependencyStoreV2 } from '@/features/orderDependencies';

import { CalendarStore } from '../calendars/calendarTypes';
import { MultiSelectActionsStore } from '../multiSelectActions/types';
import { OrderStoreV2 } from '../orders/types';
import { PauseStoreV2 } from '../pauses/types';
import { WbsSectionStoreV2 } from '../projectStructure/types';
import { ProjectTradeSequenceStoreV2 } from '../projectTradeSequences/types';
import { ProjectChangeEventContext } from '../realTimeCollaboration/types';
import { UndoRedoActionNames, UndoRedoCommit } from './types';

export interface FullInteractiveEntityStores
  extends Omit<InteractiveEntityStores, 'projectStore' | 'projectTradeSequenceStore'> {
  pauseStore: () => PauseStoreV2;
  orderStore: () => OrderStoreV2;
  wbsSectionStore: () => WbsSectionStoreV2;
  calendarStore: () => CalendarStore;
  projectTradeSequenceStore: () => ProjectTradeSequenceStoreV2;
  milestoneStore: () => MilestoneStoreV2;
  orderDependencyStore: () => OrderDependencyStoreV2;
  multiSelectActionsStore: () => MultiSelectActionsStore;
}

// eslint-disable-next-line complexity -- Numerous cases, but not complex
export function callEntityStoreFromCommit(
  entityStores: FullInteractiveEntityStores,
  commit: UndoRedoCommit,
  context?: ProjectChangeEventContext,
  inverse = true,
): Promise<InteractiveOperationReturnType> {
  const action = inverse ? commit.inverseAction : commit.action;
  // pauses
  if (action.name === UndoRedoActionNames.CreatePauses) {
    return entityStores.pauseStore().create(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.UpdatePauses) {
    return entityStores.pauseStore().update(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RestorePauses) {
    return entityStores.pauseStore().restore(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.DeletePauses) {
    return entityStores.pauseStore().delete(action.data, false, context);
  }
  // calendars
  if (action.name === UndoRedoActionNames.CreateCalendars) {
    return entityStores.calendarStore().create(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.UpdateCalendars) {
    return entityStores.calendarStore().update(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RestoreCalendars) {
    return entityStores.calendarStore().restore(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.DeleteCalendars) {
    return entityStores.calendarStore().delete(action.data, false, context);
  }
  // orders
  if (action.name === UndoRedoActionNames.CreateOrders) {
    return entityStores.orderStore().create(
      action.data.map((order) => {
        if (typeof order.duration !== 'number') {
          throw new Error(`Duration not set on create order input. ${JSON.stringify(order)}`);
        }
        return { ...order, duration: order.duration! };
      }),
      false,
      context,
    );
  }
  if (action.name === UndoRedoActionNames.UpdateOrders) {
    return entityStores.orderStore().update(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.DeleteOrders) {
    return entityStores.orderStore().delete(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RestoreOrders) {
    return entityStores.orderStore().restore(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.CopyOrders) {
    return entityStores.orderStore().restoreCopiedOrders(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RevertCopyOrders) {
    return entityStores.orderStore().removeCopiedOrders(action.data, false, context);
  }
  // order dependencies
  if (action.name === UndoRedoActionNames.CreateOrderDependencies) {
    return entityStores.orderDependencyStore().create(action.data, false, entityStores, context);
  }
  if (action.name === UndoRedoActionNames.UpdateOrderDependencies) {
    return entityStores.orderDependencyStore().update(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.DeleteOrderDependencies) {
    return entityStores.orderDependencyStore().delete(action.data, false, entityStores, context);
  }
  if (action.name === UndoRedoActionNames.RestoreOrderDependencies) {
    return entityStores.orderDependencyStore().restore(action.data, false, entityStores, context);
  }
  // trade sequences
  if (action.name === UndoRedoActionNames.CreateTradeSequences) {
    return entityStores.projectTradeSequenceStore().create(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.UpdateTradeSequences) {
    return entityStores.projectTradeSequenceStore().update(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.DeleteTradeSequences) {
    return entityStores.projectTradeSequenceStore().delete(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RestoreTradeSequences) {
    return entityStores.projectTradeSequenceStore().restore(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.InsertTradeSequence) {
    return entityStores.projectTradeSequenceStore().insert(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RevertInsertTradeSequence) {
    return entityStores
      .projectTradeSequenceStore()
      .revertInsert(action.data.orders, false, context);
  }
  if (action.name === UndoRedoActionNames.RestoreInsertTradeSequence) {
    return entityStores
      .projectTradeSequenceStore()
      .restoreInsert(action.data.orders, false, context);
  }
  // milestones
  if (action.name === UndoRedoActionNames.CreateMilestones) {
    return entityStores.milestoneStore().create(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.UpdateMilestones) {
    return entityStores.milestoneStore().update(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.DeleteMilestones) {
    return entityStores.milestoneStore().delete(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RestoreMilestones) {
    return entityStores.milestoneStore().restore(action.data, false, context);
  }
  // sections
  if (action.name === UndoRedoActionNames.CreateWBSSections) {
    return entityStores
      .wbsSectionStore()
      .create(action.data.sections, action.data.root, false, context);
  }
  if (action.name === UndoRedoActionNames.UpdateWBSSection) {
    return entityStores.wbsSectionStore().update(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.DeleteWBSSections) {
    return entityStores.wbsSectionStore().delete(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RestoreWBSSections) {
    return entityStores.wbsSectionStore().restore(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.IndentWBSSection) {
    return entityStores.wbsSectionStore().indent(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.OutdentWBSSection) {
    const sectionToOutdent = entityStores.wbsSectionStore().wbsSections.get(action.data.id);
    if (!sectionToOutdent) return Promise.reject(new Error('Section to outdent not found'));
    return entityStores.wbsSectionStore().outdent(sectionToOutdent, false, context);
  }
  if (action.name === UndoRedoActionNames.UndoOutdentWBSSection) {
    return entityStores
      .wbsSectionStore()
      .undoOutdent(action.data.originallyOutdentedSection, action.data.sectionsToRecreate, context);
  }
  // multi select actions
  if (action.name === UndoRedoActionNames.RescheduleScheduleNodes) {
    return entityStores.multiSelectActionsStore().rescheduleEntities(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RemoveScheduleNodes) {
    return entityStores.multiSelectActionsStore().removeEntities(action.data, false, context);
  }
  if (action.name === UndoRedoActionNames.RestoreScheduleNodes) {
    return entityStores.multiSelectActionsStore().restoreEntities(action.data, false, context);
  }

  return Promise.reject(new Error('Unknown action'));
}

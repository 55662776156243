import { Ref, ref, watch } from 'vue';

import { debounce } from '@/helpers/utils/functions';

export interface UseSearch {
  isUserTyping: Ref<boolean>;
  searchTerm: Ref<string>;
  tmpSearchTerm: Ref<string>;
  onSearchInputUpdate: (newValue: string) => void;
}

/** Use helpers for searching for a specific item */
export function useSearch(onSearch?: (searchTerm: string) => void): UseSearch {
  const searchTerm = ref('');
  const tmpSearchTerm = ref('');
  const isUserTyping = ref(false);

  const debouncedVariableUpdate = debounce(() => {
    isUserTyping.value = false;
    searchTerm.value = tmpSearchTerm.value;
    if (onSearch) onSearch(searchTerm.value);
  }, 400);

  watch(tmpSearchTerm, () => {
    isUserTyping.value = true;
    debouncedVariableUpdate();
  });

  const onSearchInputUpdate = (newValue: string) => {
    tmpSearchTerm.value = newValue;
  };

  return {
    isUserTyping,
    searchTerm,
    tmpSearchTerm,
    onSearchInputUpdate,
  };
}

export interface SelectItem<T = Dictionary> {
  title: string;
  value: string | null;
  data?: T;
  group?: string;
}

export interface VSelectItem<T = Dictionary> {
  title: string;
  value: string;
  raw: {
    title: string;
    value: string;
    data?: T;
  };
}

export interface HeaderSelectItem {
  header?: string;
  divider?: boolean;
}

export interface TimelineItem<T = undefined> {
  author: string;
  timestamp: string;
  title: string;
  data?: T;
  edited?: boolean;
}

/** Returns array of select items with text and value property */
export function formatSelectItems<T extends Dictionary>(
  objects: T[] | readonly T[],
  title: keyof T | ((object: T) => string) = 'name',
  value: keyof T | ((object: T) => string) = 'id',
): SelectItem<T>[] {
  return (objects as T[]).map((object) => ({
    value: typeof value === 'function' ? value(object) : object[value],
    title: typeof title === 'function' ? title(object) : object[title],
    data: object ? (object as T) : undefined,
  }));
}

export interface UseClearSearchInputOnSelect {
  searchInput: Ref<string | null>;
}

export function useClearSearchInputOnSelect<T>(input: Ref<T>): UseClearSearchInputOnSelect {
  const searchInput = ref<string | null>(null);

  // reset search input, if input changed
  watch(input, () => {
    searchInput.value = '';
  });

  return {
    searchInput,
  };
}

import { OperationNames } from 'events.schema';
import { intersection } from 'lodash';

import {
  Entity,
  InteractiveEntities,
  InteractiveEntityChangeMaps,
  InteractiveEntityChanges,
  InteractiveEntityMaps,
  MilestoneEntity,
  OrderEntity,
  TradeSequenceActivityEntity,
} from '@/common/types';
import { isEqual, omitKeys } from '@/helpers/utils/objects';

import { LocalProjectChangeEvent, RemoteProjectChangeEvent } from '../types';

export class RTCConflictDetection {
  public static changesHaveConflicts(
    changesA: InteractiveEntityChanges,
    changesB: InteractiveEntityChanges,
  ): boolean {
    const changesMapsA = this.transformChangesToMaps(changesA);
    const changesMapsB = this.transformChangesToMaps(changesB);

    return (
      this.hasAdditionDeletionConflicts(changesMapsA, changesMapsB) ||
      this.hasUpdateConflicts(changesMapsA, changesMapsB)
    );
  }

  private static hasAdditionDeletionConflicts(
    changesMapsA: InteractiveEntityChangeMaps,
    changesMapsB: InteractiveEntityChangeMaps,
  ): boolean {
    return Object.keys({ ...changesMapsA.add, ...changesMapsA.delete }).some((entity) => {
      const entitiesA = [
        ...Array.from(changesMapsA.add?.[entity]?.keys() ?? []),
        ...Array.from(changesMapsA.delete?.[entity]?.keys() ?? []),
      ];
      const entitiesB = [
        ...Array.from(changesMapsB.add?.[entity]?.keys() ?? []),
        ...Array.from(changesMapsB.update?.[entity]?.keys() ?? []),
        ...Array.from(changesMapsB.delete?.[entity]?.keys() ?? []),
      ];
      const commonIds = intersection(entitiesA, entitiesB);
      return !!commonIds.length;
    });
  }

  private static hasUpdateConflicts(
    changesMapsA: InteractiveEntityChangeMaps,
    changesMapsB: InteractiveEntityChangeMaps,
  ): boolean {
    const specialConflictingFields = {
      orders: {
        status: {
          condition: (entity: OrderEntity) => ['REPORTED_DONE', 'DONE'].includes(entity?.status),
          fields: ['startAt', 'finishAt', 'wbsSection'],
        },
        isFixed: {
          condition: (entity: OrderEntity) => entity.isFixed,
          fields: ['startAt', 'finishAt', 'wbsSection'],
        },
      },
      milestones: {
        completedAt: {
          condition: (entity: MilestoneEntity) => !!entity?.completedAt,
          fields: ['date', 'wbsSection'],
        },
      },
    };

    /**
     * Functions to filter specific fields from entities that should not be respected in conflict detection
     */
    const filterEntityFields = {
      tradeSequences: {
        activities: (activities?: TradeSequenceActivityEntity[]) =>
          activities?.map((activity) => omitKeys(activity, ['taskTemplates'])),
      },
      orders: {
        tasks: () => [],
      },
    };

    if (!changesMapsA.update) return false;

    return Object.keys(changesMapsA.update).some((entity) => {
      const entitiesA = changesMapsA.update![entity];
      const entitiesB = changesMapsB.update?.[entity];
      const commonIds = intersection(
        Array.from(entitiesA?.keys() ?? []),
        Array.from(entitiesB?.keys() ?? []),
      );
      if (!commonIds.length) return false;

      return commonIds.some((id) => {
        const entityA = entitiesA.get(id);
        const entityB = entitiesB.get(id);

        const specialCases = specialConflictingFields[entity] as Record<
          string,
          { condition: (entity: Entity) => boolean; fields: string[] }
        >;
        if (specialCases) {
          const specialConflict = Object.entries(specialCases).some(([, { condition, fields }]) => {
            let hasConflict = false;
            if (condition(entityA)) {
              hasConflict = !!intersection(fields, Object.keys(entityB)).length;
            }
            if (condition(entityB)) {
              hasConflict = hasConflict || !!intersection(fields, Object.keys(entityA)).length;
            }
            return hasConflict;
          });
          if (specialConflict) return true;
        }

        const commonFields = intersection(Object.keys(entityA), Object.keys(entityB));
        return commonFields.some((field) => {
          if (field === 'id') return false;
          const filter = filterEntityFields[entity]?.[field] ?? ((e) => e);
          return !isEqual(filter(entityA[field]), filter(entityB[field]));
        });
      });
    });
  }

  public static eventsHaveConflicts(
    eventA: LocalProjectChangeEvent | RemoteProjectChangeEvent,
    eventB: LocalProjectChangeEvent | RemoteProjectChangeEvent,
  ): boolean {
    if (
      eventA.operation.name === OperationNames.InsertTradeSequence &&
      eventB.operation.name === OperationNames.UpdateTradeSequence
    ) {
      return eventA.operation.input.tradeSequenceId === eventB.operation.input.id;
    }
    if (
      eventA.operation.name === OperationNames.UpdateTradeSequence &&
      eventB.operation.name === OperationNames.InsertTradeSequence
    ) {
      return eventB.operation.input.tradeSequenceId === eventA.operation.input.id;
    }
    if (
      eventA.operation.name === OperationNames.UpdateTradeSequence &&
      eventB.operation.name === OperationNames.UpdateTradeSequence
    ) {
      return eventA.operation.input.id === eventB.operation.input.id;
    }
    return false;
  }

  private static transformChangesToMaps(
    changes: InteractiveEntityChanges,
  ): InteractiveEntityChangeMaps {
    const maps = {} as InteractiveEntityChangeMaps;
    Object.keys(changes).forEach((change) => {
      if (changes[change]) {
        maps[change] = this.transformEntitiesToMaps(changes[change]);
      }
    });
    return maps;
  }

  private static transformEntitiesToMaps(entities: InteractiveEntities): InteractiveEntityMaps {
    const maps = {} as InteractiveEntityMaps;
    Object.keys(entities).forEach((entity) => {
      if (entities[entity]) {
        maps[entity] = new Map(entities[entity].map((o) => [o.id, o]));
      }
    });
    return maps;
  }
}

import { RTCClient } from './client';
import { OneShotRTCClient } from './oneShotClient';

let client: RTCClient;

export function useRTCClient(): RTCClient {
  return client;
}

export function installRTCClient(newClient: RTCClient) {
  client = newClient;
}

let oneShotClient: OneShotRTCClient;

export function useOneShotRTCClient(): OneShotRTCClient {
  return oneShotClient;
}

export function installOneShotRTCClient(newClient: OneShotRTCClient) {
  oneShotClient = newClient;
}

import VueGoogleMaps from '@fawmi/vue-google-maps';
import { App } from 'vue';

import { GOOGLE_MAPS_API_KEY } from '@/utils/keys';
import { getBrowserLanguage, Language } from '@/utils/languages';

export interface GoogleMaps {
  $gmapApiPromiseLazy: () => void;
}

export function installGoogleMaps(app: App): GoogleMaps {
  const component = app.use(
    VueGoogleMaps,
    getOptions(getBrowserLanguage()),
  ) as unknown as GoogleMaps;
  return component;
}

function getOptions(language: Language) {
  return {
    load: {
      key: GOOGLE_MAPS_API_KEY,
      libraries: 'places',
      region: language.toUpperCase(),
      language,
    },
    installComponents: false,
  };
}

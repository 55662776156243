import { LoggingService } from '@/interfaces/services';
import { NodeName } from '@/repositories/utils/cache';
import { ScheduleStore } from '@/services/store/schedule';
import { MultiEventCounts } from '@/utils/analyticsEvents/eventCategories/schedule';

const NODE_TO_COUNT_MAP = {
  [NodeName.ORDER]: 'orderCount',
  [NodeName.MILESTONE]: 'milestoneCount',
  [NodeName.PAUSE]: 'constructionBreakCount',
};

export const trackMultiSelect = (
  scheduleStore: ScheduleStore,
  loggingService: LoggingService,
): void => {
  const multiEventCount: MultiEventCounts = {
    orderCount: 0,
    milestoneCount: 0,
    constructionBreakCount: 0,
  };

  Array.from(scheduleStore.utils.multiSelectedEvents.entries()).forEach(([, eventType]) => {
    multiEventCount[NODE_TO_COUNT_MAP[eventType]] += 1;
  });

  loggingService.trackEvent(
    new loggingService.AnalyticEventCategories.ScheduleEntitiesSelected(multiEventCount),
  );
};

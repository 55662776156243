import { defineStore } from 'pinia';

import {
  AttachmentFragment,
  CreateTicketInput,
  FetchAllTicketsQueryVariables,
  TitoTicketFragment,
  UpdateTicketInput,
} from '@/graphql/__generated__/graphql';
import { subtract } from '@/helpers/utils/arrays';
import { useApolloClient } from '@/plugins/apollo';
import { createConnection, NodeName } from '@/repositories/utils/cache';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

import { TitoTicket } from '../types';
import {
  allTicketsQuery,
  createTicketAttachmentMutation,
  createTicketMutation,
  createTicketStatusMutation,
  deleteTicketAttachmentMutation,
  deleteTicketMutation,
  exportTicketsMutation,
  singleTicketQuery,
  updateTicketMutation,
} from './gqlFunctions';
import { TicketStatus } from './types';

const convertToTypedTicket = (ticket: TitoTicketFragment): TitoTicket => ({
  ...ticket,
  status: ticket.status as TicketStatus,
});

export const useTicketStore = defineStore('ticket-store', () => {
  const tickets = ref<Map<string, TitoTicket>>(new Map());
  const isInitialized = ref(false);

  const fetchAll = async (variables: FetchAllTicketsQueryVariables) => {
    const client = useApolloClient();
    const queryResult = await client.query({
      query: allTicketsQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
    isInitialized.value = true;

    const ticketData = flattenNodeConnection(queryResult.data.tickets);
    tickets.value = new Map(ticketData.map((ticket) => [ticket.id, convertToTypedTicket(ticket)]));
    return ticketData;
  };

  const createTicket = async (ticket: CreateTicketInput) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: createTicketMutation,
      variables: { input: ticket },
    });
    const ticketData = mutationResult.data?.createTicket?.ticket;
    if (ticketData) {
      tickets.value.set(ticketData.id, convertToTypedTicket(ticketData));
    }
    return ticketData;
  };

  const updateTicket = async (ticket: UpdateTicketInput) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: updateTicketMutation,
      variables: {
        input: ticket,
      },
    });
    const ticketData = mutationResult.data?.updateTicket?.ticket;
    if (ticketData) {
      tickets.value.set(ticketData.id, convertToTypedTicket(ticketData));
    }
    return ticketData;
  };

  const deleteTicket = async (ticketId: string) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: deleteTicketMutation,
      variables: {
        input: {
          id: ticketId,
          timestamp: new Date().toISOString(),
        },
      },
    });
    const wasSuccessfullyDeleted = mutationResult.data?.deleteTicket?.success;
    if (wasSuccessfullyDeleted) {
      tickets.value.delete(ticketId);
    }
    return wasSuccessfullyDeleted;
  };

  const updateTicketStatus = async (ticketId: string, newStatus: TicketStatus) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: createTicketStatusMutation,
      variables: {
        input: {
          ticket: ticketId,
          status: newStatus,
          timestamp: new Date().toISOString(),
          attachments: [],
        },
      },
    });
    const ticketData = mutationResult.data?.createTicketStatusUpdate?.ticketStatusUpdate?.ticket;
    if (ticketData) {
      tickets.value.set(ticketData.id, convertToTypedTicket(ticketData));
    }
    return ticketData;
  };

  const updateTicketAttachments = async (ticketId: string, attachments: AttachmentFragment[]) => {
    const existingAttachments = flattenNodeConnection(tickets.value.get(ticketId)?.attachments);

    const client = useApolloClient();

    const attachmentsIdsToDelete = subtract(existingAttachments, attachments).map(
      (attachment: AttachmentFragment) => attachment.id,
    );
    const attachmentIdsToCreate = subtract(attachments, existingAttachments).map(
      (attachment: AttachmentFragment) => attachment.id,
    );

    const createResults = attachmentIdsToCreate.map((attachment) =>
      client.mutate({
        mutation: createTicketAttachmentMutation,
        variables: {
          input: {
            ticket: ticketId,
            attachment,
            timestamp: new Date().toISOString(),
          },
        },
      }),
    );
    const deleteResults = attachmentsIdsToDelete.map((attachment) =>
      client.mutate({
        mutation: deleteTicketAttachmentMutation,
        variables: {
          input: {
            ticket: ticketId,
            attachment,
            timestamp: new Date().toISOString(),
          },
        },
      }),
    );

    await Promise.all([...createResults, ...deleteResults]);
    const updatedTicket = tickets.value.get(ticketId);
    if (updatedTicket) {
      updatedTicket.attachments = createConnection(attachments, NodeName.ATTACHMENT);
    }
  };

  const fetchTicket = async (ticketId: string) => {
    const client = useApolloClient();
    const queryResult = await client.query({
      query: singleTicketQuery,
      variables: { id: ticketId },
      fetchPolicy: 'no-cache',
    });
    isInitialized.value = true;

    const ticketData = queryResult.data.ticket;
    if (ticketData) {
      tickets.value.set(ticketData.id, convertToTypedTicket(ticketData));
    }
    return ticketData;
  };

  const exportTickets = async (ticketIds: string[], fileName: string) => {
    const client = useApolloClient();
    const mutationResult = await client.mutate({
      mutation: exportTicketsMutation,
      variables: {
        input: {
          tickets: ticketIds,
          title: fileName,
        },
      },
    });
    const file = mutationResult.data?.createTicketExport?.ticketExport?.file;
    return file;
  };

  const useTicketsForOrder = (orderId: Ref<string>) =>
    computed(() => {
      const ticketsForOrder = Array.from(tickets.value.values()).filter(
        (ticket: TitoTicketFragment) => ticket.order?.id === orderId.value,
      );
      return ticketsForOrder;
    });

  return {
    createTicket,
    deleteTicket,
    exportTickets,
    fetchTicket,
    fetchAll,
    isInitialized,
    tickets,
    updateTicket,
    updateTicketStatus,
    useTicketsForOrder,
    updateTicketAttachments,
  };
});

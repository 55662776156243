import { RouteLocationNormalized } from 'vue-router';

import { MembershipStore } from '@/features/memberships/membershipStore';
import { ProjectContributorGroupType } from '@/graphql/__generated__/graphql';
import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { AuthenticationService, LoggingService } from '@/interfaces/services';
import { useUserStore } from '@/services/store/user';
import { EXTERNAL_TENANT_ID } from '@/utils/config';

/** Sets new membership to store */
function setAndReturnMembership(
  to: RouteLocationNormalized,
  memberships: MembershipWithTenant[],
): MembershipWithTenant | null | undefined {
  const userStore = useUserStore();

  const userHasOnlyOneMembership = memberships.length === 1;

  const membershipToSet = userHasOnlyOneMembership
    ? memberships[0]
    : memberships.find((mem) => {
        const isCurrentTenantMembership = mem.tenant.id === to.params.tenantId;
        const isCurrentlySetMembership = mem.id === userStore.membershipId;
        return isCurrentTenantMembership || isCurrentlySetMembership;
      });

  userStore.setMembershipId(membershipToSet?.id ?? null);

  return membershipToSet;
}

export interface GuardMeta {
  authenticationService: AuthenticationService;
  loggingService: LoggingService;
  membershipStore: MembershipStore;
}

export type UseWithMembershipGuard = (
  activeMembership: MembershipWithTenant | undefined | null,
  memberships: MembershipWithTenant[],
) => any;

/** Use provided guard in addition to related membership */
export async function useWithMembership(
  { to }: { to: RouteLocationNormalized },
  { membershipStore }: Omit<GuardMeta, 'authenticationService'>,
  guard: UseWithMembershipGuard,
): Promise<any> {
  const [memberships, contributorGroupMemberships] = await Promise.all([
    membershipStore.fetchTenantMemberships(),
    membershipStore.fetchContributorGroupMemberships(),
  ]);

  const actualMemberships = [...memberships];
  if (
    contributorGroupMemberships.some(
      (m) => m.contributorGroup.type === ProjectContributorGroupType.External,
    )
  ) {
    actualMemberships.push({
      id: EXTERNAL_TENANT_ID,
      //TODO: https://linear.app/koppla/issue/KOP-2461/[ppp1]-replace-copy-and-icon-for-virtual-external-tenant
      tenant: { id: EXTERNAL_TENANT_ID, name: 'External' },
      isAdmin: false,
    });
  }

  const activeMembership = setAndReturnMembership(to, actualMemberships);

  return guard(activeMembership, actualMemberships);
}

import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const tradeSequenceFragment = gql`
  fragment TradeSequenceV2 on TradeSequenceNode {
    id
    name
    defaultDuration
    calendar {
      id
    }
    activities {
      edges {
        node {
          ...TradeSequenceActivity
        }
      }
    }
    dependencies {
      ...TradeSequenceActivityDependency
    }
  }
`;

export const tradeSequencesQuery = gqlFn(/* GraphQL */ `
  query tradeSequencesV2($project: ID!, $orderBy: [String!]) {
    tradeSequences(project: $project, orderBy: $orderBy) {
      edges {
        node {
          ...TradeSequenceV2
        }
      }
    }
  }
`);

export const tradeSequenceQuery = gqlFn(/* GraphQL */ `
  query tradeSequenceV2($id: ID!) {
    tradeSequence(id: $id) {
      ...TradeSequenceV2
    }
  }
`);

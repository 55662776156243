export interface UsePercentage {
  getPercentage: (value: number) => number;
}

type NumberRange = [number, number];

export function getRange(numbers: number[]): NumberRange {
  return [Math.min(...numbers), Math.max(...numbers)];
}

export function checkIfInRange(value: number, [min, max]: NumberRange) {
  return value >= min && value <= max;
}

// return value if in bounds, otherwise bounds
export function clamp(value: number, [min, max]: [number, number]) {
  return Math.min(Math.max(min, value), max);
}

/** Returns percent of numeric value */
export function getPercentage(value: number, digits = 1): number {
  return Number((value * 100).toFixed(digits));
}

/**  Sort ascending and prioritize null values */
export function sortAscAndPrioritizeNullValues(a: number | null, b: number | null): number {
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }

  return a - b;
}

import { ThemeDefinition } from 'vuetify';
import colors from 'vuetify/lib/util/colors';

const kopplaBlue = '#005A7E';

const light: ThemeDefinition = {
  colors: {
    primaryBackground: '#002837',
    primary: kopplaBlue,
    error: colors.red.darken1,
    success: colors.green.darken1,
    secondary: '#F2C36B',
    info: colors.blue.darken2,
    infoButton: '#BDD1EE',
    warning: '#FFAD00',
    accent: colors.amber.darken2,
    unknown: colors.grey.darken1,
    unknownLight: colors.grey.lighten1,
    customBaseGrey: '#F5F5F5',
    customBaseGreyDark: '#E2E4E5',
    lightBlue: colors.lightBlue.lighten2,
    kopplaBlue,
    primaryLight: '#A3B0B5',
    alternativeBackground: '#F2F3F3',
    primaryTextButton: kopplaBlue,
    drawerIcons: '#FFFFFF',
    activeSelection: '#F2C36B',
    footerText: '#FFFFFF',
    darkBlue40: '#A1A8AB',
  },
};

export default light;

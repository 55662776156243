import { FirebaseError } from 'firebase/app';
import {
  applyActionCode,
  AuthCredential,
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  getAuth,
  linkWithCredential,
  OAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
  UserCredential,
  verifyPasswordResetCode as verifyPasswordReset,
} from 'firebase/auth';

import { getEmailDomain } from '@/helpers/email';
import { UserRepository } from '@/interfaces/repositories';
import { SignUpConfiguration, UserAccountStatus } from '@/interfaces/repositories/users';
import { AuthenticationService, LoggingService } from '@/interfaces/services';
import {
  AccountNotLinkedToMicrosoftError,
  EmailOfAccountsNotMatchingError,
  IllegalEmailDomainError,
  InvalidNewUserError,
  MicrosoftAccountDetails,
  MicrosoftFlowCanceledError,
  ResetPasswordMicrosoftAccountError,
  ResetPasswordNoAccountError,
} from '@/interfaces/services/authentication';
import { getMicrosoftFirebaseApp } from '@/plugins/firebase';
import { AppErrorCode } from '@/utils/errors';
import eventBus from '@/utils/eventBus';

const microsoftProvider = new OAuthProvider('microsoft.com');

function getMicrosoftProvider(email: string): OAuthProvider {
  const provider = new OAuthProvider(microsoftProvider.providerId);
  // Prefills email in popup with given value
  provider.setCustomParameters({ login_hint: email });
  return provider;
}

function isNewlyCreatedUser(credentials: UserCredential): boolean {
  if (!credentials.user.metadata.creationTime) return false;
  /**
   * Using creation and last login time also deletes the user
   * when logging in for the first time after sign up.
   */
  const timeSinceCreationMs =
    new Date().getTime() - new Date(credentials.user.metadata.creationTime).getTime();
  return timeSinceCreationMs < 4000;
}

function getCurrentFirebaseUserOrThrow(): User {
  const { currentUser } = getAuth();
  if (!currentUser) throw new Error('Unable to perform action without authenticated user.');
  return currentUser;
}

function handleFirebaseError(error: unknown): void {
  if (error instanceof FirebaseError) {
    if (error.code === 'auth/popup-closed-by-user') throw new MicrosoftFlowCanceledError();

    if (error.code === 'auth/account-exists-with-different-credential') {
      throw new AccountNotLinkedToMicrosoftError();
    }
  }
}

export class FirebaseAuthenticationService implements AuthenticationService {
  private verifiedMicrosoftUserIds = new Set<string>();

  public constructor(
    private loggingService: LoggingService,
    private getUserRepository: () => UserRepository,
  ) {
    this.restoreFirebaseState();
  }

  public login(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(getAuth(), email, password).then((value) => {
      this.loggingService.trackEvent(
        new this.loggingService.AnalyticEventCategories.SignInEvent({
          username: email,
          provider: 'password',
        }),
      );
      return value;
    });
  }

  public logout(): Promise<void> {
    return this.getUser().then((user) =>
      signOut(getAuth())
        .then(() => {
          this.loggingService
            .trackEvent(
              new this.loggingService.AnalyticEventCategories.SignOutEvent({
                username: user?.email ?? '',
              }),
            )
            .then(() => {
              this.loggingService.reset();
            });
          this.afterLogout();
        })
        .catch((e) => {
          this.loggingService.error(e, { code: AppErrorCode.NETWORK });
          this.afterLogout();
        }),
    );
  }

  private afterLogout(): void {
    eventBus.emit('logout');
  }

  public async isAuthenticated(): Promise<boolean> {
    await this.restoreFirebaseState();

    const user = getAuth().currentUser;
    if (!user) return false;

    if (!this.verifiedMicrosoftUserIds.has(user.uid)) {
      const linkedToMicrosoft = user.providerData.some(
        (provider) => provider.providerId === microsoftProvider.providerId,
      );
      /**
       * Check that user didn't cancel sign up process and is now logged in.
       */
      if (linkedToMicrosoft) {
        const accountStatus = await this.getUserRepository().getOwnAccountStatus();
        if (accountStatus !== UserAccountStatus.Active) return false;
      }
      this.verifiedMicrosoftUserIds.add(user.uid);
    }

    const token = await this.getIdToken();
    return !!token;
  }

  public async waitForAuthentication(): Promise<void> {
    const auth = getAuth();
    return new Promise<void>((resolve, reject) => {
      onAuthStateChanged(
        auth,
        async (user) => {
          // If user is null we wait for the next state change
          if (!user) return;
          try {
            const token = await this.getIdToken();
            if (token) {
              resolve();
            } else {
              reject(new Error('Failed to obtain token'));
            }
          } catch (error) {
            reject(error);
          }
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  public verifyEmail(): Promise<void> {
    const user = getAuth().currentUser;
    return user ? sendEmailVerification(user) : Promise.resolve();
  }

  public async isVerified(): Promise<boolean> {
    await this.restoreFirebaseState();
    const { currentUser } = getAuth();
    if (!currentUser) return false;

    const isMicrosoftUser =
      currentUser.providerData.length === 1 &&
      currentUser.providerData[0].providerId === microsoftProvider.providerId;
    // Backend verifies email during sign up, we need to reload user so this is reflected
    if (isMicrosoftUser && !currentUser.emailVerified) {
      await currentUser.reload();
    }

    return currentUser.emailVerified;
  }

  public setAsVerified(code: string): Promise<void> {
    return applyActionCode(getAuth(), code);
  }

  public async getUser(): Promise<User | null> {
    await this.restoreFirebaseState();
    return getAuth().currentUser;
  }

  public getIdToken(refresh?: boolean): Promise<string> | undefined {
    return getAuth().currentUser?.getIdToken(refresh);
  }

  public verifyPasswordResetCode(code: string): Promise<string> {
    return verifyPasswordReset(getAuth(), code);
  }

  public setPassword(code: string, password: string): Promise<void> {
    return confirmPasswordReset(getAuth(), code, password);
  }

  public async resetPassword(email: string): Promise<void> {
    const auth = getAuth();
    const availableMethods = await fetchSignInMethodsForEmail(auth, email);
    if (!availableMethods.length) {
      throw new ResetPasswordNoAccountError();
    }

    // Resetting password seems to disconnect MSSO so we only allow it
    // if password is the only authentication method.
    if (availableMethods.some((method) => method !== 'password')) {
      throw new ResetPasswordMicrosoftAccountError();
    }

    return sendPasswordResetEmail(auth, email);
  }

  private restoreFirebaseState(): Promise<void> {
    return getAuth().authStateReady();
  }

  /** MICROSOFT START */

  public async loginWithMicrosoft(): Promise<void> {
    try {
      const signInResult = await signInWithPopup(getAuth(), microsoftProvider);

      const deleteAndThrow = async () => {
        await signInResult.user.delete();
        throw new InvalidNewUserError(signInResult.user.email ?? '-');
      };

      if (isNewlyCreatedUser(signInResult)) {
        await deleteAndThrow();
      }

      const accountStatus = await this.getUserRepository().getOwnAccountStatus();
      // Sign up process canceled
      if (accountStatus !== UserAccountStatus.Active) {
        await deleteAndThrow();
      }

      this.loggingService.trackEvent(
        new this.loggingService.AnalyticEventCategories.SignInEvent({
          username: signInResult.user.email!,
          provider: 'microsoft',
        }),
      );
    } catch (error) {
      handleFirebaseError(error);
      throw error;
    }
  }

  public async linkAccountWithMicrosoft(): Promise<boolean> {
    try {
      const currentUser = getCurrentFirebaseUserOrThrow();

      const isLinkedToMicrosoft = currentUser.providerData.some(
        (provider) => provider.providerId === microsoftProvider.providerId,
      );
      if (isLinkedToMicrosoft) return true;

      const credentials = await this.getMicrosoftAuthCredentials(currentUser);
      if (!credentials) {
        return false;
      }

      await linkWithCredential(currentUser, credentials!);

      this.loggingService.trackEvent(
        new this.loggingService.AnalyticEventCategories.UserSSOProviderConnectedEvent(),
      );

      return true;
    } catch (e) {
      handleFirebaseError(e);
      throw e;
    }
  }

  public isLinkedToMicrosoft(): boolean {
    const auth = getAuth();
    return (
      auth.currentUser?.providerData.some(
        (provider) => provider.providerId === microsoftProvider.providerId,
      ) ?? false
    );
  }

  public async createAccountWithMicrosoft(
    email: string,
    config: SignUpConfiguration,
  ): Promise<MicrosoftAccountDetails> {
    try {
      const provider = getMicrosoftProvider(email);
      const auth = getAuth();

      const signInResult = await signInWithPopup(auth, provider);
      const isNewUser = isNewlyCreatedUser(signInResult);
      const signInEmail = signInResult.user.email!;
      const emailMatchesDomain = !config.domain
        ? true
        : config.domain === getEmailDomain(signInEmail);
      // Microsoft account email doesn't match invited email or invalid domain
      if (signInEmail !== email || !emailMatchesDomain) {
        if (isNewUser) {
          await signInResult.user.delete();
        } else {
          await auth.signOut();
        }
        if (!emailMatchesDomain) throw new IllegalEmailDomainError(signInEmail, config.domain!);

        throw new EmailOfAccountsNotMatchingError(signInEmail!);
      }
      const accountStatus = await this.getUserRepository().getOwnAccountStatus();
      if (accountStatus !== UserAccountStatus.Invited) {
        await auth.signOut();
        throw new InvalidNewUserError(email);
      }
      const [firstName, ...lastName] = signInResult.user.displayName?.split(' ') ?? ['-', '-'];
      return {
        firstName,
        lastName: lastName.join(' '),
      };
    } catch (e) {
      handleFirebaseError(e);
      throw e;
    }
  }

  private async getMicrosoftAuthCredentials(
    currentUser: User,
  ): Promise<AuthCredential | undefined> {
    /**
     * Use separate Firebase app so sign in with Microsoft into another account
     * doesn't log out current user.
     */
    const loginApp = getMicrosoftFirebaseApp();
    const microsoftAuth = getAuth(loginApp);
    const provider = getMicrosoftProvider(currentUser.email ?? '');
    try {
      const credentials = await signInWithPopup(microsoftAuth, provider);
      const signInEmail = credentials.user.email;
      if (currentUser && signInEmail !== currentUser.email) {
        /**
         * There could already be another koppla account for the Microsoft
         * email. We can only delete the user, if we just created it.
         */
        if (isNewlyCreatedUser(credentials)) {
          await credentials.user.delete();
        }
        throw new EmailOfAccountsNotMatchingError(signInEmail!);
      }
      /**
       * Can't think of a case where this code is actually reached as there are three cases:
       * - user cancels flow -> error thrown
       * - user would new user with email -> auth/account-exists-with-different-credential' handled below
       * - user with email already exists -> EmailOfAccountsNotMatchingError thrown
       */

      return undefined;
    } catch (error) {
      // Expected case when user with email already exists
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/account-exists-with-different-credential'
      ) {
        const email = error.customData?.email as string;
        const credentials = OAuthProvider.credentialFromError(error);
        if (typeof email !== 'string' || credentials === null) {
          throw new Error('Expected email and/or credentials are not set on error.');
        }

        return credentials;
      }
      throw error;
    } finally {
      // Reset app state
      if (microsoftAuth.currentUser) await microsoftAuth.signOut();
    }
  }

  /** MICROSOFT END */
}

import { isBefore } from 'date-fns';

import { Entity } from '@/interfaces/repositories/base';

import { copy, isEqual } from './objects';

/** Returns numeric array of a given length */
export function getArrayTo(n: number): number[] {
  return [...Array(n).keys()];
}

/**
 * Convert a flat list to a matrix with the given column height.
 * See: https://stackoverflow.com/a/39838921
 */
export function toMatrix(arr: string[], columnHeight: number): string[][] {
  return arr.reduce((columns: string[][], key: string, index: number): string[][] => {
    if (index % columnHeight === 0) {
      // push to existing column
      columns.push([key]);
    } else {
      // create new column with the value
      columns[columns.length - 1].push(key);
    }
    return columns;
  }, [] as string[][]);
}

/**
 * Returns true, if all values in this array are unique
 */
export function isUnique<T>(array: T[]): boolean {
  return new Set(array).size === array.length;
}

/**
 * Compares two arrays for shallow equality. Returns true, if all elements of the arrays are identical.
 * Otherwise the order of the elements does not need to be identical.
 */
export function hasEqualElements<T extends { id: string }>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) return false;

  return isEqual(sortObjectsByKey(copy(array1), 'id'), sortObjectsByKey(copy(array2), 'id'));
}

/**
 * Combines all objects to an array depending on specific key
 * @param objects objects, which should be combined
 * @param key key, which should be used for indicator of combination
 */
export function combineObjectsByKey<T>(objects: T[], key: keyof T): T[][] {
  return objects.reduce((objectsByKey, object) => {
    const related = objectsByKey.find((objs) => objs.map((o) => o[key]).includes(object[key]));

    if (related) {
      related.push(object);
    } else {
      objectsByKey.push([object]);
    }
    return objectsByKey;
  }, [] as T[][]);
}

/**
 * Sorts all objects of an array depending on specific key
 * @param objects objects, which should be sorted
 * @param key key, which should be used for indicator of sorting
 */
export function sortObjectsByKey<T extends Dictionary, N>(
  objects: T[],
  key?: keyof T,
  secondary?: { top: keyof T; nested: keyof N },
  order: 'ASC' | 'DESC' = 'ASC',
): T[] {
  const greaterThanOrder = order === 'ASC' ? 1 : -1;
  const lowerThanOrder = order === 'ASC' ? -1 : 1;

  return objects.sort((o1, o2) => {
    if (key) {
      if (o1[key] > o2[key]) return greaterThanOrder;
      if (o1[key] < o2[key]) return lowerThanOrder;
    }

    /* sort by secondary nested key */
    if (secondary) {
      const v1 = o1[secondary.top];
      const v2 = o2[secondary.top];
      if (!v1 || !v2) return 0;
      if (v1[secondary.nested] > v2[secondary.nested]) return greaterThanOrder;
      if (v1[secondary.nested] < v2[secondary.nested]) return lowerThanOrder;
    }

    return 0;
  });
}

/**
 * Sorts a numeric array ascending
 * @param numbers objects, which should be sorted
 */
export function sortNumbers(numbers: number[]): number[] {
  return numbers.sort((a, b) => a - b);
}

/** Sorts a given array by the occurence in the other array */
export function sortByOccurence<T extends { id: string }>(array1: T[], array2: string[]): T[] {
  return array1.sort((a, b) => {
    const aIdx = array2.findIndex((elem) => elem === a.id);
    const bIdx = array2.findIndex((elem) => elem === b.id);
    // move non-related elements to the end of the list
    if (aIdx === -1) return 1;
    // apply the same ordering of elements to the current array as in array2
    return aIdx - bIdx;
  });
}

/**
 * Checks if all values of an object array at specific key are identical
 * @param objects objects, which should be checked
 * @param key key, which should be used for indicator of equality
 */
export function isEqualAtKey<T>(objects: (T | null)[], key: keyof T): boolean {
  return objects.every((val, _, arr) => (val ? val[key] : null) === (arr[0] ? arr[0][key] : null));
}

/**
 * Filters all values of the given array that have all linear predecessors. Returns the maximum of those values
 * @param array array, that should be used for caclutaion
 * @param startValue initial value, where to start the linear search
 */
export function getLinearMaxValue(array: number[], startValue = 0): number {
  if (!array.length) return 0;
  return sortNumbers(copy(array)).reduce((max, entry) => {
    if (max + 1 === entry) max = entry;
    return max;
  }, startValue);
}

/**
 * Returns indicator, if array elements are all subsequent
 * @param array
 */
export function isSubsequent(array: number[]): boolean {
  // Checks which value is reachable at most, when counting +1 and starting from min value
  // If this value is equal to the max value of the array, the whole array is subsequent
  return getLinearMaxValue(array, Math.min(...array) - 1) === Math.max(...array);
}

/** Returns the number of events, that are max. simultaneously happening
 * Assumes that for all orders have startDate < EndDate
 */
export function getNumberOfMaxOverlappingEvents<T extends { start: Date; end: Date }>(
  objects: T[],
): number {
  if (!objects.length) return 0;
  // Create a list from the provided events that hold on of the dates and an operation
  // This list is then used to create a stack, which bascially represents the simoultaneous events at a given time
  const dateListWithOperation: { date: Date; operation: 'begin' | 'end' }[] = [];
  objects.forEach((element) => {
    dateListWithOperation.push({ date: element.start, operation: 'begin' });
    dateListWithOperation.push({ date: element.end, operation: 'end' });
  });
  // Sort the list of dates with operation, because we use it to create a stack
  dateListWithOperation.sort((a, b) => (isBefore(a.date, b.date) ? -1 : 1));
  let maxOverlapping = 0;
  let temporaryStack = 0;
  // Iterate trough the list of dates and increase the stack if we have an order beginning, remove one if one ended
  dateListWithOperation.forEach((dateWithOperation) => {
    if (dateWithOperation.operation === 'begin') temporaryStack++;
    else temporaryStack--;
    maxOverlapping = temporaryStack > maxOverlapping ? temporaryStack : maxOverlapping;
  });
  return maxOverlapping;
}

/** Returns max value of numeric array */
export function findMax(array: number[]): number {
  return array.reduce((max, current) => Math.max(max, current), Number.NEGATIVE_INFINITY);
}

/** Returns min value of numeric array */
export function findMin(array: number[]): number {
  return array.reduce((min, current) => Math.min(min, current), Number.POSITIVE_INFINITY);
}

/**
 * Returns chunks of a given array. The given limit can either be numeric, if all chunks should be same-sized,
 * or an array of limits, if some chunks should have a different length. In this case, make sure to pass in a
 * limit for all chunks to be made. Otherwise, the first limit will be taken for all chunks.
 * A chunk can be smaller than the actual limit, if there are not enough values in the given base array.
 * @param array - array to be chunkenized
 * @param limits - upper limits for the chunks
 * @returns array of chunks containing the data from the base array
 */
export function makeChunks<T>(array: T[], limits: number[] | number): T[][] {
  const chunks = [] as T[][];
  // position of the cursor iterating over the plain array
  let cursor = 0;

  /** Returns the limit of the next chunk to create */
  const getLimit = () => {
    // take the numeric limit, if no array given
    if (!Array.isArray(limits)) return limits;
    // if the length of limits does not match the length of chunks take the very first limit for all chunks
    if (limits.length <= chunks.length) {
      if (!limits.length) throw new Error('No limits for chunks given');
      return limits[0];
    }
    // take the limit for the next chunk from array
    return limits[chunks.length];
  };

  // move cursor through whole array
  while (cursor < array.length) {
    const limit = getLimit();
    // create a new chunk from cursor position to next limit
    chunks.push(array.slice(cursor, cursor + limit));
    // update cursor
    cursor += limit;
  }
  return chunks;
}

/**
 * Finds the name for a copied element, depending on number of elements with same base name
 * @param array
 * @param name
 */
export function findNameForCopy<T extends Dictionary>(
  array: T[],
  name: string,
  getName: (t: T) => string = (t: T) => t.name,
): string {
  const baseName = name.split(' (')[0];
  const equalNamedElements = array.filter((element) => {
    const regex = new RegExp(`${baseName} [(][0-9]+[)]`);
    return getName(element) === baseName || !!getName(element).match(regex);
  });

  let newName = baseName;
  if (equalNamedElements.length) {
    newName = `${baseName} (${equalNamedElements.length})`;
  }
  return newName;
}

/**
 * Equivalent to python's zip function for arrays. Combines all objects of given arrays at same index
 * into new array. Example: a = [a1, a2, a3] and b = [b1, b2, b3], then zip(a,b) = [[a1, b1], [a2, b2], [a3, b3]]
 * @param arrays
 * @returns
 */
export function zip<T extends Array<unknown> = unknown[]>(
  ...arrays: (unknown[] | readonly unknown[])[]
): T[] {
  return arrays[0].map((_, c) => arrays.map((row) => row[c])) as unknown as T[];
}

/**
 * Subtract all elements from array1, that are present in array2 by id.
 * NOTE: This is the efficient version to achieve this
 * @param array1
 * @param array2
 * @param getId
 * @returns filtered array
 */
export function subtract<T>(
  array1: T[],
  array2: T[],
  getId: (element: T) => string = (element: T) =>
    typeof element === 'string' ? element : (element as Dictionary).id,
): T[] {
  const idsToFilter = new Map(array2.map((element) => [getId(element), '']));

  return array1.filter((element) => !idsToFilter.has(getId(element)));
}

/**
 * Removes an item of the array in-place
 * @param array
 * @param value
 * @returns
 */
export function removeItem<T>(
  array: T[],
  value: T,
  getId: (item: T) => string = (item: T) => (item as Dictionary).id,
): T[] {
  const index = array.findIndex((item) => getId(item) === getId(value));
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
}

/**
 * Replaces old value in array with new array
 * @param array
 * @param oldValue
 * @param newValue
 * @returns
 */
export function replaceItem<T>(
  array: T[],
  oldValue: T,
  newValue: T,
  getId: (item: T) => string = (item: T) => (item as Dictionary).id,
): T[] {
  const index = array.findIndex((item) => getId(item) === getId(oldValue));
  if (index > -1) {
    array.splice(index, 1, newValue);
  }
  return array;
}

/**
 * Samples a random item from an array
 * @param items
 * @returns
 */
export function pickRandomItem<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

interface ArrayDifferences<T> {
  added: T[];
  deleted: T[];
  updated: T[];
}

/**
 * Compares two arrays for difference. Per default (if no comparison function is provided) only compares
 * the elements of the arrays shallowly.
 * @param oldArray Existing array
 * @param newArray New array
 * @param keys Key to compare
 * @param areElementsEqual Function accepting two element and return boolean if they are equal
 * @returns
 */
export function findArrayDifferences<T extends Entity>(
  oldArray: T[],
  newArray: T[],
  keys: string[],
  areElementsEqual?: (firstElement: T, secondElement: T) => boolean,
): ArrayDifferences<T> {
  const added = subtract(newArray, oldArray);
  const deleted = subtract(oldArray, newArray);

  const oldArrayMap = new Map(oldArray.map((item) => [item.id, item]));
  const updated = newArray.filter((newItem) => {
    const oldItem = oldArrayMap.get(newItem.id);
    if (!oldItem) return false;
    if (areElementsEqual) {
      return areElementsEqual(newItem, oldItem);
    }
    return keys.some((key) => newItem[key] !== oldItem[key]);
  });

  return { added, deleted, updated };
}

/**
 * Deep-merges two arrays of objects by id. If an object is present in both arrays, the object from the second array
 * will overwrite the object from the first array.
 * @param array1
 * @param array2
 * @returns merged array
 */
export function mergeArrays<T extends { id: string }>(array1: T[], array2: T[]): T[] {
  if (!array1.length) return array2;
  if (!array2.length) return array1;

  const mergedArray = [...array1];
  const array1Map = new Map(array1.map((item, index) => [item.id, { item, index }]));

  array2.forEach((item) => {
    const value = array1Map.get(item.id);
    if (value === undefined) {
      mergedArray.push(item);
    } else {
      mergedArray[value.index] = { ...value.item, ...item };
    }
  });

  return mergedArray;
}

if (!('at' in Array.prototype)) {
  // from https://stackoverflow.com/questions/68464114/why-am-i-getting-at-is-not-a-function
  function at(this: Array<unknown> | string, n: number) {
    // ToInteger() abstract op
    n = Math.trunc(n) || 0;
    // Allow negative indexing from the end
    if (n < 0) n += this.length;
    // OOB access is guaranteed to return undefined
    if (n < 0 || n >= this.length) return undefined;
    // Otherwise, this is just normal property access
    return this[n];
  }

  const TypedArray = Reflect.getPrototypeOf(Int8Array);
  [Array, String, TypedArray].forEach((C) => {
    if (C === null) return;
    Object.defineProperty((C as any).prototype, 'at', {
      value: at,
      writable: true,
      enumerable: false,
      configurable: true,
    });
  });
}

import { TradeSequenceActivityDependencyEntity, TradeSequenceActivityEntity } from '@/common/types';
import { NewOrUpdatedTradeSequenceActivity } from '@/features/flexibleTradeSequences';
import { omitKeys, pickKeys } from '@/helpers/utils/objects';

import { NewOrUpdatedTradeSequenceTemplate } from './types';

function mapActivityFragmentsToInputs(
  fragments: NewOrUpdatedTradeSequenceActivity[],
  existingActivities: { activities: TradeSequenceActivityEntity[] },
) {
  return fragments.map((activity) => {
    const oldActivity = existingActivities.activities.find((a) => a.id === activity.id);
    return {
      ...pickKeys(activity, ['id', 'name', 'duration', 'offset']),
      tasks: activity.taskTemplates
        .map((task) => {
          const oldTask = oldActivity?.taskTemplates.find((t) => t.id === task.id);
          return { ...omitKeys(task, ['__typename']), id: oldTask?.id ?? undefined };
        })
        .filter((task) => task.name),
      dryingBreak: activity.dryingBreak
        ? {
            name: activity.dryingBreak.name,
            duration: activity.dryingBreak.duration,
          }
        : null,
      tenantTradeVariation: activity.tenantTradeVariation.id,
    };
  });
}

function mapDependencyFragmentsToInputs(fragments: TradeSequenceActivityDependencyEntity[]) {
  return fragments.map((dependency) => ({
    ...pickKeys(dependency, ['lagInMinutes', 'bufferInMinutes', 'type', 'id', 'useDryingBreak']),
    fromActivity: dependency.from.id,
    toActivity: dependency.to.id,
  }));
}

export function mapTradeSequenceToUpdateInput(
  tradeSequence: Omit<NewOrUpdatedTradeSequenceTemplate, '__typename'>,
  existingActivities: { activities: TradeSequenceActivityEntity[] },
) {
  return {
    ...pickKeys(tradeSequence, ['id', 'name', 'defaultDuration']),
    activities: mapActivityFragmentsToInputs(tradeSequence.activities, existingActivities),
    dependencies: mapDependencyFragmentsToInputs(tradeSequence.dependencies),
  };
}

export function mapTradeSequenceToCreateInput(
  tradeSequence: Omit<NewOrUpdatedTradeSequenceTemplate, '__typename'>,
) {
  return mapTradeSequenceToUpdateInput(tradeSequence, { activities: [] });
}

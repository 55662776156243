import { OperationNames } from 'events.schema';

import { ProjectEntity } from '@/common/types';
import { useNotificationStore } from '@/features/notifications/notificationStore';
import { isRealProject, LifecycleStatus } from '@/features/projects/projectUtils';

import { RTCClientResponseStatus } from '../../rtcClient/types';
import { BlockingInteraction, RemoteProjectChangeEvent } from '../../types';

export class RTCBlockingInteractionHandler {
  public blockingInteraction = ref<BlockingInteraction | null>(null);

  public setBlockingInteractionForOutOfOrderEventsError(): void {
    this.blockingInteraction.value = {
      reason: 'INVALID_LOCAL_EVENT_ORDER',
    };
  }

  public setBlockingInteractionForRestoredEntityNotFoundError(): void {
    this.blockingInteraction.value = {
      reason: 'RESTORED_ENTITY_NOT_FOUND',
    };
  }

  public removeBlockingInteraction(): void {
    this.blockingInteraction.value = null;
  }

  /**
   * Checks if the event is a remote event that requires blocking interaction.
   * If the event is a remote event that requires blocking interaction, the blockingInteraction
   * ref will be set to the corresponding BlockingInteraction object.
   * @param event The remote event to check for blocking interaction.
   */
  // eslint-disable-next-line complexity -- TODO: KOP-2384
  public setBlockingInteractionForRemoteEvent(
    event: RemoteProjectChangeEvent,
    project: ProjectEntity | undefined,
  ): void {
    if (
      event.operation.name === OperationNames.PublishProjectAlternative ||
      event.operation.name === OperationNames.RestoreProjectVersion
    ) {
      if (event.status === RTCClientResponseStatus.SUCCESS) {
        this.blockingInteraction.value = {
          reason: 'SCHEDULE_VERSION_CHANGE',
          data: { user: event.user },
        };
      } else if (event.status === RTCClientResponseStatus.ERROR) {
        const notificationStore = useNotificationStore();
        notificationStore.push({
          titleI18nKey: 'objects.realTimeCollaboration.scheduleVersionChangeFailedTitle',
          type: 'attention',
          iconProps: { icon: 'info-circle' },
        });
        this.blockingInteraction.value = null;
      }
    } else if (
      event.operation.name === OperationNames.PublishProjectAlternativeResult ||
      event.operation.name === OperationNames.RestoreProjectVersionResult
    ) {
      if (event.operation.input?.status === RTCClientResponseStatus.SUCCESS) {
        this.blockingInteraction.value = {
          reason: 'SCHEDULE_VERSION_CHANGE_COMPLETED',
          data: { user: event.user },
        };
      } else if (event.operation.input?.status === RTCClientResponseStatus.ERROR) {
        const notificationStore = useNotificationStore();
        notificationStore.push({
          titleI18nKey: 'objects.realTimeCollaboration.scheduleVersionChangeFailedTitle',
          type: 'attention',
          iconProps: { icon: 'info-circle' },
        });
        this.blockingInteraction.value = null;
      }
    } else if (event.operation.name === OperationNames.DeleteProjectAlternative) {
      this.blockingInteraction.value = {
        reason: 'PROJECT_ALTERNATIVE_DELETED',
        data: {
          user: event.user,
        },
      };
    } else if (event.operation.name === OperationNames.DeleteProject) {
      this.blockingInteraction.value = {
        reason: 'PROJECT_DELETED',
        data: {
          user: event.user,
        },
      };
    } else if (event.operation.name === OperationNames.UpdateProjectStatus) {
      if (event.operation.input?.status === LifecycleStatus.ARCHIVED) {
        this.blockingInteraction.value = {
          reason: 'PROJECT_ARCHIVED',
          data: {
            user: event.user,
          },
        };
        return;
      }

      const existingStatus = (project?.lifecycleStatus ?? '') as LifecycleStatus;
      const newStatus = (event.operation.input?.status ?? '') as LifecycleStatus;

      if (!isRealProject(existingStatus) && isRealProject(newStatus)) {
        this.blockingInteraction.value = {
          reason: 'PROJECT_CONVERTED_TO_REAL',
          data: {
            user: event.user,
          },
        };
      }
    }
  }

  public reset(): void {
    this.blockingInteraction.value = null;
  }
}

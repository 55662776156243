import { inject } from 'vue';

type EventBusFn = (...data: any[]) => void;

export class EventBus {
  private events: Record<string, EventBusFn[]> = {};

  public constructor() {
    this.events = {};
  }

  public on(eventName: string, fn: EventBusFn): void {
    // add new event handler
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(fn);
  }

  public off(eventName: string): void {
    if (!this.events[eventName]) return;

    delete this.events[eventName];
  }

  public emit(eventName: string, ...data: any[]): void {
    if (!this.events[eventName]) return;

    // call registered event handler
    this.events[eventName].forEach((fn) => {
      fn(...data);
    });
  }
}

export const eventBusKey = Symbol('eventBus');

export function useEventBus(): EventBus {
  return inject(eventBusKey) as EventBus;
}

const eventBus = new EventBus();
export default eventBus;

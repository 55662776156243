import { NodeName } from '@/repositories/utils/cache';

import { useNotificationStore } from '../notifications';

/**
 * Show a notification that some elements that were changed by the user are filtered out.
 * @param elementTypes
 */
export function showChangedFilteredElementsNotification({
  addedElementTypes,
  editedElementTypes,
}: {
  addedElementTypes: NodeName[];
  editedElementTypes: NodeName[];
}): void {
  const notificationStore = useNotificationStore();

  if (addedElementTypes.length && editedElementTypes.length) {
    notificationStore.push({
      titleI18nKey: 'filter.addedAndEditedVariousItemsFilteredNotificationTitle',
      bodyI18nKey: 'filter.addedAndEditedVariousItemsFilteredNotificationMessage',
      type: 'warning',
      iconProps: {
        icon: 'info-circle',
      },
      timeout: 10000,
    });
    return;
  }

  if (addedElementTypes.length) {
    let titleKey = '';
    let messageKey = '';
    let count = 0;

    const uniqueTypes = new Set(addedElementTypes);
    if (uniqueTypes.size === 1) {
      const type = uniqueTypes.values().next().value;
      if (type === NodeName.ORDER) {
        titleKey = 'filter.addedOrderFilteredNotificationTitle';
        messageKey = 'filter.addedOrderFilteredNotificationMessage';
      } else if (type === NodeName.MILESTONE) {
        titleKey = 'filter.addedMilestoneFilteredNotificationTitle';
        messageKey = 'filter.addedMilestoneFilteredNotificationMessage';
      } else if (type === NodeName.PAUSE) {
        titleKey = 'filter.addedPauseFilteredNotificationTitle';
        messageKey = 'filter.addedPauseFilteredNotificationMessage';
      }
      count = addedElementTypes.filter((item) => item === type).length;
    } else {
      titleKey = 'filter.addedVariousItemsFilteredNotificationTitle';
      messageKey = 'filter.addedVariousItemsFilteredNotificationMessage';
    }

    notificationStore.push({
      titleI18nKey: titleKey,
      titleI18nKeyVariables: {
        count,
      },
      bodyI18nKey: messageKey,
      bodyI18nKeyVariables: {
        count,
      },
      type: 'warning',
      iconProps: {
        icon: 'info-circle',
      },
      timeout: 10000,
    });
    return;
  }

  if (editedElementTypes.length) {
    let titleKey = '';
    let messageKey = '';
    let count = 0;

    const uniqueTypes = new Set(editedElementTypes);
    if (uniqueTypes.size === 1) {
      const type = uniqueTypes.values().next().value;
      if (type === NodeName.ORDER) {
        titleKey = 'filter.editedOrderFilteredNotificationTitle';
        messageKey = 'filter.editedOrderFilteredNotificationMessage';
      } else if (type === NodeName.MILESTONE) {
        titleKey = 'filter.editedMilestoneFilteredNotificationTitle';
        messageKey = 'filter.editedMilestoneFilteredNotificationMessage';
      } else if (type === NodeName.PAUSE) {
        titleKey = 'filter.editedPauseFilteredNotificationTitle';
        messageKey = 'filter.editedPauseFilteredNotificationMessage';
      }
      count = editedElementTypes.filter((item) => item === type).length;
    } else {
      titleKey = 'filter.editedVariousItemsFilteredNotificationTitle';
      messageKey = 'filter.editedVariousItemsFilteredNotificationMessage';
    }

    notificationStore.push({
      titleI18nKey: titleKey,
      titleI18nKeyVariables: {
        count,
      },
      bodyI18nKey: messageKey,
      bodyI18nKeyVariables: {
        count,
      },
      type: 'warning',
      iconProps: {
        icon: 'info-circle',
      },
      timeout: 10000,
    });
  }
}

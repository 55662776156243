import { CalendarEntity, PartialEntity, ProjectEntity, WbsSectionEntity } from '@/common/types';
import { defaultHoursPerDay } from '@/helpers/leanProjects/config';
import { MINUTES_PER_DAY, MINUTES_PER_HOUR } from '@/helpers/utils/timeConstants';

import { toCalendarEntity } from '../calendars/calendarUtils';
import { OperationInputType } from '../realTimeCollaboration/types';

export function convertProjectUpdateStatusOperationInput(
  projectId: string,
  input: OperationInputType<'UpdateProjectStatus'>,
): PartialEntity<ProjectEntity>[] {
  return [
    {
      id: projectId,
      completed: input.status === 'COMPLETED',
      lifecycleStatus: input.status,
    },
  ];
}

export function convertProjectCompleteSetupOperationInput(
  projectId: string,
  input: OperationInputType<'CompleteProjectSetup'>,
): {
  addedCalendars: CalendarEntity[];
  addedSections: WbsSectionEntity[];
  updatedProjects: PartialEntity<ProjectEntity>[];
} {
  const addedCalendars = [
    toCalendarEntity({
      ...input.calendar,
      minutesPerDay: input.hourlyPlanningEnabled
        ? defaultHoursPerDay * MINUTES_PER_HOUR
        : MINUTES_PER_DAY,
      isDefault: true,
    }),
  ];
  const addedSections = input.sections;
  const updatedProjects = [
    {
      id: projectId,
      hourlyPlanningEnabled: input.hourlyPlanningEnabled,
      setupStatus: 'ACTIVE',
    },
  ];
  return {
    addedCalendars,
    addedSections,
    updatedProjects,
  };
}

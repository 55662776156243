import { OrderSchedulingEngine } from '@koppla-tech/scheduling-engine';

let engine: OrderSchedulingEngine;

export function installLocalOrderSchedulingEngine(newEngine: OrderSchedulingEngine) {
  engine = newEngine;
}

export function useLocalOrderSchedulingEngine() {
  if (!engine) {
    throw new Error(
      "Engine not installed, please call 'installLocalOrderSchedulingEngine' during setup.",
    );
  }
  return engine;
}

// eslint-disable-next-line no-restricted-imports
import { UTCDate } from '@date-fns/utc';
import { isDate } from 'date-fns';

const getDateForUnpackedNumbers = (args: [Date] | [string] | [number] | number[]) => {
  const {
    0: year,
    1: month,
    2: day,
    3: hours,
    4: minutes,
    5: seconds,
    6: milliseconds,
  } = args.map((arg) => Number(arg));
  return new UTCDate(year, month, day, hours ?? 0, minutes ?? 0, seconds ?? 0, milliseconds ?? 0);
};

/**
 * This class represents a date in a schedule or plan, not bound to an actual timestamp in the real world.
 * It is initialized with the current time if no parameter is provided, which technically yields an incorrect
 * UTC timestamp if called in a timezone != +00
 */
class SchedulingDate extends UTCDate {
  constructor(...args: [Date] | [string] | [number] | number[]) {
    if (args.length <= 1) {
      const chooseCorrectDateClass = (date: Date | string | number) => {
        if (isDate(date) && date.getTimezoneOffset() !== 0) {
          return new Date(date);
        }
        return new UTCDate(date);
      };

      // If we just want a new date object, we assume that we want to create a date with the current timestamp
      // e.g. 15:26+01 should be converted to 15:26+00
      const localDate = args.length === 1 ? chooseCorrectDateClass(args[0]) : new Date();

      const year = localDate.getFullYear();
      const month = localDate.getMonth();
      const day = localDate.getDate();
      const hours = localDate.getHours();
      const minutes = localDate.getMinutes();
      const seconds = localDate.getSeconds();
      const milliseconds = localDate.getMilliseconds();
      super(year, month, day, hours, minutes, seconds, milliseconds);
      return;
    }
    if (args.length < 3) {
      throw new Error('Invalid number of argument. Must at least provide year, month and day');
    }
    const utcDate = getDateForUnpackedNumbers(args);
    super(utcDate);
  }
}
if (typeof global === 'object' && global) {
  (global as any).SchedulingDate = SchedulingDate;
} else if (typeof window === 'object' && window) {
  (window as any).SchedulingDate = SchedulingDate;
}

export {};

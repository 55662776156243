import { TradeSequenceActivityDependencyEntity, TradeSequenceActivityEntity } from '@/common/types';
import { DEFAULT_ACTIVITY_DURATION_IN_DAYS } from '@/features/projectTradeSequences/utils';
import { copy, pickKeys } from '@/helpers/utils/objects';
import { MINUTES_PER_DAY } from '@/helpers/utils/timeConstants';

import {
  NewOrUpdatedTaskTemplate,
  NewOrUpdatedTradeSequenceActivity,
  TradeSequenceVariables,
  UpdatedTradeSequenceEntity,
} from './types';

export function initTradeSequenceData(defaultCalendarId: string) {
  const generalData = ref<TradeSequenceVariables>({
    calendar: {
      id: defaultCalendarId,
    },
    defaultDuration: DEFAULT_ACTIVITY_DURATION_IN_DAYS * MINUTES_PER_DAY,
  });
  const activities = ref<TradeSequenceActivityEntity[]>([]);
  const dependencies = ref<TradeSequenceActivityDependencyEntity[]>([]);
  return { generalData, activities, dependencies };
}

export function convertToLocalFormat(tradeSequence: UpdatedTradeSequenceEntity) {
  const generalData = ref<TradeSequenceVariables>({
    ...pickKeys(tradeSequence, ['id', 'name', 'defaultDuration']),
    ...(tradeSequence.calendar ? { calendar: { id: tradeSequence.calendar.id } } : {}),
  });
  const activities = ref(copy(tradeSequence.activities));
  const dependencies = ref(copy(tradeSequence.dependencies));
  return { generalData, activities, dependencies };
}

export function omitIdsOfNewlyCreatedTasks(
  newActivities: NewOrUpdatedTradeSequenceActivity[],
  existingActivities?: TradeSequenceActivityEntity[],
): NewOrUpdatedTradeSequenceActivity[] {
  const existingActivityMap = new Map(
    existingActivities?.map((activity) => [activity.id, activity]),
  );

  const isNewTask = (
    newTask: NewOrUpdatedTaskTemplate,
    existingTasks?: TradeSequenceActivityEntity['taskTemplates'],
  ) => {
    return !existingTasks?.some((task) => task.id === newTask.id);
  };

  return newActivities.map((activity) => {
    const existingActivity = existingActivityMap.get(activity.id);
    const activityTasks = activity.taskTemplates.map((task) => ({
      ...task,
      id: isNewTask(task, existingActivity?.taskTemplates) ? undefined : task.id,
    }));
    return {
      ...activity,
      taskTemplates: activityTasks,
    };
  });
}

import { Composer } from 'vue-i18n';

import {
  MINUTES_PER_DAY,
  MINUTES_PER_HOUR,
  MINUTES_PER_WEEK,
  MINUTES_PER_YEAR,
} from '@/helpers/utils/timeConstants';

import { BaseActualColor, BaseActualStatus, BaseState } from './types';

export type DiscreteTimeDifference = {
  years: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
};

export function getDiscreteTimeDifference(timeDifferenceInMinutes: number): DiscreteTimeDifference {
  const differenceInMinutes = Math.abs(timeDifferenceInMinutes);

  const fullYears = Math.floor(differenceInMinutes / MINUTES_PER_YEAR);
  const remainingMinutesAfterYears = differenceInMinutes % MINUTES_PER_YEAR;

  const fullWeeks = Math.floor(remainingMinutesAfterYears / MINUTES_PER_WEEK);
  const remainingMinutesAfterWeeks = remainingMinutesAfterYears % MINUTES_PER_WEEK;

  const fullDays = Math.floor(remainingMinutesAfterWeeks / MINUTES_PER_DAY);
  const remainingMinutesAfterDays = remainingMinutesAfterWeeks % MINUTES_PER_DAY;

  const fullHours = Math.floor(remainingMinutesAfterDays / MINUTES_PER_HOUR);
  const remainingMinutesAfterHours = remainingMinutesAfterDays % MINUTES_PER_HOUR;

  const fullMinutes = remainingMinutesAfterHours;

  return {
    years: fullYears,
    weeks: fullWeeks,
    days: fullDays,
    hours: fullHours,
    minutes: fullMinutes,
  };
}

export function parseDiscreteTimeDifferenceToText(
  i18n: Composer,
  difference: DiscreteTimeDifference,
): string {
  const discreteTexts: string[] = [];
  Object.keys(difference).forEach((key) => {
    if (difference[key] === 0) return;
    const text = i18n.t(`time.${key}`, difference[key]);
    discreteTexts.push(text);
  });
  return `${discreteTexts.slice(0, -1).join(', ')}${
    discreteTexts.length > 1 ? ` ${i18n.t('and')} ` : ''
  }${discreteTexts.slice(-1)}`;
}

export function getStateText(
  i18n: Composer,
  entityState: BaseState,
  {
    onTimeText = 'objects.basePlan.finishesOnTime',
    behindText = 'objects.basePlan.behindOnTime',
    aheadText = 'objects.basePlan.aheadOfTime',
  } = {},
): string | undefined {
  if (entityState.status === BaseActualStatus.ON_TIME) {
    return i18n.t(onTimeText);
  }
  if (!entityState.timeDifference) return undefined;
  const discreteDifference = getDiscreteTimeDifference(entityState.timeDifference);
  const differenceText = parseDiscreteTimeDifferenceToText(i18n, discreteDifference);
  if (entityState.status === BaseActualStatus.DELAYED) {
    return i18n.t(behindText, { amount: differenceText });
  }
  if (entityState.status === BaseActualStatus.AHEAD_OF_TIME) {
    return i18n.t(aheadText, { amount: differenceText });
  }
  return undefined;
}

export function getFixedStateText(i18n: Composer, entityState: BaseState): string | undefined {
  return getStateText(i18n, entityState, {
    onTimeText: 'objects.basePlan.finishesOnTimeFixed',
    behindText: 'objects.basePlan.behindOnTimeFixed',
    aheadText: 'objects.basePlan.aheadOfTimeFixed',
  });
}

export function getReachedStateText(i18n: Composer, entityState: BaseState): string | undefined {
  return getStateText(i18n, entityState, {
    onTimeText: 'objects.basePlan.finishesOnTimeReached',
    behindText: 'objects.basePlan.behindOnTimeReached',
    aheadText: 'objects.basePlan.aheadOfTimeReached',
  });
}

export function getBaseActualColor(state: BaseActualStatus) {
  return BaseActualColor[state];
}

export function getBaseActualLegendEntries(i18n: Composer) {
  const { t } = i18n;
  return [
    {
      title: t('objects.basePlan.legendBeforeTime'),
      color: BaseActualColor.AHEAD_OF_TIME,
    },
    {
      title: t('objects.basePlan.legendOnTime'),
      color: BaseActualColor.ON_TIME,
    },
    {
      title: t('objects.basePlan.legendBehindTime'),
      color: BaseActualColor.DELAYED,
    },
    {
      title: t('objects.basePlan.legendUnavailable'),
      color: BaseActualColor.UNAVAILABLE,
    },
  ];
}

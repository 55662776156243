type Props = Record<string, any> | null;
type Tag = string | ((props: any, children: any[]) => Element) | ((props: any) => Element);

export function h(tag: Tag, props: Props, ...children: (HTMLElement | string)[]) {
  /*
   * This is an implementation of a JSX factory function. Documentation about them can be found here: https://www.typescriptlang.org/tsconfig/#jsxFactory
   * An example implementation can be found here: https://github.com/ConnorJamesLow/texsaur
   * When implementing JSX, please keep in mind that this function assumes properly formed JSX.
   * If special requirements are needed, please modify this function accordingly.
   *
   * @param {Tag} tag
   * @param {Props} props
   * @param {HTMLElement[]} children
   * @returns {Element}
   */

  if (typeof tag === 'function') {
    if (tag.length === 1) {
      return tag({ ...props, children }, []);
    }
    return tag({ ...props }, children);
  }

  const svgTags = ['svg', 'path', 'circle', 'rect', 'line', 'polyline', 'polygon', 'text', 'g'];
  const isSvgElement = svgTags.includes(tag);

  let element: Element;
  if (isSvgElement) {
    element = document.createElementNS('http://www.w3.org/2000/svg', tag);
  } else {
    element = document.createElement(tag);
  }

  if (props) {
    Object.entries(props).forEach(([key, value]) => {
      if (key === 'style') {
        if (element instanceof HTMLElement) {
          Object.assign(element.style, value);
        }
      } else if (key === 'class') {
        if (element instanceof HTMLElement) {
          [...value.split(' ')].forEach(
            (c: string) => c.trim() !== '' && element.classList.add(c.trim()),
          );
        }
      } else if (key.startsWith('on') && value instanceof Function) {
        element.removeEventListener(key.slice(2).toLowerCase(), value);
        element.addEventListener(key.slice(2).toLowerCase(), value);
      } else {
        element.setAttribute(key, value);
      }
    });
  }

  children.forEach((child) => {
    if (!child) return;

    if (typeof child === 'string') {
      element.appendChild(document.createTextNode(child));
    } else if (child instanceof Element) {
      element.appendChild(child);
    }
  });

  return element;
}

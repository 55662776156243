import { gql as gqlFn } from '@/graphql/__generated__';

export const calendarsQuery = gqlFn(/* GraphQL */ `
  query calendarsV2($projectId: ID!, $orderBy: [String!]) {
    calendars(project: $projectId, orderBy: $orderBy) {
      edges {
        node {
          ...Calendar
        }
      }
    }
  }
`);

export const calendarIsDeletableQuery = gqlFn(/* GraphQL */ `
  query calendarsIsDeletableV2($projectId: ID!) {
    calendars(project: $projectId) {
      edges {
        node {
          id
          isDeletable
        }
      }
    }
  }
`);

// TODO: Should be enabled once the other fragments got deleted
// export const calendarFragment = gql`
//   fragment Calendar on CalendarNode {
//     id
//     name
//     isDefault
//     workingDays {
//       edges {
//         node {
//           ...WorkingDay
//         }
//       }
//     }
//     minutesPerDay
//     exceptions {
//       edges {
//         node {
//           ...CalendarException
//         }
//       }
//     }
//   }
// `;

import { DependencyType, GenerationArguments, IdMapping } from '@koppla-tech/scheduling-engine';

import { Entity, TradeSequenceEntity } from '@/common/types';
import { omitKeys } from '@/helpers/utils/objects';
import { OrderTaskTemplate } from '@/interfaces/repositories';

import { OperationInputType } from '../realTimeCollaboration/types';
import { TradeSequenceOrderAssignments } from './types';

export function convertProjectTradeSequenceCreateOperationInput(
  input: OperationInputType<'CreateTradeSequence'>,
): {
  addedTradeSequences: TradeSequenceEntity[];
  idMapping: IdMapping | null;
  orderAssignments: TradeSequenceOrderAssignments | null;
} {
  const addedTradeSequences = [
    {
      id: input.id,
      name: input.name,
      defaultDuration: input.defaultDuration,
      calendar: { id: input.calendarId },
      activities: input.activities.map((activity) => ({
        ...activity,
        tenantTradeVariation: { id: activity.tradeId },
        taskTemplates: (activity.taskTemplates?.filter((template) => template.id) ??
          []) as OrderTaskTemplate[],
      })),
      dependencies: input.dependencies.map((dependency) => ({
        ...dependency,
        type: dependency.type as DependencyType,
        from: { id: dependency.fromActivityId },
        to: { id: dependency.toActivityId },
      })),
    },
  ];

  const idMapping =
    input.idMapping && input.orderAssignments
      ? {
          tradeSequenceInstanceId: input.orderAssignments.tradeSequenceInstanceId,
          activityIdToOrderId: input.idMapping.activityToOrderId,
          orderIdsToOrderDependencyId: input.idMapping.dependencies,
        }
      : null;

  const orderAssignments = input.orderAssignments ?? null;

  return {
    addedTradeSequences,
    idMapping,
    orderAssignments,
  };
}

export function convertProjectTradeSequenceDeleteOperationInput(
  input: OperationInputType<'RemoveScheduleNodes'>,
): Entity[] {
  return input.tradeSequences?.map((id) => ({ id })) ?? [];
}

export function convertProjectTradeSequenceUpdateOperationInput(
  input: OperationInputType<'UpdateTradeSequence'>,
): { updatedTradeSequences: TradeSequenceEntity[]; idMappings: IdMapping[] } {
  const updatedTradeSequences = [
    {
      ...omitKeys(input, ['idMappings']),
      calendar: { id: input.calendarId },
      activities: input.activities.map((activity) => ({
        ...omitKeys(activity, ['tradeId']),
        tenantTradeVariation: { id: activity.tradeId },
        taskTemplates: (activity.taskTemplates?.filter((template) => template.id) ??
          []) as OrderTaskTemplate[],
      })),
      dependencies: input.dependencies.map((dependency) => ({
        ...omitKeys(dependency, ['fromActivityId', 'toActivityId']),
        type: dependency.type as DependencyType,
        from: { id: dependency.fromActivityId },
        to: { id: dependency.toActivityId },
      })),
    },
  ];
  const idMappings = input.idMappings.map((idMapping) => ({
    tradeSequenceInstanceId: idMapping.tradeSequenceInstanceId,
    activityIdToOrderId: idMapping.activityToOrderId,
    orderIdsToOrderDependencyId: idMapping.dependencies,
  }));

  return {
    updatedTradeSequences,
    idMappings,
  };
}

export function convertProjectTradeSequenceInsertOperationInput(
  input: OperationInputType<'InsertTradeSequence'>,
): GenerationArguments {
  return {
    date: new SchedulingDate(input.startAt),
    sectionId: input.wbsSectionId,
    tradeSequenceInstanceId: input.instanceId,
    idMapping: {
      tradeSequenceInstanceId: input.instanceId,
      activityIdToOrderId: input.idMapping.activityToOrderId,
      orderIdsToOrderDependencyId: input.idMapping.dependencies,
    },
  };
}

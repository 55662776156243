import { RTC_CONTROLLER_POLLING_INTERVAL } from '@/features/realTimeCollaboration/const';

export class RTCPollingHandler {
  protected pollingListeners: (() => void)[] = [];

  protected pollingInterval: number | null = null;

  public registerPollingListener(listener: () => void): void {
    this.pollingListeners.push(listener);
  }

  public unregisterPollingListener(listener: () => void): void {
    const index = this.pollingListeners.indexOf(listener);
    if (index !== -1) {
      this.pollingListeners.splice(index, 1);
    }
  }

  public async initialize(): Promise<void> {
    this.pollingInterval = window.setInterval(() => {
      this.pollingListeners.forEach((listener) => listener());
    }, RTC_CONTROLLER_POLLING_INTERVAL);
  }

  public reset(): void {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }
}

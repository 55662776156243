import { AttachmentFragment } from '@/graphql/__generated__/graphql';

export interface LocalAttachment {
  file: File;
  objectUrl: string;
  attachmentId: string;
  createdAt: Date;
}

/**
 * This class holds attachments that need to be added to results of backend queries
 * that handle attachments. The backend does not guarantee read/write consistency
 * for v2 attachments (> 10MB) so we need to keep track of them manually.
 */
export class AttachmentCache {
  // Key = attachmentId
  private static attachments: Map<string, AttachmentFragment> = new Map();

  // Key = entityId (ticket, order), value = attachment ids
  private static assignments: Map<string, string[]> = new Map();

  private constructor() {}

  public static getAttachments(entityId: string): AttachmentFragment[] {
    const attachmentIds = this.assignments.get(entityId) ?? [];

    return attachmentIds
      .map((id) => this.attachments.get(id))
      .filter((v) => v)
      .map((v) => v!);
  }

  public static addAttachment(attachmentId: string, attachment: AttachmentFragment): void {
    this.attachments.set(attachmentId, attachment);
  }

  public static createAssignment({
    entityId,
    attachmentId,
  }: {
    entityId: string;
    attachmentId: string;
  }): void {
    if (!this.assignments.has(entityId)) {
      this.assignments.set(entityId, []);
    }

    this.assignments.get(entityId)!.push(attachmentId);
  }

  public static removeAttachment({
    entityId,
    attachmentId,
  }: {
    entityId?: string;
    attachmentId: string;
  }): void {
    const deleted = this.attachments.delete(attachmentId);

    if (!deleted || !entityId || !this.assignments.has(entityId)) return;

    const newAttachments = this.assignments
      .get(entityId)!
      .filter((existingAttachmentId) => existingAttachmentId !== attachmentId);
    if (!newAttachments.length) {
      this.assignments.delete(entityId);
      return;
    }

    this.assignments[entityId] = newAttachments;
  }
}

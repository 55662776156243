import { RouteLocationRaw, RouteRecordRaw } from 'vue-router';

import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { GuardMeta, useWithMembership } from '@/router/helpers/memberships';
import { getLoginRoute, getPickTenantRoute } from '@/router/helpers/redirection';

import { useRouteNames } from '../helpers/routeNames';

export function useUserRoutes(guardMeta: GuardMeta): RouteRecordRaw[] {
  return routes(guardMeta);
}

const { Routes } = useRouteNames();

const routes = (guardMeta: GuardMeta): RouteRecordRaw[] => [
  {
    path: 'noMemberships',
    name: Routes.User.NoMemberships,
    component: () => import('@/views/NoMemberships.vue'),
    beforeEnter: (to): Promise<boolean | RouteLocationRaw> => {
      const guard = (
        membership: MembershipWithTenant | undefined | null,
        memberships: MembershipWithTenant[],
      ) => {
        /** redirect, if memberships exist */
        if (membership) {
          return getLoginRoute();
        }
        if (memberships.length) {
          return getPickTenantRoute();
        }
        return true;
      };

      return useWithMembership({ to }, guardMeta, guard);
    },
    meta: {
      group: 'Users',
    },
  },
];

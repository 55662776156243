import { gql as gqlFn } from '@/graphql/__generated__';

export const allTicketsQuery = gqlFn(/* GraphQL */ `
  query FetchAllTickets($project: ID!) {
    tickets(project: $project) {
      edges {
        node {
          ...TitoTicket
        }
      }
    }
  }
`);

export const singleTicketQuery = gqlFn(/* GraphQL */ `
  query FetchSingleTicket($id: ID!) {
    ticket(id: $id) {
      ...TitoTicket
    }
  }
`);

export const createTicketStatusMutation = gqlFn(/* GraphQL */ `
  mutation CreateTicketStatus($input: CreateTicketStatusUpdateInput!) {
    createTicketStatusUpdate(input: $input) {
      ticketStatusUpdate {
        ticket {
          ...TitoTicket
        }
      }
    }
  }
`);

// TODO TITO: Rename to CreateTicket when old ticket stuff is removed
export const createTicketMutation = gqlFn(/* GraphQL */ `
  mutation CreateTitoTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      ticket {
        ...TitoTicket
      }
    }
  }
`);

// TODO TITO: Rename to UpdateTicket when old ticket stuff is removed
export const updateTicketMutation = gqlFn(/* GraphQL */ `
  mutation UpdateTitoTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input) {
      ticket {
        ...TitoTicket
      }
    }
  }
`);

// TODO TITO: Rename to DeleteTicket when old ticket stuff is removed
export const deleteTicketMutation = gqlFn(/* GraphQL */ `
  mutation DeleteTitoTicket($input: DeleteTicketInput!) {
    deleteTicket(input: $input) {
      success
    }
  }
`);

// TODO TITO: Rename to ExportTickets when old ticket stuff is removed
export const exportTicketsMutation = gqlFn(/* GraphQL */ `
  mutation ExportTitoTickets($input: CreateTicketExportInput!) {
    createTicketExport(input: $input) {
      ticketExport {
        file {
          url
          downloadUrl
        }
      }
    }
  }
`);

// TODO TITO: Rename to CreateTicketAttachment when old ticket stuff is removed
export const createTicketAttachmentMutation = gqlFn(/* GraphQL */ `
  mutation CreateTitoTicketAttachment($input: CreateTicketAttachmentInput!) {
    createTicketAttachment(input: $input) {
      success
    }
  }
`);

// TODO TITO: Rename to DeleteTicketAttachment when old ticket stuff is removed
export const deleteTicketAttachmentMutation = gqlFn(/* GraphQL */ `
  mutation DeleteTitoTicketAttachment($input: DeleteTicketAttachmentInput!) {
    deleteTicketAttachment(input: $input) {
      success
    }
  }
`);

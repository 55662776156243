import { Entity, PartialEntity, PauseEntity } from '@/common/types';
import { OperationInputType } from '@/features/realTimeCollaboration/types';

export function convertPauseUpdateOperationInput(
  input: OperationInputType<'UpdatePauses'>,
): PartialEntity<PauseEntity>[] {
  return input.map((p) => ({
    id: p.id,
    ...(p.name !== undefined ? { name: p.name ?? '' } : {}),
    ...(p.start !== undefined ? { start: new SchedulingDate(p.start!) } : {}),
    ...(p.end !== undefined ? { end: new SchedulingDate(p.end!) } : {}),
  }));
}

export function convertPauseCreateOperationInput(
  input: OperationInputType<'CreatePauses'>,
): PauseEntity[] {
  return input.map((p) => ({
    ...p,
    start: new SchedulingDate(p.start),
    end: new SchedulingDate(p.end),
  }));
}

export function convertPauseDeleteOperationInput(
  input: OperationInputType<'RemoveScheduleNodes'>,
): Entity[] {
  return input.pauses?.map((id) => ({ id })) ?? [];
}

<template>
  <div class="tw-m-6">
    <header
      class="tw-flex tw-flex-col tw-justify-between tw-items-center"
      :class="{
        'tw-mb-3': showDivider,
      }"
    >
      <div class="tw-flex tw-justify-between tw-w-full" :class="{ 'tw-h-16': hasFixedHeight }">
        <div class="tw-flex tw-items-center tw-justify-start tw-min-w-[33%]">
          <div v-if="slots.picture" class="tw-mr-5">
            <slot name="picture" />
          </div>
          <div>
            <div class="tw-flex tw-items-center tw-gap-3">
              <h1
                class="tw-ds-display-xs--medium tw-text-grey-900 text-wrap"
                :class="USERBACK_IGNORE_CLASS"
              >
                {{ title || (i18nPageKey ? $t(`${i18nPageKey}.title`) : '') }}
              </h1>
              <slot name="infos" />
              <DsChip v-if="badgeText" color="primary">{{ badgeText }}</DsChip>
            </div>
            <p
              v-if="subtitle || $slots.subtitle"
              class="tw-ds-text-sm--regular tw-text-grey-600 tw-mt-1"
            >
              <slot name="subtitle">{{ subtitle }}</slot>
            </p>
          </div>
        </div>
        <slot name="center" />
        <div class="tw-flex tw-items-center tw-justify-end tw-min-w-[33%] tw-gap-3">
          <slot name="actions" />
        </div>
      </div>
    </header>
    <DsDivider v-if="showDivider" divider-color="tw-bg-grey-100" />
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import { RouteLocationNormalized, useRoute } from 'vue-router';

import { USERBACK_IGNORE_CLASS } from '@/plugins/userback';

defineProps({
  title: { type: String as PropType<string | null>, default: null },
  subtitle: { type: String as PropType<string | null>, default: null },
  badgeText: { type: String as PropType<string | null>, default: null },
  showDivider: { type: Boolean, default: false },
  hasFixedHeight: { type: Boolean, default: false },
});

const i18nKeyForRoute = (route: RouteLocationNormalized): string => {
  const fullPath: string[] = [];

  // add group
  const group = route.meta?.group;
  if (group) fullPath.push(group as string);

  // add path
  const path = route.meta?.path;
  if (path) fullPath.concat(path as string);

  fullPath.push(route.name as string);
  return fullPath.join('.');
};

const route = useRoute();
const slots = useSlots();
const i18nPageKey = computed(() => i18nKeyForRoute(route));
</script>

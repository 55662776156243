import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const fetchAllProjectsQuery = gqlFn(/* GraphQL */ `
  query Projects($tenant: ID!, $orderBy: [String!], $hasProjectMembership: Boolean) {
    projects(tenant: $tenant, orderBy: $orderBy, hasProjectMembership: $hasProjectMembership) {
      edges {
        node {
          ...Project
        }
      }
    }
  }
`);

export const fetchProjectsForUserQuery = gqlFn(/* GraphQL */ `
  query ProjectsForUser($orderBy: [String!]) {
    userProjects(orderBy: $orderBy) {
      edges {
        node {
          ...Project
        }
      }
    }
  }
`);

export const fetchProjectQuery = gqlFn(/* GraphQL */ `
  query Project($id: ID!) {
    project(id: $id) {
      ...Project
    }
  }
`);

export const createProjectMutation = gqlFn(/* GraphQL */ `
  mutation CreateProject(
    $tenant: ID!
    $internalId: String
    $name: String!
    $street: String
    $street2: String
    $postalCode: String
    $city: String
    $country: String
    $image: Upload
    $timeZone: String
    $lifecycleStatus: String
  ) {
    createProject(
      input: {
        tenant: $tenant
        internalId: $internalId
        name: $name
        address: {
          street: $street
          street2: $street2
          postalCode: $postalCode
          city: $city
          country: $country
        }
        image: $image
        timeZone: $timeZone
        lifecycleStatus: $lifecycleStatus
      }
    ) {
      project {
        ...Project
      }
    }
  }
`);

export const updateProjectMutation = gqlFn(/* GraphQL */ `
  mutation UpdateProject(
    $id: ID!
    $internalId: String
    $name: String!
    $street: String
    $street2: String
    $postalCode: String
    $city: String
    $country: String
    $image: Upload
    $status: String
    $hourlyPlanningEnabled: Boolean
  ) {
    updateProject(
      input: {
        id: $id
        internalId: $internalId
        name: $name
        address: {
          street: $street
          street2: $street2
          postalCode: $postalCode
          city: $city
          country: $country
        }
        image: $image
        status: $status
        hourlyPlanningEnabled: $hourlyPlanningEnabled
      }
    ) {
      project {
        ...Project
      }
    }
  }
`);

export const fetchProgressQuery = gqlFn(/* GraphQL */ `
  query GetProjectProgress($id: ID!, $contributorGroup: ID) {
    project(id: $id) {
      progress(contributorGroup: $contributorGroup)
    }
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProjectFragment = gql`
  fragment Project on ProjectNode {
    id
    name
    internalId
    address {
      ...Address
    }
    imageUrl
    tenant {
      ...Tenant
    }
    completed
    hourlyPlanningEnabled
    lastStatusChange {
      changedBy {
        email
        firstName
        lastName
      }
      changedAt
      previousStatus
    }
    lifecycleStatus
    createdAt
    updatedAt
    setupStatus
  }
`;

import { OwnUserQuery, OwnUserQueryVariables } from '@/graphql/__generated__/graphql';
import OWN_USER from '@/graphql/users/OwnUser.gql';
import { useApolloClient } from '@/plugins/apollo';

export class UserStoreRepository {
  public async fetchOwn() {
    const client = useApolloClient();

    return client
      .query<OwnUserQuery, OwnUserQueryVariables>({
        query: OWN_USER,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        if (!result.data.ownUser) return null;
        return result.data.ownUser;
      });
  }
}

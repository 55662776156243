import {
  Order,
  OrderSchedulingEngine,
  ReducedOrderScheduleState,
  ScheduleChanges,
} from '@koppla-tech/scheduling-engine';

import {
  Entity,
  InteractiveEntities,
  InteractiveEntityChanges,
  MilestoneEntity,
  OrderDependencyEntity,
  OrderEntity,
  PartialEntity,
} from '@/common/types';
import StatusReport from '@/features/orderHistory/components/events/StatusReport.vue';
import { mergeArrays } from '@/helpers/utils/arrays';
import { pickKeys } from '@/helpers/utils/objects';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';

import { ProjectChangeEventContext } from '../../types';

export function mapInteractiveChangesToSENG(
  changes: InteractiveEntityChanges,
): ScheduleChanges<ReducedOrderScheduleState> {
  // order status typing is wrong
  return changes as unknown as ScheduleChanges<ReducedOrderScheduleState>;
}

/**
 * Filters non-scheduling related changes from the InteractiveEntityChanges object.
 * Merges additional context into the changes.
 * @param changes
 * @param context
 * @returns
 */
export function sanitizeSchedulingChanges(
  changes: InteractiveEntityChanges,
  context?: ProjectChangeEventContext,
): InteractiveEntityChanges {
  const schedulingRelatedKeys: (keyof InteractiveEntities)[] = [
    'pauses',
    'orders',
    'milestones',
    'calendars',
    'dependencies',
  ];
  return {
    ...(changes.add
      ? {
          add: pickKeys(changes.add, schedulingRelatedKeys),
        }
      : {}),
    update: pickKeys(mergeEventContext(changes.update, context)!, schedulingRelatedKeys),
    ...(changes.delete
      ? {
          delete: pickKeys(changes.delete, schedulingRelatedKeys),
        }
      : {}),
  };
}

function mergeEventContext(
  changes: InteractiveEntityChanges['update'],
  context?: ProjectChangeEventContext,
): InteractiveEntityChanges['update'] {
  const contextOrders = (context?.orders ?? []).map((order) => ({
    ...order,
    startAt: order.startAt ? new SchedulingDate(order.startAt) : undefined,
    finishAt: order.finishAt ? new SchedulingDate(order.finishAt) : undefined,
  }));
  const contextMilestones = (context?.milestones ?? []).map((milestone) => ({
    ...milestone,
    date: milestone.date ? new SchedulingDate(milestone.date) : undefined,
  }));
  return {
    ...changes,
    orders: mergeArrays(changes?.orders ?? [], contextOrders as PartialEntity<OrderEntity>[]),
    milestones: mergeArrays(
      changes?.milestones ?? [],
      contextMilestones as PartialEntity<MilestoneEntity>[],
    ),
    dependencies: mergeArrays(
      changes?.dependencies ?? [],
      (context?.dependencies ?? []) as PartialEntity<OrderDependencyEntity>[],
    ),
  };
}

/**
 * This function takes two sets of changes and returns a new set of changes that is the union of the two, where the changes from the second set take precedence over the changes from the first set.
 * @param changesA
 * @param changesB
 */
export function unionizeChanges(
  changesA: InteractiveEntityChanges,
  changesB: InteractiveEntityChanges,
): InteractiveEntityChanges {
  const unionizedChanges: InteractiveEntityChanges = { ...changesA };

  for (const type in changesB) {
    if (!unionizedChanges[type]) {
      unionizedChanges[type] = changesB[type];
    } else {
      const entities = changesB[type];
      for (const entity in entities) {
        if (!unionizedChanges[type][entity]) {
          unionizedChanges[type][entity] = entities[entity];
        } else {
          unionizedChanges[type][entity] = mergeEntityChanges(
            unionizedChanges[type][entity],
            entities[entity],
          );
        }
      }
    }
  }

  return unionizedChanges;
}

function mergeEntityChanges(entitiesA: Entity[], entitiesB: Entity[]): Entity[] {
  const merged: Entity[] = entitiesA.slice();
  const entitiesAMap = new Map(entitiesA.map((entity) => [entity.id, entity]));
  const entitiesAIndicesMap = new Map(entitiesA.map((entity, index) => [entity.id, index]));

  entitiesB.forEach((entityB) => {
    const entityA = entitiesAMap.get(entityB.id);
    const entityAIndex = entitiesAIndicesMap.get(entityB.id);
    if (entityA && entityAIndex !== undefined) {
      merged[entityAIndex] = {
        ...entityA,
        ...entityB,
      };
      return;
    }
    merged.push(entityB);
  });

  return merged;
}

const enhanceOrderWithMissingAttributes = (order: Order, id: string): OrderEntity => {
  return {
    ...order,
    id,
    finishedAt: null,
    progress: 0,
    status: StatusReport.NOT_SET,
  };
};

/**
 * When generating new orders and dependencies using the generateDerivedOrdersFromTradeSequence and syncDerivedOrdersWithTradeSequence functions,
 * the ids returned for these orders and dependencies are raw UUIDs. However, for subsequent operations, we need to encode them as graphql ids.
 */
export function encodeIdsOfNewOrdersAndDependencies(
  engine: OrderSchedulingEngine,
  changes: InteractiveEntityChanges,
  stateless: boolean,
): InteractiveEntityChanges {
  const encodedChanges: InteractiveEntityChanges = { ...changes };
  const newOrderIds = new Set<string>(encodedChanges.add?.orders?.map((o) => o.id) ?? []);
  const newDependencyIds = new Set<string>(
    encodedChanges.add?.dependencies?.map((d) => d.id) ?? [],
  );

  if (encodedChanges.add?.orders) {
    const encodedIds = Array.from(newOrderIds).map((id) => toGlobalId(NodeName.ORDER, id));
    encodedChanges.add.orders = encodedChanges.add.orders.map((o, idx) =>
      enhanceOrderWithMissingAttributes(o as unknown as Order, encodedIds[idx]),
    );
    if (!stateless) {
      engine.amend(
        {
          orders: Array.from(newOrderIds),
        },
        {
          orders: encodedIds.map((id) => ({ id })),
        },
      );
    }
  }

  if (encodedChanges.add?.dependencies) {
    const encodedIds = encodedChanges.add.dependencies.map((d) => ({
      id: toGlobalId(NodeName.ORDER_DEPENDENCY, d.id),
      from: newOrderIds.has(d.from.id) ? toGlobalId(NodeName.ORDER, d.from.id) : d.from.id,
      to: newOrderIds.has(d.to.id) ? toGlobalId(NodeName.ORDER, d.to.id) : d.to.id,
    }));
    encodedChanges.add.dependencies = encodedChanges.add.dependencies.map((o, idx) => ({
      ...o,
      id: encodedIds[idx].id,
      from: {
        id: encodedIds[idx].from,
      },
      to: {
        id: encodedIds[idx].to,
      },
    }));
    if (!stateless) {
      engine.amend(
        {
          dependencies: Array.from(newDependencyIds),
        },
        {
          dependencies: encodedIds.map((encoded) => ({
            id: encoded.id,
            from: {
              id: encoded.from,
            },
            to: {
              id: encoded.to,
            },
          })),
        },
      );
    }
  }
  return encodedChanges;
}

export function amendOrdersInEngine(
  engine: OrderSchedulingEngine,
  filterEngineOrders: (order: Order) => boolean,
  amendEngineOrders: (order: Order) => PartialEntity<Order>,
): void {
  const filteredOrders = engine.getState().orders.filter(filterEngineOrders);
  engine.amend(
    {
      orders: filteredOrders.map((o) => o.id),
    },
    {
      orders: filteredOrders.map(amendEngineOrders),
    },
  );
}

import { ApolloClient, ApolloLink, InMemoryCache, Observable } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { uncrunch } from 'graphql-crunch';
import { v4 as uuidv4 } from 'uuid';

import { AppApolloClient } from '@/interfaces/graphql';
import { AuthenticationService } from '@/interfaces/services';
import {
  APP_VERSION,
  ENABLE_SERVER_TIMING,
  GRAPHQL_API_ENDPOINT,
  SERVER_TIMING_KEY,
} from '@/utils/config';
import { EventBus } from '@/utils/eventBus';
import { useTimer } from '@/utils/performance';

export class ApolloClientFactory {
  private client: AppApolloClient;

  // Cache implementation
  constructor(
    authenticationService: AuthenticationService,
    eventBus: EventBus,
    baseLink: ApolloLink,
  ) {
    const link = ApolloLink.from([
      baseLink,
      this.authLink(authenticationService),
      this.crunchHeaderLink(),
      this.serverTimingHeaderLink(),
      this.timeStartLink(),
      this.uncrunchLink(),
      this.httpLink(),
    ]);

    // Create the apollo client
    this.client = new ApolloClient({
      link,
      cache,
      name: 'web',
      version: APP_VERSION,
    });

    // TODO: This needs to go somehwere
    if (eventBus) {
      eventBus.on('logout', () => {
        this.client.clearStore();
      });
    }
  }

  public getClient(): AppApolloClient {
    return this.client;
  }

  private crunchHeaderLink() {
    return setContext((_, { headers }) => {
      const newHeaders = {
        ...headers,
        'X-koppla-graphql-crunch-version': 2,
      };

      return {
        headers: newHeaders,
      };
    });
  }

  private serverTimingHeaderLink() {
    return setContext((_, { headers }) => {
      if (!ENABLE_SERVER_TIMING) return headers;
      const newHeaders = {
        ...headers,
        'X-koppla-timing': SERVER_TIMING_KEY,
        // Can be replaced with Sentry transaction ID for distributed tracing
        'X-koppla-request': uuidv4(),
      };

      return {
        headers: newHeaders,
      };
    });
  }

  private timeStartLink() {
    return new ApolloLink((operation, forward) => {
      operation.setContext({ start: new Date() });
      return forward(operation);
    });
  }

  private uncrunchLink() {
    return new ApolloLink((operation, forward) =>
      forward(operation).map((response) => {
        const context = operation.getContext();

        if (response.data) {
          if (response.data.crunched) {
            useTimer({
              name: `uncrunch ${operation.operationName}`,
              callback: () => {
                response.data = uncrunch(response.data);
              },
            });
          }

          if (context.start) {
            useTimer({
              name: `${operation.operationName} query`,
              start: context.start,
            });
          }
        }

        return response;
      }),
    );
  }

  private authLink(authenticationService?: AuthenticationService): ApolloLink {
    return setContext(async (_, { headers }) => {
      const accessToken = await authenticationService?.getIdToken();
      const newHeaders = {
        ...headers,
      };
      if (accessToken) {
        newHeaders['authorization'] = `JWT ${accessToken}`;
      }

      return {
        headers: newHeaders,
      };
    });
  }

  private httpLink(): ApolloLink {
    const opts = { uri: GRAPHQL_API_ENDPOINT };
    // type error in createUploadLink (outdated apollo package)
    return createUploadLink(opts) as unknown as ApolloLink;
  }
}

export class ApolloClientTestFactory {
  private client: AppApolloClient;

  // Cache implementation
  constructor() {
    const link = new ApolloLink(() => new Observable(() => () => {}));

    // Create the apollo client
    this.client = new ApolloClient({
      link,
      cache,
    });
  }

  public getClient(): AppApolloClient {
    return this.client;
  }
}

const cache = new InMemoryCache({
  typePolicies: {
    // merge existing and incoming objects on connections
    // see: https://www.apollographql.com/docs/react/caching/cache-field-behavior/
    OrderTaskTemplateNodeConnection: {
      merge: true,
    },
    LeanProjectPauseNodeConnection: {
      merge: true,
    },
    OrderPhotoNodeConnection: {
      merge: true,
    },
    OrderNodeConnection: {
      merge: true,
    },
    ProjectMembershipNodeConnection: {
      merge: true,
    },
    Query: {
      fields: {
        tickets: relayStylePagination(),
        projectOrders: relayStylePagination([
          '@connection',
          ['key'],
          'project',
          'inPeriodFrom',
          'inPeriodTo',
        ]),
      },
    },
  },
  possibleTypes: {
    // relationships of union types need to be defined manually
    // see https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
    AttachmentNode: ['DocumentAttachmentNode', 'PhotoAttachmentNode'],
    TicketChangeNode: [
      'TicketValueChangeNode',
      'TicketAttachmentChangeNode',
      'TicketStatusUpdateNode',
    ],
    OrderHistoryUnion: [
      'OrderHistoryChangeEvent',
      'OrderStatusReportHistoryEvent',
      'OrderTaskHistoryEvent',
      'OrderPhotoHistoryEvent',
    ],
  },
});

export const createShadowRoot = (): { shadowRoot: HTMLElement; unmountShadowRoot: () => void } => {
  const shadowRoot = document.createElement('div');
  shadowRoot.classList.add('tw-fixed', 'tw-top-0', 'tw-left-0', 'tw-invisible');
  document.body.appendChild(shadowRoot);
  return {
    shadowRoot,
    unmountShadowRoot: () => {
      document.body.removeChild(shadowRoot);
    },
  };
};

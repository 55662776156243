import { App, inject } from 'vue';

import { debounce, wrap } from '@/helpers/utils/functions';
import { USERBACK_KEY } from '@/utils/keys';
import { getBrowserLanguage, Language } from '@/utils/languages';

export const userbackKey = Symbol('userback');

/**
 * Type of userback plugin.
 * See: https://support.userback.io/en/articles/5209252-javascript-api
 */
export interface Userback {
  identify: (id: string, data: Dictionary) => void;
  recreate: (language?: Language) => void;
  create: () => void;
  destroy: () => void;
}

function install(language: Language): void {
  const script = document.createElement('script');
  script.async = true;
  script.id = 'userback';
  script.src = 'https://static.userback.io/widget/v1.js';
  window.Userback = window.Userback || {};
  window.Userback.widget_settings = {
    language,
  };
  window.Userback.access_token = USERBACK_KEY;
  document.head.appendChild(script);
}

export function installUserback(app: App): Userback {
  install(getBrowserLanguage());

  let isLoading = true;
  let waiting: (() => void)[] = [];

  // calls functions that were called before loading of plugin finished
  function onLoadHandler(): void {
    isLoading = false;
    waiting.forEach((fn) => {
      fn();
    });
    waiting = [];
  }

  // delay function if plugin wasn't loaded yet
  function delay(fn: () => void): void {
    if (isLoading) {
      waiting.push(fn);
      return;
    }
    fn();
  }

  window.Userback.on_load = onLoadHandler;

  // Wrap the identify function to debounce it but using params of latest call
  // The reason for wrapping is that the function uses debounce which doesn't accept any params.
  // Therefore, wrap is used that stores the params separately, such that they can be
  // updated externally, while calling the function itself without any params
  const identify = wrap(
    {
      id: '',
      data: {} as Dictionary,
    },
    debounce(
      () =>
        delay(() => {
          if (!identify.params) return;
          window.Userback.identify(identify.params.id, identify.params.data);
        }),
      50,
    ),
  );

  // Wrap the recreate function to debounce it but using params of latest call
  // The reason for wrapping is that the function uses debounce which doesn't accept any params.
  // Therefore, wrap is used that stores the params separately, such that they can be
  // updated externally, while calling the function itself without any params
  const recreate = wrap(
    {
      language: getBrowserLanguage(),
    },
    debounce(
      () =>
        delay(() => {
          if (!recreate.params) return;
          isLoading = true;
          if (window.Userback) window.Userback.destroy();
          install(recreate.params.language);
          window.Userback.on_load = onLoadHandler;
        }),
      50,
    ),
  );

  const userback: Userback = {
    identify: (id, data): void => {
      identify.params = { id, data };
      identify.fn();
    },
    recreate: (language?: Language): void => {
      if (language) {
        recreate.params = { language };
      }
      recreate.fn();
    },
    create: (): void => {
      install(recreate.params.language);
    },
    destroy: (): void => {
      if (window.Userback) window.Userback.destroy();
    },
  };

  app.provide(userbackKey, userback);
  return userback;
}

export function useUserback(): Userback {
  return inject(userbackKey) as Userback;
}

export const USERBACK_IGNORE_CLASS = 'userback-ignore';

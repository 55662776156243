import { OrderSchedulingEngine } from '@koppla-tech/scheduling-engine';

import {
  InteractiveEntityChanges,
  InteractiveEntityMaps,
  InteractiveEntityStores,
} from '@/common/types';
import { showActionFailedNotification } from '@/features/realTimeCollaboration';
import { RTC_OUT_OF_ORDER_EVENT_TIMEOUT } from '@/features/realTimeCollaboration/const';
import { UndoRedoQueue } from '@/features/undoRedo/queue';
import { LoggingService } from '@/interfaces/services';
import { ConsoleLoggingService } from '@/services';
import { IS_PROD_ENV } from '@/utils/config';

import { RTCConflictDetection } from '../../rtcConflictDetection';
import {
  ExternalProjectDataChange,
  LocalProjectChangeEvent,
  RemoteProjectChangeEvent,
} from '../../types';
import { getChangesFromEvent } from './changes';
import {
  mapInteractiveChangesToSENG,
  sanitizeSchedulingChanges,
  unionizeChanges,
} from './sanitize';
import { copyStateMaps, setStoreStates, updateStateMaps } from './states';

export class RTCEventHandler {
  /**
   * Holds all remote events that arrive while the controller hasn't been fully initialized yet, which
   * will be processed once the controller is initialized.
   */
  public pendingRemoteProjectChangeEvents: {
    isRemoteEvent: boolean;
    payload: RemoteProjectChangeEvent | ExternalProjectDataChange;
  }[] = [];

  /**
   * Holds all local events that have been sent to the server, but haven't been validated by the server yet.
   */
  public localProjectChangeEvents: LocalProjectChangeEvent[] = [];

  /**
   * Holds all local events that have been validated by the server, but that have arrived out of order.
   * These events will be replayed once the server has validated the previous events.
   */
  public outOfOrderLocalProjectChangeEvents: {
    predecessorIds: string[];
    event: RemoteProjectChangeEvent;
  }[] = [];

  private outOfOrderEventTimeoutId: number | undefined;

  public constructor(
    private undoRedoQueue: () => UndoRedoQueue,
    private localOrderSchedulingEngine: () => OrderSchedulingEngine,
    private entityStores: InteractiveEntityStores,
    private loggingService: LoggingService = new ConsoleLoggingService(),
    private messageIdToCommitIdDictionary: Record<string, string>,
  ) {}

  /**
   * Due to network latency, events might arrive out of order at the client,
   * which can lead to conflicts when replaying them. To avoid this, we try to replay
   * out of order events in the correct order by making sure all predecessor events have been processed.
   */
  public replayOutOfOrderEvents(onReplay: (event: RemoteProjectChangeEvent) => void): void {
    if (!this.outOfOrderLocalProjectChangeEvents.length) {
      if (this.outOfOrderEventTimeoutId) {
        clearTimeout(this.outOfOrderEventTimeoutId);
        this.outOfOrderEventTimeoutId = undefined;
      }
      return;
    }
    const localEventIds = new Set(this.localProjectChangeEvents.map((event) => event.messageId));

    // Since local events are added sequentially, only one event can be processed at a time, which
    // is the one that has no unresolved predecessors. Hence, we sort for the event with the least
    // unresolved predecessors.
    const sortedOutOfOrderEvents = this.outOfOrderLocalProjectChangeEvents
      .map((event) => ({
        event: event.event,
        unresolvedPredecessors: event.predecessorIds.filter((id) => localEventIds.has(id)),
      }))
      .sort((a, b) => {
        return a.unresolvedPredecessors.length - b.unresolvedPredecessors.length;
      });

    const outOfOrderEvent = sortedOutOfOrderEvents[0];
    const predecessorEventsResolved = !outOfOrderEvent.unresolvedPredecessors.length;

    if (!IS_PROD_ENV) {
      // eslint-disable-next-line no-console
      console.log('Try to replay out of order event', outOfOrderEvent, {
        allResolved: predecessorEventsResolved,
      });
    }

    if (predecessorEventsResolved) {
      this.outOfOrderLocalProjectChangeEvents = this.outOfOrderLocalProjectChangeEvents.filter(
        (event) => event.event.messageId !== outOfOrderEvent.event.messageId,
      );
      onReplay(outOfOrderEvent.event);
    }
  }

  public retrieveLocalEventFromQueue(
    event: RemoteProjectChangeEvent,
    onTimeout: () => void,
  ): LocalProjectChangeEvent | undefined {
    const localEventIdx = this.localProjectChangeEvents.findIndex((localEvent) => {
      return localEvent.messageId === event.messageId;
    });
    if (localEventIdx === -1) {
      return undefined;
    }
    /**
     * If events arrive out of order, we push them to a separate queue and wait for the missing events to arrive.
     * To not end up in a deadlock, where we wait for an event that will never arrive, we set a timeout.
     */
    if (localEventIdx > 0) {
      const predecessorIds = this.localProjectChangeEvents
        .slice(0, localEventIdx)
        .map((e) => e.messageId);
      this.outOfOrderLocalProjectChangeEvents.push({
        predecessorIds,
        event,
      });
      if (!this.outOfOrderEventTimeoutId) {
        this.outOfOrderEventTimeoutId = window.setTimeout(() => {
          onTimeout();
          this.loggingService.error(
            new Error(`Out Of Order Event Timeout Reached with index ${localEventIdx}`),
            {
              code: 'OutOfOrderEventTimeout',
            },
          );
        }, RTC_OUT_OF_ORDER_EVENT_TIMEOUT);
      }
      return undefined;
    }
    const localEvent = this.localProjectChangeEvents[localEventIdx];
    this.localProjectChangeEvents.splice(localEventIdx, 1);
    return localEvent;
  }

  /**
   * Replay all events that are currently on the local queue. This will update the local order scheduling engine
   * as well as all other entity stores based on an initial state.
   */
  public replayAllLocalEvents(initialStoreStates: InteractiveEntityMaps): void {
    let storeStates = copyStateMaps(initialStoreStates);

    this.localProjectChangeEvents.forEach((localEvent) => {
      try {
        const { changes: schedulingChanges } = getChangesFromEvent(
          localEvent,
          this.localOrderSchedulingEngine(),
          this.entityStores,
          storeStates,
        );
        storeStates = updateStateMaps(storeStates, schedulingChanges as InteractiveEntityChanges);
      } catch (error) {
        this.localProjectChangeEvents.splice(this.localProjectChangeEvents.indexOf(localEvent), 1);
        this.loggingService.error(error as Error, {
          code: 'RTCController.replayAllLocalEvents',
        });
      }
    });

    setStoreStates(this.entityStores, storeStates);
  }

  /**
   * Replay all events that are currently on the local queue and that don't have any conflicts with a processed (remote) event.
   * This will update the local order scheduling engine as well as all other entity stores based on an initial state.
   * Invalid events are skipped and returned in the result.
   */
  public replayValidLocalEvents(
    processedEvent: LocalProjectChangeEvent | RemoteProjectChangeEvent,
    processedEventChanges: InteractiveEntityChanges,
    {
      initialStoreStates,
      checkForEventConflicts = true,
      unionizeSubsequentEventChanges = false,
    }: {
      initialStoreStates: InteractiveEntityMaps;
      checkForEventConflicts?: boolean;
      unionizeSubsequentEventChanges?: boolean;
    },
  ): { invalidEvents: LocalProjectChangeEvent[] } {
    const invalidEvents: LocalProjectChangeEvent[] = [];
    let storeStates = copyStateMaps(initialStoreStates);

    this.localProjectChangeEvents.forEach((localEvent) => {
      let isInvalid = false;

      if (checkForEventConflicts) {
        isInvalid = RTCConflictDetection.eventsHaveConflicts(processedEvent, localEvent);
      }

      let localEventChanges: InteractiveEntityChanges | undefined;
      try {
        const { changes: schedulingChanges } = getChangesFromEvent(
          localEvent,
          this.localOrderSchedulingEngine(),
          this.entityStores,
          storeStates,
          true,
        );
        localEventChanges = { ...(schedulingChanges as InteractiveEntityChanges) };
      } catch (error) {
        this.loggingService.error(error as Error, {
          code: 'RTCController.replayValidLocalEvents',
        });
        isInvalid = true;
      }
      if (!localEventChanges) {
        isInvalid = true;
        return;
      }

      if (RTCConflictDetection.changesHaveConflicts(processedEventChanges, localEventChanges)) {
        isInvalid = true;
      }

      if (!isInvalid) {
        this.localOrderSchedulingEngine().commit(
          mapInteractiveChangesToSENG(sanitizeSchedulingChanges(localEventChanges)),
        );
        storeStates = updateStateMaps(storeStates, localEventChanges);
      } else {
        invalidEvents.push(localEvent);
        if (unionizeSubsequentEventChanges) {
          processedEventChanges = unionizeChanges(processedEventChanges, localEventChanges);
        }
      }
    });

    setStoreStates(this.entityStores, storeStates);

    return { invalidEvents };
  }

  public notifyAndDropInvalidEvents(invalidEvents: LocalProjectChangeEvent[]) {
    if (invalidEvents.length > 0) {
      showActionFailedNotification();
    }

    invalidEvents.forEach((invalidEvent) => {
      this.localProjectChangeEvents.splice(this.localProjectChangeEvents.indexOf(invalidEvent), 1);
      this.undoRedoQueue().drop(this.messageIdToCommitIdDictionary[invalidEvent.messageId]);
    });
  }

  public reset(): void {
    this.pendingRemoteProjectChangeEvents = [];
    this.localProjectChangeEvents = [];
    this.outOfOrderLocalProjectChangeEvents = [];
    if (this.outOfOrderEventTimeoutId) {
      clearTimeout(this.outOfOrderEventTimeoutId);
      this.outOfOrderEventTimeoutId = undefined;
    }
  }
}

import { FileUploadService } from '@/interfaces/services';

export class HttpFileUploadService implements FileUploadService {
  public async upload(
    file: File,
    url: string,
    fields: { key: string; value: string }[],
  ): Promise<void> {
    const formData = new FormData();
    fields.forEach((field) => {
      formData.append(field.key, field.value);
    });
    formData.append('file', file);

    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error. Status: ${response.status}`);
    }
  }
}

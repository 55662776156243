import { Composer } from 'vue-i18n';

import { UserFragment } from '@/graphql/__generated__/graphql';
import { useUserName } from '@/helpers/users';
import { LoggingService } from '@/interfaces/services';
import { setI18nLanguageStrings } from '@/plugins/i18n';
import { Jimo } from '@/plugins/jimo/jimo';
import { Userback } from '@/plugins/userback';
import { Language } from '@/utils/languages';

export function identifyUser(
  user: UserFragment | null,
  hasMembership: boolean,
  loggingService: LoggingService,
  userback: Userback,
  jimo: Jimo,
): void {
  if (!user) return;

  loggingService
    .setUser({
      identification: user.id,
      properties: {
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        email: user.email,
        createdAt: user.createdAt,
        hasMembership,
      },
    })
    .then(() =>
      jimo.init({ id: user.id, email: user.email, name: `${user.firstName} ${user.lastName}` }),
    );
  userback.identify(user.id, {
    name: useUserName(user),
    email: user.email,
    languageCode: user.languageCode,
  });
}
export function setUserLanguage(
  user: UserFragment | null,
  userback: Userback,
  i18n: Composer,
): void {
  if (!user) return;

  if (user?.languageCode !== i18n.locale.value) {
    userback.recreate(user.languageCode as Language);
    setI18nLanguageStrings(i18n, user.languageCode as Language).then(() => {
      i18n.locale.value = user!.languageCode;
    });
  }
}

import { Entity, PartialEntity, WbsSectionEntity } from '@/common/types';
import { copy } from '@/helpers/utils/objects';
import { OrderedTree } from '@/helpers/utils/orderedTree';
import { getRandomId } from '@/helpers/utils/strings';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';

import { WbsSection } from '../types';

export function indentSection(
  section: WbsSection,
  sections: WbsSection[],
  getName: (section: WbsSection, sections: WbsSection[]) => string,
): WbsSection[] {
  const newSection: WbsSection = {
    id: toGlobalId(NodeName.SECTION, getRandomId()),
    name: '',
    position: 0,
    parentId: section.id,
  };
  const newSections = [
    ...copy(sections).map((s) => {
      if (s.parentId === section.id) return { ...s, parentId: newSection.id };
      return s;
    }),
    newSection,
  ];
  newSection.name = getName(newSection, newSections);

  return newSections;
}

export function outdentSections(
  allSections: WbsSectionEntity[],
  sectionToOutdent: WbsSectionEntity,
  targetParentId?: string | null,
): {
  updatedSections: PartialEntity<WbsSectionEntity>[];
  deletedSections: Entity[];
} {
  if (!sectionToOutdent.parentId) throw new Error('Illegal operation: cannot outdent top level');

  const orderedSectionTree = new OrderedTree(allSections);

  const sectionNodeToOutdent = orderedSectionTree.get(sectionToOutdent.id);
  if (!sectionNodeToOutdent)
    throw new Error('Given sections do not include the section to outdent');
  // recursion end condition
  if (!sectionNodeToOutdent.children.length) return { deletedSections: [], updatedSections: [] };

  const parentOfSectionToOutdent = orderedSectionTree.get(sectionToOutdent.parentId);
  if (!parentOfSectionToOutdent)
    throw new Error('Given sections do not include the parent of the section to outdent');

  const sectionsToOutdent = parentOfSectionToOutdent.children;

  if (!sectionsToOutdent.length) return { deletedSections: [], updatedSections: [] };

  const newParentId = targetParentId ?? parentOfSectionToOutdent.id;

  let updatedSections: PartialEntity<WbsSectionEntity>[] = [];
  let deletedSections: Entity[] = [];

  sectionsToOutdent.forEach((section) => {
    const sectionTreeNode = orderedSectionTree.get(section.id);
    if (!sectionTreeNode) return;
    deletedSections.push({ id: sectionTreeNode.id });
    updatedSections = updatedSections.concat(
      sectionTreeNode.children.map((child) => ({ id: child.id, parentId: newParentId })),
    );
  });

  const updatedSectionsWithChildren = updatedSections.filter(
    (s) => !!orderedSectionTree.get(s.id)?.children.length,
  );

  const getSanitizedChanges = () => {
    // fix positions for updated sections
    const positionMap = new Map<string, number>();
    updatedSections.forEach((s) => {
      const position = positionMap.get(s.parentId!) ?? 0;
      positionMap.set(s.parentId!, position + 1);
      s.position = position;
    });

    return {
      deletedSections,
      updatedSections,
    };
  };

  // all updated sections either have 0 children or all of them have children (= valid structure)
  if (
    updatedSectionsWithChildren.length === 0 ||
    updatedSectionsWithChildren.length === updatedSections.length
  ) {
    return getSanitizedChanges();
  }

  // remove sections from updateList that have children and call outdent on their children
  updatedSectionsWithChildren.forEach((section) => {
    updatedSections = updatedSections.filter((s) => s.id !== section.id);
    const outdentRecursionResult = outdentSections(
      allSections,
      orderedSectionTree.get(section.id)!,
      newParentId,
    );
    updatedSections = updatedSections.concat(outdentRecursionResult.updatedSections ?? []);
    deletedSections = deletedSections.concat(outdentRecursionResult.deletedSections ?? []);
  });

  return getSanitizedChanges();
}

import {
  InsertTradeSequenceVariables,
  InteractiveEntityChanges,
  TradeSequenceEntity,
} from '@/common/types';
import { pickKeys } from '@/helpers/utils/objects';

import { UndoRedoCommit } from '../undoRedo';
import { generateUndoRedoCommitId, UndoRedoActionNames } from '../undoRedo/types';
import {
  CreateTradeSequenceVariables,
  DeleteTradeSequenceVariables,
  RestoreTradeSequenceVariables,
} from './types';

export const createUndoRedoCreateCommit = (
  vars: CreateTradeSequenceVariables[],
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.CreateTradeSequences,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteTradeSequences,
      data: vars,
    },
  };
};

export const createUndoRedoUpdateCommit = (
  vars: TradeSequenceEntity[],
  pauses: Map<string, TradeSequenceEntity>,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.UpdateTradeSequences,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.UpdateTradeSequences,
      data: vars.map((tradeSequence) => {
        const existingTradeSequence = pauses.get(tradeSequence.id);
        if (!existingTradeSequence) {
          throw new Error('Trade sequence not found');
        }
        return pickKeys(
          existingTradeSequence,
          Object.keys(tradeSequence) as (keyof TradeSequenceEntity)[],
        );
      }),
    },
  };
};

export const createUndoRedoRestoreCommit = (
  vars: RestoreTradeSequenceVariables[],
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RestoreTradeSequences,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteTradeSequences,
      data: vars,
    },
  };
};

export const createUndoRedoDeleteCommit = (
  vars: DeleteTradeSequenceVariables[],
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.DeleteTradeSequences,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.RestoreTradeSequences,
      data: vars,
    },
  };
};

export const getInsertCommit = (
  id: string,
  vars: InsertTradeSequenceVariables,
  changes: InteractiveEntityChanges,
): UndoRedoCommit => {
  return {
    id,
    action: {
      name: UndoRedoActionNames.InsertTradeSequence,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.RevertInsertTradeSequence,
      data: {
        orders: changes.add?.orders ?? [],
      },
    },
  };
};

export const getRevertInsertCommit = (
  id: string,
  changes: InteractiveEntityChanges,
): UndoRedoCommit => {
  return {
    id,
    action: {
      name: UndoRedoActionNames.RevertInsertTradeSequence,
      data: {
        orders: changes.delete?.orders ?? [],
      },
    },
    inverseAction: {
      name: UndoRedoActionNames.RestoreInsertTradeSequence,
      data: {
        orders: changes.delete?.orders ?? [],
      },
    },
  };
};

export const getRestoreInsertCommit = (
  id: string,
  changes: InteractiveEntityChanges,
): UndoRedoCommit => {
  return {
    id,
    action: {
      name: UndoRedoActionNames.RestoreInsertTradeSequence,
      data: {
        orders: changes.add?.orders ?? [],
      },
    },
    inverseAction: {
      name: UndoRedoActionNames.RevertInsertTradeSequence,
      data: {
        orders: changes.add?.orders ?? [],
      },
    },
  };
};

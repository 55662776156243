import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

/** Working time calendars */
/** Calendar created */
export class WorkingCalendarCreatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.WORK_CALENDAR_CREATED;
}

/** Calendar updated */
export class WorkingCalendarEditedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.WORK_CALENDAR_EDITED;
}

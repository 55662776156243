<template>
  <DsSnackbar
    :model-value="isSnackbarVisible"
    :action-text="$t('Update.button')"
    @click-action="refreshApp"
  >
    <template #title>
      <p class="tw-ds-text-md">{{ $t('Update.text') }}</p>
    </template>
  </DsSnackbar>
</template>

<script setup lang="ts">
import { useLoggingService } from '@/services/logging/composable';

const loggingService = useLoggingService();

const isSnackbarVisible = ref(false);
const isRefreshing = ref(false);
const registration = ref<null | ServiceWorkerRegistration>(null);

/**
 * Display a snackbar inviting the user to refresh/reload the app due
 * to an app update being available.
 * The new service worker is installed, but not yet active.
 * Store the ServiceWorkerRegistration instance for later use.
 * Source: https://medium.com/@dougallrich/give-users-control-over-app-updates-in-vue-cli-3-pwas-20453aedc1f2
 */
function showRefreshUI(e: CustomEvent<ServiceWorkerRegistration>): void {
  registration.value = e.detail;
  isSnackbarVisible.value = true;
}

function refreshApp(): void {
  isSnackbarVisible.value = false;

  // Protect against missing registration.waiting.
  if (!registration.value?.waiting) return;

  registration.value.waiting.postMessage('skipWaiting');
}

// Refresh all open app tabs when a new service worker is installed.
onMounted((): void => {
  navigator?.serviceWorker?.addEventListener('controllerchange', () => {
    if (isRefreshing.value) return;
    isRefreshing.value = true;
    window.location.reload();
  });

  document.addEventListener('swUpdated', showRefreshUI as EventListener, { once: true });

  // add tracking for PWA install events https://developer.mozilla.org/en-US/docs/Web/API/Window/appinstalled_event
  window.addEventListener('appinstalled', () =>
    loggingService.trackEvent(new loggingService.AnalyticEventCategories.PWAInstalled()),
  );
});
</script>

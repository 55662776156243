import { Entity } from '@/common/types';
import { OperationInputType } from '@/features/realTimeCollaboration/types';

export function convertProjectAlternativeDeleteOperationInput(
  input: OperationInputType<'DeleteProjectAlternative'>,
): Entity[] {
  return [
    {
      id: input.alternativeId,
    },
  ];
}

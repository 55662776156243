import { Span, startInactiveSpan, startSpan } from '@sentry/vue';

const spans = new Map<string, Span>();

export function useMonitoring() {
  const measureCallbackDuration = async <T>(name: string, callback: () => T) => {
    return startSpan({ name, op: 'function' }, callback);
  };

  const startManualDurationMeasurement = (id: string, name: string) => {
    const span = startInactiveSpan({
      name,
      op: 'function',
    });

    spans.set(id, span);
  };

  const endManualDurationMeasurement = (id: string) => {
    const span = spans.get(id);
    if (span) {
      span.end();
    }
  };

  return { measureCallbackDuration, startManualDurationMeasurement, endManualDurationMeasurement };
}

<template>
  <TheMain :with-drawer="false" class="tw-bg-blue-900">
    <div class="tw-flex tw-min-h-full tw-flex-col tw-justify-center tw-py-1 sm:tw-px-6 lg:tw-px-8">
      <div class="tw-flex tw-justify-center sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
        <logo :icon-url="theme.icon" class="tw-w-1/2 tw-mb-6" />
      </div>

      <div class="tw-mt-4 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md tw-flex tw-justify-center">
        <slot name="content">
          <router-view />
        </slot>
      </div>
    </div>
    <TheFooter dark />
  </TheMain>
</template>

<script setup lang="ts">
import { useTheme } from '@/common/composables/theme';
import TheFooter from '@/components/partials/TheFooter.vue';
import TheMain from '@/components/partials/TheMain.vue';
import Logo from '@/components/utils/Logo.vue';

const theme = useTheme();
</script>

import { computed, Ref } from 'vue';

import { Edge } from '@/graphql/types';
import { OrderByCondition, QueryAllInfo } from '@/interfaces/repositories/base';

const defaultQueryAllInfo: QueryAllInfo = {
  count: 0,
};

interface FetchAllResult<TEntity> {
  result: Ref<readonly TEntity[]>;
  info: Ref<QueryAllInfo>;
}

/**
 * Constructs an object that can be returned from repository fetchAll functions
 * including the parsed result and the info
 * @param rawResult Return value from the query
 * @param key Key where to find data in the rawResult
 * @param buildEntities Function to tranform entities
 */
export function useFetchAllResult<
  TQuery,
  TData extends Partial<{
    __typename: string;
    totalCount?: number | null;
  }>,
  TEntity,
>(
  rawResult: Ref<TQuery | undefined>,
  key: keyof TQuery,
  buildEntities: (data: TData) => TEntity[],
): FetchAllResult<TEntity> {
  const entities = computed(() => {
    const result = rawResult.value
      ? (rawResult.value[key as never] as unknown as TData | null | undefined)
      : undefined;
    if (!result) return [];

    return buildEntities(result);
  });

  const info = computed(() => {
    const result = rawResult.value
      ? (rawResult.value[key as never] as unknown as TData | null)
      : undefined;
    if (!result) return defaultQueryAllInfo;

    return {
      count: result.totalCount ?? 0,
    };
  });

  return {
    result: entities,
    info,
  };
}

/**
 * Converts the orderBy object into an array for our API
 * @param orderBy Defines the order
 */
export function getOrderByArgument(orderBy?: OrderByCondition): string[] {
  if (!orderBy) return [];

  return Object.entries(orderBy).map(
    ([attribute, order]) => `${order === 'ASC' ? '' : '-'}${attribute}`,
  );
}

/**
 * Extracts the nodes from a node connection
 * @param nodeConnection GraphQL NodeConnection to flatten
 */
export function flattenNodeConnection<T>(
  nodeConnection?: { edges?: (Edge<T> | null)[] | null } | null,
): T[] {
  return nodeConnection?.edges?.map((edge) => edge!.node!) ?? [];
}

export function flattenNodeConnectionWithCursor<T>(
  nodeConnection?: { edges?: (Edge<T> | null)[] | null } | null,
): (T & { cursor: string | null | undefined })[] {
  return nodeConnection?.edges?.map((edge) => ({ ...edge!.node!, cursor: edge!.cursor })) ?? [];
}

import {
  TenantQuery,
  TenantQueryVariables,
  UpdateTenantMutation,
  UpdateTenantMutationVariables,
} from '@/graphql/__generated__/graphql';
import OWN_TENANT from '@/graphql/tenants/Detail.gql';
import UPDATE_TENANT from '@/graphql/tenants/Update.gql';
import { useApolloClient } from '@/plugins/apollo';

export class TenantStoreRepository {
  public async fetchOwn(vars: TenantQueryVariables) {
    const client = useApolloClient();

    return client
      .query<TenantQuery, TenantQueryVariables>({
        query: OWN_TENANT,
        variables: vars,
      })
      .then((result) => {
        if (!result.data.tenant) return null;
        return result.data.tenant;
      });
  }

  public async update(vars: UpdateTenantMutationVariables) {
    const client = useApolloClient();

    return client
      .mutate<UpdateTenantMutation, UpdateTenantMutationVariables>({
        mutation: UPDATE_TENANT,
        variables: vars,
      })
      .then((result) => {
        if (!result.data?.updateTenant?.tenant) return null;
        return result.data.updateTenant.tenant;
      });
  }
}

import { StringHelper } from '@bryntum/schedulerpro';

import { TradeSequenceActivityEntity } from '@/common/types';
import {
  PdfCustomExportConfig,
  PdfExportOrientation,
  PdfPaperFormat,
} from '@/features/filter-and-export/types';
import {
  getPaperHeightInPx,
  getPaperWidthInPx,
} from '@/features/filter-and-export/utils/pdfAppearanceUtils';
import { getExportHeader } from '@/features/filter-and-export/utils/pdfHeaderUtils';
import { TS_EXPORT_HEADER_HEIGHT } from '@/features/flexibleTradeSequences/export/config';
import { createShadowRoot } from '@/features/pdfExport/utils/dom';
import { TradeFragment } from '@/graphql/__generated__/graphql';
import { i18nInstance } from '@/plugins/i18n';

import { NewOrUpdatedTaskTemplate } from '../types';

const PAGE_WIDTH = getPaperWidthInPx({
  paperFormat: PdfPaperFormat.A3,
  orientation: PdfExportOrientation.LANDSCAPE,
});

const PAGE_HEIGHT = getPaperHeightInPx({
  paperFormat: PdfPaperFormat.A3,
  orientation: PdfExportOrientation.LANDSCAPE,
});

const TASK_LIST_PADDING_Y = 20;
const MAX_TASK_LIST_HEIGHT = PAGE_WIDTH - TS_EXPORT_HEADER_HEIGHT - 3 * TASK_LIST_PADDING_Y;

const HEADER_INFO_PLACEHOLDER = '%HEADER_INFO_PLACEHOLDER%';

export const generateTaskListPageBodies = (
  activities: TradeSequenceActivityEntity[],
  config: PdfCustomExportConfig,
  tradeVariations: TradeFragment[],
): HTMLBodyElement[] => {
  let sortedActivities = activities.sort((a, b) => a.offset - b.offset);
  const pageBodies: HTMLBodyElement[] = [];
  while (sortedActivities.length > 0) {
    const { pageBody, remainingActivities } = generateTaskListPageBody(
      sortedActivities,
      config,
      tradeVariations,
    );
    sortedActivities = remainingActivities;
    pageBodies.push(pageBody);
  }
  pageBodies.forEach((pageBody, index, all) => {
    const headerInfo = i18nInstance.global.t(
      'objects.flexibleTradeSequence.export.exportHeaderTaskList',
      [i18nInstance.global.d(new Date()), index + 1, all.length],
    );
    pageBody.innerHTML = pageBody.innerHTML.replace(HEADER_INFO_PLACEHOLDER, headerInfo);
  });

  return pageBodies;
};

const generateTaskListPageBody = (
  activities: TradeSequenceActivityEntity[],
  config: PdfCustomExportConfig,
  tradeVariations: TradeFragment[],
): { pageBody: HTMLBodyElement; remainingActivities: TradeSequenceActivityEntity[] } => {
  const pageHeader = getExportHeader(
    { ...config, headerHeight: TS_EXPORT_HEADER_HEIGHT },
    HEADER_INFO_PLACEHOLDER,
  );
  const { html: taskListTable, remainingActivities } = generateTaskListTable(
    activities,
    tradeVariations,
  );

  const pageBody = TaskListPage(`${pageHeader}${taskListTable}`);

  return { pageBody, remainingActivities };
};

const generateTaskListTable = (
  activities: TradeSequenceActivityEntity[],
  tradeVariations: TradeFragment[],
): { html: string; remainingActivities: TradeSequenceActivityEntity[] } => {
  const taskListTableContainer = TaskListTableContainer();
  const taskListTable = TaskListTable();
  const { shadowRoot, unmountShadowRoot } = createShadowRoot();

  shadowRoot.appendChild(taskListTableContainer);
  taskListTableContainer.appendChild(taskListTable);

  addHeaderToTaskListTable(taskListTable);

  const remainingActivities = activities.reduce<TradeSequenceActivityEntity[]>(
    (remaining, activity) => {
      if (remaining.length > 0) {
        remaining.push(activity);
        return remaining;
      }

      const { remainingActivity } = addRowToTaskListTable(
        activity,
        taskListTableContainer,
        taskListTable,
        tradeVariations,
      );
      if (remainingActivity !== null) {
        remaining.push(remainingActivity);
      }
      return remaining;
    },
    [],
  );

  unmountShadowRoot();

  return { html: taskListTableContainer.outerHTML, remainingActivities };
};

const addHeaderToTaskListTable = (taskListTable: HTMLElement): void => {
  [
    i18nInstance.global.t('objects.flexibleTradeSequence.workPackageDetailsTrade'),
    i18nInstance.global.t('objects.leanProject.tradeSequenceActivity.workpackage.title'),
    i18nInstance.global.t('objects.flexibleTradeSequence.workPackageDetailsTasks'),
  ].forEach((headerCellText) => {
    const headerCell = TableHeaderCell(headerCellText);
    taskListTable.appendChild(headerCell);
  });
};

const addRowToTaskListTable = (
  activity: TradeSequenceActivityEntity,
  taskListContainer: HTMLElement,
  taskListTable: HTMLElement,
  tradeVariations: TradeFragment[],
): {
  remainingActivity: TradeSequenceActivityEntity | null;
} => {
  const [tradeCell, activityCell, taskListCell] = [
    TradeCell(activity, tradeVariations),
    ActivityCell(activity),
    TaskListCell(),
  ];

  taskListTable.append(tradeCell, activityCell, taskListCell);

  const removeAllCells = () => {
    taskListTable.removeChild(tradeCell);
    taskListTable.removeChild(activityCell);
    taskListTable.removeChild(taskListCell);
  };

  const sanitizedTaskTemplates = activity.taskTemplates.filter(({ name }) => Boolean(name));

  if (sanitizedTaskTemplates.length === 0) {
    taskListCell.innerHTML += `
          <p style="color: var(--color-grey-400)">
              ${i18nInstance.global.t('objects.flexibleTradeSequence.workPackageDetailsNoTasks')}
          </p>`;

    if (taskListContainer.getBoundingClientRect().height > MAX_TASK_LIST_HEIGHT) {
      removeAllCells();
      return { remainingActivity: activity };
    }
    return { remainingActivity: null };
  }

  const remainingTaskTemplates = sanitizedTaskTemplates.reduce<NewOrUpdatedTaskTemplate[]>(
    (remainingTasks, taskTemplate) => {
      if (remainingTasks.length > 0) {
        remainingTasks.push(taskTemplate);
        return remainingTasks;
      }
      taskListCell.innerHTML += `
            <p style="display: flex; align-items: start; gap: 0.75rem;">
                <span><input style="vertical-align: middle" type="checkbox"></span>
                <span style="overflow-wrap: break-word; min-width: 0;">${taskTemplate.name}</span>
            </p>`;

      if (taskListContainer.getBoundingClientRect().height > MAX_TASK_LIST_HEIGHT) {
        taskListCell.removeChild(taskListCell.lastChild as Node);
        if (taskListCell.children.length === 0) removeAllCells();
        remainingTasks.push(taskTemplate);
      }

      return remainingTasks;
    },
    [],
  );

  return {
    remainingActivity:
      remainingTaskTemplates.length > 0
        ? { ...activity, taskTemplates: remainingTaskTemplates }
        : null,
  };
};

const TaskListPage = (innerHtml: string): HTMLBodyElement => {
  const body = createStyledHtmlElement('body', {
    width: `${PAGE_WIDTH}px`,
    height: `${PAGE_HEIGHT}px`,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }) as HTMLBodyElement;
  const main = createStyledHtmlElement('main', {
    width: `${PAGE_HEIGHT}px`,
    height: `${PAGE_WIDTH}px`,
    position: 'absolute',
    right: '100%',
    top: '0',
    transform: 'rotate(-90deg)',
    transformOrigin: 'top right',
  });
  main.innerHTML = innerHtml;
  body.appendChild(main);

  return body;
};

const TaskListTableContainer = (): HTMLElement =>
  createStyledHtmlElement('div', {
    width: `${PAGE_HEIGHT}px`,
    paddingLeft: `32px`,
    paddingRight: `32px`,
    paddingTop: `${TASK_LIST_PADDING_Y}px`,
    paddingBottom: `${TASK_LIST_PADDING_Y}px`,
  });

const TaskListTable = (): HTMLElement =>
  createStyledHtmlElement('div', {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr minmax(0, 2fr)',
    width: '100%',
    borderRadius: '12px',
    border: '1px solid var(--color-grey-100)',
    overflow: 'hidden',
  });

const TableHeaderCell = (innerText: string): HTMLElement =>
  createStyledHtmlElement(
    'div',
    {
      padding: '0.75rem 1.25rem',
      backgroundColor: 'var(--color-grey-50)',
      color: 'var(--color-black)',
      textAlign: 'left',
    },
    ['tw-ds-text-md--semibold'],
    innerText,
  );

const cellStyle: Partial<CSSStyleDeclaration> = {
  borderTop: '1px solid var(--color-grey-100)',
  color: 'var(--color-black)',
  padding: '0.75rem 1.25rem',
};

const cellClasses = ['tw-ds-text-md--medium'];

const TradeCell = (
  activity: TradeSequenceActivityEntity,
  tradeVariations: TradeFragment[],
): HTMLElement => {
  const activityTrade = tradeVariations.find((t) => t.id === activity.tenantTradeVariation?.id);

  const tradeCell = createStyledHtmlElement('div', cellStyle, cellClasses);
  tradeCell.innerHTML = `
    <p style="display: flex; align-items: start; gap: 0.75rem;">
      <span style="display: inline-block; flex: none; width: 1.25rem; height: 1.25rem; border-radius: 6px; vertical-align: middle; background-color: ${StringHelper.xss`${activityTrade?.color ?? 'white'}`}">
      </span>
      <span>${StringHelper.xss`${activityTrade?.name ?? ''}`}</span>
    </p>
  `;
  return tradeCell;
};

const ActivityCell = (activity: TradeSequenceActivityEntity): HTMLElement =>
  createStyledHtmlElement('div', cellStyle, cellClasses, activity.name);

const TaskListCell = (): HTMLElement => createStyledHtmlElement('div', cellStyle, cellClasses);

const createStyledHtmlElement = (
  tagName: string,
  style: Partial<CSSStyleDeclaration>,
  classes: string[] = [],
  innerText = '',
): HTMLElement => {
  const element = document.createElement(tagName);
  element.classList.add(...classes);
  element.innerText = innerText;
  Object.assign(element.style, style);
  return element;
};

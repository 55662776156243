import { MilestoneEntity, OrderDependencyEntity, OrderEntity, PartialEntity } from '@/common/types';
import { UndoRedoCommit, useUndoRedoQueue } from '@/features/undoRedo';
import { getRandomId } from '@/helpers/utils/strings';

import { useLocalOrderSchedulingEngine } from '../schedule';
import { useRTCController } from './rtcController';
import { LocalProjectChangeEventTemplate, ProjectChangeEventContext } from './types';

export function generateRTCMessageId(): string {
  return getRandomId();
}

export const pushLocalChangeEventAndCommit = async (
  event: LocalProjectChangeEventTemplate,
  commit: UndoRedoCommit,
  createCommit: boolean,
  contextUtils?: {
    dependencies?: Map<string, OrderDependencyEntity>;
    orders?: Map<string, OrderEntity>;
    milestones?: Map<string, MilestoneEntity>;
    sendElementContext?: boolean;
    context?: ProjectChangeEventContext;
    injectWorkingTimeDuration?: <T extends PartialEntity<OrderEntity>>(order: T) => T;
  },
) => {
  const changes = await useRTCController().pushLocalProjectChangeEvent(event, {
    commitId: commit.id,
  });

  let context: ProjectChangeEventContext | undefined = contextUtils?.context;

  if (contextUtils) {
    /**
     * When an event requires rescheduling using SENG, one change in an element, might lead to
     * additional changes in other elements, due to dependencies. We need to retrieve the original dependencies
     * in order to correctly undo/redo the changes. These original dependencies will then be set on the context
     * of the event.
     */
    if (contextUtils.dependencies) {
      const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();
      const changedSchedulingElements = [
        ...(changes.update?.orders ?? []),
        ...(changes.update?.milestones ?? []),
      ].map((element) => element.id);
      const changedSchedulingDependencies =
        changes.update?.dependencies?.map((dep) => dep.id) ?? [];
      const potentiallyRescheduledDependencies =
        localOrderSchedulingEngine.utils.getIncidentDependencies(changedSchedulingElements);
      context = {
        dependencies: Array.from(
          new Set([...changedSchedulingDependencies, ...potentiallyRescheduledDependencies]),
        )
          .map((dependencyId) => {
            const dependency = contextUtils.dependencies?.get(dependencyId);
            if (dependency) {
              return {
                id: dependencyId,
                lagInMinutes: dependency.lagInMinutes,
                bufferInMinutes: dependency.bufferInMinutes,
                useDryingBreak: dependency.useDryingBreak,
              };
            }
            return undefined;
          })
          .filter(Boolean) as PartialEntity<OrderDependencyEntity>[],
      };
    }

    /**
     * In some cases, we also need to save the context for scheduling elements such as orders and milestones,
     * in order to correctly reset the state of these elements. This is e.g. when updating the calendar (or pauses) or when a dependency creation caused
     * rescheduling of the successor.
     */
    if (contextUtils.sendElementContext && contextUtils.orders && contextUtils.milestones) {
      const changedOrders = [...(changes.update?.orders ?? [])];
      const changedMilestones = [...(changes.update?.milestones ?? [])];

      context = {
        ...context,
        orders: changedOrders
          .map((order) => {
            let orderContext = contextUtils.orders?.get(order.id);
            if (orderContext) {
              orderContext = contextUtils.injectWorkingTimeDuration?.(orderContext) ?? orderContext;
              return {
                id: order.id,
                ...(orderContext.startAt ? { startAt: orderContext.startAt.toISOString() } : {}),
                ...(orderContext.finishAt ? { finishAt: orderContext.finishAt.toISOString() } : {}),
                ...(orderContext.duration !== undefined
                  ? { workingTimeDuration: orderContext.duration }
                  : {}),
              };
            }
            return undefined;
          })
          .filter(Boolean),
        milestones: changedMilestones
          .map((milestone) => {
            const milestoneContext = contextUtils.milestones?.get(milestone.id);
            if (milestoneContext) {
              return {
                id: milestone.id,
                ...(milestoneContext.date ? { date: milestoneContext.date.toISOString() } : {}),
              };
            }
            return undefined;
          })
          .filter(Boolean),
      } as ProjectChangeEventContext;
    }
  }

  if (createCommit) {
    useUndoRedoQueue().commit(commit, changes, context);
  }
  return { changes, commit, context };
};

import { SchedulerPro } from '@bryntum/schedulerpro';

import { ScheduleConstants } from '@/common/bryntum/constants';
import { getEmptyResourceId } from '@/services/store/schedule/parsers/base';
import {
  SchedulerDependency,
  SchedulerEvent,
  SchedulerResource,
} from '@/services/store/schedule/types';

import { PdfCustomExportConfig, PdfExportOrientation } from '../types';

const FRAMES_PER_SECOND = 60;
const EMPTY_RESOURCE_FILTER_ID = 'pdf-empty-resource-filter';
const DEPENDENCY_FILTER_ID = 'pdf-dependency-filter';

export async function setDependencyFilterForExport(schedulerInstance: SchedulerPro) {
  const visibleEvents = new Set(
    (schedulerInstance.eventStore.records as SchedulerEvent[]).map((event) => event.id),
  );
  await schedulerInstance.dependencyStore.filter({
    id: DEPENDENCY_FILTER_ID,
    filterBy: (record: SchedulerDependency) =>
      visibleEvents.has(record.from) && visibleEvents.has(record.to),
  });

  return () => schedulerInstance.dependencyStore.removeFilter(DEPENDENCY_FILTER_ID);
}

export async function setEmptyResourceFilterForExport(schedulerInstance: SchedulerPro) {
  const emptyResourceId = getEmptyResourceId();
  await schedulerInstance.resourceStore.filter({
    id: EMPTY_RESOURCE_FILTER_ID,
    filterBy: (record: SchedulerResource) => record.id !== emptyResourceId,
  });

  return () => schedulerInstance.resourceStore.removeFilter(EMPTY_RESOURCE_FILTER_ID);
}

export function makeResourcesWithOverlappingMilestonesStacked(schedulerInstance: SchedulerPro) {
  const resourceMilestoneMap = getResourceMilestoneMap(schedulerInstance);

  resourceMilestoneMap.forEach((milestones: SchedulerEvent[], resourceId: string) => {
    const hasOverlappingMilestones = findOverlappingMilestones(schedulerInstance, milestones);

    if (hasOverlappingMilestones) {
      const resource = schedulerInstance.resourceStore.getById(resourceId) as SchedulerResource;
      resource.eventLayout = 'stack';
    }
  });
}

function getResourceMilestoneMap(schedulerInstance: SchedulerPro) {
  const resourceMilestoneMap = new Map<string, SchedulerEvent[]>(
    (schedulerInstance.resourceStore.records as SchedulerResource[])
      .filter((resource: SchedulerResource) => resource.isMidLevelRow || resource.isTopLevelRow)
      .map((resource) => [resource.id, []]),
  );

  (schedulerInstance.eventStore.records as SchedulerEvent[]).forEach((event: SchedulerEvent) => {
    if (event.resourceId && event.isMilestone && resourceMilestoneMap.has(event.resourceId)) {
      resourceMilestoneMap.get(event.resourceId)!.push(event);
    }
  });
  return resourceMilestoneMap;
}

function findOverlappingMilestones(schedulerInstance: SchedulerPro, milestones: SchedulerEvent[]) {
  const OFFSET = ScheduleConstants.ROW_HEIGHT / 2;
  const ranges: [number, number][] = [];

  return milestones.some((milestone: SchedulerEvent) => {
    const milestoneStart = schedulerInstance.getCoordinateFromDate(milestone.startDate) - OFFSET;
    const milestoneEnd = milestoneStart + (milestone.milestoneWidth ?? OFFSET);
    const overlaps = ranges.some(([start, end]) => {
      const hasOverlappingStart = milestoneStart < end && milestoneEnd > start;
      const hasOverlappingEnd = milestoneEnd > start && milestoneStart < end;
      return hasOverlappingStart || hasOverlappingEnd;
    });
    ranges.push([milestoneStart, milestoneEnd]);
    return overlaps;
  });
}

export function resetUiAdjustments(schedulerInstance: SchedulerPro) {
  schedulerInstance.rowHeight = ScheduleConstants.ROW_HEIGHT;
  schedulerInstance.resourceMargin = ScheduleConstants.MARGIN.RESOURCE;
  schedulerInstance.barMargin = ScheduleConstants.MARGIN.BAR;

  (schedulerInstance.resourceStore.records as SchedulerResource[])
    .filter((resource: SchedulerResource) => resource.isMidLevelRow || resource.isTopLevelRow)
    .forEach((resource) => {
      const mutatableResource = schedulerInstance.resourceStore.getById(
        resource.id,
      ) as SchedulerResource;
      mutatableResource.eventLayout = 'none';
    });
}

// Rendering after zooming takes a while (especially dependencies), so we add a little timout
// to make sure everything looks as expected
export async function waitNumberOfRenderCycles(count = FRAMES_PER_SECOND): Promise<void> {
  let counter = 0;
  while (counter < count) {
    await new Promise(requestAnimationFrame);
    counter++;
  }
}

export const PAPERSIZE_IN_PX = {
  A0: {
    height: 4492,
    width: 3177,
  },
  A1: {
    height: 3177,
    width: 2246,
  },
  A2: {
    height: 2246,
    width: 1583,
  },
  A3: {
    height: 1583,
    width: 1122,
  },
  A4: {
    height: 1122,
    width: 792,
  },
};

/** Returns the width of the paper (depending on the orientation) for common din paper formats assuming 96 PPI */
export function getPaperWidthInPx(
  config: Pick<PdfCustomExportConfig, 'orientation' | 'paperFormat'>,
): number {
  const isLandscape = config.orientation === PdfExportOrientation.LANDSCAPE;
  const papersizeForFormat = PAPERSIZE_IN_PX[config.paperFormat] ?? PAPERSIZE_IN_PX.A4;
  const width = isLandscape ? papersizeForFormat.height : papersizeForFormat.width;
  return width;
}

export function getPaperHeightInPx(
  config: Pick<PdfCustomExportConfig, 'orientation' | 'paperFormat'>,
): number {
  const isLandscape = config.orientation === PdfExportOrientation.LANDSCAPE;
  const papersizeForFormat = PAPERSIZE_IN_PX[config.paperFormat] ?? PAPERSIZE_IN_PX.A4;
  const height = isLandscape ? papersizeForFormat.width : papersizeForFormat.height;
  return height;
}

export const EXPORT_RESOURCE_MARGIN = 2;
export const EXPORT_BAR_MARGIN = EXPORT_RESOURCE_MARGIN;

export const getEventHeightForRowHeight = (schedulerInstance, rowHeight?: number) => {
  // this is only roughly correct, but we can expect the resource margin and bar margin to be the same for exports
  return (rowHeight || schedulerInstance.rowHeight) - 2 * schedulerInstance.resourceMargin;
};

export const getRowHeightForEventHeight = (schedulerInstance: SchedulerPro, eventHeight) => {
  return eventHeight + 2 * (schedulerInstance.resourceMargin as number);
};

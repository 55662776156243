class BaseError extends Error {
  public constructor(private wrappedError: Error) {
    super(wrappedError.message);
  }

  public unwrap<T extends Error>(): T {
    return this.wrappedError as T;
  }
}

export class DefaultError extends BaseError {
  public constructor(error: Error) {
    super(error);
    this.message = 'errors.default';
    this.name = 'DefaultError';
  }
}

export class NotFoundError extends BaseError {
  public constructor() {
    super(new Error('errors.notFound'));
    this.name = 'NotFoundError';
  }
}

import { ApolloError } from '@apollo/client/errors';
import { browserTracingIntegration, ErrorEvent, EventHint, init } from '@sentry/vue';
import { FirebaseError } from 'firebase/app';
import { App } from 'vue';
import { isNavigationFailure, Router } from 'vue-router';

import { APP_ENV, IS_PROD_ENV, SENTRY_DIST, SENTRY_DSN, SENTRY_RELEASE } from '@/utils/config';
import {
  BulkApiError,
  GraphQLError,
  GraphQLErrorCode,
  StoreEntityNotFoundError,
} from '@/utils/errors';

/* Responsible for configuring Sentry within the Vue application */
export function installSentry(app: App, router: Router): void {
  init({
    app,
    dsn: SENTRY_DSN,
    environment: APP_ENV,
    release: SENTRY_RELEASE,
    dist: SENTRY_DIST,
    integrations: [browserTracingIntegration({ router })],
    replaysOnErrorSampleRate: 1.0,
    tracesSampler: () => (IS_PROD_ENV ? 0.1 : 0.0),
    beforeSend: (event: ErrorEvent, hint: EventHint | undefined) => {
      const exception = hint?.originalException;

      if (shouldDiscardException(exception)) {
        return null;
      }

      if (exception instanceof GraphQLError) {
        if (exception.code === GraphQLErrorCode.PERMISSION_DENIED) {
          event.fingerprint = ['permission-error'];
        } else if (exception.code === GraphQLErrorCode.ENTITY_NOT_FOUND) {
          event.fingerprint = ['entity-not-found-error'];
        } else if (exception.code === GraphQLErrorCode.BAD_USER_INPUT) {
          event.fingerprint = ['bad-user-input-error'];
        } else if (exception.code === GraphQLErrorCode.GRAPHQL_VALIDATION_FAILED) {
          event.fingerprint = ['graphql-validation-failed-error'];
        } else if (
          exception.code === GraphQLErrorCode.INTERNAL_SERVER_ERROR &&
          (exception.message.toLowerCase().includes('socket') ||
            exception.message.toLowerCase().includes('gateway'))
        ) {
          event.fingerprint = ['server-connection-error'];
        } else {
          event.fingerprint = [exception.code, exception.message, exception.operationName];
        }
      }

      if (exception instanceof FirebaseError) {
        event.fingerprint = [exception.code];
      }

      if (exception instanceof StoreEntityNotFoundError) {
        event.fingerprint = ['store-entity-not-found-error'];
      }

      if (exception instanceof BulkApiError) {
        event.fingerprint = ['bulk-api-error', exception.message];
      }

      if (exception instanceof TypeError) {
        if (
          exception.message.includes('Failed to fetch dynamically imported module') ||
          exception.message.includes('Importing a module script failed')
        ) {
          event.fingerprint = ['dynamic-module-import-error'];
        } else if (exception.message.includes('ServiceWorker')) {
          event.fingerprint = ['service-worker-error'];
        } else {
          event.fingerprint = [exception.message];
        }
      }

      return event;
    },
  });
}

function shouldDiscardException(exception: unknown): boolean {
  // Errors from client.query are for some reason duplicated and can be ignored
  // Navigation errors and service worker update errors can also be discarded
  return (
    isNavigationFailure(exception) ||
    exception instanceof ApolloError ||
    (exception instanceof GraphQLError && exception.code === GraphQLErrorCode.UNAUTHENTICATED) ||
    (exception instanceof Error &&
      /Failed to update a ServiceWorker for scope .* with script .*/.test(exception.message))
  );
}

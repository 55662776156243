import { DependencyType } from '@koppla-tech/scheduling-engine';
import gql from 'graphql-tag';
import { defineStore } from 'pinia';

import { DefaultError, NotFoundError } from '@/common/errors';
import {
  TradeSequenceTemplate,
  TradeSequenceTemplateDetails,
} from '@/features/tenantTemplates/types';
import { gql as gqlFn } from '@/graphql/__generated__';
import {
  CreateTradeSequenceTemplateFromSequenceMutationVariables,
  CreateTradeSequenceTemplateMutationVariables,
  TradeSequenceTemplateDetailsQueryVariables,
  TradeSequenceTemplateDetailsV2Fragment,
  TradeSequenceTemplateFragment,
  TradeSequenceTemplatesQueryVariables,
  UpdateTradeSequenceTemplateMutationVariables,
} from '@/graphql/__generated__/graphql';
import { getRandomId } from '@/helpers/utils/strings';
import { useApolloClient } from '@/plugins/apollo';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

import { mapTradeSequenceToCreateInput } from './tradeSequenceTemplateUtils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TRADE_SEQUENCE_TEMPLATE_FRAGMENT = gql`
  fragment TradeSequenceTemplate on TradeSequenceTemplateNode {
    id
    name
    defaultDuration
    updatedAt
    updatedBy {
      firstName
      lastName
      profilePicture {
        imageThumbnail {
          url
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TRADE_SEQUENCE_TEMPLATE_DETAILS_FRAGMENT_V2 = gql`
  fragment TradeSequenceTemplateDetailsV2 on TradeSequenceTemplateNode {
    ...TradeSequenceTemplate
    activities {
      edges {
        node {
          ...TradeSequenceActivity
        }
      }
    }
    dependencies {
      ...TradeSequenceActivityDependency
    }
  }
`;

function duplicateTradeSequenceTemplate(
  tradeSequence: TradeSequenceTemplateDetails,
): TradeSequenceTemplateDetails {
  const oldIdToNewId = new Map<string, string>();

  const regeneratedActivities = tradeSequence.activities.map((activity) => {
    const newId = toGlobalId(NodeName.TRADE_SEQUENCE_ACTIVITY, getRandomId());
    oldIdToNewId.set(activity.id, newId);
    return {
      ...activity,
      id: newId,
      taskTemplates: activity.taskTemplates.map((task) => ({
        ...task,
        id: toGlobalId(NodeName.ORDER_TASK_TEMPLATE, getRandomId()),
      })),
    };
  });

  const regeneratedDependencies = tradeSequence.dependencies.map((dependency) => ({
    ...dependency,
    id: toGlobalId(NodeName.TRADE_SEQUENCE_DEPENDENCY, getRandomId()),
    from: { ...dependency.from, id: oldIdToNewId.get(dependency.from.id)! },
    to: { ...dependency.to, id: oldIdToNewId.get(dependency.to.id)! },
  }));

  return {
    ...tradeSequence,
    id: toGlobalId(NodeName.TRADE_SEQUENCE, getRandomId()),
    activities: regeneratedActivities,
    dependencies: regeneratedDependencies,
  };
}

export type TenantTradeSequenceStore = ReturnType<typeof useTradeSequenceTemplatesStore>;

export const useTradeSequenceTemplatesStore = defineStore('trade-sequence-templates-store', () => {
  const isInitialized = ref(false);
  const tradeSequenceTemplates = ref<Map<string, TradeSequenceTemplate>>(new Map());
  const tradeSequenceTemplatesList = computed(() =>
    Array.from(tradeSequenceTemplates.value.values()),
  );
  const tradeSequenceTemplatesWithDetails = ref<Map<string, TradeSequenceTemplateDetails>>(
    new Map(),
  );

  const parseTradeSequenceTemplateFragment = (
    fragment: TradeSequenceTemplateFragment,
  ): TradeSequenceTemplate => ({
    ...fragment,
    updatedByFullName: [fragment.updatedBy?.firstName, fragment.updatedBy?.lastName]
      .filter(Boolean)
      .join(' '),
    updatedByInitials: [fragment.updatedBy?.firstName, fragment.updatedBy?.lastName]
      .map((name) => name?.charAt(0) || '')
      .join(''),
  });

  const parseTradeSequenceTemplateDetailsFragment = (
    fragment: TradeSequenceTemplateDetailsV2Fragment,
  ): TradeSequenceTemplateDetails => ({
    ...parseTradeSequenceTemplateFragment(fragment),
    activities: flattenNodeConnection(fragment.activities),
    dependencies: fragment.dependencies.map((d) => ({
      ...d,
      type: d.type as DependencyType,
    })),
  });

  const parseDetailsFetchResultAndUpdateStore = (
    details: TradeSequenceTemplateDetailsV2Fragment,
  ): TradeSequenceTemplateDetails => {
    const parsedDetails = parseTradeSequenceTemplateDetailsFragment(details);

    tradeSequenceTemplatesWithDetails.value.set(parsedDetails.id, parsedDetails);
    tradeSequenceTemplates.value.set(parsedDetails.id, parsedDetails);

    return parsedDetails;
  };

  const fetchAll = async (variables: TradeSequenceTemplatesQueryVariables) => {
    const client = useApolloClient();

    const query = gqlFn(/* GraphQL */ `
      query TradeSequenceTemplates($tenant: ID!) {
        tradeSequenceTemplates(tenant: $tenant) {
          edges {
            node {
              ...TradeSequenceTemplate
            }
          }
        }
      }
    `);

    return client
      .query({
        query,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const flattenedTemplates = flattenNodeConnection(result?.data.tradeSequenceTemplates);
        tradeSequenceTemplates.value = new Map(
          flattenedTemplates.map((fragment) => [
            fragment.id,
            parseTradeSequenceTemplateFragment(fragment),
          ]),
        );
        return flattenedTemplates;
      });
  };

  const fetchDetails = async (
    variables: TradeSequenceTemplateDetailsQueryVariables,
  ): Promise<TradeSequenceTemplateDetails> => {
    const client = useApolloClient();

    return client
      .query({
        query: gqlFn(/* GraphQL */ `
          query tradeSequenceTemplateDetails($id: ID!) {
            tradeSequenceTemplateDetails: tradeSequenceTemplate(id: $id) {
              ...TradeSequenceTemplateDetailsV2
            }
          }
        `),
        variables,
      })
      .then((result) => {
        if (result.error) throw new DefaultError(result.error);
        const details = result?.data.tradeSequenceTemplateDetails;

        if (!details) throw new NotFoundError();

        return parseDetailsFetchResultAndUpdateStore(details);
      });
  };

  const create = async (input: CreateTradeSequenceTemplateMutationVariables['input']) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation CreateTradeSequenceTemplate($input: CreateTradeSequenceTemplateInput!) {
        createTradeSequenceTemplate(input: $input) {
          tradeSequenceTemplate {
            ...TradeSequenceTemplate
          }
        }
      }
    `);

    return client
      .mutate({
        mutation,
        variables: { input },
      })
      .then((response) => {
        const result = response.data?.createTradeSequenceTemplate?.tradeSequenceTemplate ?? null;
        if (!result) return null;

        const parsedResult = parseTradeSequenceTemplateFragment(result);
        tradeSequenceTemplates.value.set(result.id, parsedResult);
        return parsedResult;
      });
  };

  const createFromProjectTradeSequence = async (
    input: CreateTradeSequenceTemplateFromSequenceMutationVariables['input'],
  ) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation CreateTradeSequenceTemplateFromSequence(
        $input: CreateTradeSequenceTemplateFromSequenceInput!
      ) {
        createTradeSequenceTemplateFromSequence(input: $input) {
          tradeSequenceTemplate {
            ...TradeSequenceTemplate
          }
        }
      }
    `);

    return client
      .mutate({
        mutation,
        variables: { input },
      })
      .then((response) => {
        const result =
          response.data?.createTradeSequenceTemplateFromSequence?.tradeSequenceTemplate ?? null;
        if (!result) return null;

        const parsedResult = parseTradeSequenceTemplateFragment(result);
        tradeSequenceTemplates.value.set(result.id, parsedResult);
        return parsedResult;
      });
  };

  const update = async (input: UpdateTradeSequenceTemplateMutationVariables['input']) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation UpdateTradeSequenceTemplate($input: UpdateTradeSequenceTemplateInput!) {
        updateTradeSequenceTemplate(input: $input) {
          tradeSequenceTemplate {
            ...TradeSequenceTemplateDetailsV2
          }
        }
      }
    `);

    return client
      .mutate({
        mutation,
        variables: { input },
      })
      .then((response) => {
        const result = response.data?.updateTradeSequenceTemplate?.tradeSequenceTemplate ?? null;
        if (!result) return null;

        return parseDetailsFetchResultAndUpdateStore(result);
      });
  };

  const duplicate = async (id: string, newName: string, tenantId: string) => {
    const tradeSequence = await fetchDetails({ id });

    if (!tradeSequence) throw new NotFoundError();

    const duplicatedSequence = duplicateTradeSequenceTemplate(tradeSequence);
    return create({
      ...mapTradeSequenceToCreateInput(duplicatedSequence),
      tenant: tenantId,
      name: newName,
    });
  };

  const deleteTemplate = async (id: string) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation DeleteTradeSequenceTemplate($input: DeleteTradeSequenceTemplateInput!) {
        deleteTradeSequenceTemplate(input: $input) {
          success
        }
      }
    `);

    return client
      .mutate({
        mutation,
        variables: { input: { id } },
      })
      .then((response) => {
        const result = response.data?.deleteTradeSequenceTemplate?.success ?? null;
        if (!result) return null;

        tradeSequenceTemplates.value.delete(id);
        tradeSequenceTemplatesWithDetails.value.delete(id);
        return result;
      });
  };

  const initialize = (tenantId: string) => {
    return fetchAll({ tenant: tenantId }).finally(() => {
      isInitialized.value = true;
    });
  };

  const replaceTrade = (previousTradeId: string, newTradeId: string) => {
    tradeSequenceTemplatesWithDetails.value.forEach((template) => {
      const updatedActivities = template.activities.map((activity) => {
        if (activity.tenantTradeVariation.id === previousTradeId) {
          return {
            ...activity,
            tenantTradeVariation: { ...activity.tenantTradeVariation, id: newTradeId },
          };
        }
        return activity;
      });

      tradeSequenceTemplatesWithDetails.value.set(template.id, {
        ...template,
        activities: updatedActivities,
      });
    });
  };

  return {
    isInitialized,
    tradeSequenceTemplates,
    tradeSequenceTemplatesList,
    tradeSequenceTemplatesWithDetails,
    create,
    createFromProjectTradeSequence,
    fetchAll,
    fetchDetails,
    initialize,
    update,
    duplicate,
    deleteTemplate,
    replaceTrade,
  };
});

import { useI18n } from 'vue-i18n';

import { AttachmentFragment, DocumentAttachmentNode } from '@/graphql/__generated__/graphql';
import { CompressionOptions } from '@/interfaces/services/compression';

/** Default values for uploading pictures */
export const PICTURE_EXTENSIONS = ['jpeg', 'jpg', 'png'];
export const MAX_PICTURE_SIZE_KB = 50000; // in KB
export const MAX_DOCUMENT_SIZE_KB = 20000; // in KB

export const COMPRESSION_OPTIONS: CompressionOptions = {
  maxSizeMB: 1,
  maxIteration: Number.POSITIVE_INFINITY,
};

/** Default values for uploading documents */

const wordExtensions = ['doc', 'dot', 'dotx', 'msword', 'docx'];

const excelExtensions = ['xls', 'xlt', 'xla', 'xlsx', 'csv'];

export const DOCUMENT_EXTENSIONS = ['pdf', ...wordExtensions, ...excelExtensions];

export enum FileCategory {
  Word,
  Excel,
  Pdf,
  Unknown,
}

export function getFormattedFileSize(fileSizeKB: number): string {
  const i18n = useI18n();
  const decimalPoint = i18n.locale.value === 'de' ? ',' : '.';
  let fileSizeString = `${fileSizeKB.toFixed(0)} ${i18n.t('objects.file.size.kB')}`;
  if (fileSizeKB >= 1000) {
    fileSizeString = `${(fileSizeKB / 1000).toFixed(1)} ${i18n.t('objects.file.size.mB')}`;
  }
  // de: 50.0 -> 50, 50.5 -> 50,5
  return fileSizeString.replace('.0', '').replace('.', decimalPoint);
}

export function getFileCategoryFromExtension(extension: string): FileCategory {
  if (wordExtensions.includes(extension)) return FileCategory.Word;
  if (excelExtensions.includes(extension)) return FileCategory.Excel;
  if (extension === 'pdf') return FileCategory.Pdf;
  return FileCategory.Unknown;
}

/**
 * Merges extensions that represent the same file type.
 * @param extensions
 * @returns
 */
export function getDisplayExtensions(extensions: string[]): string[] {
  const unqiueCategories = new Set<string>();

  const getCategoryName = (extension: string) => {
    const category = getFileCategoryFromExtension(extension);
    switch (category) {
      case FileCategory.Excel:
        return 'Excel';
      case FileCategory.Word:
        return 'Word';
      case FileCategory.Pdf:
        return 'PDF';
      default:
        return extension;
    }
  };

  extensions.forEach((extension) => {
    unqiueCategories.add(getCategoryName(extension));
  });
  return Array.from(unqiueCategories.values());
}

export function getFileExtension(fileName: string): string | undefined {
  const parts = fileName.split('.');
  return parts.pop()?.toLowerCase();
}

/** Checks if file is document, by comparing to extension of file name */
export function isDocument(fileName: string): boolean {
  const extension = getFileExtension(fileName);
  if (!extension) return false;
  return DOCUMENT_EXTENSIONS.includes(extension);
}

export function isDocumentFragment(
  attachment: AttachmentFragment,
): attachment is DocumentAttachmentNode {
  return attachment.__typename === 'DocumentAttachmentNode';
}

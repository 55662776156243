import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { Composer } from 'vue-i18n';

import { i18nInstance } from '@/plugins/i18n';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    i18n: Composer;
  }
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
  store.i18n = i18nInstance.global;
});

export default pinia;

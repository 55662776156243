import { OperationNames } from 'events.schema';

import {
  Entity,
  InsertTradeSequenceVariables,
  OrderDependencyEntity,
  TradeSequenceEntity,
} from '@/common/types';

import { generateRTCMessageId, LocalProjectChangeEventTemplate } from '../realTimeCollaboration';
import { ProjectChangeEventContext } from '../realTimeCollaboration/types';
import {
  CreateTradeSequenceVariables,
  DeleteTradeSequenceVariables,
  RestoreTradeSequenceVariables,
} from './types';

export const createProjectChangeCreateEvent = (
  vars: CreateTradeSequenceVariables[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  const { tradeSequence, orderAssignments } = vars[0];
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CreateTradeSequence,
      input: {
        ...tradeSequence,
        calendarId: tradeSequence.calendar.id,
        activities: tradeSequence.activities.map((activity) => ({
          ...activity,
          tradeId: activity.tenantTradeVariation?.id ?? '',
        })),
        dependencies: tradeSequence.dependencies.map((dependency) => ({
          ...dependency,
          fromActivityId: dependency.from.id,
          toActivityId: dependency.to.id,
        })),
        orderAssignments,
        // NOTE: This will be replaced with the correct data at a later point within the controller
        idMapping: { activityToOrderId: {}, dependencies: [] },
      },
      context,
    },
  };
};

export const createProjectChangeUpdateEvent = (
  vars: TradeSequenceEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.UpdateTradeSequence,
      input: {
        ...vars[0],
        calendarId: vars[0].calendar.id,
        activities: vars[0].activities.map((activity) => ({
          ...activity,
          tradeId: activity.tenantTradeVariation?.id ?? '',
        })),
        dependencies: vars[0].dependencies.map((dependency) => ({
          ...dependency,
          fromActivityId: dependency.from.id,
          toActivityId: dependency.to.id,
        })),
        // NOTE: This will be replaced with the correct data at a later point within the controller
        idMappings: [],
      },
      context,
    },
  };
};

export const createProjectChangeRestoreEvent = (
  vars: RestoreTradeSequenceVariables[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RestoreScheduleNodes,
      input: {
        tradeSequences: vars.map((tradeSequence) => tradeSequence.tradeSequence.id),
      },
      context,
    },
  };
};

export const createProjectChangeDeleteEvent = (
  vars: DeleteTradeSequenceVariables[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RemoveScheduleNodes,
      input: {
        tradeSequences: vars.map((tradeSequence) => tradeSequence.tradeSequence.id),
      },
      context,
    },
  };
};

export const getInsertEvent = (
  vars: InsertTradeSequenceVariables,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.InsertTradeSequence,
      input: {
        ...vars,
        startAt: vars.date.toISOString(),
        wbsSectionId: vars.sectionId,
        // NOTE: This will be replaced with the correct data at a later point within the controller
        idMapping: { activityToOrderId: {}, dependencies: [] },
      },
      context,
    },
  };
};

export const getRevertInsertEvent = (
  orders: Entity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RemoveScheduleNodes,
      input: {
        orders: orders.map((order) => order.id),
      },
      context,
    },
  };
};

export const getRestoreInsertEvent = (
  orders: Entity[],
  context?: ProjectChangeEventContext,
  adjacentDependenciesToRestore?: OrderDependencyEntity[],
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RestoreScheduleNodes,
      input: {
        orders: orders.map((order) => order.id),
      },
      context,
    },
    restoredEntities: {
      orders: orders.map((order) => order.id),
      dependencyDetails: adjacentDependenciesToRestore?.map((dependency) => ({
        ...dependency,
        from: { orderId: dependency.from.id, milestoneId: dependency.from.id },
        to: { orderId: dependency.to.id, milestoneId: dependency.to.id },
      })),
    },
  };
};

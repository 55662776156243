import {
  generateRTCMessageId,
  LocalProjectChangeEventTemplate,
} from '@/features/realTimeCollaboration';

import { OperationNames } from '../../../../events.schema';

export const createProjectChangePublishAlternativeEvent = (vars: {
  alternativeId: string;
}): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.PublishProjectAlternative,
      input: vars,
    },
  };
};

export const createProjectChangeDeleteProjectAlternativeEvent = (vars: {
  alternativeId: string;
}): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.DeleteProjectAlternative,
      input: vars,
    },
  };
};

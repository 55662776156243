export abstract class BaseError extends Error {
  public constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = this.constructor.name;
  }
}

export class RestoredEntityNotFoundError extends BaseError {
  public constructor(entity: string, id: string) {
    super(`The ${entity} with id ${id} could not be restored`);
  }
}

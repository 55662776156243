<template>
  <a
    v-if="isExternalLink"
    v-bind="attrs"
    :href="stringUrl"
    target="_blank"
    rel="noopener noreferrer"
  >
    <slot />
  </a>
  <router-link v-else v-slot="{ isActive, href, navigate }" :to="to" :replace="replace" custom>
    <a
      v-bind="attrs"
      :href="href"
      class="app-link"
      :class="{ '--disabled': isActive }"
      @click="navigate"
    >
      <slot />
    </a>
  </router-link>
</template>

<script setup lang="ts">
import { RouteLocationRaw, RouterLink } from 'vue-router';

import { omitKeys } from '@/helpers/utils/objects';

const props = defineProps({
  replace: { type: Boolean, default: false },
  to: { type: [Object, String] as PropType<RouteLocationRaw>, required: true },
});

const isExternalLink = computed(() => typeof props.to === 'string' && props.to.startsWith('http'));
const stringUrl = computed(() => (isExternalLink.value ? (props.to as string) : ''));
const attrs = computed(() => {
  return omitKeys(useAttrs(), ['disabled']);
});
</script>

<script lang="ts">
export default { inheritAttrs: false };
</script>

<style scoped>
.app-link.--disabled {
  @apply tw-pointer-events-none tw-text-grey-300;
}
</style>

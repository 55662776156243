<template>
  <Component
    :is="is"
    ref="buttonRef"
    class="ds-button"
    :class="[
      `--${hierarchy} --${size} --icon-${icon}`,
      { '--destructive': destructive, '--full': full },
    ]"
    :disabled="disabled || loading"
    :type="type"
    @keydown.esc="onEscape"
  >
    <template v-if="loading">
      <DsLoadingCircle size="16" />
    </template>
    <slot />
  </Component>
</template>

<script setup lang="ts">
import { PopoverButton } from '@headlessui/vue';
import { PropType } from 'vue';

import AppLink from '@/ds-components/AppLink.vue';
import { blurActiveElement } from '@/ds-components/utils/blur';

const props = defineProps({
  hierarchy: {
    type: String as PropType<
      | 'primary'
      | 'primary-light'
      | 'secondary'
      | 'secondary-grey'
      | 'tertiary'
      | 'tertiary-grey'
      | 'link'
      | 'link-grey'
    >,
    default: 'primary',
  },
  destructive: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  size: { type: String as PropType<'sm' | 'md' | 'lg' | 'xl' | '2xl'>, default: 'md' },
  full: { type: Boolean, default: false },
  type: { type: String as PropType<'button' | 'submit'>, default: 'button' },
  icon: { type: String as PropType<'default' | 'only'>, default: 'default' },
  loading: { type: Boolean, default: false },
  isLink: { type: Boolean, default: false }, // expects all required RouterLink props,
  isPopoverTrigger: { type: Boolean, default: false },
});

const is = computed(() => {
  if (props.isPopoverTrigger) {
    return PopoverButton;
  }
  return props.isLink ? AppLink : 'button';
});

const onEscape = (event: KeyboardEvent) => {
  if (props.isPopoverTrigger) {
    return;
  }
  blurActiveElement(event);
};
</script>

<style>
.ds-button {
  @apply tw-inline-flex tw-items-center tw-justify-center tw-max-w-full tw-rounded-xs tw-whitespace-nowrap;
  @apply disabled:tw-cursor-not-allowed;
}
.ds-button.--sm {
  @apply tw-h-9 tw-px-[0.875rem] tw-gap-2 tw-ds-text-sm--semibold;
}
.ds-button.--sm.--icon-only {
  @apply tw-w-9 tw-px-0;
}
.ds-button.--md {
  @apply tw-h-10 tw-px-4 tw-gap-2 tw-ds-text-sm--semibold;
}
.ds-button.--md.--icon-only {
  @apply tw-w-10 tw-px-0;
}
.ds-button.--lg {
  @apply tw-h-11 tw-px-[1.125rem] tw-gap-2 tw-ds-text-md--semibold;
}
.ds-button.--lg.--icon-only {
  @apply tw-w-11 tw-px-0;
}
.ds-button.--xl {
  @apply tw-h-12 tw-px-5 tw-gap-2 tw-ds-text-md--semibold;
}
.ds-button.--xl.--icon-only {
  @apply tw-w-12 tw-px-0;
}
.ds-button.--2xl {
  @apply tw-h-[3.75rem] tw-px-7 tw-gap-3 tw-ds-text-lg--semibold;
}
.ds-button.--2xl.--icon-only {
  @apply tw-h-[3.5rem] tw-w-[3.5rem] tw-px-0;
}

.ds-button.--icon-only {
  @apply tw-flex-none;
}

.ds-button.--full {
  @apply tw-w-full;
}

.ds-button.--primary {
  @apply tw-bg-blue-500 tw-text-white;
  @apply hover:tw-bg-blue-700;
  @apply active:tw-bg-blue-500;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-50;
}
.ds-button.--primary.--destructive {
  @apply tw-bg-attention-500;
  @apply hover:tw-bg-attention-700;
  @apply active:tw-bg-attention-500;
  @apply focus-visible:tw-ring-attention-50;
}

.ds-button.--primary-light {
  @apply tw-bg-blue-25 tw-text-blue;
  @apply hover:tw-bg-blue-100 hover:tw-text-blue-700;
  @apply active:tw-bg-blue-50;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-50;
}
.ds-button.--primary-light.--destructive {
  @apply tw-bg-attention-25 tw-text-attention;
  @apply hover:tw-bg-attention-100 hover:tw-text-attention-700;
  @apply active:tw-bg-attention-500;
  @apply focus-visible:tw-ring-attention-50;
}

.ds-button.--primary,
.ds-button.--primary.--destructive,
.ds-button.--primary-light,
.ds-button.--primary-light.--destructive {
  @apply disabled:tw-bg-grey-50 disabled:tw-text-grey-100 disabled:tw-mix-blend-multiply;
}

.ds-button.--secondary {
  @apply tw-bg-white tw-text-blue-500 tw-border tw-border-solid tw-border-blue-500;
  @apply hover:tw-bg-blue-50;
  @apply active:tw-bg-white;
  @apply tw-mix-blend-multiply;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-50;
}
.ds-button.--secondary.--destructive {
  @apply tw-border tw-border-attention-500 tw-text-attention-500;
  @apply hover:tw-bg-attention-50;
  @apply active:tw-bg-white;
  @apply focus-visible:tw-ring-attention-50;
}
.ds-button.--secondary-grey {
  @apply tw-border tw-border-solid tw-border-grey-100 tw-bg-white tw-text-grey;
  @apply hover:tw-bg-grey-50 hover:tw-text-grey-700;
  @apply active:tw-bg-grey-25;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-grey-50;
}
.ds-button.--secondary,
.ds-button.--secondary.--destructive,
.ds-button.--secondary-grey {
  @apply disabled:tw-bg-white disabled:tw-text-grey-100 disabled:tw-border-grey-100 disabled:tw-mix-blend-multiply;
}

.ds-button.--tertiary {
  @apply tw-bg-transparent tw-text-blue-500;
  @apply hover:tw-bg-blue-50;
  @apply active:tw-bg-transparent;
  @apply tw-mix-blend-multiply;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-blue-50;
}
.ds-button.--tertiary.--destructive {
  @apply tw-text-attention-500;
  @apply hover:tw-bg-attention-50;
  @apply active:tw-bg-transparent;
  @apply focus-visible:tw-ring-attention-50;
}
.ds-button.--tertiary-grey {
  @apply tw-bg-transparent tw-text-grey;
  @apply hover:tw-bg-grey-50 hover:tw-text-grey-700;
  @apply active:tw-bg-grey-25;
  @apply focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-grey-50;
}
.ds-button.--tertiary-grey.--destructive {
  @apply hover:tw-text-attention-500 hover:tw-bg-attention-50;
  @apply active:tw-bg-transparent;
  @apply focus-visible:tw-ring-attention-50;
}

.ds-button.--link,
.ds-button.--link-grey {
  @apply tw-h-auto;
}

.ds-button.--link {
  @apply tw-px-0 tw-bg-transparent tw-text-blue-500;
  @apply hover:tw-text-blue-700;
  @apply focus-visible:tw-outline-none focus-visible:tw-text-blue-700;
  @apply disabled:tw-bg-white disabled:tw-text-blue-100;
}
.ds-button.--link.--destructive {
  @apply tw-px-0 tw-bg-transparent tw-text-attention-500;
  @apply hover:tw-text-attention-700;
  @apply focus-visible:tw-text-attention-700;
}
.ds-button.--link-grey {
  @apply tw-px-0 tw-bg-transparent tw-text-grey-500;
  @apply hover:tw-text-grey-700;
  @apply focus-visible:tw-outline-none focus-visible:tw-text-grey-700;
}

.ds-button.--tertiary,
.ds-button.--tertiary.--destructive,
.ds-button.--tertiary-grey,
.ds-button.--tertiary-grey.--destructive,
.ds-button.--link,
.ds-button.--link.--destructive,
.ds-button.--link-grey {
  @apply disabled:tw-bg-white disabled:tw-text-grey-100;
}
</style>

import { addBreadcrumb, captureException, getCurrentScope, setTags, setUser } from '@sentry/vue';

import { LoggingService } from '@/interfaces/services';
import { ErrorInfo } from '@/interfaces/services/logging';
import { AnalyticsLogger } from '@/plugins/segment';
import { AnalyticsEntity, AnalyticsEvent } from '@/utils/analyticsEvents/categories';
import { APP_VERSION } from '@/utils/config';

export class ProductionLoggingService extends LoggingService {
  // List of calls that were made before the user was identified
  private pendingCalls: (() => void | Promise<void>)[] = [];

  private isIdentified = false;

  public constructor(private logger: AnalyticsLogger) {
    super();
    setTags({ version: APP_VERSION ?? 'MISSING' });
  }

  public error(error: string | Error, { code, contexts }: ErrorInfo): void {
    captureException(error, { tags: { code }, contexts });
  }

  public warn(message: string): void {
    addBreadcrumb({
      message,
      level: 'warning',
    });
  }

  public info(message: string): void {
    addBreadcrumb({
      message,
      level: 'info',
    });
  }

  public async trackEvent(event: AnalyticsEvent): Promise<void> {
    if (this.isIdentified) {
      await this.logger.trackEvent(event, this.additionalProperties);
    } else {
      this.pendingCalls.push(() => {
        return this.logger.trackEvent(event, this.additionalProperties);
      });
    }
  }

  public async setPage(name: string, companyId?: string, projectId?: string): Promise<void> {
    if (this.isIdentified) {
      await this.logger.setPage(name, companyId, projectId, this.additionalProperties);
    } else {
      this.pendingCalls.push(() => {
        return this.logger.setPage(name, companyId, projectId, this.additionalProperties);
      });
    }
  }

  public async setUser(
    user: AnalyticsEntity<Dictionary & { hasMembership: boolean }>,
  ): Promise<void> {
    setUser({ email: user.properties?.email ?? null });

    await this.logger.setUser(user);
    this.isIdentified = true;
    await this.executePendingCalls();
  }

  public async setTenantGroup(
    group: AnalyticsEntity<Dictionary & { hasMembership: boolean }>,
  ): Promise<void> {
    if (this.isIdentified) {
      await this.logger.setTenantGroup(group);
    } else {
      this.pendingCalls.push(() => {
        return this.logger.setTenantGroup(group);
      });
    }
  }

  public async setProjectGroup(group: AnalyticsEntity): Promise<void> {
    if (this.isIdentified) {
      await this.logger.setProjectGroup(group);
    } else {
      this.pendingCalls.push(() => {
        return this.logger.setProjectGroup(group);
      });
    }
  }

  public reset(): void {
    getCurrentScope().setUser(null);
    this.logger.reset();
    this.isIdentified = false;
  }

  private async executePendingCalls(): Promise<void> {
    await Promise.all(this.pendingCalls.map((call) => call()));
    this.pendingCalls = [];
  }
}

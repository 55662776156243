import { parse } from 'content-disposition-header';
import { saveAs } from 'file-saver';
import { ref } from 'vue';

import { FileDownloadService, LoggingService } from '@/interfaces/services';
import { RemoteFileObject, ZipObject } from '@/interfaces/services/download';

export class HttpFileDownloadService implements FileDownloadService {
  private JSZip: any = null;

  private JSZipUtils: any = null;

  public constructor(private loggingService: LoggingService) {
    // lazy import the zip modules to reduce initial memory footprint
    import('jszip').then((jszip) => {
      this.JSZip = jszip.default;
    });
    import('jszip-utils').then((jsziputils) => {
      this.JSZipUtils = jsziputils;
    });
  }

  /**
   * Downloads file and triggers automatic browser download.
   * The filename returned by the backend has precedence over
   * the one provided on object.
   * @param object
   * @returns
   */
  public async download(object: RemoteFileObject, useObjectName = true): Promise<void> {
    /** Fetch binary content of remote object by url */
    return fetch(object.url)
      .then(async (r) => ({
        responseHeaders: r.headers,
        blob: await r.blob(),
      }))
      .then(({ blob, responseHeaders }) => {
        let { name } = object;
        if (!useObjectName) {
          try {
            const contentDisposition = responseHeaders.get('Content-Disposition');
            if (contentDisposition) {
              const parsed = parse(contentDisposition);
              if (typeof parsed.parameters.filename === 'string') name = parsed.parameters.filename;
            }
          } catch (e) {
            this.loggingService.error(e as string | Error, {
              code: 'PARSE_URL_FILENAME_FAILED',
            });
          }
        }

        saveAs(blob, name);
      });
  }

  public zipAndDownload(objects: RemoteFileObject[], nestedFolderName?: string): void {
    if (!this.JSZip) return;

    const zip = new this.JSZip();
    const numberOfAddedFiles = ref(0);
    const nestedFolder = nestedFolderName ? `${nestedFolderName}/` : null;
    /** create nested folder in zip file, which will be used as
     * top level directory for all following files to be downloaded
     */
    if (nestedFolder) {
      zip.file(nestedFolder, null, {
        dir: true,
      });
    }

    objects.forEach((object) => {
      /** Fetch binary content of remote object by url */
      this.JSZipUtils.getBinaryContent(object.url, (error: Error, data: Blob) => {
        // throw error, if fetch failed
        if (error) {
          throw error;
        }
        /** nest file name under folder name, if nested folder name provided */
        const fileName = this.getGeneratedFileName(
          nestedFolder ? `${nestedFolder}${object.name}` : object.name,
          zip,
        );
        /** add binary file to zip folder by name */
        zip.file(fileName, data, {
          binary: true,
        });

        numberOfAddedFiles.value++;
        if (numberOfAddedFiles.value !== objects.length) return;
        /** generate zip file, if all objects fetched and added */
        zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
          /** open native download window to download zip file */
          saveAs(content, nestedFolderName ? `${nestedFolderName}.zip` : undefined);
        });
      });
    });
  }

  /** Returns generated file name depending on number of equal named files */
  private getGeneratedFileName(initialFileName: string, zip: ZipObject): string {
    /** Returns splitted name and ending of file */
    const getSplittedFileNameAndSuffix = (): { name: string; suffix: string } => {
      const splittedName = initialFileName.split('.');
      return {
        name: splittedName.slice(0, splittedName.length - 1).join('.'),
        suffix: splittedName[splittedName.length - 1],
      };
    };

    /** Consists of all files, that match the same generated name and suffix */
    const equalNamedFiles = zip.filter((relativePath) => {
      const { name, suffix } = getSplittedFileNameAndSuffix();
      const regex = new RegExp(`${name} [(][0-9]+[)]\\.${suffix}`);
      return relativePath === initialFileName || !!relativePath.match(regex);
    });

    /** Generates file name depending on number of equal named files */
    const generateFileName = (): string => {
      let generatedFileName = initialFileName;
      if (equalNamedFiles.length) {
        /** Add suffix to file name, if multiple files with same name exist */
        const { name, suffix } = getSplittedFileNameAndSuffix();
        generatedFileName = `${name} (${equalNamedFiles.length}).${suffix}`;
      }
      return generatedFileName;
    };

    return generateFileName();
  }
}

import { OperationNames } from 'events.schema';

import { PartialEntity, PauseEntity } from '@/common/types';

import { generateRTCMessageId, LocalProjectChangeEventTemplate } from '../realTimeCollaboration';
import { ProjectChangeEventContext } from '../realTimeCollaboration/types';

export const createProjectChangeCreateEvent = (
  vars: PauseEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CreatePauses,
      input: vars.map((pause) => ({
        id: pause.id,
        name: pause.name ?? '',
        start: pause.start.toISOString(),
        end: pause.end.toISOString(),
      })),
      context,
    },
  };
};

export const createProjectChangeUpdateEvent = (
  vars: PartialEntity<PauseEntity>[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.UpdatePauses,
      input: vars.map((pause) => ({
        id: pause.id,
        name: pause.name,
        start: pause.start ? pause.start.toISOString() : undefined,
        end: pause.end ? pause.end.toISOString() : undefined,
      })),
      context,
    },
  };
};

export const createProjectChangeRestoreEvent = (
  pauses: PauseEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RestoreScheduleNodes,
      input: {
        pauses: pauses.map((pause) => pause.id),
      },
      context,
    },
    restoredEntities: {
      pauses: pauses.map((pause) => pause.id),
    },
  };
};

export const createProjectChangeDeleteEvent = (
  pauses: PauseEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RemoveScheduleNodes,
      input: {
        pauses: pauses.map((pause) => pause.id),
      },
      context,
    },
  };
};

import { defineStore } from 'pinia';

import { gql } from '@/graphql/__generated__';
import { useApolloClient } from '@/plugins/apollo';
import { getBulkApiClient } from '@/services/bulkApiClient';

import { BaseMilestoneState, BaseOrderState, BasePlan, BaseSectionState } from './types';

export const useBasePlanStore = defineStore('base-plan-store', () => {
  const basePlan = ref<BasePlan | undefined>(undefined);
  const baseMilestones = ref(new Map<string, BaseMilestoneState>());
  const baseOrders = ref(new Map<string, BaseOrderState>());
  const baseSections = ref(new Map<string, BaseSectionState>());

  const hasBasePlan = ref(false);
  const basePlanLoading = ref(true);
  const fetchAllPromise: Ref<Promise<void> | null> = ref(null);

  const initialize = async (projectId: string): Promise<void> => {
    fetchAllPromise.value = fetchAll(projectId);
    await fetchAllPromise.value;
  };

  const fetchAll = async (projectId: string) => {
    basePlanLoading.value = true;
    basePlan.value = await getBasePlan(projectId);

    if (!basePlan.value) {
      hasBasePlan.value = false;
      basePlanLoading.value = false;
      baseMilestones.value = new Map();
      baseOrders.value = new Map();
      baseSections.value = new Map();
      return;
    }
    const client = getBulkApiClient();
    const entities = await client.getBaseEntities(projectId);

    if (!entities) {
      hasBasePlan.value = false;
      return;
    }
    hasBasePlan.value = true;
    basePlanLoading.value = false;

    const orders = entities.orders.map((order) => ({
      ...order,
      dryingBreakDuration: order.dryingBreakDuration ?? undefined,
    }));

    baseSections.value = new Map(entities.wbsSections.map((section) => [section.id, section]));
    baseOrders.value = new Map(orders.map((order) => [order.id, order]));
    baseMilestones.value = new Map(entities.milestones.map((m) => [m.id, m]));
  };

  const getBasePlan = async (projectId: string) => {
    const query = gql(/* GraphQL */ `
      query GetBasePlan($projectId: ID!) {
        project(id: $projectId) {
          id
          basePlan {
            id
            name
            progress
            basePlanTime
            createdAt
            updatedAt
          }
        }
      }
    `);
    const client = useApolloClient();
    const variables = { projectId };
    const response = await client.query({ query, variables, fetchPolicy: 'no-cache' });
    const rawBasePlan = response.data?.project?.basePlan;

    if (rawBasePlan) {
      return {
        ...rawBasePlan,
        createdAt: new Date(rawBasePlan.createdAt as string),
        updatedAt: new Date(rawBasePlan.updatedAt as string),
        basePlanTime: new Date(rawBasePlan.basePlanTime as string),
      };
    }
    return undefined;
  };

  const setBasePlan = async (input: { project: string; name: string; projectVersion?: string }) => {
    const mutation = gql(/* GraphQL */ `
      mutation setBasePlan($input: SetProjectBasePlanInput!) {
        setProjectBasePlan(input: $input) {
          projectBasePlan {
            id
          }
        }
      }
    `);

    const client = useApolloClient();
    await client.mutate({
      mutation,
      variables: {
        input: { project: input.project, name: input.name, projectVersion: input.projectVersion },
      },
    });

    await initialize(input.project);
  };

  return {
    initialize,
    basePlan,
    baseMilestones,
    baseOrders,
    baseSections,
    hasBasePlan,
    setBasePlan,
    basePlanLoading,
    fetchAllPromise,
  };
});

import { WatchStopHandle } from 'vue';

type DebouncedFunc<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;

/**  Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds.
 */
export function debounce<T extends (...args: any) => void>(
  func: T,
  wait: number,
): DebouncedFunc<T> {
  let timeout: undefined | ReturnType<typeof setTimeout>;

  return (...args: any): void => {
    const later = (): void => {
      timeout = undefined;
      func(...args);
    };

    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
    if (!timeout) func(...args);
  };
}

/**
 * Returns a promise that can be resolved manually.
 */
export function getManualPromise<T>() {
  let resolve: (value: T) => void = () => {};
  let reject: (reason?: any) => void = () => {};

  const promise = new Promise<T>((promiseResolve, promiseReject) => {
    resolve = (value) => {
      promiseResolve(value);
    };
    reject = (value) => {
      promiseReject(value);
    };
  });

  return {
    promise,
    resolve,
    reject,
  };
}

/**
 * Wraps a function into a dedicated object that contains parameters and
 * function implementation separately. Can be used to store parameters
 * outside of function, which then can access the parameters from the object.
 * As a consequence, the function itself can be called without parameters.
 * NOTE: only works with objects as params currently.
 * @param params
 * @param fn
 * @returns
 */
export function wrap<P extends Dictionary>(
  params: P,
  fn: (params: P) => unknown,
): { params: P; fn: () => unknown } {
  return {
    params,
    fn: () => {
      return fn(params);
    },
  };
}

/**
 * Using a list of promises and a watch function, will transform the watch to always await
 * all promises asynchronously before starting the watching. In case the promises change,
 * the watch will be stopped and started again after awaiting the new promises.
 * @param promises
 * @param watchFn
 */
export function toAsyncWatch(
  promises: Ref<Promise<any> | null>[],
  watchFn: () => WatchStopHandle,
): WatchStopHandle {
  let stopOriginalWatch: WatchStopHandle | null = null;

  const stopAsyncWatch = watch(
    promises,
    async (newPromises) => {
      if (stopOriginalWatch) {
        stopOriginalWatch();
        stopOriginalWatch = null;
      }

      await Promise.all(newPromises);
      const watchHandle = watchFn();
      stopOriginalWatch = watchHandle;
    },
    { immediate: true },
  );

  return () => {
    if (stopOriginalWatch) {
      stopOriginalWatch();
    }
    stopAsyncWatch();
  };
}

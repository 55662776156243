import { isSupportOrTechSupportUserId } from '@/features/authentication/utils/sanitization';
import { TenantFragment, UserFragment } from '@/graphql/__generated__/graphql';
import { ProjectUser } from '@/helpers/users';

/**
 * Filters out users with specific IDs.
 */
function filterSupportUsers(users: ProjectUser[]): ProjectUser[] {
  return users.filter((u) => !isSupportOrTechSupportUserId(u.id));
}

/**
 * Sorts an array of users based on their `joinedAt` property in descending order.
 */
function sortByJoinedAt(users: ProjectUser[]): ProjectUser[] {
  return users.sort((u1, u2) => new Date(u2.joinedAt).getTime() - new Date(u1.joinedAt).getTime());
}

export function filterAndSortLiveUsers(users: ProjectUser[] = []): ProjectUser[] {
  const filteredUsers = filterSupportUsers(users);
  const sortedUsers = sortByJoinedAt(filteredUsers);
  return sortedUsers;
}

export function addOrUpdateCurrentUserToLiveUsers(
  users: ProjectUser[] = [],
  currentUser: UserFragment,
  currentTenant: TenantFragment | undefined,
): ProjectUser[] {
  const ownUser = users.find((u) => u.id === currentUser.id);

  const tenant = currentTenant ?? { id: '', name: '' };
  return [
    ownUser ?? {
      id: currentUser.id ?? '',
      firstName: currentUser.firstName ?? '',
      lastName: currentUser.lastName ?? '',
      thumbnailUrl: currentUser.profilePicture?.imageThumbnail?.url ?? '',
      email: currentUser.email ?? '',
      tenant: tenant,
      tenantId: tenant.id,
      tenantName: tenant.name,
      joinedAt: new Date().toISOString(),
    },
    ...users.filter((user) => user.id !== currentUser.id),
  ];
}

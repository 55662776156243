import {
  constructActivitiesFromOrders,
  constructActivityDependenciesFromOrderDependencies,
  Order,
  OrderSchedulingEngine,
  OrderStatus,
} from '@koppla-tech/scheduling-engine';

import {
  CalendarEntity,
  OrderDependencyEntity,
  OrderEntity,
  OrderTaskEntity,
  TradeSequenceEntity,
} from '@/common/types';
import { useOrderStoreV2 } from '@/features/orders';
import { getRandomId } from '@/helpers/utils/strings';
import { MINUTES_PER_DAY } from '@/helpers/utils/timeConstants';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';

export const DEFAULT_ACTIVITY_DURATION_IN_DAYS = 5;

export const identifyTradeSequenceInstances = (
  oldTradeSequence?: TradeSequenceEntity,
): { instanceIds: string[]; allRelatedOrderIds: string[] } => {
  if (!oldTradeSequence)
    return {
      instanceIds: [],
      allRelatedOrderIds: [],
    };
  const activityIds = new Set(oldTradeSequence.activities.map((a) => a.id));
  const orders = useOrderStoreV2().orders;
  const allRelatedOrderIds: string[] = [];
  const instanceIds = Array.from(orders.values()).reduce((ids, order) => {
    if (!order.tradeSequenceActivity?.id || !order.tradeSequenceInstanceId) {
      return ids;
    }
    const orderBelongsToActivity = activityIds.has(order.tradeSequenceActivity.id);
    if (orderBelongsToActivity) {
      allRelatedOrderIds.push(order.id);
      ids.add(order.tradeSequenceInstanceId);
      return ids;
    }
    return ids;
  }, new Set<string>());
  return {
    instanceIds: Array.from(instanceIds),
    allRelatedOrderIds,
  };
};

export function generateTradeSequenceFromOrders(
  schedulingEngine: OrderSchedulingEngine,
  orders: OrderEntity[],
  tasks: OrderTaskEntity[][],
  dependencies: OrderDependencyEntity[],
  defaultCalendar: CalendarEntity,
): {
  tradeSequence: TradeSequenceEntity;
  orderAssignments: {
    tradeSequenceInstanceId: string;
    assignments: { orderId: string; activityId: string }[];
  };
  activitiesHaveSameCalendar: boolean;
} {
  const activities = constructActivitiesFromOrders(
    orders.map(
      (o, idx): Order => ({
        ...o,
        duration: schedulingEngine.utils.computeWorkingTimeBetween(
          o.finishAt,
          o.startAt,
          o.calendar.id,
        ),
        status: o.status as unknown as OrderStatus,
        tasks: tasks[idx],
      }),
    ),
    { engine: schedulingEngine },
  ).map((a) => ({
    ...a,
    id: toGlobalId(NodeName.TRADE_SEQUENCE_ACTIVITY, a.id),
    taskTemplates: a.taskTemplates.map((t) => ({
      ...t,
      id: toGlobalId(NodeName.ORDER_TASK_TEMPLATE, t.id!),
    })),
  }));

  const orderIdToActivityIdMapping = new Map(orders.map((o, idx) => [o.id, activities[idx].id]));
  const activityDependencies = constructActivityDependenciesFromOrderDependencies(dependencies, {
    orderIdToActivityIdMapping,
  }).map((d) => ({
    ...d,
    id: toGlobalId(NodeName.TRADE_SEQUENCE_DEPENDENCY, d.id),
  }));

  const haveSameCalendar = orders.every((o) => o.calendar.id === orders[0].calendar.id);
  const haveSameDuration = activities.every((a) => a.duration === activities[0].duration);

  const tradeSequence: TradeSequenceEntity = {
    id: toGlobalId(NodeName.TRADE_SEQUENCE, getRandomId()),
    name: '',
    activities,
    dependencies: activityDependencies,
    calendar: haveSameCalendar ? orders[0].calendar : defaultCalendar,
    defaultDuration: haveSameDuration
      ? activities[0].duration
      : DEFAULT_ACTIVITY_DURATION_IN_DAYS * MINUTES_PER_DAY,
  };

  const tradeSequenceInstanceId = getRandomId();

  const assignments = Array.from(orderIdToActivityIdMapping.entries()).reduce(
    (acc, [orderId, activityId]) => {
      acc.push({ orderId, activityId });
      return acc;
    },
    [] as { orderId: string; activityId: string }[],
  );

  return {
    tradeSequence,
    orderAssignments: { tradeSequenceInstanceId, assignments },
    activitiesHaveSameCalendar: haveSameCalendar,
  };
}

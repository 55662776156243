import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

/** Schedule handling */

export class ConstructionBreakCreatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.CONSTRUCTION_BREAK_CREATED;
}

export class ConstructionBreakOpenedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.CONSTRUCTION_BREAK_OPENED;
}

export class ConstructionBreaksEditedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.CONSTRUCTION_BREAKS_EDITED;

  public constructor(
    public properties: {
      method: 'details' | 'drag_and_drop' | 'resize' | 'keyboard' | 'toolbar';
      count: number;
    },
  ) {
    super();
  }
}

export class ConstructionBreaksDeletedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.CONSTRUCTION_BREAKS_DELETED;

  public constructor(
    public properties: {
      method: 'details' | 'keyboard' | 'toolbar';
      count: number;
    },
  ) {
    super();
  }
}

import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { GuardMeta, useWithMembership } from '@/router/helpers/memberships';
import {
  getProjectsAllOrPickTenantRoute,
  getRouteForMembershipStatus,
} from '@/router/helpers/redirection';

type GuardReturn = (to: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<void>;

export function useCompanyGuard(meta: GuardMeta): GuardReturn {
  return async (to): Promise<any> => {
    const guard = (
      membership: MembershipWithTenant | undefined | null,
      memberships: MembershipWithTenant[],
    ): boolean | RouteLocationRaw => {
      // handle no memberships
      const membershipRedirectResult = getRouteForMembershipStatus(to, membership, memberships);

      if (membershipRedirectResult) return membershipRedirectResult;
      const activeMembership = membership;

      // We expect that either activeMembership is set
      const tempTenantId = activeMembership?.tenant.id;

      if (!activeMembership) {
        return getProjectsAllOrPickTenantRoute(tempTenantId);
      }

      return true;
    };
    return useWithMembership({ to }, meta, guard);
  };
}

import {
  CalendarEntity,
  MilestoneEntity,
  OrderEntity,
  PartialEntity,
  PauseEntity,
} from '@/common/types';
import { UndoRedoActionNames, UndoRedoCommit } from '@/features/undoRedo';
import { generateUndoRedoCommitId } from '@/features/undoRedo/types';

import {
  RemoveEntitiesVariables,
  RescheduleEntitiesVariables,
  RestoreEntitiesVariables,
} from './types';

export const createUndoRedoRescheduleScheduleNodesCommit = (
  vars: RescheduleEntitiesVariables,
  orders: Map<string, OrderEntity>,
  milestones: Map<string, MilestoneEntity>,
  pauses: Map<string, PauseEntity>,
  calendars: Map<string, CalendarEntity>,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RescheduleScheduleNodes,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.RescheduleScheduleNodes,
      data: {
        orders: vars.orders ? getInverseOrderUpdate(vars.orders, orders) : undefined,
        milestones: vars.milestones
          ? getInverseMilestoneUpdate(vars.milestones, milestones)
          : undefined,
        pauses: vars.pauses ? getInversePauseUpdate(vars.pauses, pauses) : undefined,
        calendars: vars.calendars ? getInverseCalendarUpdate(vars.calendars, calendars) : undefined,
      },
    },
  };
};

function getInverseOrderUpdate(
  orderUpdates: PartialEntity<OrderEntity>[],
  orders: Map<string, OrderEntity>,
): PartialEntity<OrderEntity>[] {
  return orderUpdates.map((updatedOrder) => {
    const existingOrder = orders.get(updatedOrder.id);
    if (!existingOrder) {
      throw new Error(`Order with id ${updatedOrder.id} not found`);
    }
    return {
      id: updatedOrder.id,
      startAt: updatedOrder.startAt !== undefined ? existingOrder.startAt : undefined,
      finishAt: updatedOrder.finishAt !== undefined ? existingOrder.finishAt : undefined,
      wbsSection: updatedOrder.wbsSection ? existingOrder.wbsSection : undefined,
    };
  });
}
function getInverseMilestoneUpdate(
  milestoneUpdates: PartialEntity<MilestoneEntity>[],
  milestones: Map<string, MilestoneEntity>,
): PartialEntity<MilestoneEntity>[] {
  return milestoneUpdates.map((updatedMilestone) => {
    const existingMilestone = milestones.get(updatedMilestone.id);
    if (!existingMilestone) {
      throw new Error(`Milestone with id ${updatedMilestone.id} not found`);
    }
    return {
      id: updatedMilestone.id,
      wbsSection:
        updatedMilestone.wbsSection !== undefined ? existingMilestone.wbsSection : undefined,
      date: updatedMilestone.date !== undefined ? existingMilestone.date : undefined,
    };
  });
}
function getInversePauseUpdate(
  pauseUpdates: PartialEntity<PauseEntity>[],
  pauses: Map<string, PauseEntity>,
): PartialEntity<PauseEntity>[] {
  return pauseUpdates.map((updatedPause) => {
    const existingPause = pauses.get(updatedPause.id);
    if (!existingPause) {
      throw new Error(`Pause with id ${updatedPause.id} not found`);
    }
    return {
      id: updatedPause.id,
      start: updatedPause.start !== undefined ? existingPause.start : undefined,
      end: updatedPause.end !== undefined ? existingPause.end : undefined,
    };
  });
}

function getInverseCalendarUpdate(
  calendarUpdates: PartialEntity<CalendarEntity>[],
  calendars: Map<string, CalendarEntity>,
): PartialEntity<CalendarEntity>[] {
  return calendarUpdates.map((updatedCalendar) => {
    const existingCalendar = calendars.get(updatedCalendar.id);
    if (!existingCalendar) {
      throw new Error(`Calendar with id ${updatedCalendar.id} not found`);
    }
    return {
      id: updatedCalendar.id,
      workingDays:
        updatedCalendar.workingDays !== undefined ? existingCalendar.workingDays : undefined,
      exceptions:
        updatedCalendar.exceptions !== undefined ? existingCalendar.exceptions : undefined,
    };
  });
}

export const createUndoRedoRemoveNodesCommit = (input: RemoveEntitiesVariables): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RemoveScheduleNodes,
      data: input,
    },
    inverseAction: {
      name: UndoRedoActionNames.RestoreScheduleNodes,
      data: input,
    },
  };
};

export const createUndoRedoRestoreNodesCommit = (
  input: RestoreEntitiesVariables,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RestoreScheduleNodes,
      data: input,
    },
    inverseAction: {
      name: UndoRedoActionNames.RemoveScheduleNodes,
      data: input,
    },
  };
};

import { ClientProjectChangeMessage } from 'events.schema';

import { LocalProjectChangeEvent, RemoteProjectChangeEvent } from '../types';
import { parseIncomingProjectChangeEvent, parseOutgoingProjectChangeEvent } from './utils';

export interface RTCMessageParser {
  parseOutgoing(event: LocalProjectChangeEvent): ClientProjectChangeMessage;
  parseIncoming(payload: string): RemoteProjectChangeEvent;
}

export class DefaultRTCMessageParser implements RTCMessageParser {
  public parseOutgoing(event: LocalProjectChangeEvent): ClientProjectChangeMessage {
    return parseOutgoingProjectChangeEvent(event);
  }

  public parseIncoming(payload: string): RemoteProjectChangeEvent {
    return parseIncomingProjectChangeEvent(payload);
  }
}

export class PlaywrightRTCMessageParser implements RTCMessageParser {
  public parseOutgoing(event: LocalProjectChangeEvent): ClientProjectChangeMessage {
    const element = document.getElementById('pw-dispatcher');
    element?.dispatchEvent(new PlaywrightSocketIOEvent(event));

    return parseOutgoingProjectChangeEvent(event);
  }

  public parseIncoming(payload: string): RemoteProjectChangeEvent {
    return parseIncomingProjectChangeEvent(payload);
  }
}

export class PlaywrightSocketIOEvent extends Event {
  public static eventName = 'project_change';

  public constructor(public event: LocalProjectChangeEvent) {
    super(PlaywrightSocketIOEvent.eventName);
  }
}

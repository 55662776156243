import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const pausesQuery = gqlFn(/* GraphQL */ `
  query projectPausesV2($project: ID!, $orderBy: [String!]) {
    projectPauses(project: $project, orderBy: $orderBy) {
      edges {
        node {
          ...Pause
        }
      }
    }
  }
`);

export const pauseFragment = gql`
  fragment Pause on LeanProjectPauseNode {
    id
    name
    start
    end
  }
`;

import { useNotificationStore } from '@/features/notifications/notificationStore';
import { SEVEN_SECONDS_IN_MS } from '@/helpers/utils/timeConstants';
import { AnalyticsEvent } from '@/utils/analyticsEvents/categories';
import { support } from '@/utils/pages';

import { ConflictNotificationShownEvent } from './analytics';

export const showUndoRedoFailedNotification = () => {
  showActionFailedNotification(new ConflictNotificationShownEvent({ isUndoRedoConflict: true }));
};

export const showActionFailedNotification = (
  analyticsEvent: AnalyticsEvent = new ConflictNotificationShownEvent({
    isUndoRedoConflict: false,
  }),
) => {
  const notificationStore = useNotificationStore();

  notificationStore.push({
    titleI18nKey: 'objects.realTimeCollaboration.actionFailedNotificationTitle',
    bodyI18nKey: 'objects.realTimeCollaboration.actionFailedNotificationDescription',
    primaryAction: {
      callback: () => {
        notificationStore.closeCurrentNotification();
        window?.open(support.WORKING_TOGETHER, '_blank');
      },
      i18nKey: 'objects.realTimeCollaboration.actionFailedNotificationAction',
    },
    iconProps: { icon: 'info-circle' },
    type: 'attention',
    timeout: SEVEN_SECONDS_IN_MS,
    analyticsEvent,
  });
};

import './main.css';
import './polyfills';
import '../@types/utcDate';

import { createApp } from 'vue';

import TheHeader from '@/components/partials/TheHeader.vue';
import { installDsDirectives } from '@/ds-components/directives/install';

import App from './App.vue';
import { installPlugins } from './plugins';
import { i18nInstance } from './plugins/i18n';
import pinia from './plugins/pinia';

const app = createApp(App);
app.use(pinia);
installPlugins(app);
app.use(i18nInstance);
installDsDirectives(app);

// 2. Assign the global variable before mounting
app.component('TheHeader', TheHeader);

app.mount('#app');

export { app };

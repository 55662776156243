import '@/scss/variables.scss';

import { App } from 'vue';
import { createVuetify, VuetifyOptions } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, md } from 'vuetify/iconsets/md';

import { getThemes } from '@/assets/themes';
import { NODE_ENV } from '@/utils/config';

export function installVuetify(app: App) {
  let vuetifyOptions: VuetifyOptions = {
    theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme: getThemes(),
      },
    },
    display: {
      mobileBreakpoint: 1264,
      thresholds: {
        // https://tailwindcss.com/docs/responsive-design
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
      },
    },
    icons: {
      defaultSet: 'md',
      aliases,
      sets: {
        md,
      },
    },
  };

  // enable treeshaking only on production, to deal with page reloads KOP-110
  if (NODE_ENV === 'development') {
    vuetifyOptions = {
      ...vuetifyOptions,
      components,
      directives,
    };
  }

  const vuetify = createVuetify(vuetifyOptions);
  app.use(vuetify);
}

import { OperationNames } from 'events.schema';

import { sanitizeListWithNamedItems } from '@/common/sanitization/lists';
import { MilestoneEntity, OrderDependencyEntity, PartialEntity } from '@/common/types';
import {
  generateRTCMessageId,
  LocalProjectChangeEventTemplate,
} from '@/features/realTimeCollaboration';
import {
  OperationInputType,
  ProjectChangeEventContext,
} from '@/features/realTimeCollaboration/types';

export const createProjectChangeCreateEvent = (
  vars: MilestoneEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CreateMilestones,
      input: vars.map((milestone) => ({
        id: milestone.id,
        name: milestone.name,
        date: milestone.date.toISOString(),
        /** Color in hex format e.g. '#ff0000'. */
        color: milestone.color,
        wbsSectionId: milestone.wbsSection?.id,
        type: milestone.type,
        criteria:
          milestone.acceptanceCriteria && sanitizeListWithNamedItems(milestone.acceptanceCriteria),
      })),
      context,
    },
  };
};

export const createProjectChangeUpdateEvent = (
  vars: PartialEntity<MilestoneEntity>[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.UpdateMilestones,
      input: vars.map((partialMilestone) => ({
        ...partialMilestone,
        date: partialMilestone.date?.toISOString(),
        wbsSectionId: partialMilestone.wbsSection?.id,
        criteria:
          partialMilestone.acceptanceCriteria &&
          sanitizeListWithNamedItems(partialMilestone.acceptanceCriteria),
        ignoreFixed: true,
        type: partialMilestone.type,
      })),
      context,
    },
  };
};

export const createProjectChangeRestoreEvent = (
  milestones: MilestoneEntity[],
  context?: ProjectChangeEventContext,
  adjacentDependenciesToRestore?: OrderDependencyEntity[],
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RestoreScheduleNodes,
      input: {
        milestones: milestones.map((milestone) => milestone.id),
      },
      context,
    },
    restoredEntities: {
      milestones: milestones.map((milestone) => milestone.id),
      dependencyDetails: adjacentDependenciesToRestore?.map((dependency) => ({
        ...dependency,
        from: { orderId: dependency.from.id, milestoneId: dependency.from.id },
        to: { orderId: dependency.to.id, milestoneId: dependency.to.id },
      })),
    },
  };
};

export const createProjectChangeDeleteEvent = (
  milestones: MilestoneEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RemoveScheduleNodes,
      input: {
        milestones: milestones.map((milestone) => milestone.id),
      },
      context,
    },
  };
};

export const createProjectChangeUpdateMilestoneStatusEvent = (
  vars: OperationInputType<'UpdateMilestoneStatus'>,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.UpdateMilestoneStatus,
      input: vars,
      context,
    },
  };
};

import { WbsSectionEntity } from '@/common/types';
import { getRandomId } from '@/helpers/utils/strings';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';

/**
 * Copies section content and generates new ids
 * for sections.
 * @param projectId
 * @param projectSections
 * @returns
 */
export function copySections(sections: WbsSectionEntity[]) {
  const idMapping: Record<string, string> = {};

  const newSections = sections.map((section) => {
    const newId = toGlobalId(NodeName.SECTION, getRandomId());
    idMapping[section.id] = newId;
    return { ...section, id: newId };
  });

  return newSections.map((s) => ({
    id: s.id,
    name: s.name,
    parentId: s.parentId ? (idMapping[s.parentId] ?? null) : null,
    position: s.position,
  }));
}

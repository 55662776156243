import { Key } from '@/interfaces';
import { AppApolloClient } from '@/interfaces/graphql';
import { GoogleMaps } from '@/plugins/googleMaps';
import { AnalyticsLogger } from '@/plugins/segment';
import { Userback } from '@/plugins/userback';

export class IoCContainer {
  public isSetup(): boolean {
    return this.builderMap != null;
  }

  private builderMap: Record<string, () => any> | null = null;

  private instanceMap: Record<string, any> = {};

  public register<T>(key: Key, builder: () => T): void {
    if (this.builderMap === null) this.builderMap = {};
    if (this.builderMap[key]) return;
    this.builderMap[key] = builder;
  }

  public resolve<T>(key: Key): T | undefined {
    if (this.builderMap === null) return undefined;

    const builder = this.builderMap[key];
    if (!builder) return undefined;

    let instance = this.instanceMap[key];
    if (!instance) {
      instance = builder() as T;
      this.instanceMap[key] = instance;
    }
    return instance;
  }
}

/**
 * Module to register classes in the container
 */
export interface ContainerModule {
  onCreate(
    container: IoCContainer,
    client: AppApolloClient,
    googleMaps: GoogleMaps,
    userback: Userback,
    segment: AnalyticsLogger,
  ): void;
}

import defaultProjectPicture from '@/assets/media/img/default_project_picture.jpeg';
import { Weekday } from '@/features/calendars/calendarTypes';
import { getArrayTo } from '@/helpers/utils/arrays';
import { copy } from '@/helpers/utils/objects';

export const DEFAULT_PROJECT_PICTURE = defaultProjectPicture;

export const defaultWorkingDays = getArrayTo(5) as Weekday[];

export const defaultWorkingTime = {
  from: '09:00',
  to: '17:00',
};

export const defaultFullDayTime = {
  from: '00:00',
  to: '00:00',
};

export const defaultWorkingDaysWithTimes = defaultWorkingDays.map((day) => ({
  weekday: day,
  workingTimes: [copy(defaultWorkingTime)],
}));

export const defaultWorkingDayFullDay = defaultWorkingDays.map((day) => ({
  weekday: day,
  workingTimes: [copy(defaultFullDayTime)],
}));

export const defaultHoursPerDay = 8;

import { DirectiveBinding } from 'vue';

type ClickOutsideElement = HTMLElement & {
  __clickOutside?: {
    lastMouseDownWasOutside: boolean;
    clickHandler: (e: MouseEvent) => void;
    mouseDownHandler: (e: MouseEvent) => void;
  };
};
type ClickOutsideDirectiveBinding = DirectiveBinding<(e: MouseEvent) => void>;

const onClick = (
  el: ClickOutsideElement,
  ev: MouseEvent,
  binding: ClickOutsideDirectiveBinding,
) => {
  if (!el.__clickOutside) return;
  if (el.__clickOutside.lastMouseDownWasOutside && !el.contains(ev.target as Node)) {
    binding.value(ev);
  }
};

const onMouseDown = (el: ClickOutsideElement, ev: MouseEvent) => {
  if (!el.__clickOutside) return;
  el.__clickOutside.lastMouseDownWasOutside = !el.contains(ev.target as Node);
};

export const ClickOutside = {
  mounted(el: ClickOutsideElement, binding: ClickOutsideDirectiveBinding) {
    const windowOnClickHandler = (ev) => onClick(el, ev, binding);
    const windowOnMousedownHandler = (ev) => onMouseDown(el, ev);
    window.addEventListener('click', windowOnClickHandler);
    window.addEventListener('mousedown', windowOnMousedownHandler);
    el.__clickOutside = {
      lastMouseDownWasOutside: false,
      clickHandler: windowOnClickHandler,
      mouseDownHandler: windowOnMousedownHandler,
    };
  },
  unmounted(el: ClickOutsideElement) {
    if (!el.__clickOutside) return;

    window.removeEventListener('click', el.__clickOutside.clickHandler);
    window.removeEventListener('mousedown', el.__clickOutside.mouseDownHandler);
    delete el.__clickOutside;
  },
};

<template>
  <DsIconButton icon="x-close" :size="props.size" :color="props.color" :theme="props.theme" />
</template>

<script setup lang="ts">
import { PropType } from 'vue';

const props = defineProps({
  size: {
    type: String as PropType<'sm' | 'md' | 'lg' | 'xl'>,
    default: 'md',
  },
  color: {
    type: String as PropType<'primary' | 'grey'>,
    default: 'primary',
  },
  theme: {
    type: String as PropType<'light' | 'dark'>,
    default: 'light',
  },
});
</script>

import { App } from 'vue';

import { useMembershipStore } from '@/features/memberships/membershipStore';
import { installRealTimeCollaboration } from '@/features/realTimeCollaboration/install';
import { IoCContainer } from '@/ioc/framework/container';
import { registerMembershipRepository } from '@/ioc/modules/repositories';
import { registerAuthenticationService, registerLoggingService } from '@/ioc/modules/services';
import { installRouter } from '@/router/index';
import { installBulkApiClient } from '@/services/bulkApiClient';
import { registerLoggingServiceComposable } from '@/services/logging/composable';
import { WEBSOCKET_ENDPOINT } from '@/utils/config';
import eventBus from '@/utils/eventBus';

import { installApollo, registerRouter } from './apollo';
import { initializeFirebaseApp } from './firebase';
import { installGoogleMaps } from './googleMaps';
import { installIoCContainer } from './ioc';
import { installJimo } from './jimo/jimo';
import { registerServiceWorker } from './registerServiceWorker';
import { installSentry } from './sentry';
import { installUserback } from './userback';
import { installVuetify } from './vuetify';

export function installPlugins(app: App): void {
  initializeFirebaseApp();

  // installVeeValidate(i18n);

  const container = new IoCContainer();

  const { loggingService, logger, loggingRequiresSentry } = registerLoggingService(container);
  registerLoggingServiceComposable(loggingService);
  const authenticationService = registerAuthenticationService(container, loggingService);

  installRealTimeCollaboration(authenticationService, loggingService, WEBSOCKET_ENDPOINT);

  const { apolloClient } = installApollo(app, authenticationService, eventBus, loggingService);
  installBulkApiClient(authenticationService);
  registerMembershipRepository(container, apolloClient);

  logger?.setAuthenticationService(authenticationService);

  const googleMaps = installGoogleMaps(app);
  installIoCContainer(app, apolloClient, container, googleMaps);

  const membershipStore = useMembershipStore();
  const router = installRouter(app, { membershipStore }, { authenticationService, loggingService });
  registerRouter(router);

  if (loggingRequiresSentry) installSentry(app, router);

  installUserback(app);
  installVuetify(app);

  installJimo(app, eventBus, loggingService);

  registerServiceWorker(loggingService);
}

<template>
  <slot :is-hovering="isHovering" :props="slotProps" />
</template>
<script setup lang="ts">
const isHovering = ref(false);
const isActuallyHovering = ref(false);

const props = defineProps({
  delayMs: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits<{
  (ev: 'hover-start'): void;
  (ev: 'hover-end'): void;
}>();

const slotProps = {
  onmouseover: () => {
    isActuallyHovering.value = true;
    if (!props.delayMs) {
      isHovering.value = true;
      emit('hover-start');
      return;
    }
    window.setTimeout(() => {
      if (isActuallyHovering.value) {
        isHovering.value = isActuallyHovering.value;
        emit('hover-start');
      }
    }, props.delayMs);
  },
  onmouseleave: () => {
    emit('hover-end');
    isActuallyHovering.value = false;
    isHovering.value = false;
  },
};
</script>

<style scoped></style>

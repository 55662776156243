import { addMinutes, isAfter, isBefore, startOfDay } from 'date-fns';
import { intersection, isEqual } from 'lodash';

import {
  ScheduleFilterState,
  useScheduleFilterStore,
} from '@/features/filter-and-export/store/scheduleFilterStore';
import { useMilestoneStoreV2 } from '@/features/milestones';
import { useOrderStoreV2 } from '@/features/orders';
import { usePauseStoreV2 } from '@/features/pauses';
import { useWbsSectionStoreV2 } from '@/features/projectStructure';
import { DateRangeObject } from '@/helpers/utils/dates';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';
import {
  getEmptyResourceId,
  getMainResourceId,
  getPlaceholderEventId,
} from '@/services/store/schedule/parsers/base';
import { SchedulerEvent, SchedulerResource } from '@/services/store/schedule/types';

export function applyFilter(): void {
  const scheduleFilterStore = useScheduleFilterStore();

  if (
    !scheduleFilterStore.isPermanentFilterActive &&
    !scheduleFilterStore.isTemporaryFilterActive
  ) {
    const scheduler = getScheduler();
    scheduler?.eventStore.clearFilters();
    scheduler?.resourceStore.clearFilters();
    return;
  }

  filterEvents(scheduleFilterStore.currentFilter);
  filterResources(scheduleFilterStore.currentFilter);
}

function identifyEntityDatesAndSection(
  entityId: string,
  entityType: NodeName.ORDER | NodeName.MILESTONE | NodeName.PAUSE,
) {
  let startDate: Date = new Date();
  let endDate: Date = new Date();
  let sectionId: string = '';
  if (entityType === NodeName.ORDER) {
    const orderStore = useOrderStoreV2();
    const order = orderStore.orders.get(entityId)!;
    startDate = order.startAt;
    endDate = addMinutes(order.finishAt, order.dryingBreak?.duration ?? 0);
    sectionId = order.wbsSection.id;
  } else if (entityType === NodeName.MILESTONE) {
    const milestoneStore = useMilestoneStoreV2();
    const milestone = milestoneStore.milestones.get(entityId)!;
    startDate = milestone.date;
    endDate = milestone.date;
    sectionId = milestone.wbsSection?.id ?? '';
  } else if (entityType === NodeName.PAUSE) {
    const pauseStore = usePauseStoreV2();
    const pause = pauseStore.pauses.get(entityId)!;
    startDate = pause.start;
    endDate = pause.end;
  }
  return { startDate, endDate, sectionId };
}

export function entityIsVisible(
  entityId: string,
  entityType: NodeName.ORDER | NodeName.MILESTONE | NodeName.PAUSE,
  filterState: ScheduleFilterState,
): boolean {
  const { startDate, endDate } = identifyEntityDatesAndSection(entityId, entityType);

  if (filterState.temporaryFilter.range?.start && filterState.temporaryFilter.range?.end) {
    if (
      !doesEventOverlapWithRange(
        startDate,
        endDate,
        filterState.temporaryFilter.range.start,
        filterState.temporaryFilter.range.end,
      )
    ) {
      return false;
    }
  }

  if (entityType === NodeName.ORDER) {
    const orderStore = useOrderStoreV2();
    const order = orderStore.orders.get(entityId)!;

    let isAllowed = true;
    if (filterState.temporaryFilter.contributorGroups?.length) {
      isAllowed = filterState.temporaryFilter.contributorGroups.includes(
        order?.contributorGroup?.id ?? '',
      );
    }
    if (filterState.temporaryFilter.trades?.length) {
      isAllowed =
        isAllowed &&
        filterState.temporaryFilter.trades.includes(order?.tenantTradeVariation?.id ?? '');
    }
    return isAllowed;
  }
  return true;
}

function sectionIsVisible(
  sectionId: string,
  allSectionsWithEvents: Set<string>,
  filterState: ScheduleFilterState,
): boolean {
  let allowedSections: string[] = [];
  const sectionStore = useWbsSectionStoreV2();
  const section = sectionStore.wbsSections.get(sectionId)!;
  const children = sectionStore.getOrderedWbsSectionsTree().get(sectionId)?.children ?? [];

  if (
    !filterState.permanentFilter.sections.length ||
    !filterState.temporaryFilter.sections.length
  ) {
    allowedSections = [
      ...filterState.temporaryFilter.sections,
      ...filterState.permanentFilter.sections,
    ];
  } else {
    allowedSections = intersection(
      filterState.temporaryFilter.sections,
      filterState.permanentFilter.sections,
    );
  }

  const shouldShowSection = (sectionId: string) => {
    let isAllowed = true;

    if (allowedSections.length) {
      isAllowed = allowedSections.includes(sectionId);
    }
    if (
      filterState.temporaryFilter.contributorGroups.length ||
      filterState.temporaryFilter.trades.length
    ) {
      return isAllowed && allSectionsWithEvents.has(sectionId);
    }
    return isAllowed;
  };

  return shouldShowSection(section.id) || children.some((child) => shouldShowSection(child.id));
}

/**
 * returns true if event (i.e.: order, milestone etc.) partially
 * overlaps within the provided range
 * CASE 1 -> returns true
 * order:        ---------
 * range:             ---------
 *
 * CASE 2 -> returns true
 * order:        ---------
 * range:          ----
 *
 * CASE 3 -> returns false
 * order:        ---------
 * range:                  ----
 */
export function doesEventOverlapWithRange(
  eventStart: Date,
  eventEnd: Date,
  rangeStart?: string,
  rangeEnd?: string,
) {
  if (!rangeStart || !rangeEnd) return true;
  if (!isBefore(eventStart, startOfDay(new SchedulingDate(rangeEnd)))) return false;

  // end date usually is exclusive, except when start and end date are identical
  if (isEqual(eventStart, eventEnd)) {
    if (isBefore(eventEnd, startOfDay(new SchedulingDate(rangeStart)))) return false;
  } else {
    if (!isAfter(eventEnd, startOfDay(new SchedulingDate(rangeStart)))) return false;
  }
  return true;
}

function filterEvents(currentFilter: ScheduleFilterState): void {
  const scheduler = getScheduler();

  scheduler?.eventStore.filter({
    filters: (event: SchedulerEvent) => {
      if (event.id === getPlaceholderEventId()) {
        return true;
      }
      if (event.entity === NodeName.SECTION || event.entity === NodeName.SECTION_BASEPLAN) {
        return doesEventOverlapWithRange(
          new SchedulingDate(event.startDate),
          new SchedulingDate(event.endDate),
          currentFilter.temporaryFilter.range?.start,
          currentFilter.temporaryFilter.range?.end,
        );
      }
      if (event.entity === NodeName.DRYING_BREAK) {
        const orderId = DryingBreakEventParser.dryingBreakIdToOrderId(event.id);
        return entityIsVisible(orderId, NodeName.ORDER, currentFilter);
      }
      return entityIsVisible(
        event.id,
        event.entity as NodeName.ORDER | NodeName.MILESTONE | NodeName.PAUSE,
        currentFilter,
      );
    },
    replace: true,
  });
}

function filterResources(currentFilter: ScheduleFilterState): void {
  const scheduler = getScheduler();
  if (!scheduler) return;

  const resourcesWithEvents = getResourcesWithEvents();
  // NOTE: We clear old filters first, otherwise, it messes up positioning of resources sometimes
  scheduler.resourceStore.clearFilters().then(() => {
    scheduler.resourceStore.filter({
      filters: (resource: SchedulerResource) => {
        if (resource.id === getMainResourceId() || resource.id === getEmptyResourceId()) {
          return true;
        }
        return sectionIsVisible(resource.id, resourcesWithEvents, currentFilter);
      },
      replace: true,
    });
  });
}

function getResourcesWithEvents(): Set<string> {
  const scheduler = getScheduler();
  if (!scheduler) return new Set();

  const visibleTimeSpan = scheduler.eventStore.getTotalTimeSpan() as DateRangeObject;
  if (!visibleTimeSpan.startDate || !visibleTimeSpan.endDate) return new Set();
  const visibleEvents = (
    scheduler.eventStore.getEvents(visibleTimeSpan) as SchedulerEvent[]
  ).filter((e) => e.entity === NodeName.ORDER);
  return new Set(visibleEvents.map((event) => event.resourceId as string));
}

export const checkIfEventIsFilteredOut = (
  entityId: string,
  entityType: NodeName.ORDER | NodeName.MILESTONE | NodeName.PAUSE,
): boolean => {
  const scheduleFilterStore = useScheduleFilterStore();

  if (
    !scheduleFilterStore.isTemporaryFilterActive &&
    !scheduleFilterStore.isPermanentFilterActive
  ) {
    return false;
  }

  const scheduler = getScheduler();
  if (!scheduler) return true;

  const entityIsVisibleInSchedule = entityIsVisible(
    entityId,
    entityType,
    scheduleFilterStore.currentFilter,
  );
  if (!entityIsVisibleInSchedule) return true;

  const { sectionId } = identifyEntityDatesAndSection(entityId, entityType);
  // events with no section assignment are always visible
  if (!sectionId) return false;

  const sectionIsVisibleInSchedule = sectionIsVisible(
    sectionId,
    getResourcesWithEvents(),
    scheduleFilterStore.currentFilter,
  );
  if (!sectionIsVisibleInSchedule) return true;

  return false;
};

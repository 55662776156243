import { TradeSequenceEntity } from '@/common/types';
import { getRandomId } from '@/helpers/utils/strings';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';

export function duplicateProjectTradeSequence(
  tradeSequence: TradeSequenceEntity,
): TradeSequenceEntity {
  const oldIdToNewId = new Map<string, string>();

  const regeneratedActivities = tradeSequence.activities.map((activity) => {
    const newId = toGlobalId(NodeName.TRADE_SEQUENCE_ACTIVITY, getRandomId());
    oldIdToNewId.set(activity.id, newId);
    return {
      ...activity,
      id: newId,
      taskTemplates: activity.taskTemplates.map((task) => ({
        ...task,
      })),
    };
  });

  const regeneratedDependencies = tradeSequence.dependencies.map((dependency) => ({
    ...dependency,
    id: toGlobalId(NodeName.TRADE_SEQUENCE_DEPENDENCY, getRandomId()),
    from: { ...dependency.from, id: oldIdToNewId.get(dependency.from.id)! },
    to: { ...dependency.to, id: oldIdToNewId.get(dependency.to.id)! },
  }));

  return {
    ...tradeSequence,
    id: toGlobalId(NodeName.TRADE_SEQUENCE, getRandomId()),
    activities: regeneratedActivities,
    dependencies: regeneratedDependencies,
  };
}

import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

/** Schedule handling */

export class ProjectAlternativeModeEntered extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.PROJECT_ALTERNATIVE_MODE_ENTERED;
}

export class ProjectAlternativeModeLeft extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.PROJECT_ALTERNATIVE_MODE_LEFT;
}

export class ProjectAlternativeCreated extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PROJECT_ALTERNATIVE_CREATED;

  public constructor(
    public properties: {
      source: 'alternative' | 'live';
      name: string;
    },
  ) {
    super();
  }
}

export class ProjectAlternativeArchived extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PROJECT_ALTERNATIVE_ARCHIVED;

  public constructor(
    public properties: {
      nextAction: 'switch_alternative' | 'exit_planning_mode';
    },
  ) {
    super();
  }
}

export class ProjectAlternativeEdited extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PROJECT_ALTERNATIVE_DESCPRIPTION_EDITED;
}

export class ProjectAlternativeActivated extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PROJECT_ALTERNATIVE_ACTIVATED;

  public constructor(
    public properties: {
      age_in_days: number;
    },
  ) {
    super();
  }
}

export class ProjectAlternativeSwitched extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PROJECT_ALTERNATIVE_SWITCHED;

  public constructor(
    public properties: {
      from: string;
      to: string;
    },
  ) {
    super();
  }
}

import { WbsSectionEntity } from '@/common/types';

type SectionInput = WbsSectionEntity | string;
export type SectionsInput = WbsSectionEntity[] | Map<string, WbsSectionEntity>;

export const getSections = (sectionsInput: SectionsInput): WbsSectionEntity[] => {
  return Array.from(sectionsInput.values());
};

export const getSectionsMap = (sectionsInput: SectionsInput): Map<string, WbsSectionEntity> => {
  return sectionsInput instanceof Map
    ? sectionsInput
    : new Map(sectionsInput.map((s) => [s.id, s]));
};

export const getSection = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
): WbsSectionEntity => {
  if (typeof sectionInput === 'string') {
    const actualSection = getSections(sectionsInput).find((s) => s.id === sectionInput) ?? null;
    if (!actualSection) {
      throw new Error(`Section ${sectionInput} not found`);
    }
    return actualSection;
  }
  return sectionInput;
};

export const getSectionChildren = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
): WbsSectionEntity[] => {
  const sections = getSections(sectionsInput);
  const section = getSection(sectionsInput, sectionInput);
  return sections.filter((s) => s.parentId === section.id);
};

export const getSectionSiblings = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
): WbsSectionEntity[] => {
  const sections = getSections(sectionsInput);
  const section = getSection(sectionsInput, sectionInput);
  if (!section.parentId) return sections.filter((s) => !s.parentId);
  return getSectionChildren(sections, section.parentId);
};

export const getSectionSiblingsByParentId = (
  sectionsInput: SectionsInput,
  parentId: string | null,
): WbsSectionEntity[] => {
  const sections = getSections(sectionsInput);
  if (parentId === null) return sections.filter((s) => !s.parentId);
  return getSectionChildren(sections, parentId);
};

export const getSectionAncestors = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
  includeGivenSection = true,
): WbsSectionEntity[] => {
  const sections = getSectionsMap(sectionsInput);
  const section = getSection(sectionsInput, sectionInput);
  if (!section) return [];

  const ancestors: WbsSectionEntity[] = includeGivenSection ? [section] : [];
  let { parentId } = section;

  while (parentId) {
    const parent = sections.get(parentId);
    if (!parent) break;

    ancestors.unshift(parent);
    parentId = parent.parentId;
  }

  return ancestors;
};

export const getSectionDescendants = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
  includeGivenSection = true,
): WbsSectionEntity[] => {
  const sections = getSections(sectionsInput);
  const section = getSection(sectionsInput, sectionInput);

  return [
    ...(includeGivenSection ? [section] : []),
    ...getSectionChildren(sections, section).flatMap((child) =>
      getSectionDescendants(sections, child),
    ),
  ];
};

export const getSectionRoot = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
): WbsSectionEntity => {
  const section = getSection(sectionsInput, sectionInput);
  if (!section.parentId) return section;
  const parent = getSection(sectionsInput, section.parentId);
  if (!parent) return section;
  return getSectionRoot(sectionsInput, parent);
};

export const getSectionDepth = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
): number => {
  const children = getSectionChildren(sectionsInput, sectionInput);
  if (!children.length) {
    return 0;
  }
  const childDepths = children.map((child) => getSectionDepth(sectionsInput, child));
  return Math.max(...childDepths) + 1;
};

export const getSectionIndentation = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
): number => {
  let indentation = 0;
  let currentSection: WbsSectionEntity | null = getSection(sectionsInput, sectionInput);

  while (currentSection?.parentId) {
    indentation++;
    currentSection = getSection(sectionsInput, currentSection?.parentId ?? '') ?? null;
  }

  return indentation;
};

export const getSectionDescendantsByIndentation = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
): Record<number, WbsSectionEntity[]> => {
  const sections = getSections(sectionsInput);
  const section = getSection(sectionsInput, sectionInput);
  let descendantsMap: Record<number, WbsSectionEntity[]> = {};

  const children = getSectionChildren(sections, section);
  children.forEach((child) => {
    const childIndentation = getSectionIndentation(sections, child);
    if (!descendantsMap[childIndentation]) {
      descendantsMap[childIndentation] = [];
    }
    descendantsMap[childIndentation].push(child);
    descendantsMap = {
      ...descendantsMap,
      ...getSectionDescendantsByIndentation(sections, child),
    };
  });
  return descendantsMap;
};

export const getInvalidSectionChildren = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
): WbsSectionEntity[] => {
  const children = getSectionChildren(sectionsInput, sectionInput);
  const depths = children.map((child) => getSectionDepth(sectionsInput, child));
  const minDepth = Math.min(...depths);
  return minDepth === 0 ? children.filter((_, i) => depths[i] > minDepth) : [];
};

export const isValidSection = (
  sectionsInput: SectionsInput,
  sectionInput: SectionInput,
): boolean => {
  return (
    !getInvalidSectionChildren(sectionsInput, sectionInput).length &&
    getSectionChildren(sectionsInput, sectionInput).every((child) => {
      return isValidSection(sectionsInput, child);
    })
  );
};

export const sanitizeSections = (sectionsInput: SectionsInput): WbsSectionEntity[] => {
  const sorted: WbsSectionEntity[] = [];
  const updatedSections = getSections(sectionsInput);

  const addToSortedStructure = (section: WbsSectionEntity, idx: number): void => {
    sorted.push({
      ...section,
      position: idx,
    });
    const children = updatedSections.filter((s) => section.id === s.parentId);
    children.forEach(addToSortedStructure);
  };

  const topLevelSections = updatedSections.filter((s) => !s.parentId);

  topLevelSections.forEach(addToSortedStructure);
  return sorted;
};

export const getFlatSortedSections = (sectionsInput: SectionsInput): WbsSectionEntity[] => {
  const sections = getSections(sectionsInput);
  const noParentId = '';

  // Precompute children for each section to avoid repeated filtering
  const childrenMap = new Map<string, WbsSectionEntity[]>();
  childrenMap.set(noParentId, []);
  sections.forEach((section) => {
    const id = section.parentId ?? noParentId;
    if (!childrenMap.has(id)) {
      childrenMap.set(id, []);
    }
    const children = childrenMap.get(id);

    if (children) {
      children.push(section);
    }
  });

  // Sort children for each parent once
  childrenMap.forEach((children) => {
    children.sort((a, b) => a.position - b.position);
  });

  const sorted: WbsSectionEntity[] = [];

  const addToSortedStructure = (section: WbsSectionEntity): void => {
    sorted.push(section);
    const children = childrenMap.get(section.id);
    if (children) {
      children.forEach(addToSortedStructure);
    }
  };

  const children = childrenMap.get(noParentId);
  if (children) {
    children.forEach(addToSortedStructure);
  }

  return sorted;
};

export const isActivityLevelWbsSection = (
  sectionInput: SectionInput,
  sectionsInput: SectionsInput,
): boolean => {
  return !getSectionChildren(sectionsInput, sectionInput).length;
};

import {
  Entity,
  InteractiveEntityChanges,
  InteractiveEntityMaps,
  InteractiveEntityStores,
} from '@/common/types';

export function copyStoreStates(entityStores: InteractiveEntityStores): InteractiveEntityMaps {
  return {
    pauses: entityStores.pauseStore().copyState(),
    milestones: entityStores.milestoneStore().copyState(),
    wbsSections: entityStores.wbsSectionStore().copyState(),
    orders: entityStores.orderStore().copyState(),
    calendars: entityStores.calendarStore().copyState(),
    dependencies: entityStores.orderDependencyStore().copyState(),
    tradeSequences: entityStores.projectTradeSequenceStore().copyState(),
    orderStatus: new Map(),
    projectAlternatives: entityStores.projectAlternativeStore().copyState(),
    projects: entityStores.projectStore().copyState(),
  };
}

export function setStoreStates(
  entityStores: InteractiveEntityStores,
  states: InteractiveEntityMaps,
): void {
  entityStores
    .wbsSectionStore()
    .setState(
      states.wbsSections,
      entityStores.orderStore().copyState(),
      entityStores.milestoneStore().copyState(),
    );
  entityStores.pauseStore().setState(states.pauses);
  entityStores.milestoneStore().setState(states.milestones);
  entityStores.orderStore().setState(states.orders);
  entityStores.orderStore().setOrderStatusState(states.orderStatus);
  entityStores.calendarStore().setState(states.calendars);
  entityStores.projectTradeSequenceStore().setState(states.tradeSequences);
  entityStores.orderDependencyStore().setState(states.dependencies);
  entityStores.projectStore().setState(states.projects);
  entityStores.projectAlternativeStore().setState(states.projectAlternatives);
}

export function copyStateMaps(stateMaps: InteractiveEntityMaps): InteractiveEntityMaps {
  return {
    pauses: new Map(stateMaps.pauses),
    wbsSections: new Map(stateMaps.wbsSections),
    orders: new Map(stateMaps.orders),
    calendars: new Map(stateMaps.calendars),
    tradeSequences: new Map(stateMaps.tradeSequences),
    milestones: new Map(stateMaps.milestones),
    dependencies: new Map(stateMaps.dependencies),
    orderStatus: new Map(stateMaps.orderStatus),
    projects: new Map(stateMaps.projects),
    projectAlternatives: new Map(stateMaps.projectAlternatives),
  };
}

export function updateStateMaps(
  stateMaps: InteractiveEntityMaps,
  changes: InteractiveEntityChanges,
): InteractiveEntityMaps {
  if (changes.add) {
    Object.entries(changes.add).forEach(([entityKey, entities]) => {
      if (!entities) return;
      (entities as Entity[]).forEach((entity) => {
        stateMaps[entityKey]?.set(entity.id, entity);
      });
    });
  }
  if (changes.update) {
    Object.entries(changes.update).forEach(([entityKey, entities]) => {
      if (!entities) return;
      (entities as Entity[]).forEach((entity) => {
        stateMaps[entityKey]?.set(entity.id, {
          ...stateMaps[entityKey].get(entity.id)!,
          ...entity,
        });
      });
    });
  }
  if (changes.delete) {
    Object.entries(changes.delete).forEach(([entityKey, entities]) => {
      if (!entities) return;
      entities.forEach((entity) => {
        stateMaps[entityKey]?.delete(entity.id);
      });
    });
  }

  return stateMaps;
}

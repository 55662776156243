import { MilestoneEntity, PartialEntity } from '@/common/types';
import { UndoRedoCommit } from '@/features/undoRedo';
import { generateUndoRedoCommitId, UndoRedoActionNames } from '@/features/undoRedo/types';
import { pickKeys } from '@/helpers/utils/objects';

export const createUndoRedoCreateCommit = (vars: MilestoneEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.CreateMilestones,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteMilestones,
      data: vars,
    },
  };
};

export const createUndoRedoUpdateCommit = (
  vars: PartialEntity<MilestoneEntity>[],
  milestones: Map<string, MilestoneEntity>,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.UpdateMilestones,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.UpdateMilestones,
      data: vars.map((milestone) => {
        const existingMilestone = milestones.get(milestone.id);
        if (!existingMilestone) {
          throw new Error('Milestone not found');
        }
        return pickKeys(existingMilestone, Object.keys(milestone) as (keyof MilestoneEntity)[]);
      }),
    },
  };
};

export const createUndoRedoRestoreCommit = (vars: MilestoneEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RestoreMilestones,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteMilestones,
      data: vars,
    },
  };
};

export const createUndoRedoDeleteCommit = (vars: MilestoneEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.DeleteMilestones,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.RestoreMilestones,
      data: vars,
    },
  };
};

import { SchedulerPro } from '@bryntum/schedulerpro';
import { ref } from 'vue';

import { SchedulerProInstance } from '@/services/store/schedule/types';

/**
 * Binding the scheduler directly to the pinia store creates a memory leak and
 * hinders the store from persisting correctly.
 * As a workaround, we use a shared ref to access the instance anyway.
 */
export const schedulerRef = ref<SchedulerProInstance | null>(null);

export function toggleCssClassForSchedule(className: string, applyClass: boolean): void {
  const scheduler = schedulerRef.value?.$el;
  if (!scheduler) return;

  if (applyClass) {
    scheduler.classList.add(className);
  } else {
    scheduler.classList.remove(className);
  }
}

export function setCssVariableForSchedule(name: string, value: string): void {
  const scheduler = schedulerRef.value?.$el;
  if (!scheduler) return;

  scheduler.style.setProperty(name, value);
}

export function getScheduler(): SchedulerPro | undefined {
  return schedulerRef.value?.instance?.value;
}

export function getSchedulerHtmlElement(): HTMLDivElement | undefined {
  return schedulerRef.value?.$el;
}

import { FetchResult } from '@apollo/client/core';
import { computed, Ref, ref, watch } from 'vue';

import { buildAddress } from '@/helpers/address';
import { MutateOptions } from '@/interfaces/repositories/base';
import { Address, OmitAddress } from '@/interfaces/repositories/utility';

/**
 * Converts the mutate function to only accept defined input
 * @param mutate Function to be wrapped
 */
export function getTypedMutate<TMapped, TRoot, TReturn = FetchResult>(
  mutate: (vars?: TRoot, options?: MutateOptions) => Promise<TReturn | null>,
  additionalVars?: Partial<Extract<TRoot, TMapped>>,
): (vars?: TMapped, options?: MutateOptions) => Promise<TReturn | null> {
  return (newVars?: TMapped, options?: MutateOptions): Promise<TReturn | null> =>
    mutate(
      newVars ? { ...newVars, ...(additionalVars as Extract<TRoot, TMapped>) } : undefined,
      options,
    );
}

/** Removes address input from top level and replaces it with an additional adress layer */
export function omitAndReplaceAddress<T extends { address?: Partial<Address> | undefined | null }>(
  data: T,
): OmitAddress<T> {
  return {
    ...data,
    address: buildAddress(data.address ?? undefined),
  };
}

/** Returns boolean value that only returns true, if given skip condition returns true. Enables execution, if variables passed to refetch function, it overwrites the base vars.
 *  Can be used with `enabled` property of `useQuery` and `useMutation`.
 * @param variables variables encapsulating the id to be queried from
 * @param skipCondition reactive boolean used for skip indicator
 */
export function useSkipExecution<TVars, TReturn = Dictionary>(
  variables: Ref<TVars>,
  { skipCondition }: { skipCondition?: Ref<boolean> },
): {
  enabled?: Ref<boolean>;
  getRefetch: (
    refetch: (vars?: TVars) => Promise<TReturn> | undefined,
  ) => (vars?: TVars) => Promise<TReturn> | undefined;
  getMutate: (
    mutate: (vars?: TVars, options?: MutateOptions) => Promise<TReturn>,
  ) => (vars?: TVars, options?: MutateOptions) => Promise<TReturn>;
} {
  const enabled = computed(() => (skipCondition ? !skipCondition.value : true));

  return {
    enabled,
    getRefetch:
      (
        refetch: (vars?: TVars) => Promise<TReturn> | undefined,
      ): ((vars?: TVars) => Promise<TReturn> | undefined) =>
      (vars?: TVars): Promise<TReturn> | undefined => {
        if (vars) variables.value = vars;
        return refetch(vars);
      },
    getMutate:
      (
        mutate: (vars?: TVars, options?: MutateOptions) => Promise<TReturn>,
      ): ((vars?: TVars, options?: MutateOptions) => Promise<TReturn>) =>
      (vars?: TVars, options?: MutateOptions): Promise<TReturn> => {
        if (vars) variables.value = vars;
        return enabled.value ? mutate(vars, options) : Promise.resolve({} as TReturn);
      },
  };
}

/** Returns indicator, if the related query already was executed. Requires onResult callback function to be present.
 * @param onResult on result handler of useQuery function
 */
export function useCalledIndicator<TQuery>(result: Ref<TQuery>): { called: Ref<boolean> } {
  const called = ref(false);

  /** set called to true on result, which will also be fired if data was read from cache */
  watch(
    result,
    (newResult) => {
      /** skip setting query as called if no data present, which means there is no data in cache and the network request did not finish yet */
      if (!newResult) return;
      called.value = true;
    },
    {
      immediate: true,
    },
  );

  return {
    called,
  };
}

export const sanitizeListWithNamedItems = <T extends { name: string; sequenceNumber: number }>(
  sequence: T[],
) => {
  return sequence
    .map((item) => ({
      ...item,
      name: item.name.trim(),
    }))
    .filter((item) => !!item.name)
    .map((item, sequenceNumber) => ({
      ...item,
      sequenceNumber,
    }));
};

import { COMPRESSION_OPTIONS } from '@/helpers/files/config';
import { CompressionOptions, CompressionService } from '@/interfaces/services/compression';

export class ImageCompressionService implements CompressionService {
  public compress(image: File, options: CompressionOptions = COMPRESSION_OPTIONS): Promise<File> {
    // lazy import the compression module to reduce initial memory footprint
    return import('browser-image-compression').then((imageCompression) =>
      imageCompression.default(image, options),
    );
  }
}

export const DAYS_PER_YEAR = 365;
export const DAYS_PER_MONTH = 30;
export const DAYS_PER_WEEK = 7;
export const HOURS_PER_DAY = 24;
export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = 3600;
export const SECONDS_PER_DAY = HOURS_PER_DAY * SECONDS_PER_HOUR;
export const MINUTES_PER_HOUR = 60;
export const MINUTES_PER_DAY = HOURS_PER_DAY * MINUTES_PER_HOUR;
export const MINUTES_PER_WEEK = MINUTES_PER_DAY * DAYS_PER_WEEK;
export const MINUTES_PER_YEAR = MINUTES_PER_DAY * DAYS_PER_YEAR;
export const MILLISECONDS_PER_SECOND = 1000;

/**
 * Maximimum range in ms covered by js to each side of the base side.
 * See: https://262.ecma-international.org/5.1/#sec-15.9.1.1
 */
export const MAX_JS_DATE_RANGE_IN_MS = 864 * 10 ** 13;

export const ISO_WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const SEVEN_SECONDS_IN_MS = MILLISECONDS_PER_SECOND * 7;

export const FIVE_MINUTES_IN_MS = MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * 5;

import { Operation } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import {
  DefaultApolloClient,
  provideApolloClient,
  useApolloClient as apolloClientResolver,
} from '@vue/apollo-composable';
import { print } from 'graphql/language/printer';
import { App } from 'vue';
import { Router } from 'vue-router';

import { ApolloClientFactory } from '@/graphql/apollo/factory';
import { AppApolloClient } from '@/interfaces/graphql';
import { AuthenticationService, LoggingService } from '@/interfaces/services';
import { useRouteNames } from '@/router/helpers/routeNames';
import { AppErrorCode, GraphQLError, GraphQLErrorCode } from '@/utils/errors';
import { EventBus } from '@/utils/eventBus';

let router: Router | null = null;

export function registerRouter(newRouter: Router): void {
  router = newRouter;
}

export function installApollo(
  app: App,
  authenticationService: AuthenticationService,
  eventBus: EventBus,
  loggingService: LoggingService,
): {
  apolloClient: AppApolloClient;
} {
  const { Routes } = useRouteNames();
  function getOperationErrorContext({ operationName, variables, query }: Operation) {
    return {
      operationName,
      variables: JSON.stringify(variables),
      query: print(query),
    };
  }

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      const isUnauthenticated = graphQLErrors.some(
        (error) => error.extensions?.code === GraphQLErrorCode.UNAUTHENTICATED,
      );

      graphQLErrors.forEach((error) => {
        const code = error.extensions?.code as string;

        // for having better errors in sentry
        const parsedError = new GraphQLError({
          message: error.message,
          code,
          operationName: operation.operationName,
        });

        loggingService.error(parsedError, {
          code: error.extensions?.code as string,
          contexts: {
            operation: getOperationErrorContext(operation),
          },
        });
      });

      if (isUnauthenticated) {
        authenticationService.logout().finally(() => {
          router?.push({ name: Routes.Unauthenticated.Login });
        });
        return;
      }
    }

    if (networkError) {
      loggingService.error(networkError, {
        code: AppErrorCode.NETWORK,
        contexts: {
          operation: getOperationErrorContext(operation),
        },
      });
    }
  });

  const apolloClient = new ApolloClientFactory(
    authenticationService,
    eventBus,
    errorLink,
  ).getClient()!;

  provideApolloClient(apolloClient);
  app.provide(DefaultApolloClient, apolloClient);

  return {
    apolloClient,
  };
}

export function useApolloClient(): AppApolloClient {
  return apolloClientResolver().resolveClient();
}

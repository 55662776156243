import { defineStore } from 'pinia';

import {
  Entity,
  InteractiveEntityChanges,
  InteractiveEntityStores,
  OrderDependencyEntity,
  PartialEntity,
  WrapRef,
} from '@/common/types';
import { convertBulkOrderDependencyToOrderDependencyEntity } from '@/features/orderDependencies/orderDependencyUtils';
import { getBulkApiClient } from '@/services/bulkApiClient';

import { ProjectChangeEventContext } from '../realTimeCollaboration/types';
import { pushLocalChangeEventAndCommit } from '../realTimeCollaboration/utils';
import { UndoRedoCommit } from '../undoRedo/types';
import {
  createUndoRedoCreateCommit,
  createUndoRedoDeleteCommit,
  createUndoRedoRestoreCommit,
  createUndoRedoUpdateCommit,
} from './orderDependencyCommits';
import {
  createProjectChangeCreateEvent,
  createProjectChangeDeleteEvent,
  createProjectChangeRestoreEvent,
  createProjectChangeUpdateEvent,
} from './orderDependencyEvents';
import { OrderDependencyStoreV2 } from './types';

const createRelationKey = (fromId: string, toId: string) => `${fromId}__${toId}`;

export const useOrderDependencyStoreV2 = defineStore(
  'order-dependency-store-v2',
  (): WrapRef<OrderDependencyStoreV2, 'dependencies' | 'relations' | 'fetchAllPromise'> => {
    const dependencies = ref(new Map<string, OrderDependencyEntity>());
    const deletedDependencies = ref(new Map<string, OrderDependencyEntity>());
    const dependencyList = computed(() => Array.from(dependencies.value.values()));

    const relations = computed(
      () =>
        new Set(
          dependencyList.value.map((orderDependency) =>
            createRelationKey(orderDependency.from.id, orderDependency.to.id),
          ),
        ),
    );

    const create = async (
      vars: OrderDependencyEntity[],
      createCommit = true,
      entityStores: Pick<InteractiveEntityStores, 'orderStore' | 'milestoneStore'>,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeCreateEvent(vars, context);
      const commit = createUndoRedoCreateCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: copyState(),
        orders: entityStores.orderStore().copyState(),
        milestones: entityStores.milestoneStore().copyState(),
        sendElementContext: vars.length === 1,
        injectWorkingTimeDuration: (o) => entityStores.orderStore().injectWorkingTimeDuration(o),
      });
    };

    const update = async (
      vars: PartialEntity<OrderDependencyEntity>[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeUpdateEvent(vars, context);
      const commit = createUndoRedoUpdateCommit(vars, dependencies.value);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: copyState(),
      });
    };

    const restore = async (
      vars: OrderDependencyEntity[],
      createCommit = true,
      entityStores: Pick<InteractiveEntityStores, 'orderStore' | 'milestoneStore'>,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeRestoreEvent(vars, context);
      const commit = createUndoRedoRestoreCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: copyState(),
        orders: entityStores.orderStore().copyState(),
        milestones: entityStores.milestoneStore().copyState(),
        sendElementContext: vars.length === 1,
        injectWorkingTimeDuration: (o) => entityStores.orderStore().injectWorkingTimeDuration(o),
      });
    };

    const remove = async (
      vars: OrderDependencyEntity[],
      createCommit = true,
      entityStores: Pick<InteractiveEntityStores, 'orderStore' | 'milestoneStore'>,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeDeleteEvent(vars, context);
      const commit = createUndoRedoDeleteCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: copyState(),
        orders: entityStores.orderStore().copyState(),
        milestones: entityStores.milestoneStore().copyState(),
        sendElementContext: vars.length === 1,
        injectWorkingTimeDuration: (o) => entityStores.orderStore().injectWorkingTimeDuration(o),
      });
    };

    const fetchAllPromise: Ref<Promise<OrderDependencyEntity[]> | null> = ref(null);

    const fetchAll = async (projectId: string): Promise<OrderDependencyEntity[]> => {
      const client = getBulkApiClient();

      fetchAllPromise.value = client.getDependencies(projectId).then((bulkOrderDependencies) => {
        const newOrderDependencies = bulkOrderDependencies.map<OrderDependencyEntity>(
          convertBulkOrderDependencyToOrderDependencyEntity,
        );

        dependencies.value = new Map(
          newOrderDependencies.map((orderDependency) => [orderDependency.id, orderDependency]),
        );
        return newOrderDependencies;
      });

      return fetchAllPromise.value;
    };

    const reset = () => {
      dependencies.value = new Map();
      fetchAllPromise.value = null;
    };

    const setState = (state?: Map<string, OrderDependencyEntity>) => {
      if (!state) {
        return;
      }
      state.forEach((orderDependency) => {
        if (deletedDependencies.value.has(orderDependency.id)) {
          deletedDependencies.value.set(orderDependency.id, orderDependency);
        }
      });
      dependencies.value.forEach((orderDependency) => {
        if (!state.has(orderDependency.id)) {
          deletedDependencies.value.set(orderDependency.id, orderDependency);
        }
      });
      dependencies.value = new Map(state);
    };

    const applyChanges = (changes: {
      add?: OrderDependencyEntity[];
      update?: PartialEntity<OrderDependencyEntity>[];
      delete?: Entity[];
    }): void => {
      changes.add?.forEach((orderDependency) => {
        dependencies.value.set(orderDependency.id, orderDependency);
      });
      changes.update?.forEach((orderDependency) => {
        dependencies.value.set(orderDependency.id, {
          ...dependencies.value.get(orderDependency.id)!,
          ...orderDependency,
        });
        if (deletedDependencies.value.has(orderDependency.id)) {
          deletedDependencies.value.set(orderDependency.id, {
            ...dependencies.value.get(orderDependency.id)!,
            ...orderDependency,
          });
        }
      });
      changes.delete?.forEach((orderDependency) => {
        const existingOrderDependency = dependencies.value.get(orderDependency.id);
        if (existingOrderDependency) {
          deletedDependencies.value.set(orderDependency.id, existingOrderDependency);
          dependencies.value.delete(orderDependency.id);
        }
      });
    };

    const copyState = () => {
      return new Map(dependencies.value);
    };

    const setSoftDeletedEntity = (dependency: OrderDependencyEntity): void => {
      deletedDependencies.value.set(dependency.id, dependency);
    };

    const getSoftDeletedEntity = (id: string): OrderDependencyEntity | undefined => {
      return deletedDependencies.value.get(id);
    };

    const checkHasRelation = (fromOrderId: string, toOrderId: string, orderSensitive = false) => {
      if (relations.value.has(createRelationKey(fromOrderId, toOrderId))) return true;
      if (orderSensitive) return false;
      return relations.value.has(createRelationKey(toOrderId, fromOrderId));
    };

    const getDependenciesPartiallyRelatedToEntityIds = (entityIds: Set<string>) => {
      return dependencyList.value.filter(
        (dependency) => entityIds.has(dependency.from.id) || entityIds.has(dependency.to.id),
      );
    };

    const getDependencyForRelation = (fromId: string, toId: string) => {
      return dependencyList.value.find(
        (dependency) => dependency.from.id === fromId && dependency.to.id === toId,
      );
    };

    return {
      dependencies,
      relations,
      create,
      update,
      restore,
      delete: remove,
      applyChanges,
      setState,
      copyState,
      fetchAll,
      fetchAllPromise,
      reset,
      checkHasRelation,
      getSoftDeletedEntity,
      setSoftDeletedEntity,
      getDependenciesPartiallyRelatedToEntityIds,
      getDependencyForRelation,
    };
  },
);

import { Buffer } from 'buffer';
import {
  Callback,
  RTCSocketActions as GeneratedRTCSocketActions,
  SingleProjectChangeMessage,
} from 'events.schema';
import { io, Socket } from 'socket.io-client';
import { v4 } from 'uuid';

import { LifecycleStatus } from '@/features/projects/projectUtils';
import { getRandomId } from '@/helpers/utils/strings';
import { AuthenticationService, LoggingService } from '@/interfaces/services';
import { ConsoleLoggingService } from '@/services';
import { IS_PROD_ENV } from '@/utils/config';

import { OperationInputType } from '../types';
import { DefaultRTCMessageParser, RTCMessageParser } from './messageParser';
import { RTCSocketActions } from './types';

/**
 * Time after every operation that we wait before disconnecting
 * socket.
 */
const INACTIVITY_DELAY_S = 30;

export class OneShotRTCClient {
  private socket: Socket;

  private clientId: string | undefined;

  private pendingPromises: Record<string, { resolve: () => void; reject: (err: unknown) => void }> =
    {};

  private connectedBefore = false;

  private inactivityDisconnectTimer: NodeJS.Timeout | undefined;

  public constructor(
    private authenticationService: AuthenticationService,
    private endpoint: string,
    private messageParser: RTCMessageParser = new DefaultRTCMessageParser(),
    private loggingService: LoggingService = new ConsoleLoggingService(),
  ) {
    this.clientId = getRandomId();
    this.socket = this.createSocket();

    this.socket.on(RTCSocketActions.ServerToClientActions.Connect, () => {
      this.connectedBefore = true;
      this.log('Connected to RTC, transport:', this.socket.io.engine.transport.name);
    });

    this.socket.on(RTCSocketActions.ServerToClientActions.Disconnect, () => {
      this.log('Disconnected');
    });

    this.socket.on(RTCSocketActions.ServerToClientActions.ConnectError, (error) => {
      // Code set by backend
      // @ts-expect-error-next-line
      const errorCode = error?.data?.code;
      const isNetworkError = errorCode !== 'WS_CONNECTION_ERROR';
      if (!this.connectedBefore && isNetworkError) {
        // https://socket.io/docs/v3/client-initialization/#low-level-engine-options
        // Revert to classic upgrade, websocket upgrade will be attempted after connection was established
        this.socket.io.opts.transports = ['polling', 'websocket'];
        // always log this to console so support can easily verify behavior for customers
        this.log('Change to polling approach');
      }
    });

    this.socket.on(
      GeneratedRTCSocketActions.ServerClientSocketActions.ProjectChangeMessage,
      (payload) => {
        const event = this.messageParser.parseIncoming(payload);
        this.log('Incoming event', event);

        const pendingPromise = this.pendingPromises[event.messageId];
        if (!pendingPromise) {
          this.log('Received non pending event message', event);
          return;
        }

        delete this.pendingPromises[event.messageId];
        if (event.status === 'SUCCESS') {
          pendingPromise.resolve();
        } else {
          pendingPromise.reject(event.error);
        }

        if (Object.keys(this.pendingPromises).length === 0) {
          this.inactivityDisconnectTimer = setTimeout(() => {
            this.disconnect();
            this.clearDisconnectTimer();
          }, INACTIVITY_DELAY_S * 1000);
        }
      },
    );
  }

  private createSocket(): Socket {
    return io(this.endpoint, {
      autoConnect: false,
      reconnection: true,
      query: {
        clientId: this.clientId,
      },
      secure: true,
      // Enable for load balancer cookies
      withCredentials: true,
      transports: ['websocket', 'polling'],
      auth: async (cb) => {
        // "auth" is called when executing socket.connect()

        const token = await this.authenticationService.getIdToken();
        if (!token)
          throw new Error('Auth token not found. Cannot continue with socket connection.');

        cb({ token: `JWT ${token}` });
      },
    });
  }

  public async deleteProject(args: { projectId: string }): Promise<void> {
    const operationInput: OperationInputType<'DeleteProject'> = {};

    await this.sendChange({
      projectId: args.projectId,
      operation: {
        name: 'DeleteProject',
        input: Buffer.from(JSON.stringify(operationInput)),
      },
    });
  }

  public async updateProjectStatus(args: {
    projectId: string;
    lifecycleStatus: LifecycleStatus;
  }): Promise<void> {
    const mapStatusToGQL = (
      status: LifecycleStatus,
    ): OperationInputType<'UpdateProjectStatus'>['status'] => {
      switch (status) {
        case LifecycleStatus.ARCHIVED:
          return 'ARCHIVED';
        case LifecycleStatus.COMPLETED:
          return 'COMPLETED';
        case LifecycleStatus.IN_PREPARATION:
          return 'IN_PREPARATION';
        case LifecycleStatus.ACTIVE:
          return 'ACTIVE';
      }
      throw new Error(`Cannot update project status to '${args.lifecycleStatus}'.`);
    };

    const operationInput: OperationInputType<'UpdateProjectStatus'> = {
      status: mapStatusToGQL(args.lifecycleStatus),
    };

    await this.sendChange({
      projectId: args.projectId,
      operation: {
        name: 'UpdateProjectStatus',
        input: Buffer.from(JSON.stringify(operationInput)),
      },
    });
  }

  private async sendChange(
    message: Pick<SingleProjectChangeMessage, 'operation' | 'projectId'>,
  ): Promise<void> {
    await this.ensureConnected();
    this.clearDisconnectTimer();

    const changeMessage: SingleProjectChangeMessage = {
      messageId: v4(),
      projectId: message.projectId,
      operation: message.operation,
      clientTimestampMs: new Date().getTime(),
    };

    this.log('Outgoing Event', changeMessage);

    return new Promise<void>((resolve, reject) => {
      const onProjectChange = (response: Callback) => {
        if (response.status === 'ERROR') {
          reject(new Error(response.error.message));
          return;
        }

        this.pendingPromises[changeMessage.messageId] = { resolve, reject };
      };

      this.socket.emit(
        GeneratedRTCSocketActions.ClientServerSocketActions.SingleProjectChange,
        changeMessage,
        onProjectChange,
      );
    });
  }

  private async ensureConnected(): Promise<void> {
    if (!this.socket.connected) {
      this.connect();
    }
  }

  private clearDisconnectTimer(): void {
    if (this.inactivityDisconnectTimer) {
      clearTimeout(this.inactivityDisconnectTimer);
      this.inactivityDisconnectTimer = undefined;
    }
  }

  private connect(): void {
    this.socket.connect();
  }

  private disconnect(): void {
    this.socket.disconnect();
  }
  private log(...args: unknown[]) {
    if (!IS_PROD_ENV) {
      // eslint-disable-next-line no-console
      console.log('[RTCOneShotClient]', ...args);
    }
  }
}

<template>
  <Transition name="vertical-slide" mode="out-in">
    <slot />
  </Transition>
</template>

<style>
.vertical-slide-enter-active,
.vertical-slide-leave-active {
  transition: all 0.2s ease;
}

.vertical-slide-enter-from {
  opacity: 0;
  margin-right: -400px !important;
}

.vertical-slide-leave-to {
  opacity: 0;
  margin-right: -400px !important;
}
</style>

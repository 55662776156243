import { CalendarEntity, MilestoneEntity, OrderEntity, PauseEntity } from '@/common/types';
import {
  BaseSchedulerInterval,
  SchedulerDependency,
  SchedulerEvent,
} from '@/services/store/schedule/types/entities';

export enum SchedulerSidebarComponent {
  PAUSE_CREATE,
  PAUSE_DETAILS,
  PAUSE_EDIT,
  MILESTONE_CREATE,
  MILESTONE_DETAILS,
  MILESTONE_EDIT,
  ORDER_CREATE,
  ORDER_DETAILS,
  ORDER_HISTORY,
  ORDER_EDIT,
  ORDER_MULTI_EDIT,
  PROJECT_VERSIONS,
  CALENDARS,
  CALENDAR_CREATE,
  CALENDAR_EDIT,
}

export interface SchedulerSidebarState {
  [SchedulerSidebarComponent.PAUSE_CREATE]?: BaseSchedulerInterval;
  [SchedulerSidebarComponent.PAUSE_DETAILS]?: PauseEntity;
  [SchedulerSidebarComponent.PAUSE_EDIT]?: PauseEntity;
  [SchedulerSidebarComponent.MILESTONE_CREATE]?: BaseSchedulerInterval & {
    creationMethod: MilestoneCreationMethod;
  };
  [SchedulerSidebarComponent.MILESTONE_DETAILS]?: MilestoneEntity & {
    dependencyToBeExpanded: string | null;
  };
  [SchedulerSidebarComponent.MILESTONE_EDIT]?: MilestoneEntity;
  [SchedulerSidebarComponent.ORDER_CREATE]?: BaseSchedulerInterval & {
    creationMethod: OrderCreationMethod;
  };
  [SchedulerSidebarComponent.ORDER_DETAILS]?: OrderEntity & {
    dependencyToBeExpanded: string | null;
  };
  [SchedulerSidebarComponent.ORDER_HISTORY]?: OrderEntity;
  [SchedulerSidebarComponent.ORDER_EDIT]?: OrderEntity;
  [SchedulerSidebarComponent.ORDER_MULTI_EDIT]?: OrderEntity[];
  [SchedulerSidebarComponent.PROJECT_VERSIONS]?: boolean;
  [SchedulerSidebarComponent.CALENDARS]?: boolean;
  [SchedulerSidebarComponent.CALENDAR_CREATE]?: number;
  [SchedulerSidebarComponent.CALENDAR_EDIT]?: CalendarEntity;
}

export interface OpenSchedulerSidebarOptions {
  component?: SchedulerSidebarComponent;
  payload?: SchedulerSidebarState[SchedulerSidebarComponent];
  event?: SchedulerEvent;
  dependency?: SchedulerDependency;
  replace?: boolean;
}

export interface CloseSchedulerSidebarOptions {
  preserveEventSelection?: boolean;
}

export type OrderCreationMethod = 'menu' | 'double_click' | 'drag' | null;
export type MilestoneCreationMethod = 'menu' | 'double_click';

import { defineStore } from 'pinia';
import { Ref } from 'vue';

import {
  Entity,
  InteractiveEntityChanges,
  PartialEntity,
  PauseEntity,
  WrapRef,
} from '@/common/types';
import { pushLocalChangeEventAndCommit } from '@/features/realTimeCollaboration/utils';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

import { useMilestoneStoreV2 } from '../milestones';
import { useOrderDependencyStoreV2 } from '../orderDependencies';
import { useOrderStoreV2 } from '../orders';
import { ProjectChangeEventContext } from '../realTimeCollaboration/types';
import { UndoRedoCommit } from '../undoRedo/types';
import {
  createUndoRedoCreateCommit,
  createUndoRedoDeleteCommit,
  createUndoRedoRestoreCommit,
  createUndoRedoUpdateCommit,
} from './pauseCommits';
import {
  createProjectChangeCreateEvent,
  createProjectChangeDeleteEvent,
  createProjectChangeRestoreEvent,
  createProjectChangeUpdateEvent,
} from './pauseEvents';
import { pausesQuery } from './pauseGql';
import { PauseStoreV2 } from './types';

export const usePauseStoreV2 = defineStore(
  'pause-store-v2',
  (): WrapRef<PauseStoreV2, 'pauses' | 'fetchAllPromise'> => {
    const pauses = ref(new Map<string, PauseEntity>());
    const deletedPauses = ref(new Map<string, PauseEntity>());

    const create = async (
      vars: PauseEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeCreateEvent(vars, context);
      const commit = createUndoRedoCreateCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
        orders: useOrderStoreV2().copyState(),
        milestones: useMilestoneStoreV2().copyState(),
        sendElementContext: true,
        injectWorkingTimeDuration: (o) => useOrderStoreV2().injectWorkingTimeDuration(o),
      });
    };

    const update = async (
      vars: PartialEntity<PauseEntity>[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeUpdateEvent(vars, context);
      const commit = createUndoRedoUpdateCommit(vars, pauses.value);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
        orders: useOrderStoreV2().copyState(),
        milestones: useMilestoneStoreV2().copyState(),
        sendElementContext: true,
        injectWorkingTimeDuration: (o) => useOrderStoreV2().injectWorkingTimeDuration(o),
      });
    };

    const restore = async (
      vars: PauseEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeRestoreEvent(vars, context);
      const commit = createUndoRedoRestoreCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
        orders: useOrderStoreV2().copyState(),
        milestones: useMilestoneStoreV2().copyState(),
        sendElementContext: true,
        injectWorkingTimeDuration: (o) => useOrderStoreV2().injectWorkingTimeDuration(o),
      });
    };

    const remove = async (
      vars: PauseEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeDeleteEvent(vars, context);
      const commit = createUndoRedoDeleteCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
        orders: useOrderStoreV2().copyState(),
        milestones: useMilestoneStoreV2().copyState(),
        sendElementContext: true,
        injectWorkingTimeDuration: (o) => useOrderStoreV2().injectWorkingTimeDuration(o),
      });
    };

    const fetchAllPromise: Ref<Promise<PauseEntity[]> | null> = ref(null);

    const fetchAll = async (projectId: string): Promise<PauseEntity[]> => {
      const client = useApolloClient();

      fetchAllPromise.value = client
        .query({
          query: pausesQuery,
          variables: {
            project: projectId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          const sanitizedPauses = flattenNodeConnection(result.data.projectPauses).map((pause) => ({
            ...pause,
            name: pause.name ?? '',
            start: new SchedulingDate(pause.start),
            end: new SchedulingDate(pause.end),
          }));
          pauses.value = new Map(sanitizedPauses.map((pause) => [pause.id, pause]));
          return sanitizedPauses;
        });
      return fetchAllPromise.value;
    };

    const reset = () => {
      pauses.value = new Map();
      fetchAllPromise.value = null;
    };

    const setState = (state?: Map<string, PauseEntity>) => {
      if (!state) {
        return;
      }
      state.forEach((pause) => {
        if (deletedPauses.value.has(pause.id)) {
          deletedPauses.value.set(pause.id, pause);
        }
      });
      pauses.value.forEach((pause) => {
        if (!state.has(pause.id)) {
          deletedPauses.value.set(pause.id, pause);
        }
      });
      pauses.value = new Map(state);
    };

    const applyChanges = (changes: {
      add?: PauseEntity[];
      update?: PartialEntity<PauseEntity>[];
      delete?: Entity[];
    }): void => {
      changes.add?.forEach((pause) => {
        pauses.value.set(pause.id, pause);
      });
      changes.update?.forEach((pause) => {
        pauses.value.set(pause.id, {
          ...pauses.value.get(pause.id)!,
          ...pause,
        });
        if (deletedPauses.value.has(pause.id)) {
          deletedPauses.value.set(pause.id, { ...pauses.value.get(pause.id)!, ...pause });
        }
      });
      changes.delete?.forEach((pause) => {
        const existingPause = pauses.value.get(pause.id);
        if (existingPause) {
          deletedPauses.value.set(pause.id, { ...existingPause });
          pauses.value.delete(pause.id);
        }
      });
    };

    const copyState = () => {
      return new Map(pauses.value);
    };

    const getSoftDeletedEntity = (id: string): PauseEntity | undefined => {
      return deletedPauses.value.get(id);
    };

    return {
      pauses,
      create,
      update,
      restore,
      delete: remove,
      fetchAll,
      fetchAllPromise,
      applyChanges,
      copyState,
      setState,
      reset,
      getSoftDeletedEntity,
    };
  },
);

import { Ref, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import { AddressFormInputs, AddressFormInputsRequired } from '@/components/types';
import { buildAddress, useFormattedAddressWithout } from '@/helpers/address';
import { SelectItem, UseSearch, useSearch } from '@/helpers/inputs/selects';
import { useIocProvider } from '@/ioc/injectKey';

export function getInitialAddressFormInput<T extends Partial<AddressFormInputs>>(
  addressFormInput?: T,
): AddressFormInputs {
  return buildAddress(addressFormInput);
}

type SelectedAddress = {
  placeId: string;
  text: string;
};

interface UseAddressAutocompleteReturn extends UseSearch {
  addressItems: Ref<SelectItem[]>;
  selectedAddress: Ref<SelectedAddress>;
}

export function useAddressAutocomplete(
  variables: Ref<AddressFormInputsRequired | null>,
): UseAddressAutocompleteReturn {
  const i18n = useI18n();
  const searchHelper = useSearch();
  const { locationService } = useIocProvider();

  const addressItems = ref([]) as Ref<SelectItem[]>;
  const selectedAddress = ref({ placeId: '', text: '' }) as Ref<SelectedAddress>;

  const { result: predictions, refetch: refetchPredictions } = locationService.usePredictions();
  const { result: place, refetch: refetchPlace } = locationService.usePlace();

  watch(predictions, () => {
    addressItems.value = predictions.value.map((prediction) => ({
      value: prediction.placeId,
      title: prediction.description,
    }));
  });

  watch(place, () => {
    variables.value = {
      ...variables.value,
      ...place.value,
    };
  });

  watchEffect(() => {
    if (!searchHelper.searchTerm.value) return;
    refetchPredictions(searchHelper.searchTerm.value);
  });

  watch(selectedAddress, (newValue) => {
    if (newValue.placeId === '' && newValue.text === '') {
      variables.value = buildAddress();
      return;
    }
    if (newValue.placeId === '') return;
    refetchPlace(newValue.placeId);
  });

  watch(
    variables,
    () => {
      if (
        selectedAddress.value.text !== '' ||
        selectedAddress.value.placeId !== '' ||
        !variables.value
      ) {
        return;
      }
      const initialAddress = useFormattedAddressWithout(
        i18n,
        variables.value,
        undefined,
        undefined,
        '',
      );
      if (!initialAddress.trim()) return;
      addressItems.value = [{ value: '', title: initialAddress }];
      selectedAddress.value.text = initialAddress;
    },
    { immediate: true, deep: true },
  );

  return {
    ...searchHelper,
    addressItems,
    selectedAddress,
  };
}

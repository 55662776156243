import { ExternalProjectDataChange, RemoteProjectChangeEvent } from '../types';

export enum RTCClientConnectionState {
  IDLE = 'IDLE',
  CONNECTING = 'CONNECTING',
  ERROR = 'ERROR',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export const RTCSocketActions = {
  ClientToServerActions: {
    SubscribeProject: 'subscribe_to_project' as const,
    UnsubscribeProject: 'unsubscribe_from_project' as const,
    ProjectChange: 'project_change' as const,
  },
  ServerToClientActions: {
    Connect: 'connect' as const,
    ConnectError: 'connect_error' as const,
    Disconnect: 'disconnect' as const,
    Reconnect: 'reconnect' as const,
    ReconnectAttempt: 'reconnect_attempt' as const,
    ReconnectError: 'reconnect_error' as const,
    ReconnectFailed: 'reconnect_failed' as const,
    ProjectChanged: 'project_changed' as const,
    ProjectSubscriptionCompleted: 'project_subscription_completed' as const,
  },
};

export type ProjectChangeEventListener = {
  id: string;
  callback: (message: RemoteProjectChangeEvent, isOwn: boolean) => void;
};

export type ExternalProjectDataChangeListener = {
  id: string;
  callback: (message: ExternalProjectDataChange) => void;
};

export enum RTCClientResponseStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

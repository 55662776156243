import { computed } from 'vue';

import { ProjectUser } from '@/helpers/users';

import { filterAndSortLiveUsers } from './liveUsersUtils';

const liveUsers = ref<ProjectUser[]>([]);

/**
 * A Vue composable function to manage live user data.
 * It provides a reactive `users` computed property that reflects the current state of live users,
 * and a `setLiveUsers` method to update the live users list after filtering support users and sorting by joined at.
 */
export function useLiveUsers() {
  const users = computed(() => liveUsers.value);

  const setLiveUsers = (newLiveUsers: ProjectUser[] = []) => {
    liveUsers.value = filterAndSortLiveUsers(newLiveUsers);
  };

  return { users, setLiveUsers };
}

import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

/** Track trade sequence updates */
export class TradeSequenceInsertedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.TRADE_SEQUENCE_INSERTED;

  public constructor(
    public properties: {
      method: 'drag_and_drop' | 'context_menu';
    },
  ) {
    super();
  }
}

export class TradeSequenceDuplicatedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.TRADE_SEQUENCE_DUPLICATED;

  public constructor(
    public properties: {
      type: 'global' | 'project';
    },
  ) {
    super();
  }
}

export class TradeSequenceCreatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.TRADE_SEQUENCE_CREATED;

  public constructor(
    public properties: {
      type: 'global' | 'project';
      source: 'new' | 'project' | 'global';
      method: 'schedule' | 'sidebar';
    },
  ) {
    super();
  }
}

export class TradeSequenceDeletedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.TRADE_SEQUENCE_DELETED;

  public constructor(
    public properties: {
      type: 'global' | 'project';
    },
  ) {
    super();
  }
}

export class TradeSequenceEditedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.TRADE_SEQUENCE_EDITED;

  public constructor(
    public properties: {
      type: 'global' | 'project';
      hasConflicts: boolean;
      existingInstances: number;
    },
  ) {
    super();
  }
}

export class TradeSequenceUpdateConflictsDetected extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.TRADE_SEQUENCE_UPDATE_CONFLICTS_DETECTED;

  public constructor(
    public properties: {
      conflictsViewed: boolean;
    },
  ) {
    super();
  }
}

import { Composer } from 'vue-i18n';

import { Address } from '@/interfaces/repositories/utility';

/** Use combined address string, depending on street, postalCode, city, country */
export function useFormattedAddressWithout(
  { t }: Composer,
  address: Partial<Address> | undefined | null,
  hideKeys: (keyof Address)[] = ['street2'],
  mapCountry?: (code?: string | null) => string,
  defaultValue = '-',
): string {
  if (!address) return '-';
  const { city, street, street2, country, postalCode } = address;
  const parts = [
    { data: street, key: 'street' },
    { data: street2, key: 'street2' },
    { data: postalCode, key: 'postalCode' },
    { data: city, key: 'city', combine: true },
    {
      data: country,
      key: 'country',
      format: mapCountry ? () => mapCountry(country) : () => t(`countries.${country}`).toString(),
    },
  ];

  const addressParts: string[] = [];
  parts.forEach((part, idx) => {
    const { data, combine, format, key } = part;
    if (!data || hideKeys.includes(key as keyof Address)) return;
    const formatted = format ? format() : data;
    const previous = idx > 0 ? parts[idx - 1] : null;
    /** combine with previous address part, if combine indicator present */
    if (combine && previous?.data && !hideKeys.includes(previous?.key as keyof Address)) {
      addressParts.splice(
        addressParts.length - 1,
        1,
        `${addressParts[addressParts.length - 1]} ${formatted}`,
      );
      return;
    }
    addressParts.push(formatted);
  });

  return addressParts.length ? addressParts.join(', ') : defaultValue;
}

/** Returns address of entity holding address data */
export function buildAddress<T extends Partial<Address>>(data?: T): Address {
  return {
    street: data?.street ?? null,
    street2: data?.street2 ?? null,
    postalCode: data?.postalCode ?? null,
    city: data?.city ?? null,
    country: data?.country ?? null,
  };
}

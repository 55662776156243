import lodashIsEqual from 'lodash/isEqual.js';

/** Converts empty string values to be null */
export function changeEmptyValuesToNull<T extends Dictionary>(vars: T): T {
  const objWithNull = {} as Dictionary;
  Object.keys(vars).forEach((k: string) => {
    if (isObject(vars[k])) {
      objWithNull[k] = changeEmptyValuesToNull(vars[k]);
    } else {
      objWithNull[k] = vars[k] === '' ? null : vars[k];
    }
  });
  return objWithNull as T;
}

/** Filters unallowed keys out of an object  https://stackoverflow.com/a/38750895
 * @param raw raw object to be filtered
 * @param allowed array of keys that are allowed and should not be removed
 */
export function pickKeys<T extends Dictionary, V extends keyof T>(
  raw: T,
  allowed: V[],
): Pick<T, V> {
  if (allowed.length === 0) return raw;

  return Object.keys(raw)
    .filter((key) => allowed.includes(key as V))
    .reduce((obj, key: string) => {
      obj[key] = raw[key];
      return obj;
    }, {} as Dictionary) as T;
}

/** Filters unallowed keys out of an object  https://stackoverflow.com/a/38750895
 * @param raw raw object to be filtered
 * @param keys array of keys that are to be emitted from the object
 */
export function omitKeys<T extends Dictionary, V extends keyof T>(raw: T, keys: V[]): Omit<T, V> {
  if (keys.length === 0) return raw;

  return Object.keys(raw)
    .filter((key) => !keys.includes(key as V))
    .reduce((obj, key: string) => {
      obj[key] = raw[key];
      return obj;
    }, {} as Dictionary) as T;
}

/**
 * Returns a deep copy of the original object.
 *
 * Warning: This function will throw an error if `obj` is undefined or not a valid object
 * that can be serialized by JSON.stringify. This includes objects with circular references,
 * functions, and other non-serializable values.
 */
export function copy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Compares the stringified version of two objects for equality
 */
export function isEqual(obj1: any, obj2: any): boolean {
  return lodashIsEqual(obj1, obj2);
}

/** Returns all keys of an object, that should not be omitted
 * @param obj object to pick keys from
 * @param keysToBeOmitted keys that should not be picked
 */
export function keysToOmit<T extends Dictionary, V extends keyof T>(
  obj: T,
  keysToBeOmitted: V[],
): Omit<keyof T, V>[] {
  return (Object.keys(obj) as (keyof T)[]).filter((key) => !keysToBeOmitted.includes(key as V));
}

/**
 * Filters duplicate items by ID from a list
 */
export function filterDuplicates<T>(
  list: T[],
  getId: (element: T) => unknown = (element) => element,
): T[] {
  const seen = new Set();
  return list.filter((value) => {
    const key = getId(value);
    return seen.has(key) ? false : seen.add(key);
  });
}

/**
 * Returns the text immediately following a given word
 * @param text text to filter from
 * @param word word to find
 */
export function getTextAfterWord(text: string, word: string): string {
  return text.slice(text.indexOf(word) + word.length);
}

/**
 * Returns if given variable is an object. Null values are excluded, as well as arrays, functions, files and blobs.
 * @param val
 * @returns boolean
 */
export function isObject(val: unknown): boolean {
  if (val === null || Array.isArray(val) || val instanceof Blob || val instanceof File) {
    return false;
  }
  return typeof val === 'object';
}

export interface MapDifferences {
  added: string[];
  updated: string[];
  deleted: string[];
}

/**
 * Finds differences between two different map-like objects.
 * @param next
 * @param prev
 * @returns
 */
export function findMapDifferences<T>(
  next: Map<string, T> | IterableIterator<[string, T]>,
  prev?: Map<string, T>,
): MapDifferences {
  const deleted: string[] = [];
  const added: string[] = [];
  const updated: string[] = [];

  if (!prev) {
    for (const [key] of next) {
      added.push(key);
    }
    return { added, deleted, updated };
  }

  const prevKeys = new Set(prev.keys());

  for (const [key, value] of next) {
    const storedValue = prev.get(key);
    if (storedValue === undefined) {
      added.push(key);
    } else if (!isEqual(value, storedValue)) {
      updated.push(key);
    }
    prevKeys.delete(key);
  }

  deleted.push(...prevKeys);

  return {
    added,
    deleted,
    updated,
  };
}

function findEqualKeyValuesForKeys<T extends Dictionary>(
  objects: T[],
  keys: (keyof T)[],
): (keyof T)[] {
  if (!objects.length) return [];

  return keys.filter((key) => objects.every((object) => isEqual(objects[0][key], object[key])));
}

/** Compares a list of objects of same type and returns keys that have the same value */
export function findKeysWithEqualValues<T extends Dictionary>(objects: T[]): (keyof T)[] {
  if (!objects.length) return [];

  const keys = Object.keys(objects[0]);
  const equalKeys = findEqualKeyValuesForKeys(objects, keys);
  return equalKeys;
}

/** Compares a list of objects of same type and returns keys that have different value */
export function findKeysWithDivergingValues<T extends Dictionary>(objects: T[]): (keyof T)[] {
  if (!objects.length) return [];

  const keys = Object.keys(objects[0]);
  const equalKeys = findEqualKeyValuesForKeys(objects, keys);
  return keys.filter((key) => !equalKeys.includes(key));
}

export function omitEqualValues<T extends Partial<K>, K extends Dictionary>(
  objectToOmitKeysFrom: T,
  objectToCompareWith: K,
): Partial<K> {
  const shallowCopy = { ...objectToOmitKeysFrom };
  Object.keys(objectToCompareWith).forEach((key) => {
    if (isEqual(shallowCopy[key], objectToCompareWith[key])) {
      delete shallowCopy[key];
    }
  });
  return shallowCopy;
}

export function deleteUndefinedValues<T extends Dictionary>(obj: T): Partial<T> {
  const shallowCopy = { ...obj };

  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete shallowCopy[key];
    }
  });

  return shallowCopy;
}

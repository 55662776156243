import { OrderEntity, OrderStatusEntity, PartialEntity } from '@/common/types';
import { convertOrderStatusToStatusReport } from '@/helpers/orders/status';
import { useUserStore } from '@/services/store/user';

import { useLiveUsers } from '../realTimeCollaboration/components/liveUsers/useLiveUsers';
import {
  LocalProjectChangeEvent,
  LocalProjectChangeEventTemplate,
  OperationInputType,
  RemoteProjectChangeEvent,
} from '../realTimeCollaboration/types';

function isRemoteProjectChangeEvent(
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
): event is RemoteProjectChangeEvent {
  return event && 'user' in event && typeof event.user !== 'undefined';
}

function getCreatedByInformation(
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
) {
  const createdBy = { id: '', email: '', firstName: '', lastName: '' };
  const createdByContributorGroup = { id: '', name: '' };
  const userStore = useUserStore();

  const { users } = useLiveUsers();

  if (isRemoteProjectChangeEvent(event)) {
    if (event.user) {
      createdBy.firstName = event.user.firstName;
      createdBy.lastName = event.user.lastName;
      createdBy.id = event.user.id;
    }

    const tenant = users.value.find((u) => u.id === event.user?.id)?.tenant;

    if (tenant) {
      createdByContributorGroup.id = tenant.id;
      createdByContributorGroup.name = tenant.name;
    }
  } else {
    const ownUser = users.value.find((user) => user.id === userStore.ownUser?.id);

    if (ownUser) {
      createdBy.id = ownUser.id;
      createdBy.firstName = ownUser.firstName;
      createdBy.lastName = ownUser.lastName;
      createdByContributorGroup.id = ownUser.tenant.id;
      createdByContributorGroup.name = ownUser.tenant.name;
    }
  }

  return { createdBy, createdByContributorGroup };
}

export function convertOrderStatusReportCreateOperationInput(
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
  input: OperationInputType<'CreateOrderStatusReports'>,
): {
  addedOrderStatusReports: OrderStatusEntity[];
  updatedOrders: PartialEntity<OrderEntity>[];
} {
  const { createdBy, createdByContributorGroup } = getCreatedByInformation(event);

  const addedOrderStatusReports: OrderStatusEntity[] = [];
  const updatedOrders: PartialEntity<OrderEntity>[] = [];

  for (const orderCreateStatusInput of input) {
    const newStatusReport = convertOrderStatusToStatusReport(orderCreateStatusInput.status);

    addedOrderStatusReports.push({
      id: orderCreateStatusInput.id,
      orderId: orderCreateStatusInput.orderId,
      status: newStatusReport,
      progress: orderCreateStatusInput.progress,
      reason: orderCreateStatusInput.reason,
      comment: orderCreateStatusInput.comment,
      reportedAt: new Date(orderCreateStatusInput.reportedAt),
      createdBy,
      createdByContributorGroup,
    });
    updatedOrders.push({
      id: orderCreateStatusInput.orderId,
      status: newStatusReport,
      progress: orderCreateStatusInput.progress,
      finishedAt: newStatusReport === 'DONE' ? new Date(orderCreateStatusInput.reportedAt) : null,
    });
  }

  return {
    addedOrderStatusReports,
    updatedOrders,
  };
}

import { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from 'vue-router';

import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { GuardMeta, useWithMembership } from '@/router/helpers/memberships';
import { getPickTenantRoute, getRouteForMembershipStatus } from '@/router/helpers/redirection';
import { useRouteNames } from '@/router/helpers/routeNames';

export function useProjectRoutes(): RouteRecordRaw[] {
  return routes();
}

/** Guard wrapper used for children */
export function useProjectGuard({
  loggingService,
  membershipStore,
}: GuardMeta): (to: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<any> {
  return (to): Promise<void> =>
    useWithMembership(
      { to },
      { loggingService, membershipStore },
      async (
        membership: MembershipWithTenant | undefined | null,
        memberships: MembershipWithTenant[],
      ): Promise<void | RouteLocationRaw> => {
        if (getRouteForMembershipStatus(to, membership, memberships)) {
          return;
        }

        const pathTenantId = to.params.tenantId;

        const matchingMembership = memberships.find((m) => m.tenant.id === pathTenantId);
        if (matchingMembership) return;

        if (memberships.length === 1) {
          return {
            name: to.name,
            params: { ...to.params, tenantId: memberships[0].tenant.id },
            query: to.query,
          };
        }

        return getPickTenantRoute();
      },
    );
}

const { Routes } = useRouteNames();

const routes = (): RouteRecordRaw[] => {
  const leanProjectRoutes: RouteRecordRaw[] = [
    {
      path: '',
      name: Routes.Lean.Dashboard,
      component: () => import('@/features/dashboard/components/Dashboard.vue'),
    },
    {
      path: 'participants',
      name: Routes.Lean.Participants,
      component: () => import('@/features/projectContributors/pages/Participants.vue'),
      children: [
        {
          path: 'contributors',
          name: Routes.Lean.ParticipantsContributors,
          meta: {
            group: Routes.Lean.Participants,
          },
          component: () =>
            import('@/features/projectContributors/pages/ParticipantsContributors.vue'),
        },
        {
          path: 'management',
          name: Routes.Lean.ParticipantsManagement,
          meta: {
            group: Routes.Lean.Participants,
          },
          component: () => import('@/features/projectMemberships/pages/ParticipantsManagement.vue'),
        },
      ],
      redirect: { name: Routes.Lean.ParticipantsManagement },
    },
    {
      path: 'schedule/:objectId?', // e.g., object is order, milestone, pause
      name: Routes.Lean.Schedule,
      component: () => import('@/views/leanProjects/dashboard/Schedule.vue'),
      props: (route) => ({
        notification: route.query.notification,
      }),
    },
    {
      path: 'tickets/:ticketId?',
      name: Routes.Lean.Tickets,
      component: () => import('@/features/tickets/pages/TicketView.vue'),
    },
    {
      path: 'photos/:photoId?',
      name: Routes.Lean.PhotoDocumentation,
      component: () => import('@/features/photoDocumentation/pages/PhotoDocumentation.vue'),
    },
  ];

  return leanProjectRoutes;
};

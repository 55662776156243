import {
  FetchOptions,
  MultiPageExporter,
  PdfExportConfig,
  SinglePageExporter,
} from '@bryntum/schedulerpro';

import { APP_ENV, APP_VERSION, BRYNTUM_AUTH_TOKEN, bryntumExportServer } from '@/utils/config';

export const EXPORT_HEADER_HEIGHT = 80;
const DIGITAL_EXPORTER_NAME = 'customsinglepageexporter';

class CustomFormatSinglePageExporter extends SinglePageExporter {
  // type is required for exporter
  public static get type() {
    return DIGITAL_EXPORTER_NAME;
  }

  public get name() {
    return DIGITAL_EXPORTER_NAME;
  }

  public async export(config) {
    config = { ...config, paperFormat: 'A0' };
    // @ts-expect-error-next-line
    const result = await super.export(config);
    return result;
  }
}

export const BRYNTUM_FETCH_OPTIONS: FetchOptions = {
  headers: {
    'app-version': APP_VERSION,
    stage: APP_ENV,
    Authorization: BRYNTUM_AUTH_TOKEN,
    'Content-Type': 'application/json',
  },
};

export function getPdfExportConfig(): Partial<PdfExportConfig> {
  return {
    exportServer: bryntumExportServer,
    // @ts-expect-error-next-line
    exporters: [CustomFormatSinglePageExporter, MultiPageExporter, SinglePageExporter],
    rowsRange: 'all',
    scheduleRange: 'daterange',
    keepRegionSizes: {
      locked: true,
    },
    showErrorToast: false,
    fetchOptions: {
      headers: {
        'app-version': APP_VERSION,
        stage: APP_ENV,
        Authorization: BRYNTUM_AUTH_TOKEN,
        'Content-Type': 'application/json',
      },
    },
  };
}

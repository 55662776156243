export const supportUserIds = [
  // prod
  'VXNlck5vZGU6NDY5MQ==', // tech-support@koppla.de
  'VXNlck5vZGU6MjIwNw==', // support@koppla.de

  // staging
  'VXNlck5vZGU6MTYwNQ==', // support@koppla.de
  // tech support doesn't exist on staging
];

export const isSupportOrTechSupportUserId = (userId: string): boolean =>
  supportUserIds.includes(userId);

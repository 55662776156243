import { SchedulerSidebarComponent } from '@/services/store/schedule/types/sidebar';

const EDIT_SIDEBARS: SchedulerSidebarComponent[] = [
  SchedulerSidebarComponent.PAUSE_EDIT,
  SchedulerSidebarComponent.MILESTONE_EDIT,
  SchedulerSidebarComponent.ORDER_EDIT,
  SchedulerSidebarComponent.CALENDAR_EDIT,
  SchedulerSidebarComponent.ORDER_MULTI_EDIT,
];

const CREATE_SIDEBARS: SchedulerSidebarComponent[] = [
  SchedulerSidebarComponent.PAUSE_CREATE,
  SchedulerSidebarComponent.MILESTONE_CREATE,
  SchedulerSidebarComponent.ORDER_CREATE,
  SchedulerSidebarComponent.CALENDAR_CREATE,
];

export const isEditSidebar = (sidebar: SchedulerSidebarComponent): boolean =>
  EDIT_SIDEBARS.includes(sidebar);

export const isCreateSidebar = (sidebar: SchedulerSidebarComponent): boolean =>
  CREATE_SIDEBARS.includes(sidebar);

export const getEditSidebarOpenedMethod = (
  isSidebarOpenByKeyboardShortcut: boolean,
  isMultiSelectToolbarOpen: boolean,
): 'details' | 'toolbar' | 'keyboard' => {
  if (isSidebarOpenByKeyboardShortcut) return 'keyboard';
  if (isMultiSelectToolbarOpen) return 'toolbar';
  return 'details';
};

<template>
  <DsSnackbar v-model="showNotification" :action-text="action?.text" :hide-after="2000">
    <template #title>
      <p class="tw-ds-text-md tw-whitespace-nowrap">{{ notification }}</p>
    </template>
  </DsSnackbar>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';

import eventBus from '@/utils/eventBus';

interface Action {
  icon?: string;
  text?: string;
  fn: () => void;
}

interface Resolver {
  force: boolean;
  fn: () => void;
}

interface Data {
  notification: Ref<string | null>;
  showNotification: Ref<boolean>;
  action: Ref<Action | null>;
  onAction: () => void;
}

export default defineComponent({
  setup(): Data {
    const { t } = useI18n();
    const notification: Ref<string | null> = ref(null);
    const showNotification = ref(false);
    const timeout = ref(null) as Ref<NodeJS.Timeout | null>;
    const resolver = ref(null) as Ref<Resolver | null>;
    const action = ref(null) as Ref<Action | null>;

    eventBus.on(
      'new-notification',
      (
        _notification: string | undefined,
        _timeout: number | undefined = 3000,
        _action: Action | null = null,
        _resolver: Resolver | null = null,
        _overwrite = true,
      ) => {
        if (showNotification.value && !_overwrite) return;
        /**
         * Clear last timeout, if new notification appeared
         * to make sure the notification stays the whole given time.
         * Value of snackbar has to be reset explicitly to force re-render
         */
        if (timeout.value) {
          clearTimeout(timeout.value);
          showNotification.value = false;
          /** Call the resolver again, before cleaning up the timeout, when it is forced */
          if (resolver.value && resolver.value.fn && resolver.value.force) {
            resolver.value.fn();
          }
        }
        setTimeout(
          () => {
            showNotification.value = true;
            notification.value = _notification ?? t('form.changesApplied').toString();
            action.value = _action;
            resolver.value = _resolver;
            /** This is a hack to enable the multiple use of the snackbar. The timeout prop does not reinitialize if the value changes.
             * https://github.com/vuetifyjs/vuetify/issues/371
             */
            timeout.value = setTimeout(() => {
              showNotification.value = false;
              if (resolver.value && resolver.value.fn) resolver.value.fn();
              resolver.value = null;
              timeout.value = null;
              action.value = null;
            }, _timeout);
          },
          // if the notification is stacked on another one, the function has to be timeouted
          // to remove flicker and to show the notification correctly
          timeout.value ? 200 : 0,
        );
      },
    );
    function onAction(): void {
      if (action.value) action.value.fn();
      resolver.value = null;
      showNotification.value = false;
    }
    return { action, notification, showNotification, onAction };
  },
});
</script>

import { DependencyType } from '@koppla-tech/scheduling-engine';

import { Entity, OrderDependencyEntity, PartialEntity } from '@/common/types';
import { OperationInputType } from '@/features/realTimeCollaboration/types';

export const convertOrderDependencyCreateEventInput = (
  input: OperationInputType<'CreateDependencies'>,
): OrderDependencyEntity[] => {
  return input.map((createDependencyInput) => ({
    ...createDependencyInput,
    type: createDependencyInput.type as DependencyType,
    from: createDependencyInput.fromMilestoneId
      ? {
          id: createDependencyInput.fromMilestoneId!,
        }
      : {
          id: createDependencyInput.fromOrderId!,
        },
    to: createDependencyInput.toMilestoneId
      ? {
          id: createDependencyInput.toMilestoneId!,
        }
      : {
          id: createDependencyInput.toOrderId!,
        },
  }));
};

export const convertOrderDependencyUpdateEventInput = (
  input: OperationInputType<'UpdateDependencies'>,
): PartialEntity<OrderDependencyEntity>[] => {
  return input.map((updateDependencyInput) => ({
    id: updateDependencyInput.id,
    ...(updateDependencyInput.lagInMinutes !== undefined
      ? {
          lagInMinutes: updateDependencyInput.lagInMinutes ?? 0,
        }
      : {}),
    ...(updateDependencyInput.bufferInMinutes !== undefined
      ? {
          bufferInMinutes: updateDependencyInput.bufferInMinutes ?? 0,
        }
      : {}),
    ...(updateDependencyInput.useDryingBreak !== undefined
      ? {
          useDryingBreak: updateDependencyInput.useDryingBreak ?? false,
        }
      : {}),
    ...(updateDependencyInput.type !== undefined
      ? {
          type: updateDependencyInput.type as DependencyType,
        }
      : {}),
  }));
};

export function convertOrderDependencyDeleteOperationInput(
  input: OperationInputType<'RemoveScheduleNodes'>,
): Entity[] {
  return input.dependencies?.map((id) => ({ id })) ?? [];
}

import { Composer, createI18n } from 'vue-i18n';

import { fallbackLanguage, getBrowserLanguage, Language } from '@/utils/languages';

import dates from './dates';
import numbers from './numbers';

const defaultLanguage = getBrowserLanguage();
document.documentElement.setAttribute('lang', defaultLanguage);

export const i18nInstance = createI18n({
  legacy: false,
  locale: defaultLanguage,
  fallbackLocale: fallbackLanguage,
});

const nativeD = i18nInstance.global.d;

i18nInstance.global.d = (...args: any): string => {
  const date = args[0];
  if (!date) {
    throw new Error('Date object is required');
  }

  const format = args.length >= 2 ? args[1] : 'date';

  const isUTC = date.getTimezoneOffset ? date.getTimezoneOffset() === 0 : 0;

  const dateDependingFormat = isUTC ? `${format}UTC` : format;

  args[1] = dateDependingFormat;

  // @ts-expect-error-next-line
  return nativeD(...args);
};

export async function setI18nLanguageStrings(i18n: Composer, language: Language): Promise<void> {
  Object.keys(dates).forEach((k) => {
    dates[`${k}UTC`] = {
      ...dates[k],
      timeZone: 'UTC',
    };
  });
  // lazy load i18n strings to not waste memory, if additional strings are not needed
  return import(`./${language.toLowerCase()}/strings.ts`).then((strings) => {
    i18n.setLocaleMessage(language, strings.default);
    i18n.setDateTimeFormat(language, dates);
    i18n.setNumberFormat(language, numbers);
  });
}

setI18nLanguageStrings(i18nInstance.global, defaultLanguage);

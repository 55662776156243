import { ProjectContributorGroupChangePayload } from 'events.schema';

import { PartialEntity } from '@/common/types';
import {
  ProjectContributorGroupFragment,
  ProjectContributorGroupType,
  ProjectPermission,
} from '@/graphql/__generated__/graphql';

export function convertProjectContributorGroupChangeOperationInput(
  input: ProjectContributorGroupChangePayload,
): Map<string, PartialEntity<ProjectContributorGroupFragment>> {
  return new Map(
    input.contributorGroups.map((group) => {
      const item: PartialEntity<ProjectContributorGroupFragment> = {
        ...group,
        permission: group.permission as ProjectPermission,
        type:
          group.type === 'INTERNAL'
            ? ProjectContributorGroupType.Internal
            : ProjectContributorGroupType.External,
        tenantTradeVariationAssignments: group.tenantTradeVariationAssignments.map((assignment) => {
          return {
            tenantTradeVariation: { id: assignment.tenantTradeVariationId },
            isDefaultContributor: assignment.isDefaultContributor,
          };
        }),
      };
      return [group.id, item];
    }),
  );
}

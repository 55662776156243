import { DependencyType } from '@koppla-tech/scheduling-engine';

import {
  InteractiveEntityStores,
  MilestoneEntity,
  OrderDependencyEntity,
  OrderEntity,
} from '@/common/types';
import { NodeName } from '@/repositories/utils/cache';
import { BulkOrderDependency } from '@/services/store/bulkApiClient';

export const convertBulkOrderDependencyToOrderDependencyEntity = (
  bulkOrderDependency: BulkOrderDependency,
): OrderDependencyEntity => ({
  id: bulkOrderDependency.id,
  lagInMinutes: bulkOrderDependency.lagInMinutes,
  bufferInMinutes: bulkOrderDependency.bufferInMinutes,
  type: bulkOrderDependency.type as DependencyType,
  from: bulkOrderDependency.from.milestoneId
    ? {
        id: bulkOrderDependency.from.milestoneId!,
      }
    : {
        id: bulkOrderDependency.from.orderId!,
      },
  to: bulkOrderDependency.to.milestoneId
    ? {
        id: bulkOrderDependency.to.milestoneId!,
      }
    : {
        id: bulkOrderDependency.to.orderId!,
      },
  useDryingBreak: bulkOrderDependency.useDryingBreak,
});

export const getDependentEntities = (
  entityStores: Pick<
    InteractiveEntityStores,
    'orderStore' | 'orderDependencyStore' | 'milestoneStore'
  >,
  params: {
    dependencyOrId?: OrderDependencyEntity | string;
    fromId?: string;
    toId?: string;
  },
): {
  from: OrderEntity | MilestoneEntity | undefined;
  fromType: NodeName.ORDER | NodeName.MILESTONE;
  to: OrderEntity | MilestoneEntity | undefined;
  toType: NodeName.ORDER | NodeName.MILESTONE;
} => {
  if (!params.dependencyOrId && (!params.fromId || !params.toId)) {
    throw new Error('Dependency or fromId and toId must be provided');
  }

  const dependency =
    typeof params.dependencyOrId === 'string'
      ? entityStores.orderDependencyStore().dependencies.get(params.dependencyOrId)
      : params.dependencyOrId;

  const fromId = dependency?.from?.id ?? params.fromId ?? '';
  const toId = dependency?.to?.id ?? params.toId ?? '';

  const fromOrder = entityStores.orderStore().orders.get(fromId);
  const from = fromOrder || entityStores.milestoneStore().milestones.get(fromId);
  const fromType = fromOrder ? NodeName.ORDER : NodeName.MILESTONE;

  const toOrder = entityStores.orderStore().orders.get(toId);
  const to = toOrder || entityStores.milestoneStore().milestones.get(toId);
  const toType = toOrder ? NodeName.ORDER : NodeName.MILESTONE;

  return {
    from,
    fromType,
    to,
    toType,
  };
};

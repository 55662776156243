import gql from 'graphql-tag';
import { defineStore } from 'pinia';

import { TradeEntity } from '@/common/types';
import { gql as gqlFn } from '@/graphql/__generated__';
import {
  CreateProjectTradeMutationVariables,
  DeleteTradeMutationVariables,
  TradeDetailsQueryVariables,
  TradeFragment,
  TradesInUseQueryVariables,
  TradesQueryVariables,
  UpdateTradeMutation,
  UpdateTradeMutationVariables,
} from '@/graphql/__generated__/graphql';
import { subtract } from '@/helpers/utils/arrays';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection, getOrderByArgument } from '@/repositories/utils/fetchAll';

export const enum TradeType {
  Project = 'Project',
  Tenant = 'Tenant',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TRADE_FRAGMENT = gql`
  fragment Trade on TenantTradeVariationNode {
    color
    id
    name
    type
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TRADE_DETAILS_FRAGMENT = gql`
  fragment TradeDetails on TenantTradeVariationNode {
    ...Trade
    isDeletable
  }
`;

export const useProjectTradeStore = defineStore('project-trade-store', () => {
  const trades = ref<Map<string, TradeFragment>>(new Map());
  const tradesInUse = ref<Map<string, TradeFragment>>(new Map());

  const sortedTrades = computed(() => {
    return Array.from(trades.value.values()).sort((a, b) => a.name.localeCompare(b.name));
  });

  const createProjectTrade = async (variables: CreateProjectTradeMutationVariables['input']) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation CreateProjectTrade($input: CreateProjectTradeVariationInput!) {
        createProjectTradeVariation(input: $input) {
          tradeVariation {
            ...Trade
          }
        }
      }
    `);

    return client.mutate({ mutation, variables: { input: variables } }).then((response) => {
      const result = response.data?.createProjectTradeVariation?.tradeVariation ?? null;
      if (!result) return null;
      trades.value.set(result.id, result);
      return result;
    });
  };
  const updateOne = async (variables: UpdateTradeMutationVariables['input']) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation UpdateTrade($input: UpdateTradeVariationInput!) {
        updateTradeVariation(input: $input) {
          tradeVariation {
            ...Trade
          }
        }
      }
    `);

    return client
      .mutate<UpdateTradeMutation>({ mutation, variables: { input: variables } })
      .then((response) => {
        const result = response.data?.updateTradeVariation?.tradeVariation ?? null;
        if (!result) return null;
        trades.value.set(result.id, result);
        return result;
      });
  };
  const deleteOne = async (variables: DeleteTradeMutationVariables['input']) => {
    const client = useApolloClient();

    const mutation = gqlFn(/* GraphQL */ `
      mutation DeleteTrade($input: DeleteTradeVariationInput!) {
        deleteTradeVariation(input: $input) {
          success
        }
      }
    `);

    return client.mutate({ mutation, variables: { input: variables } }).then(() => {
      trades.value.delete(variables.id);
      return null;
    });
  };

  const fetchTradeDetails = async (vars: TradeDetailsQueryVariables) => {
    const client = useApolloClient();

    const query = gqlFn(/* GraphQL */ `
      query TradeDetails($id: ID!) {
        tenantTradeVariation(id: $id) {
          ...TradeDetails
        }
      }
    `);

    return client
      .query({
        query,
        variables: vars,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const tradeVariation = result.data.tenantTradeVariation;
        if (!tradeVariation) return null;
        trades.value.set(tradeVariation.id, tradeVariation);
        return tradeVariation;
      });
  };

  const fetchAllPromise: Ref<Promise<TradeEntity[]> | null> = ref(null);

  const fetchAll = async (vars: TradesQueryVariables) => {
    const client = useApolloClient();

    const query = gqlFn(/* GraphQL */ `
      query Trades($project: ID!, $orderBy: [String!]) {
        projectTradeVariations(
          project: $project
          orderBy: $orderBy
          includeTenantVariations: true
        ) {
          edges {
            node {
              ...Trade
            }
          }
        }
      }
    `);

    fetchAllPromise.value = client
      .query({
        query,
        variables: {
          ...vars,
          orderBy: getOrderByArgument({ name: 'ASC' }),
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const flattened = flattenNodeConnection(result.data.projectTradeVariations);
        trades.value = new Map(
          flattened.map((tradeVariation) => [tradeVariation.id, tradeVariation]),
        );
        return flattened;
      });
    return fetchAllPromise.value;
  };

  const fetchAllInUse = async (vars: TradesInUseQueryVariables) => {
    const client = useApolloClient();

    const query = gqlFn(/* GraphQL */ `
      query TradesInUse($project: ID!, $orderBy: [String!]) {
        tradeVariationsUsedInProject(project: $project, orderBy: $orderBy) {
          edges {
            node {
              ...Trade
            }
          }
        }
      }
    `);

    return client
      .query({
        query,
        variables: {
          ...vars,
          orderBy: getOrderByArgument({ name: 'ASC' }),
        },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const flattened = flattenNodeConnection(result.data.tradeVariationsUsedInProject);
        tradesInUse.value = new Map(
          flattened.map((tradeVariation) => [tradeVariation.id, tradeVariation]),
        );
        return flattened;
      });
  };

  const setTradesByType = (newTrades: TradeEntity[], type: string) => {
    const newFilteredTrades = newTrades.filter((trade) => trade.type === type);
    const oldFilteredTrades = Array.from(trades.value.values()).filter(
      (trade) => trade.type === type,
    );
    const removedTrades = subtract(oldFilteredTrades, newFilteredTrades);

    newFilteredTrades.forEach((trade) => {
      trades.value.set(trade.id, trade);
    });
    removedTrades.forEach((trade) => {
      trades.value.delete(trade.id);
    });
  };

  const setProjectTrades = (newTrades: TradeEntity[]) => {
    setTradesByType(newTrades, TradeType.Project);
  };

  const setTenantTrades = (newTrades: TradeEntity[]) => {
    setTradesByType(newTrades, TradeType.Tenant);
  };

  const deleteTrade = (tradeId: string) => {
    trades.value.delete(tradeId);
  };

  return {
    trades,
    tradesInUse,
    sortedTrades,
    createProjectTrade,
    updateOne,
    deleteOne,
    fetchTradeDetails,
    fetchAll,
    fetchAllPromise,
    fetchAllInUse,
    setProjectTrades,
    setTenantTrades,
    deleteTrade,
  };
});

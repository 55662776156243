import { OperationNames } from 'events.schema';

import { PartialEntity, WbsSectionEntity } from '@/common/types';
import {
  generateRTCMessageId,
  LocalProjectChangeEventTemplate,
} from '@/features/realTimeCollaboration';
import { ProjectChangeEventContext } from '@/features/realTimeCollaboration/types';
import { getRandomId } from '@/helpers/utils/strings';
import { i18nInstance } from '@/plugins/i18n';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';

export const createProjectChangeCreateEvent = (
  vars: WbsSectionEntity[],
  context?: ProjectChangeEventContext,
  milestonesToRestore?: string[],
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CreateWBSSections,
      input: vars,
      context,
    },
    restoredEntities: {
      milestones: milestonesToRestore || [],
    },
  };
};

export const createProjectChangeUpdateEvent = (
  partialWbsSection: PartialEntity<WbsSectionEntity>,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.UpdateWBSSection,
      input: partialWbsSection,
      context,
    },
  };
};

export const createProjectChangeRestoreEvent = (
  wbsSections: WbsSectionEntity[],
  entitiesToRestore: {
    orders: string[];
    milestones: string[];
  },
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RestoreScheduleNodes,
      input: {
        wbsSections: wbsSections.map((wbsSection) => wbsSection.id),
      },
      context,
    },
    restoredEntities: {
      wbsSections: wbsSections.map((wbsSection) => wbsSection.id),
      ...entitiesToRestore,
    },
  };
};

export const createProjectChangeDeleteEvent = (
  wbsSections: WbsSectionEntity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RemoveScheduleNodes,
      input: {
        wbsSections: wbsSections.map((wbsSection) => wbsSection.id),
      },
      context,
    },
  };
};

export const createProjectChangeIndentEvent = (
  vars: WbsSectionEntity,
  context?: ProjectChangeEventContext,
): { newSectionId: string; event: LocalProjectChangeEventTemplate } => {
  const newSectionId = toGlobalId(NodeName.SECTION, getRandomId());
  return {
    newSectionId,
    event: {
      messageId: generateRTCMessageId(),
      operation: {
        name: OperationNames.IndentWBSSection,
        input: {
          id: vars.id,
          newSectionId,
          name: i18nInstance.global.t('objects.section.placeHolderMidLevel'),
        },
        context,
      },
    },
  };
};

export const createProjectChangeOutdentEvent = (
  vars: WbsSectionEntity,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.OutdentWBSSection,
      input: {
        id: vars.id,
      },
      context,
    },
  };
};

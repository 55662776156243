export type TreeAbleEntity = { id: string; parentId: string | null; position: number };

export type TreeNode<Entity extends TreeAbleEntity> = Entity & {
  children: Entity[];
  indentation: number;
  originalEntity: Entity;
  siblings: Entity[];
};

export class OrderedTree<Entity extends TreeAbleEntity> extends Map<string, TreeNode<Entity>> {
  public rootEntities: Entity[] = [];

  public entityMap: Map<string, Entity> = new Map();

  public constructor(originalEntities: Entity[]) {
    super();
    this.setMultiple(originalEntities);
  }

  public get baseEntities(): Entity[] {
    return Array.from(this.entityMap.values());
  }

  public get sortedEntities(): Entity[] {
    const getSortedSubtree = (entities: Entity[]): Entity[] => {
      if (entities.length === 0) return [];
      const sortedSubtree: Entity[] = [];
      this.sortEntities(entities.slice()).forEach((entity) => {
        sortedSubtree.push(entity);
        sortedSubtree.push(...getSortedSubtree(this.get(entity.id)?.children ?? []));
      });

      return sortedSubtree;
    };

    return getSortedSubtree(this.rootEntities);
  }

  public setMultiple(entities: Entity[]) {
    entities.forEach((entity) => {
      this.entityMap.set(entity.id, entity);
    });
    this.calculateTreeMapAndRootEntities();
  }

  public update(entityUpdates: (Partial<Entity> & Pick<Entity, 'id'>)[]) {
    entityUpdates.forEach((entityUpdate) => {
      const existingEntity = this.entityMap.get(entityUpdate.id);
      if (!existingEntity) return;
      this.entityMap.set(entityUpdate.id, {
        ...existingEntity,
        ...entityUpdate,
      });
    });
    this.calculateTreeMapAndRootEntities();
  }

  public getNodeIndentation(entityId: string): number {
    const entity = this.get(entityId);
    return entity?.parentId ? this.getNodeIndentation(entity.parentId) + 1 : 0;
  }

  public getNodeSiblings(entityId: string): Entity[] {
    const entity = this.get(entityId);
    return entity?.parentId ? (this.get(entity.parentId)?.children ?? []) : this.rootEntities;
  }

  public getMaxIndentationForSubtree(entityId: string): number {
    const entity = this.get(entityId);
    if (!entity) return -1;
    const childrenIndentations = entity.children.map((child) =>
      this.getMaxIndentationForSubtree(child.id),
    );
    return Math.max(entity.indentation, ...childrenIndentations);
  }

  private calculateTreeMapAndRootEntities() {
    const entities = Array.from(this.entityMap.values());
    const rawRootEntities: Entity[] = [];
    const parentChildrenMap = new Map<string | null, Entity[]>();
    entities.forEach((entity) => {
      const parentId = entity.parentId;

      if (parentId === entity.id) throw new Error('Entity cannot be its own parent');

      if (parentId === null) {
        rawRootEntities.push(entity);
        return;
      }

      const maybeChildren = parentChildrenMap.get(parentId) ?? [];
      parentChildrenMap.set(parentId, [...maybeChildren, entity]);
    });

    this.rootEntities = this.sortEntities(rawRootEntities);
    entities.forEach((entity) => {
      this.set(entity.id, {
        ...entity,
        children: this.sortEntities(parentChildrenMap.get(entity.id) ?? []),
        indentation: 0,
        originalEntity: entity,
        siblings: [],
      });
    });
    entities.forEach((entity) => {
      const existingEntity = this.get(entity.id);
      if (!existingEntity) throw new Error('Unexpected entity not found');
      const indentation = this.getNodeIndentation(entity.id);
      this.set(entity.id, {
        ...existingEntity,
        indentation,
        siblings: this.getNodeSiblings(entity.id),
      });
    });
  }

  private sortEntities(entities: Entity[]): Entity[] {
    return entities.sort((a, b) => a.position - b.position);
  }
}

import { IntlDateTimeFormat } from 'vue-i18n';

export default {
  date: {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  } as IntlDateTimeFormat,
  dateTime: {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  } as IntlDateTimeFormat,
  dateWithWeekday: {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  } as IntlDateTimeFormat,
  dateShort: {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
  } as IntlDateTimeFormat,
  dateWDMY: {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  } as IntlDateTimeFormat,
  dateWDMNumericY: {
    weekday: 'long',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  } as IntlDateTimeFormat,
  dateDMY: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  } as IntlDateTimeFormat,
  dateDMYShortW: {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  } as IntlDateTimeFormat,
  dateShortDMYShortW: {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  } as IntlDateTimeFormat,
  dateDMLongY: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  } as IntlDateTimeFormat,
  dateMonthDMY: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  } as IntlDateTimeFormat,
  dateMonthShortDMY: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  } as IntlDateTimeFormat,
  dayMonth: {
    day: 'numeric',
    month: 'numeric',
  } as IntlDateTimeFormat,
  long: {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  } as IntlDateTimeFormat,
  long24: {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  } as unknown as IntlDateTimeFormat,
  longWithShortW: {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  } as IntlDateTimeFormat,
  monthShort: {
    month: 'short',
  } as IntlDateTimeFormat,
  monthShortYear: {
    month: 'short',
    year: 'numeric',
  } as IntlDateTimeFormat,
  monthLongYear: {
    month: 'long',
    year: 'numeric',
  } as IntlDateTimeFormat,
  time: {
    hour: '2-digit',
    minute: '2-digit',
  } as IntlDateTimeFormat,
  weekDay: {
    weekday: 'short',
  } as IntlDateTimeFormat,
  weekDayLong: {
    weekday: 'long',
  } as IntlDateTimeFormat,
  weekdayLongDateDigitsTimeHoursMinutes: {
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  } as IntlDateTimeFormat,
  // looks like: Mon., 01.01.2021, 12:00
  dayDateTime: <IntlDateTimeFormat>{
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
};

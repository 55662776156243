import {
  DependencyModel,
  DependencyStore,
  EventModel,
  Override,
  ResourceModel,
  SchedulerDependencyModel,
  TimeSpan,
} from '@bryntum/schedulerpro';

export class CustomEventModel extends EventModel {
  public static get fields(): Dictionary[] {
    // add new custom field to all fields of event model
    return [
      { name: 'calendarId', type: 'string', defaultValue: '' },
      { name: 'appearance', type: 'object', defaultValue: null },
      { name: 'isSelectable', type: 'boolean', defaultValue: false },
      { name: 'isFixed', type: 'boolean', defaultValue: false },
    ];
  }
}

export class CustomResourceModel extends ResourceModel {
  public static get fields(): Dictionary[] {
    // add new custom field to all fields of resource model
    return [
      { name: 'isTopLevelRow', type: 'boolean', defaultValue: false },
      { name: 'isMidLevelRow', type: 'boolean', defaultValue: false },
      { name: 'isBottomLevelRow', type: 'boolean', defaultValue: false },
      { name: 'sectionIndentation', type: 'number', defaultValue: 0 },
    ];
  }
}

export class CustomDependencyModel extends DependencyModel {
  public static get fields(): Dictionary[] {
    // add new custom field to all fields of dependency model
    return [{ name: 'fake', type: 'boolean', defaultValue: false }];
  }
}

/**
 * Extends the dependency store with custom functions
 */
export class CustomDependencyStore extends DependencyStore {
  public _isValidDependency:
    | ((
        dependencyOrFromId: SchedulerDependencyModel | TimeSpan | number | string,
        toId?: TimeSpan | number | string,
        type?: number,
      ) => boolean)
    | null = null;

  public injectCustomIsValidDependencyFunction(
    fn: (
      dependencyOrFromId: SchedulerDependencyModel | TimeSpan | number | string,
      toId?: TimeSpan | number | string,
      type?: number,
    ) => boolean,
  ): void {
    this._isValidDependency = fn;
  }
}

/**
 * Overrides existing functions of the dependency store with the custom ones,
 * but still calls the default logic. See: https://bryntum.com/docs/gantt/#Core/mixin/Override
 */
class CustomDependencyStoreOverride extends CustomDependencyStore {
  public static get target(): Dictionary {
    return {
      class: CustomDependencyStore,
    };
  }

  public isValidDependency(
    dependencyOrFromId: SchedulerDependencyModel | TimeSpan | number | string,
    toId?: TimeSpan | number | string,
    type?: number,
  ): Promise<boolean> {
    const result = (this as Dictionary)._overridden.isValidDependency.call(
      this,
      dependencyOrFromId,
      toId,
      type,
    );
    return Promise.resolve(
      this._isValidDependency
        ? this._isValidDependency(dependencyOrFromId, toId, type) && result
        : result,
    );
  }

  public isValidDependencyToCreate(
    dependencyOrFromId: SchedulerDependencyModel | TimeSpan | number | string,
    toId?: TimeSpan | number | string,
    type?: number,
  ): boolean {
    const result = (this as Dictionary)._overridden.isValidDependencyToCreate.call(
      this,
      dependencyOrFromId,
      toId,
      type,
    );
    if (!this._isValidDependency) return result;
    return this._isValidDependency(dependencyOrFromId, toId, type) && result;
  }
}
Override.apply(CustomDependencyStoreOverride);

import { ApolloQueryResult, OperationVariables } from '@apollo/client/core';
import {
  MutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationReturn,
  useQuery,
  UseQueryOptions,
  UseQueryReturn,
} from '@vue/apollo-composable';
import { DocumentNode } from 'graphql';
import { computed, isRef, Ref, ref, unref, watch } from 'vue';

import { useSkipExecution } from './helper';

interface CustomUseQueryOptions<TQueryResult, TQueryVariables extends OperationVariables>
  extends UseQueryOptions<TQueryResult, TQueryVariables> {
  skipOn?: (vars: TQueryVariables) => boolean;
}

interface CustomUseMutationOptions<TMutationResult, TMutationVariables>
  extends UseMutationOptions<TMutationResult, TMutationVariables> {
  skipOn?: (vars: TMutationVariables) => boolean;
}

/** Returns `useQuery` with default config for queries requesting a single entity by id */
export function useDefaultQueryOnEntity<
  TQueryResult,
  TQueryVariables extends { id: string | Ref<string> },
>(
  query: DocumentNode,
  variables: TQueryVariables | Ref<TQueryVariables>,
  options?: CustomUseQueryOptions<TQueryResult, TQueryVariables>,
): UseQueryReturn<TQueryResult, TQueryVariables> {
  const variablesCopy = ref(unref(variables)) as Ref<TQueryVariables>;
  /** update variables copy if variables change */
  watch(isRef(variables) ? variables : () => variables, (newVars) => {
    variablesCopy.value = newVars;
  });

  const { enabled, getRefetch } = useSkipExecution<
    TQueryVariables,
    ApolloQueryResult<TQueryResult>
  >(variablesCopy, { skipCondition: computed(() => !unref(variablesCopy.value.id)) });
  const useQueryReturn = useQuery<TQueryResult, TQueryVariables>(query, variablesCopy, {
    fetchPolicy: 'cache-and-network',
    enabled,
    ...options,
  });

  return {
    ...useQueryReturn,
    refetch: getRefetch(useQueryReturn.refetch),
  };
}

/** Returns `useMutation` with default config for mutations modifing a single already existing entity by id */
export function useDefaultMutationOnEntity<
  TMutationResult,
  TMutationVariables extends OperationVariables,
>(
  mutation: DocumentNode,
  variables: TMutationVariables | Ref<TMutationVariables>,
  options?: CustomUseMutationOptions<TMutationResult, TMutationVariables>,
): UseMutationReturn<TMutationResult, TMutationVariables> {
  const variablesCopy = computed(() => unref(variables));

  const useMutationReturn: UseMutationReturn<TMutationResult, TMutationVariables> = useMutation<
    TMutationResult,
    TMutationVariables
  >(mutation, () => ({
    variables: variablesCopy.value,
    ...options,
  }));
  const mutated: MutateFunction<TMutationResult, TMutationVariables> = useMutationReturn.mutate;

  return {
    ...useMutationReturn,
    mutate: mutated,
  };
}

/** Returns `useQuery` with default config for queries requesting multiple edges */
export function useDefaultQueryOnEdges<TQueryResult, TQueryVariables extends OperationVariables>(
  query: DocumentNode,
  variables: TQueryVariables | Ref<TQueryVariables>,
  options?: CustomUseQueryOptions<TQueryResult, TQueryVariables>,
): UseQueryReturn<TQueryResult, TQueryVariables> {
  const variablesCopy = ref(unref(variables)) as Ref<TQueryVariables>;
  /** update variables copy if variables change */
  watch(isRef(variables) ? variables : () => variables, (newVars) => {
    variablesCopy.value = newVars;
  });

  const { enabled, getRefetch } = useSkipExecution<
    TQueryVariables,
    ApolloQueryResult<TQueryResult>
  >(variablesCopy, {
    skipCondition: options?.skipOn
      ? computed(() => options.skipOn!(variablesCopy.value))
      : undefined,
  });

  const useQueryReturn = useQuery<TQueryResult, TQueryVariables>(query, variablesCopy, {
    fetchPolicy: 'cache-and-network',
    enabled,
    ...options,
  });

  return {
    ...useQueryReturn,
    refetch: getRefetch(useQueryReturn.refetch),
  };
}

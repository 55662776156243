import { FIREBASE_API_KEY } from '@/plugins/firebase';

import { IS_PROD_ENV } from './config';

// API KEYS
export const GOOGLE_MAPS_API_KEY = FIREBASE_API_KEY;
export const USERBACK_KEY = '34479|66441|n1zYN0Hozmg2pRg9aTDOudeFs';
export const SEGMENT_KEY = IS_PROD_ENV
  ? 'NUMs1Asia4vsJLcl9QgVqAfLSuvcqFmy'
  : 'WzPKpSm5TGpvgQb8pIMDBDYxCwqdgBgL';

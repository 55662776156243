import { defineStore } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

import { useGlobalStore } from '@/common/globalStore';
import {
  Entity,
  InteractiveProjectAlternativeStore,
  PartialEntity,
  ProjectAlternativeEntity,
} from '@/common/types';
import {
  DeleteProjectAlternativeVars,
  PublishProjectAlternativeVars,
} from '@/features/projectAlternatives/projectAlternativeTypes';
import {
  createProjectChangeDeleteProjectAlternativeEvent,
  createProjectChangePublishAlternativeEvent,
} from '@/features/projectAlternatives/store/projectAlternativeEvents';
import { useRTCController } from '@/features/realTimeCollaboration';
import {
  CreateProjectAlternativeInput,
  UpdateProjectAlternativeInput,
} from '@/graphql/__generated__/graphql';
import { useLoggingService } from '@/services/logging/composable';

import { ProjectAlternativeStoreRepository } from './projectAlternativeRepository';
import { checkIfAlternativeHasBeenDeleted } from './projectAlternativesUtils';

export type CreateProjectAlternativeVars = Omit<CreateProjectAlternativeInput, 'project'>;

export type UpdateProjectAlternativeVars = UpdateProjectAlternativeInput;

export const useProjectAlternativeStore = defineStore('project-alternative-store', () => {
  const router = useRouter();
  const route = useRoute();
  const globalStore = useGlobalStore();
  const repo = ref(new ProjectAlternativeStoreRepository());
  const fetchAllPromise = ref<Promise<ProjectAlternativeEntity[]> | null>(null);

  const isSetupPopupVisible = ref(false);
  const isInProjectPlanningMode = ref(false);
  const projectAlternatives = ref<Map<string, ProjectAlternativeEntity>>(new Map());

  const selectedAlternative = ref<ProjectAlternativeEntity | null>(null);

  watch(selectedAlternative, () => {
    globalStore.currentAlternativeProjectId = selectedAlternative.value?.projectQueryId ?? null;
  });

  watch(
    () => route.query.alternative,
    (value) => {
      // Exit planning mode when query param no longer set.
      // Can happen because of to back navigation.
      if (!value && isInProjectPlanningMode.value) {
        exitPlanningMode();
      }
    },
  );

  const removeAlternativeFromQuery = () => {
    const newQuery = { ...router.currentRoute.value.query };
    delete newQuery.alternative;
    router.replace({ query: newQuery });
  };

  const initialize = (projectId: string) => {
    fetchAllPromise.value = fetchAll(projectId).then((alternatives) => {
      const currentAlternativeId = route.query.alternative;
      if (typeof currentAlternativeId !== 'string') return alternatives;

      return enterPlanningMode(currentAlternativeId).then(() => alternatives);
    });

    return fetchAllPromise.value;
  };

  const fetchAll = async (projectId?: string) => {
    const project = projectId ?? globalStore.currentProjectId;
    if (!project) throw new Error('Cannot fetch alternatives without a project id');

    return repo.value.fetchAll({ project, orderBy: ['-createdAt'] }).then((alternatives) => {
      projectAlternatives.value = new Map(alternatives.map((a) => [a.id, a]));
      return alternatives;
    });
  };

  const pushAlternative = (alternative: Entity) => {
    projectAlternatives.value.set(alternative.id, alternative as ProjectAlternativeEntity);
  };

  const createAlternative = async (vars: CreateProjectAlternativeVars) => {
    if (!globalStore.currentProjectId) throw new Error('No current project id');

    const alternative = await repo.value.create({ ...vars, project: globalStore.currentProjectId });

    if (!alternative) throw new Error('Creating alternative failed');

    projectAlternatives.value.set(alternative.id, alternative);

    await enterPlanningMode(alternative.id);

    return alternative;
  };

  const duplicateAlternative = async (
    vars: CreateProjectAlternativeVars &
      Required<Pick<CreateProjectAlternativeVars, 'projectAlternative'>>,
  ) => {
    return createAlternative(vars);
  };

  const updateAlternative = async (vars: UpdateProjectAlternativeVars) => {
    const updated = await repo.value.update(vars);

    if (!updated) throw new Error('Updating alternative failed');

    projectAlternatives.value.set(updated.id, updated);
  };

  const publishAlternative = async (vars: PublishProjectAlternativeVars) => {
    const rtcController = useRTCController();

    return rtcController.pushLocalProjectChangeEvent(
      createProjectChangePublishAlternativeEvent(vars),
    );
  };

  const remove = (
    vars: DeleteProjectAlternativeVars,
    cb: (fallbackExists: boolean) => void = () => {},
  ) => {
    // We don't use real RTC architecture here, because the deletion currently doesn't work
    // correctly with optimistic responses
    const subscription = useRTCController().subscribeToRemoteProjectChangeEvents(async (event) => {
      if (!checkIfAlternativeHasBeenDeleted(event, vars.alternativeId)) return;

      subscription.unsubscribe();

      const deletedAlternativeIsSelectedAlternative =
        selectedAlternative.value?.id === vars.alternativeId;

      const remainingAlternatives = Array.from(projectAlternatives.value.values()).filter(
        (a) => a.id !== vars.alternativeId,
      );

      let fallbackExists = true;

      if (deletedAlternativeIsSelectedAlternative && remainingAlternatives.length > 0) {
        await enterPlanningMode(remainingAlternatives[0].id);
      }

      if (!remainingAlternatives.length) {
        fallbackExists = false;
        exitPlanningMode();
      }

      cb(fallbackExists);
    });
    return useRTCController().pushLocalProjectChangeEvent(
      createProjectChangeDeleteProjectAlternativeEvent(vars),
    );
  };

  const enterPlanningMode = async (id?: string) => {
    if (id) {
      await openAlternative(id);
      return;
    }

    await fetchAll();

    if (!projectAlternatives.value.size) {
      isSetupPopupVisible.value = true;
      return;
    }

    const alternativeId = Array.from(projectAlternatives.value.values())[0]?.id;
    if (!alternativeId) {
      return;
    }

    await openAlternative(alternativeId);
  };

  const openAlternative = async (id: string) => {
    const alternative = projectAlternatives.value.get(id) ?? null;

    selectedAlternative.value = alternative;

    if (!alternative) {
      exitPlanningMode();
      return;
    }

    const loggingService = useLoggingService();
    if (!isInProjectPlanningMode.value) {
      await loggingService.trackEvent(
        new loggingService.AnalyticEventCategories.ProjectAlternativeModeEntered(),
      );
    }
    isInProjectPlanningMode.value = true;

    await router.replace({ query: { alternative: alternative.id } });
  };

  const exitPlanningMode = () => {
    const loggingService = useLoggingService();
    if (isInProjectPlanningMode.value) {
      loggingService?.trackEvent(
        new loggingService.AnalyticEventCategories.ProjectAlternativeModeLeft(),
      );
    }
    isInProjectPlanningMode.value = false;
    selectedAlternative.value = null;
    removeAlternativeFromQuery();
  };

  const setRepo = (newRepo: ProjectAlternativeStoreRepository) => {
    repo.value = newRepo;
  };

  const interactiveProjectAlternativeStoreProperties: Omit<
    InteractiveProjectAlternativeStore,
    'projectAlternatives' | 'selectedAlternative'
  > = {
    applyChanges: (changes: {
      add?: ProjectAlternativeEntity[];
      update?: PartialEntity<ProjectAlternativeEntity>[];
      delete?: Entity[];
    }): void => {
      changes.update?.forEach((alternativeUpdate) => {
        const alternativeToUpdate = projectAlternatives.value.get(alternativeUpdate.id);
        if (!alternativeToUpdate) return;
        projectAlternatives.value.set(alternativeToUpdate.id, {
          ...alternativeToUpdate,
          ...alternativeUpdate,
        });
      });
      changes.delete?.forEach((projectAlternative) => {
        projectAlternatives.value.delete(projectAlternative.id);
      });
    },
    getSoftDeletedEntity: () => {
      throw new Error('project getSoftDeletedEntity not on RTC yet');
    },
    reset: () => {
      projectAlternatives.value = new Map();
      fetchAllPromise.value = null;
    },
    setState: (state?: Map<string, ProjectAlternativeEntity>) => {
      if (!state) {
        return;
      }
      projectAlternatives.value = new Map(state);
    },
    copyState: () => {
      return new Map(projectAlternatives.value);
    },
  };

  return {
    projectAlternatives,
    fetchAllPromise,
    initialize,
    createAlternative,
    pushAlternative,
    duplicateAlternative,
    updateAlternative,
    publishAlternative,
    delete: remove,
    selectedAlternative,
    isInProjectPlanningMode,
    isSetupPopupVisible,
    enterPlanningMode,
    exitPlanningMode,
    setRepo,
    ...interactiveProjectAlternativeStoreProperties,
  };
});

import { OrderStatus as SENGOrderStatus } from '@koppla-tech/scheduling-engine';
import { Composer } from 'vue-i18n';

import { OrderEntity } from '@/common/types';
import { OrderFragment, OrderStatusReportFragment } from '@/graphql/__generated__/graphql';
import { useUserName } from '@/helpers/users';

import { getHexFromCssVariable } from '../utils/colors';

export enum StatusReportReason {
  STAFF = 'STAFF',
  PRELIMINARY_WORK = 'PRELIMINARY_WORK',
  MATERIAL = 'MATERIAL',
  REWORK = 'REWORK',
  OTHER = 'OTHER',
}

export type OrderStatus =
  | 'GREEN'
  | 'YELLOW'
  | 'RED'
  | 'REPORTED_DONE' // Reported done by subcontractor
  | 'REJECTED' // Not accepted by general contractor
  | 'DONE'; // Accepted by general contractor

export enum StatusReport {
  SUCCESS = 'GREEN',
  DELAY = 'YELLOW',
  ERROR = 'RED',
  REPORTED_DONE = 'REPORTED_DONE',
  REJECTED = 'REJECTED',
  DONE = 'DONE',
  NOT_SET = 'NOT_SET',
}

export enum StatusReportName {
  SUCCESS = 'success',
  DELAY = 'delay',
  ERROR = 'error',
  REPORTED_DONE = 'reportedDone',
  REJECTED = 'rejected',
  DONE = 'done',
  NOT_SET = 'notSet',
}

export interface OrderStatusReport extends Omit<OrderStatusReportFragment, 'reason'> {
  reason: StatusReportReason | null;
}

export const statusToStatusReportMap = {
  GREEN: StatusReport.SUCCESS,
  YELLOW: StatusReport.DELAY,
  RED: StatusReport.ERROR,
  REPORTED_DONE: StatusReport.REPORTED_DONE,
  REJECTED: StatusReport.REJECTED,
  DONE: StatusReport.DONE,
};

const statusReportColor = {
  [StatusReport.SUCCESS]: 'green',
  [StatusReport.DELAY]: 'yellow',
  [StatusReport.ERROR]: 'red',
  [StatusReport.REPORTED_DONE]: 'reported-done',
  [StatusReport.REJECTED]: 'rejected',
  [StatusReport.DONE]: 'done',
  [StatusReport.NOT_SET]: 'not-set',
};

const statusToNameMap = {
  [StatusReport.SUCCESS]: StatusReportName.SUCCESS,
  [StatusReport.DELAY]: StatusReportName.DELAY,
  [StatusReport.ERROR]: StatusReportName.ERROR,
  [StatusReport.REPORTED_DONE]: StatusReportName.REPORTED_DONE,
  [StatusReport.REJECTED]: StatusReportName.REJECTED,
  [StatusReport.DONE]: StatusReportName.DONE,
  [StatusReport.NOT_SET]: StatusReportName.NOT_SET,
};

const statusReportReasonToi18nMap = {
  [StatusReportReason.STAFF]: 'staff',
  [StatusReportReason.PRELIMINARY_WORK]: 'preliminaryWork',
  [StatusReportReason.MATERIAL]: 'material',
  [StatusReportReason.REWORK]: 'rework',
  [StatusReportReason.OTHER]: 'other',
};

export function statusNotSet(status?: StatusReport | null): boolean {
  return (
    !status || status === StatusReport.NOT_SET || !Object.values(StatusReport).includes(status)
  );
}

export function convertOrderStatusToStatusReport(status: OrderStatus | null): StatusReport {
  switch (status) {
    case 'GREEN':
      return StatusReport.SUCCESS;
    case 'YELLOW':
      return StatusReport.DELAY;
    case 'RED':
      return StatusReport.ERROR;
    case 'REPORTED_DONE':
      return StatusReport.REPORTED_DONE;
    case 'REJECTED':
      return StatusReport.REJECTED;
    case 'DONE':
      return StatusReport.DONE;
    default:
      return StatusReport.NOT_SET;
  }
}

export function mapStatusReportToOrderStatus(report: StatusReport): OrderStatus | null {
  // Ignore default case to cause error when new enum value is added

  switch (report) {
    case StatusReport.ERROR:
      return 'RED';
    case StatusReport.DELAY:
      return 'YELLOW';
    case StatusReport.SUCCESS:
      return 'GREEN';
    case StatusReport.DONE:
      return 'DONE';
    case StatusReport.REJECTED:
      return 'REJECTED';
    case StatusReport.REPORTED_DONE:
      return 'REPORTED_DONE';
  }

  return null;
}

export function mapDomainToSENGOrderStatus(status: OrderStatus | null): SENGOrderStatus | null {
  if (!status) return null;

  // Ignore default case to cause error when new enum value is added
  switch (status) {
    case StatusReport.ERROR:
      return SENGOrderStatus.ERROR;
    case StatusReport.DELAY:
      return SENGOrderStatus.DELAY;
    case StatusReport.SUCCESS:
      return SENGOrderStatus.SUCCESS;
    case StatusReport.DONE:
      return SENGOrderStatus.DONE;
    case StatusReport.REJECTED:
      return SENGOrderStatus.REJECTED;
    case StatusReport.REPORTED_DONE:
      return SENGOrderStatus.REPORTED_DONE;
  }

  return null;
}

export function getStatusReportOfStatus(status: string): StatusReport {
  const statusReport = statusToStatusReportMap[status as never] as StatusReport | undefined;
  return statusReport ?? StatusReport.NOT_SET;
}

export const getStatusReportColor = (status?: StatusReport | null): string => {
  if (!status || statusNotSet(status)) return statusReportColor[StatusReport.NOT_SET];
  return statusReportColor[status];
};

export const getStyledStatusReportColor = (
  status?: StatusReport | null,
  lightColor?: boolean,
): string => {
  const color = getStatusReportColor(status);
  return getHexFromCssVariable(`--color-order-status-${color}${lightColor ? '-light' : ''}`);
};

export interface UseStatusReport {
  /** Returns the status color */
  getStatusReportColor: (status?: StatusReport | null) => string;
  /** Returns the status colored styled according to css variables */
  getStyledStatusReportColor: (status?: StatusReport | null, lightColor?: boolean) => string;
  /** Return the status name not translated */
  getUntranslatedStatusReportName: (status?: StatusReport | null) => string;
  /** Returns the status name */
  getStatusReportName: (status?: StatusReport | null) => string;
  /** Returns the status explanation */
  getStatusReportExplanation: (status?: StatusReport | null) => string;
  /** Returns all available statuses */
  statusReportOptions: StatusReport[];
  /** Returns the description of a given status report reason */
  getStatusReportReasonDescription: (statusReport: OrderStatusReport) => string;
  getStatusReportAuthor: (statusReport: OrderStatusReport) => string;
  getStatusReportReasonSimpleDescription: (
    statusReport: Pick<OrderStatusReport, 'reason'>,
  ) => string;
}

/**
 * Returns indicator if order is immutable based on status
 * @param order
 * @param considerFixation - Indicator, if fixed state should be omitted when determining immutable state
 * @returns
 */
export function orderIsImmutable(
  order: OrderFragment | OrderEntity,
  considerFixation = true,
): boolean {
  return (
    !!order &&
    ((considerFixation && order.isFixed) ||
      [StatusReport.DONE, StatusReport.REPORTED_DONE].includes(order.status as StatusReport))
  );
}

export function orderIsDoneOrReportedDone(order: OrderFragment | OrderEntity): boolean {
  return order.status === StatusReport.DONE || order.status === StatusReport.REPORTED_DONE;
}

export function useStatusReport({ t }: Composer): UseStatusReport {
  /** Converts status number to the native name of status (untranslated) */
  const getUntranslatedStatusReportName = (status?: StatusReport | null): string => {
    if (!status) return StatusReportName.NOT_SET;
    return statusToNameMap[status];
  };

  /** Converts status number to name of status */
  const getStatusReportName = (status?: StatusReport | null): string => {
    const i18nKey = 'objects.leanProject.statusReport.status';

    if (!status || statusNotSet(status)) {
      return t(`${i18nKey}.${StatusReportName.NOT_SET}`).toString();
    }

    return t(`${i18nKey}.${statusToNameMap[status]}`).toString();
  };

  /** Converts status number to status explanation */
  const getStatusReportExplanation = (status?: StatusReport | null): string => {
    const i18nKey = 'objects.leanProject.statusReport.explanation';

    if (!status || statusNotSet(status)) {
      return t(`${i18nKey}.${StatusReportName.NOT_SET}`).toString();
    }

    return t(`${i18nKey}.${statusToNameMap[status]}`).toString();
  };

  /** Returns the description for a status report's reason */
  const getStatusReportReasonSimpleDescription = (
    statusReport: Pick<OrderStatusReport, 'reason'> | null,
  ): string => {
    if (!statusReport?.reason) return '';
    return t(
      `objects.leanProject.statusReport.reason.${statusReportReasonToi18nMap[statusReport.reason]}`,
    ).toString();
  };

  /** Returns the description for a status report's reason or the comment */
  const getStatusReportReasonDescription = (statusReport: OrderStatusReport | null): string => {
    if (
      !statusReport?.reason ||
      ![StatusReport.DELAY, StatusReport.ERROR].includes(statusReport.status as StatusReport)
    ) {
      return statusReport?.comment ?? '';
    }

    return t('objects.leanProject.statusReport.reason.description', {
      reason: getStatusReportReasonSimpleDescription(statusReport),
    }).toString();
  };

  const getStatusReportAuthor = (statusReport: OrderStatusReport | null): string => {
    const creatorFragments = [
      useUserName(statusReport?.createdBy),
      statusReport?.createdByContributorGroup?.name,
    ];
    return creatorFragments.filter(Boolean).join(', ');
  };

  return {
    getStatusReportColor,
    getStyledStatusReportColor,
    getUntranslatedStatusReportName,
    getStatusReportName,
    getStatusReportExplanation,
    getStatusReportReasonSimpleDescription,
    getStatusReportReasonDescription,
    getStatusReportAuthor,
    statusReportOptions: [
      StatusReport.NOT_SET,
      StatusReport.SUCCESS,
      StatusReport.DELAY,
      StatusReport.ERROR,
      StatusReport.REPORTED_DONE,
      StatusReport.REJECTED,
      StatusReport.DONE,
    ],
  };
}

import { defineStore } from 'pinia';

import {
  OwnTenantFragment,
  TenantQueryVariables,
  UpdateTenantMutationVariables,
} from '@/graphql/__generated__/graphql';

import { TenantStoreRepository } from './repository';

export const useTenantStore = (repository?: TenantStoreRepository) => {
  const repo = repository ?? new TenantStoreRepository();
  return defineStore('tenant-store', () => {
    const ownTenant = ref<OwnTenantFragment | null>(null);
    const loading = ref(false);

    const fetchOwn = async (vars: TenantQueryVariables) => {
      loading.value = true;
      return repo
        .fetchOwn(vars)
        .then((result) => {
          ownTenant.value = result;
          return ownTenant.value;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const update = async (vars: UpdateTenantMutationVariables) => {
      return repo.update(vars).then((result) => {
        if (result) {
          ownTenant.value = result;
        }
        return ownTenant.value;
      });
    };

    return { ownTenant, fetchOwn, update, loading };
  })();
};

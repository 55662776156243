import { gql } from '@/graphql/__generated__';

export const sectionsQuery = gql(`
    query getWBSSections($project: ID!) {
    wbsSections(project: $project) {
      id
      name
      position
      parent {
        id
      }
    }
  }
 `);

import { v4 as uuidv4 } from 'uuid';

/**
 * Capitalize first letter of text
 * @param text
 * @returns text
 */
export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
/** Returns random uuid v4, that can be used as an client-side object id */
export function getRandomId(): string {
  return uuidv4();
}

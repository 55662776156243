import { isEscape } from '@/helpers/utils/os';

interface Closable {
  close: () => void;
  persist?: boolean;
}
const closables = ref<Closable[]>([]);
let isListening = false;

export function useClosableStack() {
  function manageClosableLifecycle(closable: Closable) {
    onMounted(() => {
      pushClosable(closable);
    });
    onBeforeUnmount(() => {
      removeClosable(closable);
    });
  }

  function pushClosable(closable: Closable) {
    closables.value.push(closable);
    ensureListening();
  }

  function removeClosable(closable: Closable) {
    const index = closables.value.indexOf(closable);
    if (index !== -1) {
      closables.value.splice(index, 1);
    }
    if (closables.value.length === 0) {
      removeListeners();
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (isEscape(event) && closables.value.length > 0) {
      event.preventDefault();
      event.stopPropagation();
      const closable = closables.value[closables.value.length - 1];
      closable.close();
      if (!closable.persist) {
        removeClosable(closable);
      }
    }
  }

  function ensureListening() {
    if (isListening) {
      return;
    }
    isListening = true;
    window.addEventListener('keydown', handleKeyDown);
  }

  function removeListeners() {
    if (!isListening) {
      return;
    }
    isListening = false;
    window.removeEventListener('keydown', handleKeyDown);
  }

  return { pushClosable, removeClosable, manageClosableLifecycle };
}

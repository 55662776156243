export type ProjectUser = {
  id: string;
  firstName: string;
  lastName: string;
  joinedAt: string;
  thumbnailUrl: string | null;
  tenant: {
    id: string;
    name: string;
  };
  tenantId: number | string | null;
  tenantName: string;
  email: string | undefined | null;
};

export type UserWithName = Partial<{
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}>;

export function useUserName(user: UserWithName | undefined | null): string {
  if (!user) return '';
  const { firstName, lastName, email } = user;
  if (!firstName && !lastName) return `${email}`;
  return `${firstName || ''} ${lastName || ''}`;
}

export function parseAvatarPlaceholder(user: UserWithName | undefined | null): string {
  return `${user?.firstName?.charAt(0)?.toUpperCase() || ''}${user?.lastName?.charAt(0)?.toUpperCase() || ''}`;
}

export function getUserColor(user: { id: string } | undefined): string {
  const usersColors = [
    '#287B8E',
    '#32747E',
    '#394185',
    '#3A7E3D',
    '#5C33A1',
    '#718521',
    '#923277',
    '#AA601B',
    '#AA7309',
    '#B23F26',
  ];

  const hash = getUserIdHash(user?.id || '');
  const colorIndex = Math.abs(hash) % usersColors.length;
  return usersColors[colorIndex];
}

/**
 * Generates a hash value for a given user ID.
 *
 * This function uses a simple hashing algorithm to convert a user ID into a number.
 * It iterates over each character in the user ID, converts it to a character code,
 * and then updates the hash value by multiplying the current hash by 31 and adding the character code.
 * It also ensures that the hash stays within the range of a 32-bit integer.
 */
export function getUserIdHash(userId: string): number {
  let hash = 0;
  for (let i = 0; i < userId.length; i++) {
    const char = userId.charCodeAt(i);
    hash = Math.imul(hash, 31) + char;
    hash -= Math.floor(hash / 2147483648) * 2147483648; // Keep it within 32-bit integer range
  }
  return hash;
}

export function getUserInitials(user: UserWithName) {
  if (user.firstName && user.lastName) {
    return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
  }
  if (user.email) {
    // Fallback to email initials. Should only happen in rare cases, like tech-support user.
    return user.email.substring(0, 2).toUpperCase();
  }
  return '';
}

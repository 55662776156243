import { OrderDependencyEntity, OrderEntity, PartialEntity } from '@/common/types';
import { pickKeys } from '@/helpers/utils/objects';

import { generateUndoRedoCommitId, UndoRedoActionNames, UndoRedoCommit } from '../undoRedo/types';

export const createUndoRedoCreateCommit = (vars: OrderEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.CreateOrders,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteOrders,
      data: vars,
    },
  };
};

export const createUndoRedoUpdateCommit = (
  vars: PartialEntity<OrderEntity>[],
  orders: Map<string, OrderEntity>,
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.UpdateOrders,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.UpdateOrders,
      data: vars.map((partialOrder) => {
        const existingOrder = orders.get(partialOrder.id);
        if (!existingOrder) {
          throw new Error(`Order with id ${partialOrder.id} not found`);
        }
        return pickKeys(existingOrder, Object.keys(partialOrder) as (keyof OrderEntity)[]);
      }),
    },
  };
};

export const createUndoRedoRestoreCommit = (vars: OrderEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RestoreOrders,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.DeleteOrders,
      data: vars,
    },
  };
};

export const createUndoRedoDeleteCommit = (vars: OrderEntity[]): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.DeleteOrders,
      data: vars,
    },
    inverseAction: {
      name: UndoRedoActionNames.RestoreOrders,
      data: vars,
    },
  };
};

export const createUndoRedoCopyCommit = (
  orders: OrderEntity[],
  dependencies: OrderDependencyEntity[],
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.CopyOrders,
      data: { orders, dependencies },
    },
    inverseAction: {
      name: UndoRedoActionNames.RevertCopyOrders,
      data: { orders, dependencies },
    },
  };
};

export const createUndoRedoRevertCopyCommit = (
  orders: OrderEntity[],
  dependencies: OrderDependencyEntity[],
): UndoRedoCommit => {
  return {
    id: generateUndoRedoCommitId(),
    action: {
      name: UndoRedoActionNames.RevertCopyOrders,
      data: { orders, dependencies },
    },
    inverseAction: {
      name: UndoRedoActionNames.CopyOrders,
      data: { orders, dependencies },
    },
  };
};

export enum Language {
  DE = 'de',
  EN = 'en',
}

const readableLanguages = {
  [Language.DE]: 'Deutsch',
  [Language.EN]: 'English',
};

export const languages = [
  { value: Language.DE, title: readableLanguages[Language.DE] },
  {
    value: Language.EN,
    title: readableLanguages[Language.EN],
  },
];
export const fallbackLanguage = Language.EN;

/**
 * Returns language of the browser
 */
export function getBrowserLanguage(): Language {
  // Extract primary language tag. See: https://datatracker.ietf.org/doc/html/rfc5646#page-4
  const [browserLanguage] = navigator.language.split('-');
  return (
    languages.find((language) => language.value === browserLanguage)?.value ?? fallbackLanguage
  );
}

import { CalendarEntity, Entity, PartialEntity } from '@/common/types';
import { OperationInputType } from '@/features/realTimeCollaboration/types';
import { deleteUndefinedValues } from '@/helpers/utils/objects';

import { findDefaultCalendar, toCalendarEntity } from './calendarUtils';

export function convertCalendarCreateOperationInput(
  input: OperationInputType<'CreateCalendars'>,
  state: Map<string, CalendarEntity>,
): {
  addedCalendars: CalendarEntity[];
  updatedCalendars: PartialEntity<CalendarEntity>[];
} {
  const { currentDefaultCalendar, unassignDefaultCalendar } = needsDefaultCalendarUnassignment(
    input,
    state,
  );
  return {
    addedCalendars: input.map(toCalendarEntity),
    updatedCalendars: unassignDefaultCalendar
      ? [{ id: currentDefaultCalendar.id, isDefault: false }]
      : [],
  };
}

export function convertCalendarUpdateOperationInput(
  input: OperationInputType<'UpdateCalendars'>,
  state: Map<string, CalendarEntity>,
): PartialEntity<CalendarEntity>[] {
  const { currentDefaultCalendar, unassignDefaultCalendar } = needsDefaultCalendarUnassignment(
    input,
    state,
  );
  const updatedCalendars = input.map(
    (calendar) =>
      deleteUndefinedValues({
        id: calendar.id,
        isDefault: calendar.isDefault ?? undefined,
        name: calendar.name ?? undefined,
        workingDays: calendar.workingDays ?? undefined,
        exceptions: calendar.exceptions
          ? calendar.exceptions.map((e) => ({
              ...e,
              startAt: new Date(e.startAt),
              finishAt: new Date(e.finishAt),
              workingTimes: e.workingTimes,
            }))
          : undefined,
      }) as PartialEntity<CalendarEntity>,
  );

  if (unassignDefaultCalendar) {
    updatedCalendars.push({ id: currentDefaultCalendar.id, isDefault: false });
  }

  return updatedCalendars;
}

export function convertCalendarDeleteOperationInput(
  input: OperationInputType<'RemoveScheduleNodes'>,
): Entity[] {
  return input.calendars?.map((id) => ({ id })) ?? [];
}

function needsDefaultCalendarUnassignment(
  input: OperationInputType<'UpdateCalendars'> | OperationInputType<'CreateCalendars'>,
  state: Map<string, CalendarEntity>,
) {
  const currentDefaultCalendar = findDefaultCalendar(state);
  const unassignDefaultCalendar = input.some(
    (calendar) => calendar.isDefault === true && calendar.id !== currentDefaultCalendar.id,
  );

  return { currentDefaultCalendar, unassignDefaultCalendar };
}

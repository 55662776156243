import { StatusReport } from '@/helpers/orders/status';
import { OrderCreationMethod } from '@/services/store/schedule/types';
import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

/** A tracked event for an reported order status */
export class OrderStatusReported extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDER_STATUS_REPORTED;

  public properties: {
    method: 'details' | 'keyboard' | 'toolbar';
    status: string;
    count: number;
  };

  /** We cant use the normal notation with the properties directly in the construction as we need to modify the provided string */
  public constructor(event: {
    status: StatusReport;
    method: 'details' | 'keyboard' | 'toolbar';
    count: number;
  }) {
    super();
    this.properties = {
      ...event,
      status: event.status.toLowerCase(),
    };
  }
}

export class OrderCreatedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDER_CREATED;

  public constructor(public properties: { source: OrderCreationMethod }) {
    super();
  }
}

export class OrderOpenedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDER_OPENED;

  public constructor(
    public properties: {
      source: 'schedule' | 'related_order';
    },
  ) {
    super();
  }
}

export class OrdersEditedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDERS_EDITED;

  public constructor(
    public properties: {
      method: 'details' | 'drag_and_drop' | 'resize' | 'keyboard' | 'toolbar';
      count: number;
      tasksWereUpdated: boolean;
    },
  ) {
    super();
  }
}

export class OrdersMovedOutsideWorkingTimesEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDERS_MOVED_OUTSIDE_WORKING_TIMES;

  public constructor(
    public properties: {
      canAddException: boolean;
    },
  ) {
    super();
  }
}

export class WorkScheduleExceptionPopupClosedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.WORK_SCHEDULE_EXCEPTION_POPUP_CLOSED;

  public constructor(
    public properties: {
      method: 'individual' | 'all' | 'cancelled';
    },
  ) {
    super();
  }
}

export class OrdersDeletedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDERS_DELETED;

  public constructor(
    public properties: {
      method: 'details' | 'keyboard' | 'toolbar';
      count: number;
    },
  ) {
    super();
  }
}

export class OrdersCutEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDERS_CUT;

  public constructor(public properties: { method: 'keyboard' | 'toolbar'; count: number }) {
    super();
  }
}

export class OrdersCopiedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDERS_COPIED;

  public constructor(public properties: { method: 'keyboard' | 'toolbar'; count: number }) {
    super();
  }
}

export class OrdersPastedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDERS_PASTED;

  public constructor(public properties: { method: 'keyboard' | 'right_click'; count: number }) {
    super();
  }
}

export class OrderPhotosUploadedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDER_PHOTOS_UPLOADED;

  public constructor(public properties: { count: number }) {
    super();
  }
}

export class OrderPhotosDownloadedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDER_PHOTOS_DOWNLOADED;

  public constructor(public properties: { photosDownloaded: 'one' }) {
    super();
  }
}

export class OrderDocumentsUploadedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDER_DOCUMENTS_UPLOADED;

  public constructor(public properties: { count: number }) {
    super();
  }
}

export class OrderDocumentViewedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.ORDER_DOCUMENT_VIEWED;
}

export class OrderPhotoOpenedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PHOTO_DETAILS_OPENED;

  public constructor(public properties: { source: 'order_details' }) {
    super();
  }
}

import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const displayableTenantMembershipsQuery = gqlFn(/* GraphQL */ `
  query DisplayableTenantMemberships($tenant: ID!, $orderBy: [String!]) {
    memberships(tenant: $tenant, orderBy: $orderBy) {
      edges {
        node {
          ...DisplayableTenantMembership
        }
      }
    }
  }
`);

export const tenantMembershipFragment = gql`
  fragment DisplayableTenantMembership on MembershipNode {
    ...Membership
    user {
      id
      email
      firstName
      lastName
      mobileNumber
      isActive
      profilePicture {
        ...UserProfilePicture
      }
    }
  }
`;

import { User, UserCredential } from 'firebase/auth';
import { useI18n } from 'vue-i18n';

import { SignUpConfiguration } from '../repositories/users';

export type MicrosoftAccountDetails = {
  firstName: string;
  lastName: string;
};

export class InvalidNewUserError extends Error {
  public constructor(public email: string) {
    super('Cannot create new user.');
    Object.defineProperty(this, 'name', { value: 'InvalidNewUserError' });
  }
}

export class EmailOfAccountsNotMatchingError extends Error {
  public constructor(public email: string) {
    super(`Cannot use email ${email} to link as it doesn't match current account email.`);
    Object.defineProperty(this, 'name', { value: 'EmailOfAccountsNotMatchingError' });
  }
}

export class IllegalEmailDomainError extends Error {
  public constructor(
    public email: string,
    public domain: string,
  ) {
    super(`Cannot use email ${email} because ${domain} domain is required.`);
    Object.defineProperty(this, 'name', { value: 'IllegalEmailDomainError' });
  }
}

export class MicrosoftFlowCanceledError extends Error {
  public constructor() {
    super('Microsoft sign up process canceled.');
    Object.defineProperty(this, 'name', { value: 'MicrosoftFlowCanceledError' });
  }
}

export class AccountNotLinkedToMicrosoftError extends Error {
  public constructor() {
    super("Email belongs to existing account that's not linked to Microsoft.");
    Object.defineProperty(this, 'name', { value: 'AccountNotLinkedToMicrosoftError' });
  }
}

export class ResetPasswordMicrosoftAccountError extends Error {
  public constructor() {
    super("Cannot reset password for account that doesn't use password provider.");
    Object.defineProperty(this, 'name', { value: 'ResetPasswordMicrosoftAccountError' });
  }
}

export class ResetPasswordNoAccountError extends Error {
  public constructor() {
    super("Cannot reset password for account that doesn't exist.");
    Object.defineProperty(this, 'name', { value: 'ResetPasswordNoAccountError' });
  }
}

export function translateAuthenticationErrorMessage(error: Error): string {
  const { t } = useI18n();
  if (error instanceof EmailOfAccountsNotMatchingError) {
    return t('MicrosoftSSO.emailsNotMatchingErrorMessage', { email: error.email });
  }

  if (error instanceof MicrosoftFlowCanceledError) {
    return t('MicrosoftSSO.flowCanceledErrorMessage');
  }

  if (error instanceof InvalidNewUserError) {
    return t('MicrosoftSSO.unknownUserError', { email: error.email });
  }

  if (error instanceof AccountNotLinkedToMicrosoftError) {
    return t('MicrosoftSSO.signInAccountNotLinkedError');
  }

  if (error instanceof IllegalEmailDomainError) {
    return t('MicrosoftSSO.illegalEmailDomainError', { domain: error.domain });
  }

  if (error instanceof ResetPasswordMicrosoftAccountError) {
    return t('ResetPassword.microsoftAccountError');
  }

  if (error instanceof ResetPasswordNoAccountError) {
    return t('ResetPassword.noAccountError');
  }

  return t('errors.default');
}

export interface AuthenticationService {
  /** Performs login of user with email and password */
  login(email: string, password: string): Promise<UserCredential>;

  /** Performs logout */
  logout(): Promise<void>;

  /** Returns indicator, if user is authenticated */
  isAuthenticated(): Promise<boolean>;

  /** Starts email verification process of user */
  verifyEmail(): Promise<void>;

  /** Returns indicator, if the user has a verified email */
  isVerified(): Promise<boolean>;

  /** Sets email of user as verified */
  setAsVerified(code: string): Promise<void>;

  /** Returns data of the current user */
  getUser(): Promise<User | null>;

  /** Returns current ID Token */
  getIdToken(refresh?: boolean): Promise<string> | undefined;

  /** Retuns indicator, if password reset code is valid */
  verifyPasswordResetCode(code: string): Promise<string>;

  /** Sets new password of user */
  setPassword(code: string, password: string): Promise<void>;

  /** Starts password reset process of user */
  resetPassword(email: string): Promise<void>;

  /** Returns a promise that will complete when the user is authenticated */
  waitForAuthentication(): Promise<void>;

  /** MICROSOFT START */

  /**
   * Logs in with Microsoft SSO.
   * Throws [MicrosoftFlowCanceledError] is user cancels flow.
   * Throws [InvalidNewUserError] is uninvited user signs in.
   * Throws [AccountNotLinkedToMicrosoftError] if account with email exists
   * but isn't linked to Microsoft.
   */
  loginWithMicrosoft(): Promise<void>;

  /**
   * Links account of currently authenticated user
   * with Microsoft provider.
   * Returns true if account was linked successfully or is already linked.
   * Throws [MicrosoftFlowCanceledError] is user cancels flow.
   * Throws [EmailOfAccountsNotMatchingError] if Microsoft account email doesn't match
   * the koppla mail.
   */
  linkAccountWithMicrosoft(): Promise<boolean>;

  isLinkedToMicrosoft(): boolean;

  createAccountWithMicrosoft(
    email: string,
    config: SignUpConfiguration,
  ): Promise<MicrosoftAccountDetails>;

  /** MICROSOFT END */
}

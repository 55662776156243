import { Mutex } from 'async-mutex';

import { CoreRTCController, RTCControllerProjectContext } from './core';

/**
 * RTCController is a class that manages the real-time collaboration
 * for a single project. It ensures that only one project is initialized
 * at a time and that the initialization process is not interrupted by
 * another project's initialization.
 * The main business logic is implemented in the CoreRTCController class.
 */
export class RTCController extends CoreRTCController {
  private initializationMutex: Mutex = new Mutex();

  private latestEnqueuedProjectId: string | undefined;

  private destroyPromise: Promise<void> | undefined;

  public async initialize(context: RTCControllerProjectContext): Promise<void> {
    this.latestEnqueuedProjectId = context.projectId;
    // Changing the line below to `const release = () => {};` should cause concurrency tests to fail
    const release = await this.initializationMutex.acquire();

    try {
      /**
       * Initialize has been called for another project while acquiring mutex.
       * We skip the init logic for this project as it would immediately
       * be destroyed on the following initialize.
       */
      if (this.latestEnqueuedProjectId !== context.projectId) {
        return;
      }

      if (this.context?.projectId === context.projectId && this.isInitialized.value) {
        return;
      }
      await super.initialize(context);
    } catch {
    } finally {
      release();
    }
  }

  public async destroy(): Promise<void> {
    if (this.destroyPromise) return this.destroyPromise;

    const doDestroy = async () => {
      const release = await this.initializationMutex.acquire();
      await this.reset();
      release();
    };

    const destroyPromise = doDestroy().finally(() => {
      this.destroyPromise = undefined;
    });
    this.destroyPromise = destroyPromise;

    return destroyPromise;
  }
}

import { RTCController } from './controller';

let controller: RTCController;

export function useRTCController(): RTCController {
  return controller;
}

export function installRTCController(newController: RTCController) {
  controller = newController;
}
